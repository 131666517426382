import React from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export const TaskDataDialog =(props)=> {

    const aColumns =[
        {label: "TaskID", value:"id"},
        {label: "TaskName", value:"name"},
        {label: "StartDate", value:"start"},
        {label: "EndDate", value:"end"},
        {label: "Dependencies", value:"dependencies"}
    ]

    /** Update the Input data
     * 
     * @param {*} oEvent 
     */
    const handleChangeEvent =(oEvent)=> {
        const { value,name } = oEvent.target;
        const oUpdatedRows = props.oData.TaskData;
        oUpdatedRows[name] = value;
        props.updateData({ ...props.oData, TaskData: oUpdatedRows });
    }

    var aTempSelectControl = [];
    if (props.inputFields) {
      for (var i = 0; i < aColumns.length; i++) {
        aTempSelectControl.push(
          <div id={"idField" + i}>
            <br></br>
            <TextField
              select
              id={i}
              label={aColumns[i].label}
              sx={{ minWidth: 450 }}
              onChange={(oEvent) => {
                handleChangeEvent(oEvent);
              }}
              name={aColumns[i].value}
              defaultValue={
                props.oData ? props.oData.TaskData[aColumns[i].value] : ""
              }
            >
              {props.inputFields.map((oField) => (
                <MenuItem key={oField} name={oField} value={oField}>
                  {oField}
                </MenuItem>
              ))}
            </TextField>
          </div>
        );
      }
    }
    return (
      <>
        {aTempSelectControl} <br/>
        <TextField
            label = "Title"
            name = "Title"
            defaultValue={
                props.oData ? props.oData.TaskData.Title : ""
            }
            sx={{ minWidth: 450 }}
            onChange={(oEvent) => {
                handleChangeEvent(oEvent);
            }}
        /> <br/><br/>
        <TextField
            label = "BarValue"
            name = "BarValue"
            defaultValue={
                props.oData ? props.oData.TaskData.BarValue : ""
            }
            sx={{ minWidth: 450 }}
            onChange={(oEvent) => {
                handleChangeEvent(oEvent);
            }}
        />
      </>
    );
}