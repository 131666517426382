import React, { useState, useEffect } from "react";
import Server from "../../../../serverDetails/server";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { TextField } from "@mui/material";

export const CreateDB = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  const [sNewCollectionName, setNewCollectionName] = useState();
  const [sErrorMessage, setErrorMessage] = useState("");
  const [bOpenSnackBar, setOpenSnackBar] = useState(false);
  const [iTabValue, setTabValue] = useState(0);
  const [jsonData, setJsonData] = useState("");
  const [aSchemaArray, setSchemaArray] = useState([]);

  useEffect(() => {
    async function handledbcall() {
      if (props.collection) {
        const oData = await fetchDataBaseList(props.collection);
        setJsonData(oData[0].fields);
      }
    }
    handledbcall();
  }, [props.open]);

  useEffect(() => {
    async function handledbcopy() {
      if (props.data) {
        setJsonData(props.data);
      }
    }
    handledbcopy();
  }, [props.open]);

  function handleIncreaseRow() {
    const updatedRows = [...aSchemaArray];
    updatedRows.push({
      Field: "",
      Type: "",
    });
    setSchemaArray(updatedRows);
    setSelectedRows([...aSelectedRows, false]);
  }

  function handleRemoveRow() {
    const updatedRows = aSchemaArray.filter(
      (_, index) => !aSelectedRows[index]
    );
    setSchemaArray(updatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...aSelectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  const handleEvent = (event, index, sKey) => {
    var value;
    value = event.target.value;
    const updatedRows = [...aSchemaArray];
    updatedRows[index][sKey] = value;
    setSchemaArray(updatedRows);
  };

  const handleOnChangeEvent = (oEvent) => {
    try {
      const parsedJson = JSON.parse(oEvent.target.value);
      setJsonData(parsedJson);
    } catch (error) {
      console.error("Invalid JSON:", error.message);
    }
  };

  const handleSubmit = async () => {
    const oTransformedJson = aSchemaArray.reduce((result, item) => {
      const { Field, Type } = item;
      result[Field] = { type: Type };
      return result;
    }, {});

    if (aSchemaArray.length > 0) {
      setJsonData(oTransformedJson);
    }

    const oPostData = {
      TABLE_NAME: "tablenames",
      PROPERTIES: {
        key: ["tableName"],
        autoGenerate: "",
        pattern: "",
        ItemKey: [],
        items: [],
        calculation: [],
      },
      DATA: [
        {
          tableName: sNewCollectionName,
          fields: aSchemaArray.length > 0 ? oTransformedJson : jsonData,
        },
      ],
      AllSchema: {
        tableName: { type: "String" },
        fields: { type: "Object" },
      },
      Update: false,
    };

    try {
      const oResponse = await Server.post("/logics/db/insert", oPostData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      if (oResponse.status === 201) {
        setErrorMessage("Collection Created");
      } else {
        setErrorMessage(oResponse.data.message);
      }
      setNewCollectionName("");
      props.close();
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const fetchDataBaseList = async (sCollectionsName) => {
    setNewCollectionName(sCollectionsName);
    var result = [];
    var oPostData = {
      collections: [
        {
          collectionName: "tablenames",
          selectionFields: ["tableName", "fields"],
          selectSingle: "",
        },
      ],
      joinCondition: [
        {
          condition: "",
          fields: [
            {
              firstTableField: "",
              secondTableField: "",
            },
          ],
          firstTable: "",
          secondTable: "",
          joinName: "",
        },
      ],
      WHERE: [
        {
          field: "tableName",
          condition: "$eq",
          value: "",
          hardcoded: sCollectionsName,
          from: "",
          to: "",
          type: "String",
        },
      ],
      bArrayCondition: false,
      ArrayWhere: [],
      ADMINAPP: false,
    };

    await Server.post("/logics/db/fetch", oPostData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then(function (oResult) {
      result = oResult.data.Data;
    });

    return result;
  };

  function handleTabChange(oEvent, newValue) {
    setTabValue(newValue);
  }

  function handleclose() {
    setTabValue(0);
    props.close();
  }

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth="md">
      <DialogTitle>Create Colloection</DialogTitle>
      <DialogContent>
        <div>
          <div>
            <label>New Collection Name</label> <br />
            <input
              type="text"
              value={sNewCollectionName}
              onChange={(oEvent) => {
                setNewCollectionName(oEvent.target.value);
              }}
            ></input>
          </div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={iTabValue} onChange={handleTabChange}>
              <Tab label="Table Schema" />
              <Tab label="Json Schema" />
            </Tabs>
          </Box>
          <br />
          {iTabValue === 0 && (
            <div>
              <IconButton>
                <AddIcon
                  onClick={handleIncreaseRow}
                  baseClassName="fas"
                  className="fa-plus-circle"
                  color="primary"
                />
              </IconButton>
              {aSchemaArray.length > 1 ? (
                <IconButton>
                  <RemoveIcon
                    onClick={handleRemoveRow}
                    baseClassName="fas"
                    className="fa-plus-circle"
                    color="secondary"
                  />
                </IconButton>
              ) : (
                ""
              )}
              <Box>
                <table
                  id="idTableConfiguration"
                  className="RenderComponent-Table"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th className="RenderComponent-Table-th">Field Name</th>
                      <th className="RenderComponent-Table-th">Field Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {aSchemaArray.map((row, index) => (
                      <tr key={index}>
                        <td className="RenderComponent-Table-td">
                          <input
                            type="checkbox"
                            checked={aSelectedRows[index]}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </td>
                        <td className="RenderComponent-Table-td">
                          <input
                            style={{ height: "90%" }}
                            className="RenderComponent-Table-input"
                            value={row.Field}
                            onChange={(event) =>
                              handleEvent(event, index, "Field")
                            }
                          />
                        </td>
                        <td className="RenderComponent-Table-td">
                          <select
                            style={{ height: "90%" }}
                            className="RenderComponent-Table-input"
                            value={row.Type}
                            onChange={(event) =>
                              handleEvent(event, index, "Type")
                            }
                          >
                            <option value="" disabled>
                              Select Type
                            </option>
                            <option value="String">String</option>
                            <option value="Number">Number</option>
                            <option value="Date">Date</option>
                            <option value="Array">Array</option>
                            <option value="Object">Object</option>
                          </select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </div>
          )}
          {iTabValue === 1 && (
            <TextField
              label="JSON Data"
              multiline
              maxRows={1000}
              fullWidth
              variant="outlined"
              defaultValue={JSON.stringify(jsonData, null, 2)}
              onChange={(oEvent) => {
                handleOnChangeEvent(oEvent);
              }}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>Create</Button>
        <Button onClick={handleclose}>Cancel</Button>
      </DialogActions>
      {sErrorMessage && (
        <Snackbar
          open={bOpenSnackBar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            onClose={() => setErrorMessage("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {sErrorMessage}
          </Alert>
        </Snackbar>
      )}
    </Dialog>
  );
};
