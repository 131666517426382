import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const WhereConditionDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);

  const handleUpdate = async () => {
    props.updateData();
    props.close();
  };

  const aConditions = [
    { value: "", label: "" },
    { value: "$eq", label: "Equal To" },
    { value: "$ne", label: "Not Equal To" },
    { value: "$gt", label: "Greater Than" },
    { value: "$lt", label: "Less Than" },
    { value: "$gte", label: "Greater Than or Equal To" },
    { value: "$lte", label: "Less Than or Equal To" },
    { value: "$contains", label: "Like" },
    { value: "$between", label: "Between" },
  ];

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...aSelectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  function handleIncreaseRow() {
    const updatedRows = [...props.data.condition];
    updatedRows.push({
      Field: "",
      Condition: "",
      Parameter: "",
      Type: ""
    });
    props.update({ ...props.data, condition: updatedRows });
    setSelectedRows([...aSelectedRows, false]);
  }

  function handleRemoveRow() {
    const updatedRows = props.data.condition.filter(
      (_, index) => !aSelectedRows[index]
    );
    props.update({ ...props.data, condition: updatedRows });
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  const handleEvent = (event, index, sKey) => {
    var value;
    value = event.target.value;
    const updatedRows = [...props.data.condition];
    updatedRows[index][sKey] = value;
    props.update({ ...props.data, condition: updatedRows });
  };

  return (
    <div>
      <Dialog maxWidth="md" fullWidth={true} open={props.open}>
        <DialogTitle>Security Configuration</DialogTitle>
        <DialogContent>
          <div>
            <IconButton>
              <AddIcon
                onClick={handleIncreaseRow}
                baseClassName="fas"
                className="fa-plus-circle"
                color="primary"
              />
            </IconButton>
            <IconButton>
              <RemoveIcon
                onClick={handleRemoveRow}
                baseClassName="fas"
                className="fa-plus-circle"
                color="secondary"
              />
            </IconButton>
            <Box>
              <table
                id="idTableConfiguration"
                className="RenderComponent-Table"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th className="RenderComponent-Table-th">Field</th>
                    <th className="RenderComponent-Table-th">Condition</th>
                    <th className="RenderComponent-Table-th">Parameter</th>
                    <th className="RenderComponent-Table-th">Type</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data &&
                    props.data.condition &&
                    props.data.condition.map((condition, index) => (
                      <tr key={index}>
                        <td className="RenderComponent-Table-td">
                          <input
                            type="checkbox"
                            checked={aSelectedRows[index]}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </td>
                        <td className="RenderComponent-Table-td">
                          <select
                            className="RenderComponent-Table-input"
                            value={condition.Field}
                            onChange={(event) =>
                              handleEvent(event, index, "Field")
                            }
                          >
                            <option value="" disabled></option>
                            {props.data.Fields &&
                              props.data.Fields.map((field) => (
                                <option key={field} value={field}>
                                  {field}
                                </option>
                              ))}
                          </select>
                        </td>
                        <td className="RenderComponent-Table-td">
                          <select
                            className="RenderComponent-Table-input"
                            value={condition.Condition}
                            onChange={(event) =>
                              handleEvent(event, index, "Condition")
                            }
                          >
                            {aConditions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td className="RenderComponent-Table-td">
                          <input
                            type="text"
                            className="RenderComponent-Table-input"
                            value={condition.Parameter}
                            onChange={(event) =>
                              handleEvent(event, index, "Parameter")
                            }
                          />
                        </td>
                        <td className="RenderComponent-Table-td">
                          <select
                            className="RenderComponent-Table-input"
                            value={condition.Type}
                            onChange={(event) =>
                              handleEvent(event, index, "Type")
                            }
                          >
                            <option value="" disabled></option>
                            <option value="String">String</option>
                            <option value="Number">Number</option>
                            <option value="Boolean">Boolean</option>
                          </select>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
