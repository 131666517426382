import React from 'react';

//React flow library components
import ReactFlow, { Controls, Background, ReactFlowProvider} from 'reactflow';

//CSS
import 'reactflow/dist/style.css';
// import "./RenderLogicFlow.css";

export default function RenderValidationFlow(props) {
    var aNodes = [];

    if (!props.visible) {
        return;
    }

    if (props.nodes) {
        aNodes = props.nodes
    }

    /**
     * Event handler for drag end
     */
    function handleDragEnd() {
        props.update(props.nodes,"validation");
    }

    /**
     * Event handler on node click
     * @param {event} oEvent 
     * @param {object} oNode 
     */
    function handleNodeClick(oEvent, oNode) {
        props.select(oNode.id);
    }

    /**
     * Event handler for delete node
     * @param {array} nodes | deleted nodes
     */
    function handleDelete(nodes) {
        props.delete(nodes);
    }

    /**
     * Event handler for change in edges
     * @param {object} changes | Edge details
     */
    function handleEdgesChange(changes) {
        props.onEdgesChange(changes);
    }

    /**
     * Event handler during edge connect
     * @param {object} params | parameters during edge connect 
     */
    function handleConnect(params) {
        props.onConnect(params);
    }

    return (
        <ReactFlowProvider>
            <ReactFlow nodes={aNodes}
                onNodesChange={props.change}
                onNodeDragStop={handleDragEnd}
                onNodeClick={handleNodeClick}
                onNodesDelete={handleDelete}
                edges={props.edges}
                onEdgesChange={handleEdgesChange}
                onConnect={handleConnect}>
                <Background />
                <Controls />
            </ReactFlow>
        </ReactFlowProvider>
    )
}