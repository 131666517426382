import React, { useState,useEffect } from "react";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";
import './collection.css'
import {SelectionFieldsDialog} from '../selectionFields/selectionFieldsDialog'

import Server from "../../../../../serverDetails/server";

export const CollectionDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  const [achooseOptions, setchooseOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [oArrayFieldsData, setOArrayFieldsData] = useState({});
  const [bOpenSelectionFields,setSelectionFields] = useState(false)
  const [iIndex,setIndex] = useState()
  const [aDataFields, setADataFields] = useState({});

  useEffect(() => {
    props.rows.collections.forEach((oDatas, index) => {
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [index]: oDatas.selectionFields,
      }));
    });
  }, [props.rows]);


function transformData(data, parent = null) {
  function processObject(obj, parent = null) {
    return Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        var subtask = value.map(item => processObject(item, key));
        subtask = subtask[0];
        return { Name: key, Type: 'Array', subtask, parent };
      } else if (typeof value === 'object' && value !== null && value.type === undefined) {
        var nestedTasks = processObject(value, key);
        return { Name: key, Type: 'Object', subtasks: nestedTasks, parent };
      } else {
        return { Name: key, Type: value.type,checked: false, parent };
      }
    });
  }
  
  return processObject(data, parent);
}


  const retriveCheckFields = (aTransformData, index) => {
    const traverseAndCheck = (data, pathParts) => {
      if (pathParts.length === 0) {
        return;
      }
  
      const currentPart = pathParts.shift();
  
      for (let item of data) {
        if (item.Name === currentPart) {
          if (pathParts.length === 0) {
            item.checked = true;
          } else if (item.subtask) {
            traverseAndCheck(item.subtask, pathParts);
          }
        }
      }
    };
  
    for (let sOptionField of selectedOptions[index]) {
      const pathParts = sOptionField.split('.');
      
      if (pathParts.length === 1) {
        const result = aTransformData.find(oTransformField => oTransformField.Name === sOptionField);
        if (result) {
          result.checked = true;
        }
      } else {
        traverseAndCheck(aTransformData, pathParts);
      }
    }
  
    return aTransformData;
  };
  const handleCollectionName = (event, index, sKey) => {
    // Handle collection name change
    var value;
    if (sKey === "selectSingle") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    const updatedRows = [...props.rows.collections];
    updatedRows[index][sKey] = value;
    props.update({ ...props.rows, collections: updatedRows });
  };

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...aSelectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  function handleIncreaseRow() {
    const updatedRows = [...props.rows.collections];
    updatedRows.push({
      collectionName: "",
      selectionFields: [],
      selectSingle: "",
    });
    props.update({ ...props.rows, collections: updatedRows });
    setSelectedRows([...aSelectedRows, false]);
  }

  function handleRemoveRow() {
    const updatedRows = props.rows.collections.filter(
      (_, index) => !aSelectedRows[index]
    );
    props.update({ ...props.rows, collections: updatedRows });
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  const handleFetchDatas = async (index) => {
    var table = props.rows.collections[index].collectionName;
    console.log("achooseOptions",achooseOptions)
    setIndex(index)
    if (table && !achooseOptions[index]) {
      Server.get(`/tablefields/${table}/${props.admin}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then(function (response) {
        if (response.data.fields) {
          const datas = response.data.fields;
          let aTempDataFields = transformData(datas);
          if(selectedOptions[index].length>0){
            aTempDataFields = retriveCheckFields(aTempDataFields,index)
          }
          setADataFields((prevOptions) => ({
            ...prevOptions,
            [index]: aTempDataFields,
          }));
          setchooseOptions((prevOptions) => ({
            ...prevOptions,
            [index]: datas,
          }));
          setSelectionFields(true);
        }
      });
    } else {
      setSelectionFields(true);
    }
  };

  const handleSelectChange = (options) => {
    const updatedRows = [...props.rows.collections];
    updatedRows[iIndex].selectionFields = options;  
    props.update({ ...props.rows, collections: updatedRows });
    setSelectionFields(false)
    
  };

  const handleCancel = () => {
    setSelectionFields(false);
  };

  return (
    <div>
      <IconButton>
        <AddIcon
          onClick={handleIncreaseRow}
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      {props.rows.collections.length > 1 ? (
        <IconButton>
          <RemoveIcon
            onClick={handleRemoveRow}
            baseClassName="fas"
            className="fa-plus-circle"
            color="secondary"
          />
        </IconButton>
      ) : (
        ""
      )}
      <Box>
        <table id="idTableConfiguration" className="RenderComponent-Table">
          <thead>
            <tr>
              <th></th>
              <th className="RenderComponent-Table-th">Collection Name</th>
              <th className="RenderComponent-Table-th">Selection Fields</th>
              <th className="RenderComponent-Table-th">Select Single</th>
              <th className="RenderComponent-Table-th">Document Field</th>
            </tr>
          </thead>
          <tbody>
            {props.rows.collections.map((row, index) => (
              <tr key={index}>
                <td className="RenderComponent-Table-td">
                  <input
                    type="checkbox"
                    checked={aSelectedRows[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    className="RenderComponent-Table-input"
                    value={row.collectionName}
                    onChange={(event) =>
                      handleCollectionName(event, index, "collectionName")
                    }
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  {/* <Select
                    isMulti
                    options={achooseOptions[index] || []}
                    value={selectedOptions[index] || []}
                    onMenuOpen={() => handleFetchDatas(index)}
                    onChange={(selectedOptions)=>handleSelectChange(index,selectedOptions)}
                  /> */}
                  <div
                    className="RenderComponent-Table-wrapper"
                    onClick={() => handleFetchDatas(index)}
                  >
                    <textarea
                      className="RenderComponent-Table-input array-input"
                      value={selectedOptions[index]}
                      onChange={(event) =>
                        handleCollectionName(event, index, "collectionName")
                      }
                    />
                  </div>
                </td>
                <td
                  className="RenderComponent-Table-td"
                  style={{ textAlign: "center" }}
                >
                  <input
                    type="checkbox"
                    style={{ height: "20px", width: "30px" }}
                    value={row.selectSingle}
                    onChange={(event) => {
                      handleCollectionName(event, index, "selectSingle");
                    }}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    className="RenderComponent-Table-input"
                    value={row.documentField}
                    onChange={(event) =>
                      handleCollectionName(event, index, "documentField")
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <SelectionFieldsDialog
        open={bOpenSelectionFields}
        handleClose={handleCancel}
        aDataFields={aDataFields}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        iIndex={iIndex}
        update={handleSelectChange}
        setADataFields={setADataFields}
      />
    </div>
  );
};
