import React, { useState, useEffect } from "react";

//Material ui components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { APICollection } from "./APICollection";

export const ApiAuthentication = (props) => {
  const [selectedArrayFields, setselectedArrayFields] = useState({});
  const [selectedNonArrayFields, setSelectedNonArrayFields] = useState([]);
  const [aRows, setRows] = useState({
    collections: [
      {
        tableName: "",
        Fields: [],
        values: [],
        condition: []
      }
    ]
  });

  useEffect(() => {
    if(props.data && 'collections' in props.data){
      setRows(props.data);
    }
  }, [props.data]);

  function handleClose() {
    props.close();
  }
  function handleUpdate(){
    props.update(aRows);
    props.close();
  }

  return (
    <div>
      <Dialog open={props.open} fullWidth={true} maxWidth='md'>
        <DialogTitle>Collection</DialogTitle>
        <DialogContent>
          <APICollection
            rows={aRows}
            update={setRows}
            arrayFields={selectedArrayFields}
            updateArrayFields={setselectedArrayFields}
            updateNonArrayFields={setSelectedNonArrayFields}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Create</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
