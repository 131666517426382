import React, { useState } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const LegendDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);

  /** CheckBox Handle function to delete the selected rows
   * 
   * @param {*} index 
   */
  const handleCheckboxChange = (index) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[index] = !aNewSelectedRows[index];
    setSelectedRows(aNewSelectedRows);
  };

  /** Increase No Rows in Table when Click + Icon
   * 
   */
  function handleIncreaseRow() {
    if (props.oData.Legend.Values.length < 6) {
      const oUpdatedRows = [...props.oData.Legend.Values];
      oUpdatedRows.push({
        FieldValue: "",
        custom_class: "",
      });

      const updatedData = {
        ...props.oData,
        Legend: {
          ...props.oData.Legend,
          Values: oUpdatedRows,
        },
      };

      props.updateData(updatedData);
      setSelectedRows([...aSelectedRows, false]);
    }
  }

  /**Decrease no of rows when click - icon
   * 
   */
  function handleRemoveRow() {
    const oUpdatedRows = props.oData.Legend.Values.filter(
      (_, index) => !aSelectedRows[index]
    );
    props.updateData({ ...props.oData, Legend: { ...props.oData.Legend, Values: oUpdatedRows } });
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  /** Update the Input data
   * 
   * @param {*} event 
   * @param {*} index 
   * @param {*} skey 
   */
  const handleChangeEvent = (event, index, skey) => {
    const { value } = event.target;
    if (skey === "FieldName") {
      props.updateData({
        ...props.oData,
        Legend: { ...props.oData.Legend, FieldName: value },
      });
    } else {
      const oUpdatedRows = [...props.oData.Legend.Values];
      oUpdatedRows[index][skey] = value;
      props.updateData({
        ...props.oData,
        Legend: { ...props.oData.Legend, Values: oUpdatedRows },
      });
    }
  };

  return (
    <div>
      <select
        style={{width:"350px",height:"40px"}}
        defaultValue={props.oData.Legend.FieldName}
        onChange={(event) => {
          handleChangeEvent(event, 0, "FieldName");
        }}
      >
        {props.inputFields && props.inputFields.length > 0 && (
          <>
            <option key="" value="" disabled>
              Select Field
            </option>
            {props.inputFields.map((sdatas) => (
              <option key={sdatas} value={sdatas}>
                {sdatas}
              </option>
            ))}
          </>
        )}
      </select> <br/><br/>
      <IconButton>
        <AddIcon
          onClick={handleIncreaseRow}
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      {props.oData.Legend.Values.length > 1 ? (
        <IconButton>
          <RemoveIcon
            onClick={handleRemoveRow}
            baseClassName="fas"
            className="fa-plus-circle"
            color="secondary"
          />
        </IconButton>
      ) : (
        ""
      )}
      <Box>
        <table id="idTableConfiguration" className="RenderComponent-Table">
          <thead>
            <tr>
              <th></th>
              <th className="RenderComponent-Table-th">FieldValue</th>
              <th className="RenderComponent-Table-th">Colour</th>
            </tr>
          </thead>
          <tbody>
            {props.oData.Legend.Values.map((row, index) => (
              <tr key={index}>
                <td className="RenderComponent-Table-td">
                  <input
                    type="checkbox"
                    checked={aSelectedRows[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    name={index}
                    className="RenderComponent-Table-input"
                    value={row.FieldValue}
                    onChange={(oEvent) => {
                      handleChangeEvent(oEvent, index, "FieldValue");
                    }}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <select
                    className="RenderComponent-Table-input"
                    value={row.custom_class}
                    onChange={(event) => {
                      handleChangeEvent(event, index, "custom_class");
                    }}
                  >
                    <option value="" disabled>
                      Select Colour
                    </option>
                    <option value="Blue">Blue</option>
                    <option value="Red">Red</option>
                    <option value="Yellow">Yellow</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </div>
  );
};
