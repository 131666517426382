export const MapSingleData = (sUniqueID, oParams, aUIData, resetUI) => {
  var sValue = '';
  if (oParams.TableData) {
    for (var i = 0; i < oParams.TableData.length; i++) {
      if (i === 0) {
        sValue = oParams.TableData[i][Object.keys(oParams.TableData[i])[0]];
      } else {
        sValue =
          sValue +
          '\n' +
          oParams.TableData[i][Object.keys(oParams.TableData[i])[0]];
      }
    }
  } else if (oParams.mapSingle) {
    sValue = oParams.mapSingle;
  }

  var oComponent = aUIData.find(function (oComponentModel) {
    return oComponentModel.uniqueId === sUniqueID;
  });
  oComponent.value = sValue;
  resetUI([...aUIData]);
};
