import { handleBinaryDataFromBackend } from "./fileConverter/BinaryData";
var aInitalLoadData = [];

export const MapTableData = (aProperties, oLogicDatas, oParams) => {
  var aRowData = [];
  var oFieldOrder = aProperties.find(function (oProperty) {
    return oProperty.id === "idMapTableFields";
  });
  var sTableUniqueId = aProperties.find(function (oProperty) {
    return oProperty.id === "idDropDownTable";
  }).value;
  oFieldOrder = oFieldOrder.value;
  var oRowData = {};
  var aParams = [];

  if (!Array.isArray(oParams.TableData)) {
    if(oParams.TableData){
      aParams.push(oParams.TableData);
    }else{
      aParams = oParams;
    }
  } else {
    aParams = oParams.TableData;
  }

  /**  Format DateTime Field
   *
   * @param {*} value
   * @param {*} dataType
   * @returns
   */
  function formatDateField(value, dataType) {
    const dDate = new Date(value);
    switch (dataType) {
      case "Date": {
        return dDate.toISOString().split("T")[0];
      }
      case "datetime-local": {
        return dDate.toISOString().slice(0, 16);
      }
      default:
        return value;
    }
  }

  if (aParams.length > 0) {
    aParams.forEach(function (oData) {
      for (var props in oData) {
        for (var rowprops in oFieldOrder) {
          if (oFieldOrder[rowprops] === props) {
            oRowData[rowprops] = oData[props];
          }
        }
      }
      aRowData.push(oRowData);
      oRowData = {};
    });
    var oValue = {
      uniqueId: oLogicDatas.sUniqueID,
      component: "rows",
      value: aRowData,
    };

    if (oLogicDatas.bOnLoad) {
      aInitalLoadData.push(oValue);
      oLogicDatas.fnCallback(aInitalLoadData);
    } else {
      var oComponent = oLogicDatas.aCurrentPage.find(
        function (oComponentModel) {
          return oComponentModel.uniqueId === sTableUniqueId;
        }
      );

      oComponent.properties.forEach(function (oProperty) {
        if (oProperty.id === "idColumnConfig") {
          oProperty.value.forEach(function (oValue) {
            aRowData.forEach(async function (oDataValue) {
              const sFieldName = oValue.fieldName;
              if (oValue.dataType === "file") {
                let binaryData = oDataValue[sFieldName];
                if (binaryData) {
                  let filename = "";
                  const { blobUrl, filetype } =
                    await handleBinaryDataFromBackend(binaryData, filename);
                  // oComponent.Files = [{ name: filename }];
                  oComponent.previews = [blobUrl];
                  oDataValue[sFieldName] =blobUrl;
                }
              }
              if (oDataValue[sFieldName]) {
                oDataValue[sFieldName] = formatDateField(
                  oDataValue[sFieldName],
                  oValue.dataType
                );
              }
            });
          });
        }
      });
      oComponent.value = {
        rows: aRowData,
      };
      oLogicDatas.resetUI([...oLogicDatas.aUIData]);
    }
  } else {
    var oComponent = oLogicDatas.aCurrentPage.find(function (oComponentModel) {
      return oComponentModel.uniqueId === sTableUniqueId;
    });
    oComponent.value = {
      rows: [],
    };
    oLogicDatas.resetUI([...oLogicDatas.aUIData]);
  }
};

export const MapInputFields = async(aProperties, oParams, oLogicDatas, results) => {
  var oMapping = aProperties.find((oProperty) => {
    return oProperty.id === "idMapInputFields";
  });
  var oData;
  if (Array.isArray(oParams.TableData) && oParams.TableData.length > 0) {
    oData = oParams.TableData[0];
  } else if (
    oParams.TableData &&
    typeof oParams.TableData === "object" &&
    Object.keys(oParams.TableData).length > 0
  ) {
    oData = oParams.TableData;
  } else if (oParams.mapSingle) {
    oData = oParams.mapSingle;
  } else if (Array.isArray(oParams)) {
    var aFormattedData = [];
    oParams.forEach(function (param) {
      aFormattedData[param.field] = param.value;
    });
    if (Object.keys(aFormattedData).length > 0) {
      oData = aFormattedData;
    }
  }

  var sTableUniqueId;
  if (oMapping && (oParams.mapSingle || oParams.TableData || oParams)) {
    for (var oProperty in oMapping.value) {
      var oComponent = oLogicDatas.aCurrentPage.find(
        function (oComponentModel) {
          return oComponentModel.uniqueId === oMapping.value[oProperty].id;
        }
      );
      if (
        (oComponent && oComponent.Component === "Input") ||
        (oComponent && oComponent.Component === "DropDown")
      ) {
        var oTypeProperty = oComponent.properties.find(
          (oProperty) => oProperty.id === "idInputTypeInput"
        );
        if (oMapping.value[oProperty].array) {
          var sArrayField = oMapping.value[oProperty].array;
          var sValueFields = oMapping.value[oProperty].value.split(".") ?? [];

          var sValue = oData[sArrayField][0];
          for (var i = 0; i < sValueFields.length; i++) {
            var propertyName = sValueFields[i];
            if (Array.isArray(sValue)) {
              sValue = sValue[0];
            }
            if (
              sValue &&
              Object.prototype.hasOwnProperty.call(sValue, propertyName)
            ) {
              sValue = sValue[propertyName];
            } else {
              sValue = "";
              break;
            }
          }
          oComponent.UserValue = sValue;
        } else if (oTypeProperty && oTypeProperty.value === "file") {
          let binaryData = oData[oMapping.value[oProperty].value];
          if (binaryData) {
            let filename = oData[`${oProperty}.filename`];
            const { blobUrl, filetype } = await handleBinaryDataFromBackend(
              binaryData,
              filename
            );
            oComponent.Files = [{ name: filename }];
            oComponent.previews = [blobUrl];
          }
        } else {
          oComponent.UserValue = oData[oMapping.value[oProperty].value] ?? "";
        }
        if (
          (oComponent.UserValue &&
            oTypeProperty &&
            oTypeProperty.value === "Date") ||
          (oTypeProperty && oTypeProperty.value === "datetime-local")
        ) {
          var dDateValue = formatDateField(
            oComponent.UserValue,
            oTypeProperty.value
          );
          oComponent.UserValue = dDateValue;
        }
      } else if (oComponent && oComponent.Component === "Label") {
        oComponent.properties.forEach(function (oDatas) {
          if (oDatas.id === "idInputTextLabel") {
            if (oMapping.value[oProperty].array) {
              var sArrayField = oMapping.value[oProperty].array;
              var sValueFields =
                oMapping.value[oProperty].value.split(".") ?? [];
              var sValue = oData[sArrayField][0];
              for (var i = 0; i < sValueFields.length; i++) {
                var propertyName = sValueFields[i];
                if (
                  sValue &&
                  Object.prototype.hasOwnProperty.call(sValue, propertyName)
                ) {
                  sValue = sValue[propertyName];
                } else {
                  sValue = "";
                  break;
                }
              }
              oDatas.value = sValue;
            } else {
              oDatas.value = oData[oMapping.value[oProperty].value] ?? "";
            }
          }
        });
      } else if (oComponent && oComponent.Component === "MapLocation") {
        let nextField =
          oMapping.value[oProperty].value === "latitude"
            ? "longitude"
            : "latitude";
        oComponent.center = {
          [oMapping.value[oProperty].value]:
            oData[oMapping.value[oProperty].value],
          [nextField]: oData[nextField],
        };
      }
    }
    oLogicDatas.resetUI([...oLogicDatas.aUIData]);
    oLogicDatas.aCurrentPage.forEach(function (oComponentModel) {
      if (oComponentModel.Component === "Table") {
        var sTableName = oComponentModel.properties.find(
          function (eachProperty) {
            return eachProperty.id === "idInputTableName";
          }
        ).value;
        for (var oProperty in oMapping.value) {
          if (oProperty === sTableName) {
            sTableUniqueId = oComponentModel.uniqueId;
            if (sTableUniqueId) {
              var oParams = oData[oMapping.value[oProperty].value];
              MapInputTableFileds(oParams, sTableUniqueId, oLogicDatas);
            }
          }
        }
      }
    });
  } else if (
    oMapping &&
    Array.isArray(results) &&
    results[0].status === "fulfilled"
  ) {
    for (var oProperty in oMapping.value) {
      var sMappingField;
      var oComponent = oLogicDatas.aUIData.find(function (oComponentModel) {
        return oComponentModel.uniqueId === oMapping.value[oProperty].id;
      });
      sMappingField = oMapping.value[oProperty].value;
      if (
        oComponent.Component === "Input" ||
        oComponent.Component === "DropDown"
      ) {
        var oMatchingParam = results[0].value.DATA;
        if (oMatchingParam) {
          oComponent.UserValue = oMatchingParam[sMappingField];
        }
      } else if (oComponent.Component === "Label") {
        oComponent.properties.forEach(function (oDatas) {
          if (oDatas.id === "idInputTextLabel") {
            var oMatchingParam = results[0].value.DATA;
            if (oMatchingParam) {
              oDatas.value = oMatchingParam[sMappingField];
            }
          }
        });
      }
    }
    oLogicDatas.resetUI([...oLogicDatas.aUIData]);
  }
};

/**
 * formatting inputfields date type
 * @param {string} value
 * @param {string} dataType
 */
function formatDateField(value, dataType) {
  if (value && value !== "undefined") {
    try {
      const dDate = new Date(value);
      switch (dataType) {
        case "Date": {
          return dDate.toISOString().split("T")[0];
        }
        case "datetime-local": {
          return dDate.toISOString().slice(0, 16);
        }
        default:
          return value;
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    return value;
  }
}

/**
 * Map InputTableFileds data
 * @param {object} oParams
 * @param {Object} oLogicDatas
 * @param {string} sTableUniqueId
 */
function MapInputTableFileds(oParams, sTableUniqueId, oLogicDatas) {
  var aRowData = [];
  var oRowData = {};
  var aParams = [];
  var sUniqueID = sTableUniqueId;

  if (!Array.isArray(oParams)) {
    aParams.push(oParams);
  } else {
    aParams = oParams;
  }
  if (aParams.length > 0) {
    aParams.forEach(function (oData) {
      oRowData = oData;
      aRowData.push(oRowData);
      oRowData = {};
    });
    var oValue = {
      uniqueId: sUniqueID,
      component: "rows",
      value: aRowData,
    };
    if (oLogicDatas.bOnLoad) {
      aInitalLoadData.push(oValue);
      oLogicDatas.fnCallback(aInitalLoadData);
    } else {
      var oComponent = oLogicDatas.aCurrentPage.find(
        function (oComponentModel) {
          return oComponentModel.uniqueId === sTableUniqueId;
        }
      );
      oComponent.value = {
        rows: aRowData,
      };
      oLogicDatas.resetUI([...oLogicDatas.aUIData]);
    }
  }
}
