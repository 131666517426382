import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const FormDownloadDialog = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  function handleIncreaseRow() {
    const updatedRows = [...props.data];
    updatedRows.push({
      Parameter: "",
      DataType: "",
      MapValue: "",
      DefaultValue: "",
      ParameterID: "",
      bLoop:false
    });
    props.updateData(updatedRows);
    setSelectedRows([...selectedRows, false]);
  }

  function handleRemoveRow() {
    const updatedRows = props.data.filter((_, index) => !selectedRows[index]);
    props.updateData(updatedRows);
    setSelectedRows(selectedRows.filter((_, index) => !selectedRows[index]));
  }

  const handleEvent = (event, index, skey) => {
    const oUpdatedRows = [...props.data];
    if (skey === "Parameter") {
      const { value } = event.target;
      const sUniqueID = props.formComponents.find(
        (data) => data.name === value
      ).uniqueId;
      oUpdatedRows[index][skey] = value;
      oUpdatedRows[index]["ParameterID"] = sUniqueID;
    } else if (skey === "bLoop") {
      const bValue = event.target.checked;
      oUpdatedRows[index][skey] = bValue;
    } else {
      const { value } = event.target;
      oUpdatedRows[index][skey] = value;
    }
    props.updateData(oUpdatedRows);
  };

  return (
    <div>
      <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
        <DialogTitle>Paramter Definition</DialogTitle>
        <DialogContent>
          <div>
            <IconButton>
              <AddIcon
                baseClassName="fas"
                className="fa-plus-circle"
                color="primary"
                onClick={handleIncreaseRow}
              />
            </IconButton>
            <IconButton>
              <RemoveIcon
                baseClassName="fas"
                className="fa-plus-circle"
                color="primary"
                onClick={handleRemoveRow}
              />
            </IconButton>
            <Box>
              <table
                id="idTableConfiguration"
                className="RenderComponent-Table"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th className="RenderComponent-Table-th">Paramter</th>
                    <th className="RenderComponent-Table-th">DataType</th>
                    <th className="RenderComponent-Table-th">Mapping Values</th>
                    <th className="RenderComponent-Table-th">ArrayMapping</th>
                    <th className="RenderComponent-Table-th">Loop Component</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data &&
                    props.data.map((row, index) => (
                      <tr key={index}>
                        <td className="RenderComponent-Table-td">
                          <input
                            type="checkbox"
                            checked={selectedRows[index]}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </td>
                        <td className="RenderComponent-Table-td">
                          <select
                            className="RenderComponent-Table-input"
                            defaultValue={row.Parameter}
                            onChange={(oEvent) => {
                              handleEvent(oEvent, index, "Parameter");
                            }}
                          >
                            <option disabled value="">
                              Select Field
                            </option>
                            {props.formComponents &&
                              props.formComponents.map((component, index) => (
                                <option key={index} value={component.name}>
                                  {component.name}
                                </option>
                              ))}
                          </select>
                        </td>
                        <td className="RenderComponent-Table-td">
                          <select
                            className="RenderComponent-Table-input"
                            defaultValue={row.DataType}
                            onChange={(oEvent) => {
                              handleEvent(oEvent, index, "DataType");
                            }}
                          >
                            <option disabled value="">
                              Select Datatype
                            </option>
                            <option value="Number">Number</option>
                            <option value="String">String</option>
                            <option value="Date">Date</option>
                            <option value="Array">Array</option>
                            <option value="Object">Object</option>
                          </select>
                        </td>
                        <td className="RenderComponent-Table-td">
                          <select
                            className="RenderComponent-Table-input"
                            defaultValue={row.MapValue}
                            onChange={(oEvent) => {
                              handleEvent(oEvent, index, "MapValue");
                            }}
                          >
                            <option disabled value="">
                              Select value
                            </option>
                            {row.DataType === "Array" &&
                              props.arrayFields &&
                              props.arrayFields.map((field) => (
                                <option key={field} value={field}>
                                  {field}
                                </option>
                              ))}
                            {row.DataType !== "Array" &&
                              props.fields &&
                              props.fields.map((odatas) => (
                                <option key={odatas} value={odatas}>
                                  {odatas}
                                </option>
                              ))}
                          </select>
                        </td>
                        <td className="RenderComponent-Table-td">
                          <input
                            type="test"
                            value={row.DefaultValue}
                            className="RenderComponent-Table-input"
                            onChange={(oEvent) =>
                              handleEvent(oEvent, index, "DefaultValue")
                            }
                          />
                        </td>
                        <td className="RenderComponent-Table-td">
                          <input
                            type="checkbox"
                            checked={row.bLoop}
                            className="RenderComponent-Table-input"
                            onChange={(oEvent) =>
                              handleEvent(oEvent, index, "bLoop")
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.update}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
