import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft,faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import AppLogo from '../images/logo.png';

//Material UI
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

//css
import './Footer.css';

export const FooterComponents = (props) => {
  const [bOpen, setOpen] = useState(false);
  const [bForce, setForce] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  if (props.openValidationMessage && !bOpen) {
    setOpen(true);
  }

  const handlelogonavigate = () => {
    navigate('/HomePage');
  };

  return (
    <div>
      <div>
        <Collapse in={bOpen}>
          <div class="popup-container" id="popup">
            <div class="popup-close">
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                  props.setVisibleErrorIcon(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
            <div>
              <ul className="validation-list">
                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {props.aValidationMessages.map((message, index) => (
                    <div key={index} className="list-item">
                      <div className="list-item-icon">
                        {message.messageType === 'error' ? (
                          <ErrorOutlineOutlinedIcon color="error" />
                        ) : (
                          <ReportProblemOutlinedIcon color="warning" />
                        )}
                      </div>
                      <div className="list-item-content">
                        <p className="message-Text">{message.message}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </ul>
            </div>
          </div>
        </Collapse>
      </div>
      <div class="Preview-footer">
        <div className='preview-back'>
          <button
            onClick={handleBack}
            style={{
              alignItems: 'center',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              color: 'white'
            }}
          >
            <FontAwesomeIcon icon={faAnglesLeft} size='2xl'/>
          </button>
          <div class="Preview-Error">
            {props.openValidationMessage ? (
              <ErrorIcon
                disabled={bOpen}
                variant="outlined"
                color="error"
                onClick={() => {
                  setOpen(true);
                }}
              ></ErrorIcon>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="Preview-footer-Logo">
          <a
            href={`http://${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ''
            }/HomePage`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              if (event.button === 0) {
                event.preventDefault();
                handlelogonavigate();
              }
            }}
          >
            <img src={AppLogo} alt="AppLaunch" className="logo" />
          </a>
        </div>
        <div style={{width:"20px"}}></div>
      </div>
    </div>
  );
};
