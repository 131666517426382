import Apicall from "./Apicall";
import Server from "../serverDetails/server";

export default async function PromptGenerator({ message, UIData, setLoading }) {
  function handleGenerateMessage(UIData) {
    var oPrevData = { UIData: {} };
    UIData.forEach((oComponent) => {
      if (oComponent.Component === "Input") {
        oComponent.properties.forEach((oProps) => {
          if (oProps.id === "idInputFieldName") {
            oPrevData.UIData[oProps.value] = {
              type: "input",
              id: oComponent.uniqueId,
            };
          }
        });
      }
      if (oComponent.Component === "Table") {
        var sTableName;
        oComponent.properties.forEach((oProps) => {
          if (oProps.id === "idInputTableName") {
            sTableName = oProps.value;
            oPrevData.UIData[oProps.value] = {
              type: "Table",
              id: oComponent.uniqueId,
            };
          }
          if (oProps.id === "idColumnConfig") {
            const fieldNames = oProps.value.map((item) => item.fieldName);
            oPrevData.UIData[sTableName] = {
              ...oPrevData.UIData[sTableName],
              fields: fieldNames,
            };
          }
        });
      }
    });

    return oPrevData;
  }

  function handleUIData(sMessage, message) {
    const sSanitizedMessage = message.replace(/['"]/g, "");
    const aMessageWords = sSanitizedMessage.split(/[\s,]+/);

    const oNewFilteredData = Object.keys(sMessage.UIData)
      .filter((key) => aMessageWords.includes(key))
      .reduce((obj, key) => {
        obj[key] = sMessage.UIData[key];
        return obj;
      }, {});

    return oNewFilteredData;
  }
  const payload1 = {
    messages: [
      {
        role: "system",
        content:
          "You are an intelligent assistant capable of finding logics  based on the message provided by the user. Analyze the message to identify logics needed and generate a response using predefined logic components to construct responses based on user inputs: 1. 'Message' for displaying messages, 2. ‘FetchInputs’ for fetching the user input, 3. 'FetchDB' for fetching data from a database, 4.'InsertDB' for inserting data into a database, 5. 'MapTableData' it maps data to table. Construct the response in JSON format, ensuring it aligns with each component's structure and intended use.Do not generate response with another ComponentID",
      },
      {
        role: "user",
        content: message,
      },
    ],
    model: "ft:gpt-3.5-turbo-0125:personal::9WLBNkzW",
  };
  let logic = await Apicall(payload1, setLoading);
  let options = {};
  let hasDB = false;
  if (logic) {
    var aLogicComponents = Object.keys(logic);
    const bFetchDB = aLogicComponents.includes("FetchDB");
    if (bFetchDB) {
      const aCollectionNames = logic["FetchDB"];
      setLoading(true);
      try {
        for (const sCollectionName of aCollectionNames) {
          await Server.get(`/tablefields/${sCollectionName}/false`, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              if (response.data.fields) {
                hasDB = true;
                const datas = response.data.fields;
                const aFields = Object.keys(datas);
                options[sCollectionName] = aFields;
              }
            })
            .catch(function (err) {
              console.error(
                `An error occurred for collection ${sCollectionName}:`,
                err
              );
            });
        }
      } finally {
        setLoading(false);
      }
    }
  }
  var sMessage = handleGenerateMessage(UIData);
  var sSpecificData = handleUIData(sMessage, message);
  if (Object.keys(sSpecificData).length > 0) {
    sMessage.UIData = sSpecificData;
  }
  var sJsonString = JSON.stringify(sMessage);
  if (hasDB) {
    var sOptions = JSON.stringify(options);
    var sUpdatedMessage = `${sJsonString},${sOptions},${message}`;
  } else {
    var sUpdatedMessage = `${sJsonString},${message}`;
  }

  const payload = {
    messages: [
      {
        role: "system",
        content:
          "You are an intelligent assistant capable of constructing logical responses based on UI component data provided in JSON format by the user. Analyze the JSON to identify UI components and generate a logic using predefined logic components to construct responses based on user inputs: 1. 'Message' for displaying messages, which requires a mandatory 'value' parameter, 2. ‘FetchInputs’ for fetching the user input from the ui, which requires mandatory ‘input components’, 3. 'DeepFetchDB' for fetching data from a database, which requires mandatory 'tableName' and 'fields' parameters, and has an optional 'where' parameter[Note: The respective DB Fields Provided in Message,If the User mentioned specific fields only fetch that else fetch all fields], 4. 'InsertDB' for inserting data into a database,5.'Paramter' Values is not depend on UIdata,6.'MapInputComponents' is MapData to Inputs 7.'MapTableData' it gets data from db and map it to Table,8.SplitColumns is to split a table to an array of objects and it has properties with value,9.CodeEditor is used to done specific operations you want to perform and it must have Result input and output. Construct the response in JSON format like this {\"Logic\":[]}, ensuring it aligns with each component's structure and intended use. If any mandatory parameters are missing in the user's request, prompt the user to provide the necessary information.Do not generate response with another ComponentID.Construct the response as proper json without any syntax errors",
      },
      {
        role: "user",
        content: sUpdatedMessage,
      },
    ],
    model: "ft:gpt-3.5-turbo-0125:personal::9Ys8zhBV",
  };
  let response = await Apicall(payload, setLoading);
  return response;
}
