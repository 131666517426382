import React, { useState } from "react";
//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";

export const ConfirmationConditionDialog = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (index) => {
    const aNewSelectedRows = [...selectedRows];
    aNewSelectedRows[index] = !aNewSelectedRows[index];
    setSelectedRows(aNewSelectedRows);
  };

  function handleIncreaseRow() {
    const aUpdatedRows = [...props.rows];
    aUpdatedRows.push({
      Field: "",
      Condition: "",
      Value: "",
    });
    props.updateData(aUpdatedRows);
    setSelectedRows([...selectedRows, false]);
  }

  function handleRemoveRow() {
    const aUpdatedRows = props.rows.filter((_, index) => !selectedRows[index]);
    props.updateData(aUpdatedRows);
    setSelectedRows(selectedRows.filter((_, index) => !selectedRows[index]));
  }

  const handleEvent = (event, index, skey) => {
    const { value } = event.target;
    const oUpdatedRows = [...props.rows];
    if(skey === 'Condition'){
      if(value === 'Null' || value === 'Not Null'){
        oUpdatedRows[index].Value = '';
      }
    }
    oUpdatedRows[index][skey] = value;
    props.updateData(oUpdatedRows);
  };

  return (
    <div>
      <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
        <DialogTitle>Map Data to Table</DialogTitle>
        <DialogContent>
          <FormGroup>
            <div>
              <IconButton>
                <AddIcon
                  baseClassName="fas"
                  className="fa-plus-circle"
                  color="primary"
                  onClick={handleIncreaseRow}
                />
              </IconButton>
              <IconButton>
                <RemoveIcon
                  baseClassName="fas"
                  className="fa-plus-circle"
                  color="secondary"
                  onClick={handleRemoveRow}
                />
              </IconButton>
              <Box>
                <table
                  id="idTableConfiguration"
                  className="RenderComponent-Table"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th className="RenderComponent-Table-th">Field</th>
                      <th className="RenderComponent-Table-th">Condition</th>
                      <th className="RenderComponent-Table-th">Value</th>
                    </tr>
                  </thead>
                  {props.rows && (
                    <tbody>
                      {props.rows.map((row, index) => (
                        <tr key={index}>
                          <td className="RenderComponent-Table-td">
                            <input
                              type="checkbox"
                              checked={selectedRows[index]}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          </td>
                          <td className="RenderComponent-Table-td">
                            <select
                              className="RenderComponent-Table-input"
                              value={row.Field}
                              onChange={(event) => {
                                handleEvent(event, index, "Field");
                              }}
                            >
                              {props.inputFields.map((odatas) => (
                                <option key={odatas.id} value={odatas.id}>
                                  {odatas.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="RenderComponent-Table-td">
                            <select
                              className="RenderComponent-Table-input"
                              value={row.Condition}
                              onChange={(event) => {
                                handleEvent(event, index, "Condition");
                              }}
                            >
                              <option value="" disabled>
                                Select Condition
                              </option>
                              <option value="Null">NULL</option>
                              <option value="Not Null">NOT NULL</option>
                              <option value="Equal to">Equal to</option>
                              <option value="NEqual to">Not Equal to</option>
                            </select>
                          </td>
                          <td className="RenderComponent-Table-td">
                            <input
                              className="RenderComponent-Table-input"
                              value={row.Value}
                              readOnly={
                                row.Condition === "Null" ||
                                row.Condition === "Not Null"
                              }
                              onChange={(event) => {
                                handleEvent(event, index, "Value");
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </Box>
            </div>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.update}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
