import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";
import "../SendEmail.css";

export const EmailParamsDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  var aRowsData = [];

  /**
   * Event handler for CheckBox
   * @param {Number} index
   */
  const handleCheckboxChange = (index) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[index] = !aNewSelectedRows[index];
    setSelectedRows(aNewSelectedRows);
  };

  /**
   * Event handler for Fields
   * @param {*} oEvent
   * @param {Number} index
   * @param {string} skey
   */
  const handleChangeFields = (oEvent, index, sKey) => {
    const { value } = oEvent.target;
    const aTemp = [...props.mapMailData.params];
    aTemp[index][sKey] = value;
    props.setData({ ...props.mapMailData, params: aTemp });
  };

  /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    var aTemp = [...props.mapMailData.params];
    aTemp.push({
      field: "",
      mappingValue: "",
    });
    props.setData({ ...props.mapMailData, params: aTemp });
    setSelectedRows([...aSelectedRows, false]);
  }

  /**
   * Event handler for Remove Rows
   */
  function handleDecreaseRow() {
    var aTemp = [...props.mapMailData.params];
    const aUpdatedRows = aTemp.filter(
      (_, index) => index === 0 || !aSelectedRows[index]
    );
    props.setData({ ...props.mapMailData, params: aUpdatedRows });
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }
  if (props.mapMailData.params && props.mapMailData.params.length > 0) {
    for (let i = 0; i < props.mapMailData.params.length; i++) {
      aRowsData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={aSelectedRows[i]}
              onChange={() => handleCheckboxChange(i)}
            />
          </td>
          <td className="RenderComponent-Table-td">
            <input
              className="RenderComponent-Table-input"
              value={props.mapMailData.params[i].field}
              disabled={i === 0 ? true : false}
              onChange={(oEvent) => {
                handleChangeFields(oEvent, i, "field");
              }}
            />
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) =>
                handleChangeFields(oEvent, i, "mappingValue")
              }
              required
              value={props.mapMailData.params[i].mappingValue}
              className="column-title"
            >
              <option value="">Select </option>
              {props.aSourceFields &&
                props.aSourceFields.map((sValidationField, index) => {
                  return (
                    <option value={sValidationField} key={index}>
                      {sValidationField}
                    </option>
                  );
                })}
            </select>
          </td>
        </tr>
      );
    }
  }

  return (
    <div className="SendEmail-render">
      <IconButton>
        <AddIcon
          onClick={handleIncreaseRow}
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      {aSelectedRows.length > 1 ? (
        <IconButton onClick={handleDecreaseRow}>
          <RemoveIcon
            baseClassName="fas"
            className="fa-plus-circle"
            color="secondary"
          />
        </IconButton>
      ) : (
        ""
      )}
      <Box>
        <table id="idTableConfiguration" className="RenderComponent-Table">
          <thead>
            <tr>
              <th></th>
              <th className="RenderComponent-Table-th">FieldName</th>
              <th className="RenderComponent-Table-th">Mapping Value</th>
            </tr>
          </thead>
          <tbody>{aRowsData}</tbody>
        </table>
      </Box>
    </div>
  );
};
