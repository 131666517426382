import React, { useState} from 'react';

//Material UI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

export const TabNameConfig = (props) => {
    const [aTabNames, setTabs] = useState(props.values);
    var aTabsDesc = [];
    if (aTabNames.length < 1 && props.values.length > 0) {
        setTabs([...props.values]);
    }
    for (var i = 0; i < props.tabs; i++) {
        aTabsDesc.push(<TextField
            required
            margin="dense"
            id={i}
            label={"Tab" + (i + 1)}
            fullWidth
            variant="standard"
            value={aTabNames[i]}
            onChange={(oEvent) => { handleChange(oEvent, i) }}
        />)
    }

    /**
     * Handle change event
     * @param {*} oEvent 
     * @param {*} i 
     */
    function handleChange(oEvent, i) {
        var tempTabs = [...aTabNames];
        tempTabs[oEvent.currentTarget.id] = oEvent.target.value;
        setTabs(tempTabs);
    }

    /**
     * Handle update event
     * @param {*} oEvent 
     */
    function handleUpdate(oEvent) {
        props.update(aTabNames);
    }

    return (

        <div>
            <Dialog
                open={props.open}>
                <DialogTitle>
                    Configure Column Description
                </DialogTitle>
                <DialogContent>
                    {aTabsDesc}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button onClick={props.close}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}