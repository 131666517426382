import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const CodeParameterDefinition = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  function handleIncreaseRow() {
    const updatedRows = [...props.rows];
    updatedRows.push({
      Parameters: "",
      DataType: "",
      Result: "",
      DefaultValue:"",
    });
    props.updateData(updatedRows);
    setSelectedRows([...selectedRows, false]);
  }

  function handleRemoveRow() {
    const updatedRows = props.rows.filter((_, index) => !selectedRows[index]);
    props.updateData(updatedRows);
    setSelectedRows(selectedRows.filter((_, index) => !selectedRows[index]));
  }

  const handleEvent = (event, index, skey) => {
    const { value } = event.target;
    const oUpdatedRows = [...props.rows];
    oUpdatedRows[index][skey] = value;
    props.updateData(oUpdatedRows);
  };

  return (
    <div>
      <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
        <DialogTitle>Paramter Definition</DialogTitle>
        <DialogContent>
          <div>
            <IconButton>
              <AddIcon
                baseClassName="fas"
                className="fa-plus-circle"
                color="primary"
                onClick={handleIncreaseRow}
              />
            </IconButton>
            <IconButton>
              <RemoveIcon
                baseClassName="fas"
                className="fa-plus-circle"
                color="primary"
                onClick={handleRemoveRow}
              />
            </IconButton>
            <Box>
              <table
                id="idTableConfiguration"
                className="RenderComponent-Table"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th className="RenderComponent-Table-th">Paramter</th>
                    <th className="RenderComponent-Table-th">DataType</th>
                    <th className="RenderComponent-Table-th">Input/Ouput</th>
                    <th className="RenderComponent-Table-th">DefaultValue</th>
                  </tr>
                </thead>
                <tbody>
                  {props.rows.map((row, index) => (
                    <tr key={index}>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="checkbox"
                          checked={selectedRows[index]}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>
                      <td className="RenderComponent-Table-td">
                        <select
                          className="RenderComponent-Table-input"
                          defaultValue={row.Parameters}
                          onChange={(oEvent)=>{
                            handleEvent(oEvent,index,'Parameters')
                          }}
                        >
                          <option disabled value="">Select Field</option>
                          {props.fields &&
                            props.fields.map((odatas) => (
                              <option key={odatas} value={odatas}>
                                {odatas}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td className="RenderComponent-Table-td">
                        <select
                          className="RenderComponent-Table-input"
                          defaultValue={row.DataType}
                          onChange={(oEvent)=>{
                            handleEvent(oEvent,index,'DataType')
                          }}
                        >
                          <option disabled value="">Select Datatype</option>
                          <option value="Number">Number</option>
                          <option value="String">String</option>
                          <option value="Date">Date</option>
                          <option value="Array">Array</option>
                          <option value="Object">Object</option>
                        </select>
                      </td>
                      <td className="RenderComponent-Table-td">
                        <select
                          className="RenderComponent-Table-input"
                          defaultValue={row.Result}
                          onChange={(oEvent)=>{
                            handleEvent(oEvent,index,'Result')
                          }}
                        >
                          <option disabled value="">Select format</option>
                          <option value="Input">Input</option>
                          <option value="Output">Output</option>
                        </select>
                      </td>
                      <td className="RenderComponent-Table-td">
                        <input
                          type={row.DataType}
                          value={row.DefaultValue}
                          className="RenderComponent-Table-input"
                          onChange={(oEvent) => handleEvent(oEvent,index,'DefaultValue')}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.update}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
