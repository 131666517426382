import React from 'react';

//Component
import { MenuItems } from './container/menuItems/MenuItems'
import { MenuComponents } from './container/menuComponents/MenuComponents';

//icons


export const Menu = (props) => {
    return (
        <div id="idStudioMainpageControllerContainer"
            className="studio_mainPage-Controller-Container">
            <MenuItems
                id="idMenuItems"
                selectedItem={props.selectedItem}
                select={props.select}
                draggable="true" />
            <MenuComponents
                userId={props.userId}
                selectedItem={props.selectedItem} 
                pages={props.appJson}
                addPage={props.addPage}
                selectPage={props.selectPage}
                deletePage={props.deletePage}
                addForm={props.addForm}
                selectForm={props.selectForm}
                deleteForm={props.deleteForm}
            />
        </div>
    )
}