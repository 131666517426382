import React, { useState, useEffect } from "react";

//Material UI Components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";

import { ParameterDialog } from "./Methods/ParameterDialog";
import { ReturnParameterDialog } from "./Methods/ReturnParameterDialog";
import { LogicWriterDialog } from "./Methods/LogicWriter";

export const CreateRoute = (props) => {
  const [sApiPath, setApiPath] = useState("");
  const [aMethodData, setMethodData] = useState([]);
  const [aSelectedRows, setSelectedRows] = useState([]);

  const [bParameterDialog, setParameterDialog] = useState(false);
  const [iParameterIndex, setParameterIndex] = useState();
  const [bReturnParameterDialog, setReturnParameterDialog] = useState(false);
  const [iReturnParameterIndex, setReturnParameterIndex] = useState();
  const [bLogicWriterDialog, setLogicWriterDialog] = useState(false);
  const [iLogicWriterIndex, setLogicWriterIndex] = useState(false);

  useEffect(() => {
    if (props.selectedPath && props.apilist && props.selectedApi) {
      props.apilist.forEach((oApi) => {
        if (oApi.API_ID === props.selectedApi) {
          oApi.RouteDetail.forEach((oRoute) => {
            if (oRoute.Path === props.selectedPath) {
              setApiPath(props.selectedPath);
              setMethodData(oRoute.Methods);
            }
          });
        }
      });
    }
  }, [props]);

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...aSelectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  function handleRemoveRow() {
    const updatedRows = aMethodData.filter((_, index) => !aSelectedRows[index]);
    setMethodData(updatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  function handleIncreaseRow() {
    if(props.geoapi && aMethodData.length > 0){
      alert("For Geo API only one route will be created")
    }else{
      const updatedRows = [...aMethodData];
      updatedRows.push({
        MethodName: "",
        Logic: "",
        Parameter: {},
        ReturnParameters: {},
      });
      setMethodData(updatedRows);
      setSelectedRows([...aSelectedRows, false]);
    }
  }

  function handleEventChange(event, index, sKey) {
    const updatedRows = [...aMethodData];
    updatedRows[index][sKey] = event.target.value;
    setMethodData(updatedRows);
  }

  function handleParameter(index) {
    setParameterIndex(index);
    setParameterDialog(true);
  }

  function handleParameterUpdate(oParameters) {
    const updatedRows = [...aMethodData];
    updatedRows[iParameterIndex]["Parameter"] = oParameters;
    setMethodData(updatedRows);
    handleCloseDialog();
  }

  function handleReturnParameter(index) {
    setReturnParameterIndex(index);
    setReturnParameterDialog(true);
  }

  function handleReturnParameterUpdate(oReturnParameters) {
    const updatedRows = [...aMethodData];
    updatedRows[iReturnParameterIndex]["ReturnParameters"] = oReturnParameters;
    setMethodData(updatedRows);
    handleCloseDialog();
  }

  function handleLogicWriter(index) {
    setLogicWriterIndex(index);
    setLogicWriterDialog(true);
  }

  function handleLogicWriterUpdate(sLogic) {
    const updatedRows = [...aMethodData];
    updatedRows[iLogicWriterIndex]["Logic"] = sLogic;
    setMethodData(updatedRows);
    handleCloseDialog();
  }

  function handleCancel() {
    setMethodData([]);
    setApiPath();
    props.close();
  }
  function handleConfirm() {
    var oRoute = {
        Path : sApiPath,
        Methods : aMethodData
    }
    props.update(oRoute);
    handleCancel();
  }

  function handleCloseDialog() {
    setParameterDialog(false);
    setReturnParameterDialog(false);
    setLogicWriterDialog(false);
  }

  return (
    <>
      <Dialog
        open={props.open}
        maxWidth="sm"
        fullWidth={true}
        sx={{ minHeight: "300px" }}
      >
        <DialogTitle>{"Create New API"}</DialogTitle>
        <DialogContent>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <label
              htmlFor="Route Path"
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              API Description
            </label>
            <input
              id="route-name"
              type="text"
              placeholder="Enter Path"
              defaultValue={sApiPath}
              style={{
                padding: "10px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                outline: "none",
                transition: "border-color 0.3s",
                width: "100%",
              }}
              onChange={(e) => setApiPath(e.target.value)}
              onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
              onBlur={(e) => (e.target.style.borderColor = "#ccc")}
            />
          </div>
          <h2>Methods</h2>
          <Box>
            <IconButton>
              <AddIcon
                onClick={handleIncreaseRow}
                baseClassName="fas"
                className="fa-plus-circle"
                color="primary"
              />
            </IconButton>
            <IconButton>
              <RemoveIcon
                onClick={handleRemoveRow}
                baseClassName="fas"
                className="fa-plus-circle"
                color="secondary"
              />
            </IconButton>
            <table id="idTableConfiguration" className="RenderComponent-Table">
              <thead>
                <tr>
                  <th></th>
                  <th className="RenderComponent-Table-th">Method Name</th>
                  <th className="RenderComponent-Table-th">Parameter</th>
                  <th className="RenderComponent-Table-th">Return Parameter</th>
                  <th className="RenderComponent-Table-th">Logic</th>
                </tr>
              </thead>
              <tbody>
                {aMethodData.map((row, index) => (
                  <tr key={index}>
                    <td className="RenderComponent-Table-td">
                      <input
                        type="checkbox"
                        checked={aSelectedRows[index]}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </td>
                    <td className="RenderComponent-Table-td">
                      <select
                        type="text"
                        className="RenderComponent-Table-input"
                        defaultValue={row.MethodName}
                        onChange={(event) =>
                          handleEventChange(event, index, "MethodName")
                        }
                      >
                        <option disabled value="">
                          Select Method
                        </option>
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                        <option value="DELETE">DELETE</option>
                      </select>
                    </td>
                    <td className="RenderComponent-Table-td">
                      <IconButton
                        size="small"
                        onClick={(event) => handleParameter(index)}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </td>
                    <td className="RenderComponent-Table-td">
                      <IconButton
                        size="small"
                        onClick={(event) => handleReturnParameter(index)}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </td>
                    <td className="RenderComponent-Table-td">
                      <IconButton
                        size="small"
                        onClick={(event) => handleLogicWriter(index)}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>Create</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <ParameterDialog
        open={bParameterDialog}
        close={handleCloseDialog}
        oMethods={aMethodData[iParameterIndex]}
        update={handleParameterUpdate}
      />
      <ReturnParameterDialog
        open={bReturnParameterDialog}
        close={handleCloseDialog}
        oMethods={aMethodData[iReturnParameterIndex]}
        update={handleReturnParameterUpdate}
      />
      <LogicWriterDialog
        open={bLogicWriterDialog}
        close={handleCloseDialog}
        oMethods={aMethodData[iLogicWriterIndex]}
        update={handleLogicWriterUpdate}
      />
    </>
  );
};
