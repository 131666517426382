import React from "react";
import "./RenderLogicDetails.css";

//Components
import RenderLogicFlow from "./renderLogicFlow/RenderLogicFlow";

export default function RenderLogicDetails(props) {
  if (!props.visible) {
    return;
  }

  return (
    <div id="reactlogicflow" style={{width:"100%",height:"100%",overflow:"hidden",position:"relative",zIndex:"0"}}>
      <RenderLogicFlow
        visible={props.renderFlow}
        nodes={props.selectedLogicNodes}
        change={props.onNodesChange}
        update={props.onNodeUpdate}
        select={props.onSelect}
        delete={props.onDelete}
        edges={props.edges}
        onEdgesChange={props.onEdgesChange}
        onConnect={props.onConnect}
        selectedComponent={props.selectedComponent}
        selectLogic={props.selectLogic}
        updateprompt={props.updateprompt}
        UIData={props.UIData}
        reSelect = {props.reSelect}
        UIdata={props.UIdata}
      />
    </div>
  );
}
