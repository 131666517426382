import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { TreeGridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-treegrid';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

export const APIFields = (props) => {
    const [aTempSelectedFields, setTempSelectedFields] = useState({});
    const [oCheckBoxData,setCheckBoxData]=useState({})

    useEffect(() => {
      if (props.selectedOptions && Object.keys(props.selectedOptions).length > 0) {
        setTempSelectedFields({ ...props.selectedOptions });
      }
      if (props.aDataFields && Object.keys(props.aDataFields).length > 0) {
        setCheckBoxData({ ...props.aDataFields });
      }
    }, [props.selectedOptions, props.aDataFields]);

  const handleCheckBoxChange = (args, item) => {
    let aSelections = [...aTempSelectedFields[props.iIndex]]
    const updatedData = oCheckBoxData[props.iIndex].map(firstLevelField => {
      if (item.parent === firstLevelField.parent && firstLevelField.Name === item.Name) {
        if (args.checked) {
          aSelections.push(item.Name);
        } else {
          aSelections = aSelections.filter(name => name !== item.Name);
        }
        return { ...firstLevelField, checked: args.checked };
      }
      if (firstLevelField.subtask) {
        const updatedSubtasks = firstLevelField.subtask.map(secondLevelField => {
          if (item.parent === secondLevelField.parent && secondLevelField.Name === item.Name) {
            if (args.checked) {
              aSelections.push(`${firstLevelField.Name}.${item.Name}`);
            } else {
              aSelections = aSelections.filter(name => name !== `${firstLevelField.Name}.${item.Name}`);
            }
            return { ...secondLevelField, checked: args.checked };
          }
          if (secondLevelField.subtask) {
            const updatedSubLeveltasks = secondLevelField.subtask.map(thirdLevelField => {
              if (item.parent === thirdLevelField.parent && thirdLevelField.Name === item.Name) {
                if (args.checked) {
                  aSelections.push(`${firstLevelField.Name}.${secondLevelField.Name}.${item.Name}`);
                } else {
                  aSelections = aSelections.filter(name => name !== `${firstLevelField.Name}.${secondLevelField.Name}.${item.Name}`);
                }
                return { ...thirdLevelField, checked: args.checked };
              }
              if (thirdLevelField.subtask) {
                const updatedFourthLeveltasks = thirdLevelField.subtask.map(fourthLevelField => {
                  if (item.parent === fourthLevelField.parent && fourthLevelField.Name === item.Name) {
                    if (args.checked) {
                      aSelections.push(`${firstLevelField.Name}.${secondLevelField.Name}.${thirdLevelField.Name}.${item.Name}`);
                    } else {
                      aSelections = aSelections.filter(name => name !== `${firstLevelField.Name}.${secondLevelField.Name}.${thirdLevelField.Name}.${item.Name}`);
                    }
                    return { ...fourthLevelField, checked: args.checked };
                  }
                  return fourthLevelField;
                });
                return { ...thirdLevelField, subtask: updatedFourthLeveltasks };
              }
              return thirdLevelField;
            });
            return { ...secondLevelField, subtask: updatedSubLeveltasks };
          }
          return secondLevelField;
        });
        return { ...firstLevelField, subtask: updatedSubtasks };
      }
      return firstLevelField;
    });
    setTempSelectedFields((prevOptions) => ({
      ...prevOptions,
      [props.iIndex]: aSelections,
    }));
    setCheckBoxData((prevOptions) => ({
      ...prevOptions,
      [props.iIndex]: updatedData,
    }));
  };


  
  const handleUpdate=()=>{
    props.setSelectedOptions({...aTempSelectedFields})
    props.setADataFields({...oCheckBoxData})
    props.update(aTempSelectedFields[props.iIndex])
  }
  
  const handleCancel = () => {
    setCheckBoxData({ ...props.aDataFields });
    props.handleClose(false);
  };

  const checkBoxTemplate = (props) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {props.Type !== 'Array' ? (
          <>
            <CheckBoxComponent
              checked={props.checked}
              change={(args) => handleCheckBoxChange(args, props)}
            />
            <span style={{ marginLeft: '8px' }}>{props.Name}</span>
          </>
        ) : (
          <span>{props.Name}</span>
        )}
      </div>
    );
  };

  return (
    <Dialog
      open={props.open}
      PaperProps={{
        sx: {
          width: "500px",
          maxHeight: "1000px",
        },
      }}
      BackdropComponent="div"
      BackdropProps={{
        style: {
          background: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogTitle style={{ textAlign: "center" }}>Select DB Fields</DialogTitle>
      <DialogContent sx={{ overflowY: "auto" }}>
        <TreeGridComponent
          style={{ width: "400px", minHeight: "fit-content", maxHeight: "300px", overflowY: "auto", overflowX: "auto" }}
          dataSource={oCheckBoxData[props.iIndex]}
          childMapping="subtask"
          treeColumnIndex={0}
        >
          <ColumnsDirective>
            <ColumnDirective field="Name" headerText="Field Name" width="200" template={checkBoxTemplate} />
            <ColumnDirective field="Type" headerText="Field Type" width="200" />
          </ColumnsDirective>
        </TreeGridComponent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdate}>Update</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
