/**
 * Returns Adjusted X, Y values for the new component
 * @param {JSON} oComponentModel 
 * @param {integer} iClientX 
 * @param {integer} iClientY 
 * @returns 
 */
export function getNewComponentPosition(oComponentModel, iClientX, iClientY) {
    var oDropzoneEdges = document.getElementById("idDropZone").getBoundingClientRect();

    //Check whether the component dropped outside the dropzone
    if (oDropzoneEdges.left < iClientX && oDropzoneEdges.right > iClientX) {
        oComponentModel.left = iClientX;
    }

    if (oDropzoneEdges.top < iClientY && oDropzoneEdges.bottom > iClientY) {
        oComponentModel.top = iClientY;
    }

    return oComponentModel;
}