import React from 'react';
import "./MenuItems.css";
import editorMenu from './images/editorMenu.png';
import formIcon from './images/formIcon.png';
import workflowIcon from "./images/workflowIcon.png";
import dataIcon from "./images/dataIcon.png";
import APIIcon from "./images/APIIcon.png";

function disableAllMenuItems() {
    document.getElementById("idDesignerButton").className = "MenuItems-Icon";
    document.getElementById("idFormButton").className = "MenuItems-Icon";
    document.getElementById("idWorkflowButton").className = "MenuItems-Icon";
    document.getElementById("idDataButton").className = "MenuItems-Icon";
    document.getElementById("idAPIButton").className = "MenuItems-Icon";
}

function loadMenuItem(props, sSelectedItem) {
    disableAllMenuItems();
    switch (sSelectedItem) {
        case "designer":
            document.getElementById("idDesignerButton").className = "MenuItems-Selected";
            props.select("designer");
            break;
        case "form":
            document.getElementById("idFormButton").className = "MenuItems-Selected";
            props.select("form");
            break;
        case "workflow":
            document.getElementById("idWorkflowButton").className = "MenuItems-Selected";
            props.select("workflow");
            break;
        case "data":
            document.getElementById("idDataButton").className = "MenuItems-Selected";
            props.select("data");
            break;
        case "api":
            document.getElementById("idAPIButton").className = "MenuItems-Selected";
            props.select("api");
            break;
        default:
            document.getElementById("idDesignerButton").className = "MenuItems-Selected";
            props.select("designer");
            break;
    }
}

export const MenuItems = (props) => {
    return (
        <div className="MenuItems-controlMenus">

            <button id="idDesignerButton" className={props.selectedItem === "designer" ? "MenuItems-Selected" : "MenuItems-Icon"}
                onClick={() => loadMenuItem(props, "designer")}>
                <img
                    src={editorMenu}
                    alt='Designer'
                    className="MenuItems-Image"
                />
            </button>


            <button id="idFormButton" className={props.selectedItem === "form" ? "MenuItems-Selected" : "MenuItems-Icon"}
                onClick={() => loadMenuItem(props, "form")} >
                <img
                    src={formIcon}
                    alt='Form'
                    className="MenuItems-Image"
                />
            </button>

            <button id="idWorkflowButton" className={props.selectedItem === "workflow" ? "MenuItems-Selected" : "MenuItems-Icon"}
                onClick={() => loadMenuItem(props, "workflow")}>
                <img
                    src={workflowIcon}
                    alt='WorkFlow'
                    className="MenuItems-Image"
                />
            </button>

            <button id="idDataButton" className={props.selectedItem === "data" ? "MenuItems-Selected" : "MenuItems-Icon"}
                onClick={() => loadMenuItem(props, "data")}>
                <img
                    src={dataIcon}
                    alt='Data'
                    className="MenuItems-Image"
                />
            </button>

            <button id="idAPIButton" className={props.selectedItem === "api" ? "MenuItems-Selected" : "MenuItems-Icon"}
                onClick={() => loadMenuItem(props, "api")}>
                <img
                    src={APIIcon}
                    alt='Api'
                    className="MenuItems-Image"
                />
            </button>

        </div >
    )
}