import React from "react";
import styled from "styled-components";
import { Chart } from "react-google-charts";
export const getDefaultChartComponent = (oComponentModel,handleOnClick,aUIComponents) => {
  var sDiv = oComponentModel.div;
  var oComponent;
  var sChartName;

  function handleClickGraph(event, oSelection) {
    oComponentModel.barSelection = oSelection;
    handleOnClick(event, oComponentModel, aUIComponents);
  }

  /**
   * Render Chart
   * @param {array} aData
   * @param {object} oOptions
   * @param {string} sChartName
   * @returns
   */
  function getDefaultChart(aData, oOptions, sChartName) {
    const EmptyState = styled.div`
      background: #f7f7f7;
      color: rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;

      h2,
    `;
    return (
      <div className="RenderComponent-Chart">
        {aData.length > 1?(
        <Chart
          chartType={sChartName}
          width="100%"
          height="100%"
          data={aData}
          options={oOptions}
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper, event }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                const selectedItem = selection[0];
                if (selectedItem) {
                  handleClickGraph(event, selectedItem);
                }
              },
            },
          ]}
        />
        ):(<EmptyState>
          <h3>{oOptions.title}</h3>
          <h4>No data available here</h4>
        </EmptyState>)}
        
      </div>
    );
  }
  if (sDiv === "barchart") {
    var sBarchartTitle = oComponentModel.properties.find(function (oProperty) {
      return oProperty.id === "idBarChartName";
    });
    var sXAxisFieldName = oComponentModel.properties.find(function (oProperty) {
      return oProperty.id === "idXAxisFieldName";
    });
    var sYAxisFieldName = oComponentModel.properties.find(function (oProperty) {
      return oProperty.id === "idYAxisFieldName";
    });
    var bColumnsChart = oComponentModel.properties.find(function (oProperty) {
      return oProperty.id === "idColumnsChart";
    });
    var bStacked = oComponentModel.properties.find(function (oProperty) {
      return oProperty.id === "idStackedBarChart";
    });
    var sLegendPosition = oComponentModel.properties.find(function (oProperty) {
      return oProperty.id === "idLegendPosition";
    });
    var aBarData = oComponentModel.Data ?? [];
    const oOptions = {
      title: sBarchartTitle.value,
      chartArea: { width: "50%" },
      bar: { groupWidth: "80%" },
      isStacked: bStacked.value,
      hAxis: {
        title: sXAxisFieldName.value,
        format: '0'
      },
      vAxis: {
        title: sYAxisFieldName.value,
      },
      legend: { position: sLegendPosition.value },
    };
    sChartName = "BarChart";
    if (bColumnsChart.value) {
      sChartName = "ColumnChart";
    }
    oComponent = getDefaultChart(aBarData, oOptions, sChartName);
    return oComponent;
  } else if (sDiv === "piechart") {
    var sPiechartTitle = oComponentModel.properties.find(function (oProperty) {
      return oProperty.id === "idPieChartName";
    });
    var aData = oComponentModel.Data ?? [];

    const options = {
      title: sPiechartTitle.value,
      pieStartAngle: 100,
    };
    sChartName = "PieChart";
    oComponent = getDefaultChart(aData, options, sChartName);
    return oComponent;
  }
};
