import React, { useState, useEffect } from "react";
import { ConditionOperators } from "./operators/conditionOperator";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
//Dialog Components
import FormGroup from "@mui/material/FormGroup";

//Server
import Server from "../../../../serverDetails/server";

export const DeleteCondition = (props) => {
  const [aConditions, setConditions] = useState([]);
  const [aFields, setFields] = useState([]);
  const [aSelectedRows, setSelectedRows] = useState([]);
  var aOperators = [" ", "Equal", "Not Equal", "Less Than", "Greater Than"];

  if (!props.open) {
    return;
  }
  if (aConditions.length === 0) {
    setConditions(props.condition);
  }
  
  if (aFields.length === 0 && props.table && props.open && props.CID) {
    Server.get(`/tablefields/${props.table}/${props.admin}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.data.fields) {
          if (response.data.fields) {
            var aFields = [];
            var oFields = response.data.fields;
            aFields = Object.keys(oFields);
            aFields.unshift(" ");
            setFields([...aFields]);
          }
        }
      })
      .catch(function (oError) {
        //Error handling
      });
  }

  function handleChangeField(name, value) {
    var temp = aConditions;
    temp[name].field = value;
    setConditions([...temp]);
  }

  function handleChangeSubField(name, value){
    var temp = aConditions;
    temp[name].subfield = value;
    setConditions([...temp]);
  }

  function handleChangeOperator(name, value) {
    var temp = aConditions;
    temp[name].operator = value;
    setConditions([...temp]);
  }

  function handleChangeInputValue(name, value) {
    var temp = aConditions;
    temp[name].value = value;
    setConditions([...temp]);
  }

  function handleChangeDataType(name, value) {
    var temp = aConditions;
    temp[name].dataType = value;
    setConditions([...temp]);
  }

  function handleUpdate() {
    props.update(aConditions);
  }

  function handleIncreaseRow() {
    var temp = aConditions;
    temp.push({
        dataType: "",
        field: "",
        operator: "",
        subfield:"",
        value:""
    });
    setConditions([...temp]);
  }

  function handleRemoveRow() {
    const updatedRows = aConditions.filter(
      (_, index) => !aSelectedRows[index]
    );
    setConditions([...updatedRows])
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...aSelectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };
  return (
    <div>
      <Dialog open={props.open} maxWidth="md" fullWidth={true}>
        <DialogTitle>Condition to be applied on table</DialogTitle>
        <DialogContent>
          <IconButton>
            <AddIcon
              onClick={handleIncreaseRow}
              baseClassName="fas"
              className="fa-plus-circle"
              color="primary"
            />
          </IconButton>
          {aConditions.length > 1 ? (
            <IconButton>
              <RemoveIcon
                onClick={handleRemoveRow}
                baseClassName="fas"
                className="fa-plus-circle"
                color="secondary"
              />
            </IconButton>
          ) : (
            ""
          )}<br></br>
          <div style={{width:"100%"}}>
          <label style={{display:"inline-block",width:"20px"}}></label>
          <label style={{display:"inline-block",width:"170px"}}>Field</label>
          <label style={{display:"inline-block",width:"170px"}}>SubField</label>
          <label style={{display:"inline-block",width:"170px"}}>Condition</label>
          <label style={{display:"inline-block",width:"170px"}}>Value</label>
          <label style={{display:"inline-block",width:"150px"}}>DataType</label>
          </div>
          <FormGroup>
            <ConditionOperators
              conditions={aConditions}
              fields={aFields}
              operators={aOperators}
              inputs={props.inputs}
              changeField={handleChangeField}
              changesubField={handleChangeSubField}
              changeOperator={handleChangeOperator}
              changeValue={handleChangeInputValue}
              changeDataType={handleChangeDataType}
              aSelectedRows={aSelectedRows}
              handleCheckboxChange={handleCheckboxChange}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
