import OpenAI from "openai";

export default async function Apicall( payload,setLoading ) {
  try {
    setLoading(true)
    const apiKey = "sk-proj-iZQkQRbD1LDrLN5SE9F5T3BlbkFJQyzGFbuzEjxODQtes7xs";
    const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });
    const response = await openai.chat.completions.create(payload);
    const messageContent = response.choices[0].message.content;
    
    try {
      const messageContentJson = JSON.parse(messageContent);
      setLoading(false)
      return messageContentJson;
    } catch (jsonError) {
      setLoading(false)
      return '{Error : Response is Not Valid}';
    }

  } catch (error) {
    setLoading(false)
    console.error("Error making API call: ", error);
    return '{Error : Response is Not Valid}';
  }
}