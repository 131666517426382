import React, { useState } from "react";

//Material UI Components
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

//Dialog Components
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { WhereConditionDialog } from "./whereConditions/WhereConditionDialog";

export const TableArithematicCalculator = (props) => {
  var rowData = [];
  const [aColumns, setColumns] = useState([]);
  const oOperators = ["", "Add", "Multiply"];
  const [iTabValue, setTabValue] = useState(0);

  if (aColumns.length <= 0 && props.fields.length > 0) {
    if (props.fields.length === props.rows.columnFields.length) {
      setColumns(props.rows.columnFields);
    } else if (props.fields.length > props.rows.columnFields.length) {
      setColumns(props.fields);
    } else if (props.fields.length < props.rows.columnFields.length) {
      var temp = [];
      props.fields.forEach(function (oField) {
        props.rows.columnFields.forEach(function (oUpdatedData) {
          if (oUpdatedData.fieldName === oField.fieldName) {
            temp.push(oUpdatedData);
          }
        });
      });
      setColumns(temp);
    }
  }
  if (aColumns.length > 0) {
    for (let i = 0; i < aColumns.length; i++) {
      rowData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              className="RenderComponent-Table-input"
              value={aColumns[i].fieldName}
              disabled
            />
          </td>
          <td className="RenderComponent-Table-td">
            <select
              className="RenderComponent-Table-input"
              value={aColumns[i].operator}
              onChange={(oEvent) => {
                handleChangeOperator(oEvent, i);
              }}
            >
              {oOperators.map(function (Field) {
                return (
                  <option key={Field} value={Field}>
                    {Field}
                  </option>
                );
              })}
            </select>
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler  for Tab changes
   * @param {*} oEvent
   * @param {Number} iNewValue
   */
  function handleTabChange(oEvent, iNewValue) {
    setTabValue(iNewValue);
  }

  /**
   * Event handler for change Operator
   * @param {*} oEvent
   * * @param {Number} i
   */
  function handleChangeOperator(oEvent, i) {
    var tempColumns = [...aColumns];
    tempColumns[i].operator = oEvent.target.value;
    setColumns(tempColumns);
  }

  /**
   * Event handler for Update
   */
  function handleUpdate() {
    props.rows.columnFields = aColumns;
    props.update(props.rows);
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Arithematic Operations</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={iTabValue} onChange={handleTabChange}>
              <Tab label="Table Columns" />
              <Tab label="Where Conditions" />
            </Tabs>
          </Box>
          <br />
          {iTabValue === 0 && (
            <Box>
              <table
                id="idTableConfiguration"
                className="RenderComponent-Table"
              >
                <tr>
                  <th className="RenderComponent-Table-th">Column Names</th>
                  <th className="RenderComponent-Table-th">Operations</th>
                </tr>
                {rowData}
              </table>
            </Box>
          )}
          {iTabValue === 1 && (
            <WhereConditionDialog
              rows={props.rows}
              update={props.handleUpdate}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
