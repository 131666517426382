import React from 'react';
import "./propertiesMenu.css"
import PropertiesRenderer from "./renderer/propertiesRenderer"

function PropertiesMenu(props) {
    return (
        <div className="PropertiesMenu-div">
            <div className="PropertiesMenu-Title">
                <label className="PropertiesMenu-Properties-Label">
                    Properties
                </label>
            </div>
            <div className="PropertiesMenu-ComponentsList">
                <PropertiesRenderer
                    selectedMenuItem={props.selectedMenuItem}
                    selectedComponent={props.selectedComponent}
                    selectedLogicComponent={props.selectedLogicComponent}
                    selectedValidationComponent={props.selectedValidationComponent}
                    CID={props.CID}
                    userId={props.userId}
                    appJson={props.appJson}
                    UIData={props.UIData}
                    onUpdate={props.onUpdateProperty}
                    onUpdateForm={props.onUpdateForm}
                    onUpdateLogic={props.onUpdateLogicProperty} 
                    selectedpage={props.selectedpage}
                    selectLogic={props.selectLogic}
                    APPID={props.APPID}
                    loader={props.loader}
                    auth={props.auth}
                    error={props.error}/>
            </div>
        </div>
    )

}

export default PropertiesMenu;