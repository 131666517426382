import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Selecto from "selecto";
import Moveable from "moveable";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./StudioPage.css";

//Icons
import AppStudioLogo from "./images/logobg.png";

//Components
import { TitleBar } from "../../components/header/titleBar/TitleBar";
import { Menu } from "../../components/menu/Menu";
import RenderComponents from "../../components/designRenderer/RenderDesigner";
import RenderForm from "../../components/formRenderer/RenderForm";
import RenderDatabase from "../../components/databaseRenderer/RenderDatabase";
import PropertiesMenu from "../../components/propertyContainer/propertiesMenu/propertiesMenu";
import LogicContainer from "../../components/workflows/logicContainer/LogicContainer";
import RenderLogicDetails from "../../components/workflows/renderLogicDetails/RenderLogicDetails";
import RenderLogicOverview from "../../components/workflows/renderLogicOverview/RenderLogicOverview";
import ValidationContainer from "../../components/workflows/validationContainer/ValidationContainer";
import RenderValidationDetails from "../../components/workflows/renderValidationDetails/RenderValidationDetails";
import { MenuBar } from "../../components/header/menuBar/MenuBar";

//Dialog
import { OperationDialog } from "../../dialog/toolBar/operationDialog/OperationDialog";
import { ResolutionDialog } from "../../dialog/resolutionDialog/ResolutionDialog";
import { MobileViewDialog } from "../../dialog/toolBar/mobileViewDialog/MobileViewDialog";
import { ComponentAlignment } from "../../utilities/component_Alignment";
import { LogicDialog } from "../../dialog/rightPanel/logicDialog/LogicDialog";
import { PublishDialog } from "../../dialog/toolBar/publishDialog/PublishDialog";
import { DeployDialog } from "../../dialog/toolBar/deployDialog/DeployDialog";
import { WizardDialog } from "../../dialog/toolBar/wizardDialog/WizardDialog";
//Server
import Server from "../../serverDetails/server";

//Private methos
import { _retrieveUserVersion } from "./utils/privateMethods";

import { useNavigate } from "react-router-dom";

//Material UI
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import PublishRoundedIcon from "@mui/icons-material/PublishRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Snackbar from "@mui/material/Snackbar";

//ReactFlow
import { applyNodeChanges, applyEdgeChanges, addEdge } from "reactflow";

//Utility files
import { getNewComponentPosition } from "../../utilities/drag_drop";
import { convertResolution } from "../../utilities/resolution_conversion";
import { object } from "prop-types";

function StudioMainPage() {
  //Constant and state declaration
  const [bLoading, setLoading] = useState(false);
  const [sSelectedMenuItem, setSelectedMenuItem] = useState("designer");
  const [aUIData, setUIData] = useState([]);
  const [APPID, setAPPID] = useState("");
  const [CID, setCID] = useState("");
  const [APPDESC, setAppDesc] = useState("");
  const [ProjectDesc, setProjectDesc] = useState("");
  const location = useLocation();
  const [bEnableClose, enableCloseButton] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [sErrorMessage, setErrorMessage] = useState("");
  const [oSelectedComponent, setSelectedComponent] = useState({});
  const [bResolutionDialog, setResolutionDialog] = useState(false);
  const [bRenderLogicFlow, setRenderLogicFlow] = useState(false);
  const [oSelectedLogic, setSelectedLogic] = useState({});
  const [aSelectedLogicNodes, setSelectedLogicNodes] = useState([]);
  const [oSelectedLogicComponent, setSelectedLogicComponent] = useState({});
  const [aEdges, setEdges] = useState([]);
  const [bOpenLogicDialog, setOpenLogicDialog] = useState(false);
  const [bOpenPublishDialog, setOpenPublishDialog] = useState(false);
  const [bOpenDeployDialog, setOpenDeployDialog] = useState(false);
  const [aGroups, setGroups] = useState([]);
  const [aEvents, setEvents] = useState([]);
  const [oSelectedPage, setSelectedPage] = useState({});
  const [oSelectedForm, setSelectedForm] = useState({});
  const [aSelectedComponents, setaSelectedComponents] = useState([]);
  const [oAppJson, setAppJSON] = useState({});
  const [brenderLogicValidateContainer, setrenderLogicValidateContainer] =
    useState(false);
  const [bRenderValidationFlow, setRenderValidationFlow] = useState(false);
  const [oSelectedValidationLogic, setSelectedValidationLogic] = useState({});
  const [aSelectedValidationLogicNodes, setSelectedValidationLogicNodes] =
    useState([]);
  const [
    oSelectedValidationLogicComponent,
    setSelectedValidationLogicComponent,
  ] = useState({});
  const [aValidationEdges, setValidationEdges] = useState([]);
  const [bOpenSnackBar, setOpenSnackBar] = useState(false);
  const [aDatabaseList, setDatabaseList] = useState([]);
  const [bOpenWizardDialog, setOpenWizardDialog] = useState(false);
  const [aSchemaFieldsData, setSchemaFieldsData] = useState([]);
  const [sSelectTemplateCard, setSelectTemplateCard] = useState("");
  const [aSearchFields, setSearchFields] = useState([]);
  const [aFormFields, setFormFields] = useState({ pageName: "", fields: [] });
  const [aTableFieldsData, setTableFieldsData] = useState({
    tableName: "",
    rows: [],
  });
  const [aAuthObjects, setAuthObjects] = useState([]);
  const [isMobileSize, setIsMobileSize] = useState(false);
  const [bMobileViewDialog, setMobileViewDialog] = useState(false);

  const containerRef = useRef(null);
  const selectedComponentsRef = useRef([]);

  var USERID;
  let navigate = useNavigate();

  if (location.state && location.state.USERID) {
    USERID = location.state.USERID;
  }

  /**
   * Execute while rendering StudioPage component
   */
  useEffect(() => {
    // Runs after the first render() lifecycle
    if (location.state && location.state.USERID) {
      USERID = location.state.USERID;
      var oVersionRequest = _retrieveUserVersion(USERID);
      setLoading(true);
      oVersionRequest
        .then(function (oResponse) {
          if (oResponse.data.UserVersion) {
            const bMobileItem = localStorage.getItem('MobileSize')
            var oVersion = oResponse.data.UserVersion;
            if (bMobileItem) {
              setIsMobileSize(bMobileItem);
              if(!oVersion.hasOwnProperty("MOBILE_DATA")){
                oVersion.MOBILE_DATA = {}
              }
            } else {
              if (!oVersion.hasOwnProperty("UIDATA")) {
                oVersion.UIDATA = {};
              }
            }
            var oState = {
              APPID: oVersion.APPID,
              APP_DESC: oVersion.APP_DESC,
              CDESC: oVersion.CDESC,
              CID: oVersion.CID,
              APP_JSON: bMobileItem ? oVersion.MOBILE_DATA : oVersion.UIDATA,
              AUTH_JSON: oVersion.LogicAuthObjects
            };
            updateAppStates(oState);
            updateGroups(oVersion.CID);
            setLoading(false);
          }
          drawCanvas();
        })
        .catch(function (oError) {
          if (oError.response.data) {
            if (oError.response.data.Message === "Please verify your USERID.") {
              setOpenDialog(true);
            } else {
              setErrorMessage(oError.response.data.Message);
              setOpenSnackBar(true);
            }
          } else {
            setErrorMessage(oError.message);
            setOpenSnackBar(true);
          }
          setLoading(false);
        });
    } else {
      setErrorMessage("Appilication interrupted, please login again.");
      setOpenSnackBar(true);
    }
  }, []);

  /**
   * Add page
   * @param {object} oNewPage
   */
  function addPage(oNewPage) {
    var oAppJSON = oAppJson;
    var oNewJSON = { ...oAppJSON };
    oAppJSON[oNewPage.id] = {
      id: oNewPage.id,
      name: oNewPage.name,
      UIComponents: oNewPage.UIComponents,
    };

    setSelectedPage({ ...oAppJSON[oNewPage.id] });
    setUIData([...oAppJSON[oNewPage.id].UIComponents]);

    setAppJSON(oNewJSON);
    updateVersionComponent(oAppJSON[oNewPage.id].UIComponents, oNewPage.id);
  }

  /**
   * SelectPage handler
   * @param {object} oPage
   */
  function handleSelectPage(oPage) {
    var oAppJSON = oAppJson;
    setSelectedPage(oAppJSON[oPage.id]);
    setUIData([...oAppJSON[oPage.id].UIComponents]);
    setSelectedComponent({});
    setRenderLogicFlow(false);
    setSelectedLogic({});
    setSelectedValidationLogic({});
    setRenderValidationFlow(false);
    if (sSelectedMenuItem === "validation") {
      setSelectedMenuItem("designer");
    }
    var canvas = document.getElementById("idCanvasGuide");
    canvas.removeAttribute("hidden");
    setrenderLogicValidateContainer(false);
  }

  useEffect(() => {
    const container = containerRef.current;
    const frameMap = new Map();

    const dragContainer =
      sSelectedMenuItem === "form"
        ? ".studio_mainPage-FormdropZone"
        : ".studio_mainPage-dropZone";
    const selectableTargets =
      sSelectedMenuItem === "form"
        ? [".studio_mainPage-FormdropZone .RenderComponent-Resizeable"]
        : [".studio_mainPage-dropZone .RenderComponent-Resizeable"];

    const selecto = new Selecto({
      container,
      dragContainer,
      selectableTargets,
      hitRate: 0,
      selectByClick: false,
      selectFromInside: false,
      toggleContinueSelect: ["shift"],
      ratio: 0,
    });

    const moveable = new Moveable(container, {
      draggable: true,
    })
      .on("clickGroup", (e) => {
        selecto.clickTarget(e.inputEvent, e.inputTarget);
      })
      .on("dragStart", (e) => {
        const target = e.target;
        if (!frameMap.has(target)) {
          frameMap.set(target, {
            translate: [0, 0],
          });
        }
        const frame = frameMap.get(target);
        e.set(frame.translate);
      })
      .on("drag", (e) => {
        const target = e.target;
        const frame = frameMap.get(target);
        frame.translate = e.beforeTranslate;
        target.style.transform = `translate(${frame.translate[0]}px, ${frame.translate[1]}px)`;
      })
      .on("dragGroupStart", (e) => {
        e.events.forEach((ev) => {
          const target = ev.target;
          if (!frameMap.has(target)) {
            frameMap.set(target, {
              translate: [0, 0],
            });
          }
          const frame = frameMap.get(target);

          ev.set(frame.translate);
        });
      })
      .on("dragGroup", (e) => {
        e.events.forEach((ev) => {
          const target = ev.target;
          const frame = frameMap.get(target);
          frame.translate = ev.beforeTranslate;
          target.style.transform = `translate(${frame.translate[0]}px, ${frame.translate[1]}px)`;
        });
      })
      .on("dragGroupEnd", (e) => {
        if(e.events.length > 0){
          handleUpdateMultiComponent(e.events)
        }
      });

    selecto
      .on("dragStart", (e) => {
        setaSelectedComponents([]);
        const target = e.inputEvent.target;
        if (
          moveable.isMoveableElement(target) ||
          selectedComponentsRef.current.some(
            (t) => t === target || t.contains(target)
          )
        ) {
          e.stop();
        }
      })
      .on("select", (e) => {
        selectedComponentsRef.current = e.selected;
        moveable.target = selectedComponentsRef.current;
      })
      .on("selectEnd", (e) => {
        if (e.selected.length > 0) {
          const aSelectedItems = [];
          e.selected.forEach(function (oElements) {
            aSelectedItems.push(oElements.childNodes[4].id);
          });
          const aMatchedData = [];
          aSelectedItems.forEach((idValue) => {
            const oMatchingData = aUIData.find(
              (item) => item.uniqueId === idValue
            );
            if (oMatchingData) {
              aMatchedData.push(oMatchingData);
            }
          });
          setaSelectedComponents(aMatchedData);
        }
        if (e.isDragStart) {
          e.inputEvent.preventDefault();
          setTimeout(() => {
            moveable.dragStart(e.inputEvent);
          });
        }
      });

    return () => {
      selecto.destroy();
      moveable.destroy();
    };
  }, [oSelectedPage,  sSelectedMenuItem, aUIData]);
  /**
   * Delete page handler
   * @param {object} oPage
   */
  function deletePage(oPage) {
    var oAppJSON = oAppJson;
    delete oAppJSON[oPage.id];
    setAppJSON({ ...oAppJSON });
    var oDefaultPage = oAppJSON[Object.keys(oAppJSON)[0]];
    if (oDefaultPage) {
      setUIData([...oDefaultPage.UIComponents]);
      setSelectedPage({ ...oDefaultPage });
    }
    updateVersionComponent(oDefaultPage.UIComponents);
  }

  /**
   * Add Form
   * @param {object} oform
   */
  function addForm(oform) {
    var oAppJSON = oAppJson;
    var oNewJSON = { ...oAppJSON };

    if (!oNewJSON.forms) {
      oNewJSON.forms = {};
    }

    oNewJSON.forms[oform.id] = {
      id: oform.id,
      name: oform.name,
      UIComponents: oform.UIComponents,
    };

    setSelectedForm({ ...oNewJSON.forms[oform.id] });
    setUIData([...oNewJSON.forms[oform.id].UIComponents]);
    setAppJSON(oNewJSON);
    updateFormComponent(oNewJSON.forms[oform.id].UIComponents, oform.id);
  }

  /**
   * SelectForm handler
   * @param {object} oForm
   */
  function handleSelectForm(oForm) {
    var oAppJSON = oAppJson;
    setSelectedForm(oAppJSON.forms[oForm.id]);
    setUIData([...oAppJSON.forms[oForm.id].UIComponents]);
  }

  /**
   * Delete Form handler
   * @param {object} oForm
   */
  function deleteForm(oForm) {
    var oAppJSON = oAppJson;
    if (
      oAppJSON.forms[oForm.id] ===
      oAppJSON.forms[Object.keys(oAppJSON.forms)[0]]
    ) {
      delete oAppJSON.forms[oForm.id];
      setAppJSON({ ...oAppJSON });
      setSelectedForm({});
      setUIData([]);
      var aUIComponents = [1];
      updateFormComponent(aUIComponents);
    } else {
      delete oAppJSON.forms[oForm.id];
      setAppJSON({ ...oAppJSON });
      var oDefaultForm = oAppJSON.forms[Object.keys(oAppJSON.forms)[0]];
      if (oDefaultForm) {
        setUIData([...oDefaultForm.UIComponents]);
        setSelectedForm({ ...oDefaultForm });
        updateFormComponent(oDefaultForm.UIComponents, oDefaultForm.id);
      }
    }
  }

  /**
   * update the version on server and state
   * @param {*} aUIComponents
   */
  function updateFormComponent(aUIComponents, sFormID) {
    var oUpdatedJSON = oAppJson;
    if (aUIComponents[0] === 1) {
      oUpdatedJSON.forms = {};
    } else {
      if (sFormID) {
        oUpdatedJSON.forms[sFormID].UIComponents = aUIComponents;
      } else {
        oUpdatedJSON.forms[oSelectedForm.id].UIComponents = aUIComponents;
      }
    }
    setErrorMessage("Application cannot be editable in Quality and Production");
    setOpenSnackBar(true);
  }

  /**
   * Draw canvas for allignment guidelines
   */
  function drawCanvas() {
    var oCanvas = document.getElementById("idCanvasGuide");
    var oDropZoneRect = document
      .getElementById("idDropZone")
      .getBoundingClientRect();
    oCanvas.width = oDropZoneRect.width;
    oCanvas.height = oDropZoneRect.height;
    if (oCanvas.getContext) {
      var ctx = oCanvas.getContext("2d");
      var iYAxis = oCanvas.height / 30;
      for (var i = 1; i < 30; i++) {
        ctx.moveTo(0, i * iYAxis);
        ctx.lineTo(oCanvas.width, i * iYAxis);
        ctx.stroke();
      }
      var iXAxis = oCanvas.width / 30;
      for (var j = 1; j < 30; j++) {
        ctx.moveTo(j * iXAxis, 0);
        ctx.lineTo(j * iXAxis, oCanvas.height);
        ctx.stroke();
      }
    }
  }

  /**
   * Update the groups for the customer
   * @param {string} sCustomerID | customer ID
   */
  function updateGroups(sCustomerID) {
    Server.get("/editor/groups", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setGroups(response.data.GroupList);
      })
      .catch(function (oError) {
        if (oError.response.data) {
          setErrorMessage(oError.response.data.Message);
        } else {
          setErrorMessage(oError.message);
        }
        setOpenSnackBar(true);
      });
  }

  /**
   * Event handler to set error message
   * @param {string} sErrorMessage | Error message
   */
  function handleError(sErrorMessage) {
    setErrorMessage(sErrorMessage);
    setOpenSnackBar(true);
  }

  /**
   * Update appilication state
   * @param {object} oAppState | Appilication state
   */
  function updateAppStates(oAppState) {
    if (oAppState) {
      if (Object.keys(oAppState.APP_JSON).length === 0) {
        oAppState.APP_JSON.page1 = {
          id: "page1",
          name: "Home Page",
          UIComponents: [],
        };
      }
      setAppJSON(oAppState.APP_JSON);
      var oDefaultPage = oAppState.APP_JSON[Object.keys(oAppState.APP_JSON)[0]];
      var aDefaultAuth = oAppState.AUTH_JSON;
      if(aDefaultAuth){
        setAuthObjects(aDefaultAuth);
      }
      var oDropZoneEdges = document
        .getElementById("idDropZone")
        .getBoundingClientRect();
      if (oDefaultPage) {
        setUIData(oDefaultPage.UIComponents);
        setSelectedPage(oDefaultPage);
      }

      setAppDesc(oAppState.APP_DESC);
      setProjectDesc(oAppState.CDESC);
      if (CID !== oAppState.CID) {
        updateGroups(oAppState.CID);
      }
      setCID(oAppState.CID);
      setAPPID(oAppState.APPID);
      if (oDefaultPage.UIComponents && oDefaultPage.UIComponents.length > 0) {
        if (
          (oDefaultPage.UIComponents[0].ScreenWidth !== window.screen.width &&
            oDefaultPage.UIComponents[0].ScreenHeight !==
              window.screen.height) ||
          oDefaultPage.UIComponents[0].DropZoneLeft !== oDropZoneEdges.left ||
          oDefaultPage.UIComponents[0].DropZoneTop !== oDropZoneEdges.top
        ) {
          setResolutionDialog(true);
        }
      }
    }
  }

  /**
   * Set selected menu item while switching between different menu items
   * @param {string} sSelectedItem | Selected Menu Item
   */
  function handleSelectMenuItem(sSelectedItem) {
    var oAppJSON = oAppJson;
    if (sSelectedItem === "form") {
      if (oAppJSON.forms) {
        var oDefaultForm = oAppJSON.forms[Object.keys(oAppJSON.forms)[0]];
        if (oDefaultForm) {
          setUIData([...oDefaultForm.UIComponents]);
          setSelectedForm({ ...oDefaultForm });
        } else {
          setUIData([]);
          setSelectedForm({});
        }
      } else {
        setUIData([]);
        setSelectedForm({});
      }
    } else {
      var oDefaultPage = oAppJSON[Object.keys(oAppJSON)[0]];
      if (oDefaultPage) {
        setUIData(oDefaultPage.UIComponents);
        setSelectedPage(oDefaultPage);
      }
    }
    setSelectedMenuItem(sSelectedItem);
    setRenderLogicFlow(false);
    setSelectedLogic({});
    setSelectedValidationLogic({});
    setRenderValidationFlow(false);
    var canvas = document.getElementById("idCanvasGuide");
    if (sSelectedItem === "workflow") {
      canvas.setAttribute("hidden", "hidden");
      setrenderLogicValidateContainer(true);
    } else {
      canvas.removeAttribute("hidden");
      setrenderLogicValidateContainer(false);
    }
  }

  /**
   * Event handler to close Operation dialog
   */
  function handleClose() {
    setOpenDialog(false);
  }

  /**
   * Even handle for open app widget
   * @param {*} oEvent
   */
  function handleOpenOperation(oEvent) {
    enableCloseButton(true);
    setOpenDialog(true);
  }

  /**
   * Handle on drop of new or existing component
   * @param {event} oEvent
   */
  function handleDropComponent(oEvent) {
    if (sSelectedMenuItem === "designer") {
      var sComponentId = oEvent.dataTransfer.getData("text");
      var oComponentModel, iIndex, offSet;
      var aUIComponents = [...aUIData];
      if (sComponentId) {
        oComponentModel = aUIData.find(function (oModel) {
          return oModel.uniqueId === sComponentId;
        });
        iIndex = aUIData.findIndex(function (oModel) {
          return oModel.uniqueId === sComponentId;
        });
        offSet = JSON.parse(oEvent.dataTransfer.getData("json"));
        oComponentModel = getNewComponentPosition(
          oComponentModel,
          oEvent.clientX - offSet.offsetX,
          oEvent.clientY - offSet.offsetY
        );
        oComponentModel = _adjustDropzoneArea(oComponentModel);
        aUIComponents[iIndex] = oComponentModel;
      } else {
        var sComponentModel = JSON.parse(oEvent.dataTransfer.getData("json"));
        var oDropZoneEdges = document
          .getElementById("idDropZone")
          .getBoundingClientRect();
        sComponentModel.DropZoneLeft = oDropZoneEdges.left;
        sComponentModel.DropZoneTop = oDropZoneEdges.top;
        sComponentModel.DropZoneRight = oDropZoneEdges.right;
        sComponentModel.DropZoneHeight = oDropZoneEdges.height;
        sComponentModel.DropZoneBottom = oDropZoneEdges.bottom;
        sComponentModel.DropZoneWidth = oDropZoneEdges.width;
        var oComponentModel = getNewComponentPosition(
          sComponentModel,
          oEvent.clientX,
          oEvent.clientY
        );
        var aComponents = aUIComponents.filter(function (oComponent) {
          return oComponent.Component === oComponentModel.Component;
        });
        if (!oComponentModel.uniqueId) {
          if (aComponents.length === 0) {
            oComponentModel.uniqueId =
              "id" +
              oSelectedPage.id +
              oComponentModel.Component +
              aComponents.length;
          } else {
            var iDLength = aComponents[aComponents.length - 1].uniqueId;
            iDLength = parseInt(iDLength.match(/\d+$/)[0], 10);
            iDLength = iDLength + 1;
            oComponentModel.uniqueId =
              "id" + oSelectedPage.id + oComponentModel.Component + iDLength;
          }
        }
        oComponentModel = _adjustDropzoneArea(oComponentModel);
        aUIComponents.push(oComponentModel);
      }
      updateVersionComponent(aUIComponents);
      var aResizers = document.getElementsByClassName(
        "RenderComponent-Resizer"
      );
      for (let index = 0; index < aResizers.length; index++) {
        aResizers[index].style.display = "none";
      }
    } else if (sSelectedMenuItem === "workflow") {
      var aLogic = oSelectedComponent.Logic;
      var oDropZoneEdges = document
        .getElementById("idDropZone")
        .getBoundingClientRect();
      var x = oEvent.pageX - oDropZoneEdges.x;
      var y = oEvent.pageY - oDropZoneEdges.y;
      var sLogicComponentModel = JSON.parse(
        oEvent.dataTransfer.getData("json")
      );
      var aComponents = oSelectedLogic.component;
      var iLength = 0;

      if (aComponents.length > 0) {
        iLength = aComponents.length;
      }
      var sId =
        sLogicComponentModel.id +
        iLength +
        "Logic" +
        aLogic.length +
        oSelectedComponent.uniqueId;

      var oLogicComponent = {
        id: sId,
        data: {
          label: sLogicComponentModel.Component,
          id: sLogicComponentModel.id,
        },
        position: {
          x: x,
          y: y,
        },
        properties: sLogicComponentModel.properties,
      };
      if (sLogicComponentModel["type"]) {
        oLogicComponent.type = sLogicComponentModel.type;
      }
      if (sLogicComponentModel["targetPosition"]) {
        oLogicComponent.targetPosition = sLogicComponentModel.targetPosition;
      }
      if (sLogicComponentModel["sourcePosition"]) {
        oLogicComponent.sourcePosition = sLogicComponentModel.sourcePosition;
      }
      var aLogicNodes = [];
      if (aSelectedLogicNodes && aSelectedLogicNodes.length > 0) {
        aLogicNodes = [...aSelectedLogicNodes];
      }
      aLogicNodes.push(oLogicComponent);
      aComponents.push(oLogicComponent);
      handleUpdateComponent(oSelectedComponent);
      setSelectedLogicNodes(aLogicNodes);
      setEdges(oSelectedLogic.edges);
      setSelectedLogicComponent(oLogicComponent);
      var aResizers = document.getElementsByClassName(
        "RenderComponent-Resizer"
      );
      for (let index = 0; index < aResizers.length; index++) {
        aResizers[index].style.display = "none";
      }
    } else if (sSelectedMenuItem === "form") {
      var sComponentId = oEvent.dataTransfer.getData("text");
      var oComponentModel, iIndex, offSet;
      var aUIComponents = [...aUIData];
      if (sComponentId) {
        oComponentModel = aUIData.find(function (oModel) {
          return oModel.uniqueId === sComponentId;
        });
        iIndex = aUIData.findIndex(function (oModel) {
          return oModel.uniqueId === sComponentId;
        });
        offSet = JSON.parse(oEvent.dataTransfer.getData("json"));
        oComponentModel = getNewComponentPosition(
          oComponentModel,
          oEvent.clientX - offSet.offsetX,
          oEvent.clientY - offSet.offsetY
        );
        oComponentModel = _adjustDropzoneArea(oComponentModel);
        aUIComponents[iIndex] = oComponentModel;
      } else {
        var sComponentModel = JSON.parse(oEvent.dataTransfer.getData("json"));
        var oDropZoneEdges = document
          .getElementById("idDropZone")
          .getBoundingClientRect();
        sComponentModel.DropZoneLeft = oDropZoneEdges.left;
        sComponentModel.DropZoneTop = oDropZoneEdges.top;
        sComponentModel.DropZoneRight = oDropZoneEdges.right;
        sComponentModel.DropZoneHeight = oDropZoneEdges.height;
        sComponentModel.DropZoneBottom = oDropZoneEdges.bottom;
        sComponentModel.DropZoneWidth = oDropZoneEdges.width;
        var oComponentModel = getNewComponentPosition(
          sComponentModel,
          oEvent.clientX,
          oEvent.clientY
        );
        var aComponents = aUIComponents.filter(function (oComponent) {
          return oComponent.Component === oComponentModel.Component;
        });
        if (!oComponentModel.uniqueId) {
          oComponentModel.uniqueId =
            "id" +
            oSelectedForm.id +
            oComponentModel.Component +
            aComponents.length;
        }
        oComponentModel = _adjustDropzoneArea(oComponentModel);
        aUIComponents.push(oComponentModel);
      }
      updateFormComponent(aUIComponents);
      var aResizers = document.getElementsByClassName(
        "RenderComponent-Resizer"
      );
      for (let index = 0; index < aResizers.length; index++) {
        aResizers[index].style.display = "none";
      }
    } else if (sSelectedMenuItem === "validation") {
      var aValidationLogic = oSelectedLogic.validations;
      var oDropZoneEdges = document
        .getElementById("idDropZone")
        .getBoundingClientRect();
      var x = oEvent.pageX - oDropZoneEdges.x;
      var y = oEvent.pageY - oDropZoneEdges.y;
      var sLogicComponentModel = JSON.parse(
        oEvent.dataTransfer.getData("json")
      );
      var aComponents = oSelectedValidationLogic.component;
      var iLength = 0;

      if (aComponents.length > 0) {
        iLength = aComponents.length;
      }
      var sId =
        sLogicComponentModel.id +
        iLength +
        "Validation" +
        aValidationLogic.length +
        oSelectedComponent.uniqueId;

      var oLogicComponent = {
        id: sId,
        data: {
          label: sLogicComponentModel.Component,
          id: sLogicComponentModel.id,
        },
        position: {
          x: x,
          y: y,
        },
        properties: sLogicComponentModel.properties,
      };
      if (sLogicComponentModel["type"]) {
        oLogicComponent.type = sLogicComponentModel.type;
      }
      if (sLogicComponentModel["targetPosition"]) {
        oLogicComponent.targetPosition = sLogicComponentModel.targetPosition;
      }
      if (sLogicComponentModel["sourcePosition"]) {
        oLogicComponent.sourcePosition = sLogicComponentModel.sourcePosition;
      }
      var aValidationLogicNodes = [];
      if (
        aSelectedValidationLogicNodes &&
        aSelectedValidationLogicNodes.length > 0
      ) {
        aValidationLogicNodes = [...aSelectedValidationLogicNodes];
      }
      aValidationLogicNodes.push(oLogicComponent);
      aComponents.push(oLogicComponent);
      handleUpdateComponent(oSelectedComponent);
      setSelectedValidationLogicNodes(aValidationLogicNodes);
      setValidationEdges(oSelectedValidationLogic.edges);
      setSelectedValidationLogicComponent(oLogicComponent);
      var aResizers = document.getElementsByClassName(
        "RenderComponent-Resizer"
      );
      for (let index = 0; index < aResizers.length; index++) {
        aResizers[index].style.display = "none";
      }
    } else if (sSelectedMenuItem === "data") {
      var sComponentId = oEvent.dataTransfer.getData("text");
      var oComponentModel, iIndex, offSet;
      var aUIComponents = [...aDatabaseList];
      if (sComponentId) {
        oComponentModel = aDatabaseList.find(function (oModel) {
          return oModel.uniqueId === sComponentId;
        });
        iIndex = aUIData.findIndex(function (oModel) {
          return oModel.uniqueId === sComponentId;
        });
        offSet = JSON.parse(oEvent.dataTransfer.getData("json"));
        oComponentModel = getNewComponentPosition(
          oComponentModel,
          oEvent.clientX - offSet.offsetX,
          oEvent.clientY - offSet.offsetY
        );
        oComponentModel = _adjustDropzoneArea(oComponentModel);
        aUIComponents[iIndex] = oComponentModel;
        setDatabaseList(aUIComponents);
      } else {
        var sComponentModel = JSON.parse(oEvent.dataTransfer.getData("json"));
        let bCheckIsAvialable = false;
        aDatabaseList.forEach(function (oProperty) {
          if (
            oProperty.oComponent.tableName ===
            sComponentModel.oComponent.tableName
          ) {
            bCheckIsAvialable = true;
          }
        });
        if (!bCheckIsAvialable) {
          var oDropZoneEdges = document
            .getElementById("idDropZone")
            .getBoundingClientRect();
          sComponentModel.DropZoneLeft = oDropZoneEdges.left;
          sComponentModel.DropZoneTop = oDropZoneEdges.top;
          sComponentModel.DropZoneRight = oDropZoneEdges.right;
          sComponentModel.DropZoneHeight = oDropZoneEdges.height;
          sComponentModel.DropZoneBottom = oDropZoneEdges.bottom;
          sComponentModel.DropZoneWidth = oDropZoneEdges.width;
          var oComponentModel = getNewComponentPosition(
            sComponentModel,
            oEvent.clientX,
            oEvent.clientY
          );
          oComponentModel.DivWidth = "auto";
          oComponentModel.DivHeight = "auto";
          oComponentModel.CSS = {
            width: "100%",
            height: "100%",
            resizer: {
              top: "0px",
              bottom: "0px",
              right: "0px",
              left: "0px",
            },
          };
          if (!oComponentModel.uniqueId) {
            oComponentModel.uniqueId =
              "id" +
              oSelectedPage.id +
              sComponentModel.oComponent.tableName +
              aUIComponents.length;
          }
          oComponentModel = _adjustDropzoneArea(oComponentModel);
          aUIComponents.push(oComponentModel);
          setDatabaseList(aUIComponents);
        }
      }
      var aResizers = document.getElementsByClassName(
        "RenderComponent-Resizer"
      );
      for (let index = 0; index < aResizers.length; index++) {
        aResizers[index].style.display = "none";
      }
    }
  }

  /**
   * Handler on update of existing component
   * @param {object} oComponentModel
   * @param {boolean} bUIOnly | update UI only
   */

  function handleUpdateComponent(oComponentModel, bUIOnly) {
    var aUIComponents = [...aUIData];
    var iIndex = aUIData.findIndex(function (oModel) {
      return oModel.uniqueId === oComponentModel.uniqueId;
    });
    oComponentModel = _adjustDropzoneArea(oComponentModel);
    aUIComponents[iIndex] = oComponentModel;
    if (!bUIOnly) {
      updateVersionComponent(aUIComponents);
    } else {
      setUIData(aUIComponents);
      var oAppJSON = { ...oAppJson };
      oAppJSON[oSelectedPage.id].UIComponents = aUIComponents;
      setAppJSON(oAppJSON);
    }
  }

  function handleUpdateMultiComponent(aComponents) {
    var aUIComponents = [...aUIData];
    aComponents.forEach(function (oComponentData) {
      var sTargetid = oComponentData.target.id;
      sTargetid = sTargetid.replace("div", "");
      var iIndex = aUIComponents.findIndex((oModel) => oModel.uniqueId === sTargetid);
      if (iIndex !== -1) {
        var updatedComponent = {
          ...aUIComponents[iIndex],
          left: oComponentData.lastEvent.left,
          top: oComponentData.lastEvent.top
        };
        updatedComponent = _adjustDropzoneArea(updatedComponent);
        aUIComponents[iIndex] = updatedComponent;
      }
    });
    updateVersionComponent(aUIComponents);
  }

  /**
   * Handler on update of existing component
   * @param {object} oComponentModel
   * @param {boolean} bUIOnly | update UI only
   */
  function handleUpdateFormComponent(oComponentModel, bUIOnly) {
    var aUIComponents = [...aUIData];

    var iIndex = aUIData.findIndex(function (oModel) {
      return oModel.uniqueId === oComponentModel.uniqueId;
    });
    oComponentModel = _adjustDropzoneArea(oComponentModel);
    aUIComponents[iIndex] = oComponentModel;
    if (!bUIOnly) {
      updateFormComponent(aUIComponents);
    } else {
      setUIData(aUIComponents);
      var oAppJSON = { ...oAppJson };
      oAppJSON.forms[oSelectedForm.id].UIComponents = aUIComponents;
      setAppJSON(oAppJSON);
    }
  }

  /**
   * Event handler to update logic component model
   * @param {object} oLogicComponentModel | logic component model
   * @param {boolean} bUIOnly | update UI only
   */
  function handleUpdateLogicComponent(oLogicComponentModel, bUIOnly) {
    if (oLogicComponentModel) {
      var aUIComponents = [...aUIData];
      if (!bUIOnly) {
        updateVersionComponent(aUIComponents);
      } else {
        setUIData(aUIComponents);

        var oAppJSON = { ...oAppJson };
        oAppJSON[oSelectedPage.id].UIComponents = aUIComponents;
        setAppJSON(oAppJSON);
      }
    }
  }

  /**
   * Adjust componet edges based on drop zone
   * @param {object} oComponentModel
   * @returns
   */
  function _adjustDropzoneArea(oComponentModel) {
    var oDropZoneEdges = document
      .getElementById("idDropZone")
      .getBoundingClientRect();
    if (
      parseFloat(oComponentModel.DivWidth) + oComponentModel.left >
      oDropZoneEdges.right
    ) {
      oComponentModel.left =
        oDropZoneEdges.right - parseFloat(oComponentModel.DivWidth);
    }

    if (oComponentModel.left < oDropZoneEdges.left) {
      oComponentModel.left = oDropZoneEdges.left;
    }
    if (oComponentModel.top < oDropZoneEdges.top) {
      oComponentModel.top = oDropZoneEdges.top;
    }
    if (
      oDropZoneEdges.bottom <
      oComponentModel.top + parseFloat(oComponentModel.DivHeight)
    ) {
      oComponentModel.top =
        oDropZoneEdges.bottom - parseFloat(oComponentModel.DivHeight);
    }
    return oComponentModel;
  }

  /**
   * update the version on server and state
   * @param {*} aUIComponents
   */
  function updateVersionComponent(aUIComponents, sPageID) {
    var oCurrentPageUIData;
    if (sSelectedMenuItem !== "form") {
      var oUpdatedJSON = oAppJson;
      if (sPageID) {
        oUpdatedJSON[sPageID].UIComponents = aUIComponents;
        oCurrentPageUIData = oUpdatedJSON[sPageID];
      } else {
        var sID = Object.keys(oUpdatedJSON);
        oUpdatedJSON[oSelectedPage.id].UIComponents = aUIComponents;
        oCurrentPageUIData = oUpdatedJSON[oSelectedPage.id];
      }
      Server.post(
        "/editor/version",
        {
          CDESC: ProjectDesc,
          APP_ID: APPID,
          APP_DESC: APPDESC,
          CURRENTPAGE_UIDATA: oCurrentPageUIData,
          bMobileMode:isMobileSize ? true : false
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
        .then(function (oResponse) {
          setAppJSON({ ...oUpdatedJSON });
          if (sPageID) {
            setUIData([...oUpdatedJSON[sPageID].UIComponents]);
          } else {
            setUIData([...oUpdatedJSON[oSelectedPage.id].UIComponents]);
          }
          setResolutionDialog(false);
          setMobileViewDialog(false);
        })
        .catch(function (oError) {
          if (oError.response.data) {
            setErrorMessage(oError.response.data.Message);
          } else {
            setErrorMessage(oError.response.data.Message);
          }
          setOpenSnackBar(true);
        });
    }
  }

  /**
   * Handler on select of component
   * @param {object} oComponent
   */
  function handleOnSelect(oComponent) {
    if (sSelectedMenuItem === "designer") {
      setSelectedComponent({ ...oComponent });
      if (oComponent.Events) {
        setEvents([...oComponent.Events]);
      }
    } else if (sSelectedMenuItem === "workflow") {
      setSelectedLogicComponent({ ...oComponent });
    } else if (sSelectedMenuItem === "form") {
      setSelectedComponent({ ...oComponent });
    } else if (sSelectedMenuItem === "validation") {
      setSelectedValidationLogicComponent({ ...oComponent });
    }
  }

  /**
   * Delete the componet
   * @param {object} oComponentModel
   */
  function handleDeleteComponent(oComponentModel) {
    var aUIComponents = [...aUIData];
    var iIndex = aUIData.findIndex(function (oModel) {
      return oModel.uniqueId === oComponentModel.uniqueId;
    });
    aUIComponents.splice(iIndex, 1);
    updateVersionComponent(aUIComponents);
  }

  /**
   * Delete the componet
   * @param {object} oComponentModel
   */
  function handleDeleteMultiComponent() {
    if(aSelectedComponents.length > 0){
      var aUIComponents = [...aUIData];
      aSelectedComponents.forEach(function (oComponentModel) {
        var iIndex = aUIComponents.findIndex(function (oModel) {
          return oModel.uniqueId === oComponentModel.uniqueId;
        });
        if (iIndex !== -1) {
          aUIComponents.splice(iIndex, 1);
        }
      });
      updateVersionComponent(aUIComponents);
      setaSelectedComponents([]);
    }
  }

  /**
   * Delete the componet
   * @param {object} oComponentModel
   */
  function handleDeleteFormComponent(oComponentModel) {
    var aUIComponents = [...aUIData];
    var iIndex = aUIData.findIndex(function (oModel) {
      return oModel.uniqueId === oComponentModel.uniqueId;
    });
    aUIComponents.splice(iIndex, 1);
    updateFormComponent(aUIComponents);
  }

  /**
   * Delete the componet
   * @param {object} oComponentModel
   */
  function handleDeleteDataBaseList(oComponentModel) {
    var aUIComponents = [...aDatabaseList];
    var iIndex = aDatabaseList.findIndex(function (oModel) {
      return oModel.uniqueId === oComponentModel.uniqueId;
    });
    aUIComponents.splice(iIndex, 1);
    setDatabaseList(aUIComponents);
  }

  /**
   * Event handler to move component using mouse
   * @param {String} oEventCode
   * @param {object} oComponentModel
   */
  function handleMouseMove(oEventCode, oComponentModel) {
    var aUIComponents = [];
    if (sSelectedMenuItem === "data") {
      aUIComponents = [...aDatabaseList];
    } else {
      aUIComponents = [...aUIData];
    }

    if (oEventCode === "ArrowLeft") {
      aUIComponents.forEach(function (oUIComponent) {
        if (oUIComponent.uniqueId === oComponentModel.uniqueId) {
          oUIComponent.left = oUIComponent.left - 1;
        }
      });
    } else if (oEventCode === "ArrowRight") {
      aUIComponents.forEach(function (oUIComponent) {
        if (oUIComponent.uniqueId === oComponentModel.uniqueId) {
          oUIComponent.left = oUIComponent.left + 1;
        }
      });
    } else if (oEventCode === "ArrowDown") {
      aUIComponents.forEach(function (oUIComponent) {
        if (oUIComponent.uniqueId === oComponentModel.uniqueId) {
          oUIComponent.top = oUIComponent.top + 1;
        }
      });
    } else if (oEventCode === "ArrowUp") {
      aUIComponents.forEach(function (oUIComponent) {
        if (oUIComponent.uniqueId === oComponentModel.uniqueId) {
          oUIComponent.top = oUIComponent.top - 1;
        }
      });
    }
    if (sSelectedMenuItem === "designer") {
      updateVersionComponent(aUIComponents);
    } else if (sSelectedMenuItem === "form") {
      updateFormComponent(aUIComponents);
    } else if (sSelectedMenuItem === "data") {
      setDatabaseList(aUIComponents);
    }
  }

  /**
   * update the component model based on resolution
   * @param {event} oEvent
   */
  function handleChangeResolution(oEvent) {
    var oConvertedAPPJson = { ...oAppJson };
    for (var oProperty in oAppJson) {
      if (oProperty !== "forms") {
        oConvertedAPPJson[oProperty].UIComponents = convertResolution(
          oAppJson[oProperty].UIComponents
        );
      }
    }
    var aConvertedUIData = convertResolution(aUIData);
    updateVersionComponent([...aConvertedUIData]);
  }

  /**
   * Event handler on Change Mobile View Toggle
   * @param {event} event | event 
   */
  function handleChangeToggle(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      setMobileViewDialog(isChecked);
    } else {
      setIsMobileSize(isChecked);
      localStorage.removeItem("MobileSize");
      window.location.reload();
    }
  }

  /**
   * Import UIDATA into MobileView
   */
  function handleImportMobileData() {
    setIsMobileSize(true)
    setResolutionDialog(true)
  }

  /**
   * AdptiveMobileResoultion
   */
  function handleAdaptMobileResolution(oEvent) {
    setIsMobileSize(true);
    localStorage.setItem("MobileSize", true);
    window.location.reload();
  }
  /**
   * Close resolution dialog
   */
  function handleCloseResolution() {
    setResolutionDialog(false);
    setMobileViewDialog(false);
  }

  /**
   * Event handler on select logic
   * @param {object} oLogic | seleted logic details
   */
  function handleSelectLogic(oLogic) {
    if (oLogic) {
      var aLogicNodes;
      //setSelectedLogic(oLogic);
      if (oLogic["component"] && oLogic.component.length > 0) {
        aLogicNodes = getNodesForLogic(oLogic.component);
      }
      setSelectedLogicNodes(aLogicNodes);
      setSelectedLogic(oLogic);
      setEdges(oLogic.edges);
      setRenderLogicFlow(true);
      setSelectedMenuItem("workflow");
      setSelectedValidationLogic({});
      setRenderValidationFlow(false);
      var canvas = document.getElementById("idCanvasGuide");
      canvas.setAttribute("hidden", "hidden");
      setrenderLogicValidateContainer(true);
    }
  }

  /**
   * Event handler on select Validationlogic
   * @param {object} oValidationLogic | seleted logic details
   */
  function handleSelectValidationLogic(oValidationLogic) {
    if (oValidationLogic) {
      var aLogicNodes;
      if (
        oValidationLogic["component"] &&
        oValidationLogic.component.length > 0
      ) {
        aLogicNodes = getNodesForLogic(oValidationLogic.component);
      }
      setSelectedValidationLogicNodes(aLogicNodes);
      setSelectedValidationLogic(oValidationLogic);
      setValidationEdges(oValidationLogic.edges);
      setRenderValidationFlow(true);
      setSelectedMenuItem("validation");
      var canvas = document.getElementById("idCanvasGuide");
      canvas.setAttribute("hidden", "hidden");
    }
  }

  /**
   * Get nodes for the selected logic
   * @param {array} aLogicComponent | logic components
   * @returns
   */
  function getNodesForLogic(aLogicComponent) {
    var aLogicNodes;
    aLogicNodes = aLogicComponent.map((oLogicComponent) => ({
      id: oLogicComponent.id,
      data: oLogicComponent.data,
      position: oLogicComponent.position,
      type: oLogicComponent.type,
      targetPosition: oLogicComponent.targetPosition,
      sourcePosition: oLogicComponent.sourcePosition,
    }));
    return aLogicNodes;
  }

  /**
   * Event handler for change in logic nodes
   * @param {array} aChanges
   */
  function handleLogicNodeChange(aChanges) {
    setSelectedLogicNodes((nds) => applyNodeChanges(aChanges, nds));
  }
  /**
   * Event handler for change in logic nodes
   * @param {array} aChanges
   */
  function handleValidationLogicNodeChange(aChanges) {
    setSelectedValidationLogicNodes((nds) => applyNodeChanges(aChanges, nds));
  }

  /**
   * Event handler for edge change in logic
   * @param {array} aChanges | changes in connection
   */
  function handleEdgeChange(aChanges) {
    setEdges((eds) => applyEdgeChanges(aChanges, eds));
    if ((aChanges.length === 1) & (aChanges[0].type === "remove")) {
      var oDeletedEdge = aChanges[0];
      var iIndex = oSelectedLogic.edges.findIndex(function (oEdge) {
        return oEdge.id === oDeletedEdge.id;
      });
      if (iIndex !== -1) {
        oSelectedLogic.edges.splice(iIndex, 1);
      }
      var aUpdatedEdges = oSelectedLogic.edges;
      var oLogic = oSelectedComponent.Logic.find(function (oLogic) {
        return oLogic.id === oSelectedLogic.id;
      });
      oLogic.edges = aUpdatedEdges;
      setSelectedLogic(oLogic);
      setEdges(aUpdatedEdges);
      handleUpdateComponent({ ...oSelectedComponent });
    }
  }

  /**
   * Event handler for edge change in Validationlogic
   * @param {array} aChanges | changes in connection
   */
  function handleValidationEdgeChange(aChanges) {
    setValidationEdges((eds) => applyEdgeChanges(aChanges, eds));
    if ((aChanges.length === 1) & (aChanges[0].type === "remove")) {
      var oDeletedEdge = aChanges[0];
      var iIndex = oSelectedValidationLogic.edges.findIndex(function (oEdge) {
        return oEdge.id === oDeletedEdge.id;
      });
      oSelectedValidationLogic.edges.splice(iIndex, 1);
      var aUpdatedEdges = oSelectedValidationLogic.edges;
      var oLogic = oSelectedLogic.validations.find(function (oLogic) {
        return oLogic.id === oSelectedValidationLogic.id;
      });
      oLogic.edges = aUpdatedEdges;
      setSelectedValidationLogic(oLogic);
      setValidationEdges(aUpdatedEdges);
      handleUpdateComponent({ ...oSelectedComponent });
    }
  }

  /**
   * Event handler for connect
   * @param {object} oParams
   */
  function handleConnect(oParams) {
    setEdges((eds) => addEdge(oParams, eds));
    oSelectedLogic.edges.push(oParams);
    // var oLogic = oSelectedComponent.Logic.find(function (oLogic) {
    //   return oLogic.id === oSelectedLogic.id;
    // });
    // oLogic = oSelectedLogic;
    handleUpdateComponent({ ...oSelectedComponent });
  }

  /**
   * Event handler for Validation connect
   * @param {object} oParams
   */
  function handleValidationConnect(oParams) {
    setValidationEdges((eds) => addEdge(oParams, eds));
    oSelectedValidationLogic.edges.push(oParams);
    handleUpdateComponent({ ...oSelectedComponent });
  }

  /**
   * Event handler to update the node to component
   * @param {array} aNodes | array of nodes
   */
  function updateNodeToComponent(aNodes) {
    var aUpdatedComponents = [];
    aNodes.forEach(function (oNode) {
      var oLogicComponent = oSelectedLogic.component.find(
        function (oComponent) {
          return oComponent.id === oNode.id;
        }
      );
      if (oLogicComponent) {
        oLogicComponent.position = oNode.position;
        oLogicComponent.sourcePosition = oNode.sourcePosition;
        oLogicComponent.targetPosition = oNode.targetPosition;
        aUpdatedComponents.push(oLogicComponent);
      }
    });
    if (aNodes.length === 0) {
      aUpdatedComponents = [];
    }
    oSelectedLogic.component = aUpdatedComponents;
    handleUpdateComponent(oSelectedComponent);
  }

  function handleupdatePrompt(oComponent) {
    handleUpdateComponent(oComponent);
  }

  /**
   * Event handler to update the node to component
   * @param {array} aNodes | array of nodes
   */
  function updateValidationNodeToComponent(aNodes) {
    var aUpdatedComponents = [];
    aNodes.forEach(function (oNode) {
      var oLogicComponent = oSelectedValidationLogic.component.find(
        function (oComponent) {
          return oComponent.id === oNode.id;
        }
      );
      if (oLogicComponent) {
        oLogicComponent.position = oNode.position;
        oLogicComponent.sourcePosition = oNode.sourcePosition;
        oLogicComponent.targetPosition = oNode.targetPosition;
        aUpdatedComponents.push(oLogicComponent);
      }
    });
    if (aNodes.length === 0) {
      aUpdatedComponents = [];
    }
    oSelectedValidationLogic.component = aUpdatedComponents;
    handleUpdateComponent(oSelectedComponent);
  }

  /**
   * Event handler to update the node to component
   * @param {array} aNodes | array of nodes
   */
  function updateValidationNodeToComponent(aNodes) {
    var aUpdatedComponents = [];
    aNodes.forEach(function (oNode) {
      var oLogicComponent = oSelectedValidationLogic.component.find(
        function (oComponent) {
          return oComponent.id === oNode.id;
        }
      );
      if (oLogicComponent) {
        oLogicComponent.position = oNode.position;
        oLogicComponent.sourcePosition = oNode.sourcePosition;
        oLogicComponent.targetPosition = oNode.targetPosition;
        aUpdatedComponents.push(oLogicComponent);
      }
    });
    if (aNodes.length === 0) {
      aUpdatedComponents = [];
    }
    oSelectedValidationLogic.component = aUpdatedComponents;
    handleUpdateComponent(oSelectedComponent);
  }

  /**
   * Event handler on delete node
   * @param {array} aNodes | array of deleted nodes
   */
  function handleOnDeleteNode(aNodes) {
    var aUpdatedComponents = [];
    var oNode = aNodes[0];
    var iIndex = oSelectedLogic.component.findIndex(function (oComponent) {
      return oComponent.id === oNode.id;
    });
    oSelectedLogic.component.splice(iIndex, 1);
    aUpdatedComponents = oSelectedLogic.component;
    var oLogic = oSelectedComponent.Logic.find(function (oLogic) {
      return oLogic.id === oSelectedLogic.id;
    });
    let aRemoveEdgeIndex = [];
    oSelectedLogic.edges.forEach(function (oComponent, index) {
      if (oComponent.source === oNode.id || oComponent.target === oNode.id) {
        aRemoveEdgeIndex.push(index);
      }
    });
    aRemoveEdgeIndex.sort((a, b) => b - a);
    aRemoveEdgeIndex.forEach(function (iIndex) {
      oSelectedLogic.edges.splice(iIndex, 1);
    });
    oLogic.edges = oSelectedLogic.edges;
    oLogic.component = aUpdatedComponents;
    setSelectedLogic(oLogic);
    handleUpdateComponent({ ...oSelectedComponent });
  }

  /**
   * Event handler on delete Validation  node
   * @param {array} aNodes | array of deleted nodes
   */
  function handleOnDeleteValidationNode(aNodes) {
    var aUpdatedComponents = [];
    var oNode = aNodes[0];
    var iIndex = oSelectedValidationLogic.component.findIndex(
      function (oComponent) {
        return oComponent.id === oNode.id;
      }
    );
    oSelectedValidationLogic.component.splice(iIndex, 1);
    aUpdatedComponents = oSelectedValidationLogic.component;
    var oValidationLogic = oSelectedLogic.validations.find(
      function (oValidationLogic) {
        return oValidationLogic.id === oSelectedValidationLogic.id;
      }
    );
    let aRemoveEdgeIndex = [];
    oSelectedValidationLogic.edges.forEach(function (oComponent, index) {
      if (oComponent.source === oNode.id || oComponent.target === oNode.id) {
        aRemoveEdgeIndex.push(index);
      }
    });
    aRemoveEdgeIndex.sort((a, b) => b - a);
    aRemoveEdgeIndex.forEach(function (iIndex) {
      oSelectedValidationLogic.edges.splice(iIndex, 1);
    });
    oValidationLogic.edges = oSelectedValidationLogic.edges;
    oValidationLogic.component = aUpdatedComponents;
    setSelectedValidationLogic(oValidationLogic);
    handleUpdateComponent({ ...oSelectedComponent });
  }

  /**
   * Event handler on node select
   * @param {string} sNodeId | Node id
   */
  function handleOnSelectNode(sNodeId) {
    var oLogicComponent = oSelectedLogic.component.find(function (oComponent) {
      return oComponent.id === sNodeId;
    });
    handleOnSelect(oLogicComponent);
  }

  /**
   * Event handler on validation node select
   * @param {string} sNodeId | Node id
   */
  function handleOnSelectValidationNode(sNodeId) {
    var oValidationLogicComponent = oSelectedValidationLogic.component.find(
      function (oComponent) {
        return oComponent.id === sNodeId;
      }
    );
    handleOnSelect(oValidationLogicComponent);
  }

  /**
   * Event handler for add logic
   */
  function handleAddLogic() {
    var aEventModel = [...oSelectedComponent.Events];
    oSelectedComponent.Logic.forEach(function (oLogic) {
      var iIndex = aEventModel.findIndex(function (oModel) {
        return oModel.event === oLogic.event;
      });
      aEventModel.splice(iIndex, 1);
    });
    setEvents(aEventModel);
    setOpenLogicDialog(true);
  }

  /**
   * Event handler for create Validation logic
   * @param {object} oValidationLogic | Selected Validationlogic
   */
  function handleCreateValidationLogic(oValidationLogic) {
    oSelectedLogic.validations.push(oValidationLogic);
    handleUpdateComponent(oSelectedComponent);
    setSelectedComponent({ ...oSelectedComponent });
    handleSelectValidationLogic(oValidationLogic);
  }

  /**
   * Event handler for create logic
   * @param {object} oLogic | Selected logic
   */
  function handleCreateLogic(oLogic) {
    oSelectedComponent.Logic.push(oLogic);
    handleUpdateComponent(oSelectedComponent);
    setSelectedComponent({ ...oSelectedComponent });
    setOpenLogicDialog(false);
    setSelectedLogicNodes([...oLogic.component]);
    setEdges([...oLogic.edges]);
    handleSelectLogic(oLogic);
  }

  /**
   * Event handler for logic dialog close
   */
  function handleLogicClose() {
    setOpenLogicDialog(false);
  }

  /**
   * Event handler on select of component logic
   * @param {object} oComponent | logic component model
   */
  function handleSelectComponentLogic(oComponent) {
    var aLogic = oComponent.Logic;
    if (aLogic.length === 0) {
      setOpenLogicDialog(true);
      setSelectedComponent(oComponent);
    } else if (aLogic.length === 1) {
      setOpenLogicDialog(false);
      setSelectedComponent(oComponent);
      handleSelectLogic(aLogic[0]);
      setRenderLogicFlow(true);
    } else {
      setOpenLogicDialog(false);
      setSelectedComponent(oComponent);
      handleSelectLogic(aLogic[0]);
      setRenderLogicFlow(true);
    }
  }

  /**
   * Event handler on delete logic
   * @param {object} oLogic | logic details
   */
  function handleDeleteLogic(oLogic) {
    if (oLogic) {
      var aLogic = oSelectedComponent.Logic;
      var iIndex = aLogic.findIndex(function (logic) {
        return logic.id === oLogic.id && oLogic.event === logic.event;
      });
      aLogic.splice(iIndex, 1);
      setSelectedComponent({ ...oSelectedComponent });
      handleUpdateComponent(oSelectedComponent);
    }
  }

  function handleDeleteValidationLogic(oValidationLogic) {
    if (oValidationLogic) {
      var aValidationsLogics = oSelectedLogic.validations;
      var iIndex = aValidationsLogics.findIndex(function (logic) {
        return logic.label === oValidationLogic.label;
      });
      aValidationsLogics.splice(iIndex, 1);
      setSelectedComponent({ ...oSelectedComponent });
      handleUpdateComponent(oSelectedComponent);
      setSelectedValidationLogicNodes([]);
      setSelectedValidationLogic({});
      setValidationEdges([]);
      handleSelectLogic(oSelectedLogic);
    }
  }

  /**
   * Event handler to open publish dialog
   */
  function handleOpenPublishDialog() {
    setOpenPublishDialog(true);
  }

  /**
   * Event handler to open publish dialog
   */
  function handleOpenDeployDialog() {
    setOpenDeployDialog(true);
  }

  /**
   * Event handler to close publish dialog
   */
  function handleClosePublishDialog() {
    setOpenPublishDialog(false);
  }

  /**
   * Event handler to close deploy dialog
   */
  function handleCloseDeployDialog() {
    setOpenDeployDialog(false);
  }

  function handleChangeDesc(sDesc) {
    if (sDesc) {
      setAppDesc(sDesc);
    }
  }

  /**
   * event handle on preview button to navigate preview page
   * @param {event} oEvent
   */
  function onClickPreview(oEvent) {
    localStorage.setItem("stopReload", false);
    var oPage = oAppJson[Object.keys(oAppJson)[0]];
    var sPageID = oPage.id;
    navigate(`/PreviewPage/${APPID}/${sPageID}`, {
      state: { USERID: USERID, pageID: sPageID },
    });
  }

  function componentAlign() {
    var aUpdatedUIData = ComponentAlignment(aSelectedComponents);
    const aMergedData = aUIData.map((oOriginalData, index) => {
      const iIndex = aUpdatedUIData.findIndex(
        (component) => component.uniqueId === oOriginalData.uniqueId
      );
      const oUpdatedData = aUpdatedUIData[iIndex];
      if (oUpdatedData && oUpdatedData.uniqueId === oOriginalData.uniqueId) {
        return oUpdatedData;
      } else {
        return oOriginalData;
      }
    });
    setaSelectedComponents([]);
    if (sSelectedMenuItem === "form") {
      updateFormComponent(aMergedData);
    } else {
      updateVersionComponent(aMergedData);
    }
  }

  function handleupdateComponentProperty() {
    if (oSelectedComponent) {
      var aComponentList = require("../../model/designer.json");
      var updateComponent = aComponentList.find(
        (oComponent) => oComponent.Component === oSelectedComponent.Component
      );
      var aDifferProperties = [];
      updateComponent.properties.forEach((oComponentListProperty) => {
        var selectedProperty = oSelectedComponent.properties.find(
          (oProperty) => oProperty.id === oComponentListProperty.id
        );
        if (!selectedProperty) {
          aDifferProperties.push(oComponentListProperty);
        }
      });
      var combinedProperties = [
        ...oSelectedComponent.properties,
        ...aDifferProperties,
      ];
      oSelectedComponent.properties = [...combinedProperties];
      setSelectedComponent(oSelectedComponent);
      aUIData.find((oComponent) => {
        if (oComponent.uniqueId === oSelectedComponent.uniqueId) {
          oComponent.properties = oSelectedComponent.properties;
        }
      });

      updateVersionComponent(aUIData);
    }
  }

  function handleUpdateAllComponentsProperty() {
    if (aUIData) {
      var aComponentList = require("../../model/designer.json");
      aUIData.forEach((oUIComponent) => {
        var updateComponent = aComponentList.find(
          (oComponent) => oComponent.Component === oUIComponent.Component
        );
        const aDifferProperties = updateComponent.properties.filter(
          (oComponentListProperty) => {
            return !oUIComponent.properties.some(
              (oProperty) => oProperty.id === oComponentListProperty.id
            );
          }
        );
        const combinedProperties = [
          ...oUIComponent.properties,
          ...aDifferProperties,
        ];
        oUIComponent.properties = [...combinedProperties];
      });
      updateVersionComponent(aUIData);
    }
  }

  /**
   * handler for creating wizard app
   */
  function handleCreateWizardAPP(oEvent) {
    if (aSchemaFieldsData.length > 0) {
      var aComponentList = require("../../model/template.json");

      var aSearch = [];
      var aSearchLabels = [];
      var aFields = [];
      var aFormLabels = [];
      var aPage1TitleLabel = aComponentList.find(
        (oComponent) =>
          oComponent.Component === "Label" &&
          oComponent.uniqueId === "idpage1Label0"
      );
      aSearchLabels.push(aPage1TitleLabel);
      aFormLabels.push(aPage1TitleLabel);
      var aPage1ButtonList = aComponentList.filter(
        (oComponent) =>
          oComponent.Component === "Button" &&
          oComponent.uniqueId.split("Button")[0] === "idpage1"
      );
      var aPage2ButtonList = aComponentList.filter(
        (oComponent) =>
          oComponent.Component === "Button" &&
          oComponent.uniqueId.split("Button")[0] === "idpage2"
      );
      var TablelComponent = {
        ...aComponentList.find(
          (oComponent) => oComponent.Component === "Table"
        ),
      };

      TablelComponent.properties.forEach((oProperty) => {
        if (
          oProperty.id === "idInputTableName" ||
          oProperty.id === "idTableTitle"
        ) {
          oProperty.value = aTableFieldsData.tableName;
        } else if (oProperty.id === "idVisibleColumns") {
          oProperty.value = aTableFieldsData.rows.length;
        } else if (oProperty.id === "idColumnConfig") {
          oProperty.value = aTableFieldsData.rows;
        }
      });

      aFormFields.fields.forEach((oSearchField, index) => {
        var oLabelComponent = {
          ...aComponentList.find(
            (oComponent) => oComponent.Component === "Label"
          ),
        };
        if (oLabelComponent) {
          if (index !== 0) {
            var halfLength = Math.round(aFormFields.fields.length / 2);
            if (index === halfLength) {
              var previousComponent = { ...aFields[0] };
              oLabelComponent.left = previousComponent.left + 180;
              oLabelComponent.top = previousComponent.top;
            } else if (index > halfLength) {
              var previousComponent = { ...aFields[index - 1] };
              oLabelComponent.left = previousComponent.left - 80;
              oLabelComponent.top = previousComponent.top + 50;
            } else {
              var previousComponent = { ...aFields[index - 1] };
              oLabelComponent.top = previousComponent.top + 50;
            }
          }
          oLabelComponent.uniqueId = `idpage2Label${aFormLabels.length}`;
          var modifiedLabelProperty = {
            ...oLabelComponent.properties.find(
              (oProperty) => oProperty.id === "idInputTextLabel"
            ),
            value: oSearchField.value,
          };
          aFormLabels.push({
            ...oLabelComponent,
            properties: oLabelComponent.properties.map((prop) =>
              prop.id === "idInputTextLabel" ? modifiedLabelProperty : prop
            ),
          });
        }
        var targetComponent = aComponentList.find(
          (oComponent) => oComponent.Component === "Input"
        );
        targetComponent = { ...targetComponent };
        if (targetComponent.Component === "Input") {
          if (index !== 0) {
            var halfLength = Math.round(aFormFields.fields.length / 2);
            if (index === halfLength) {
              var previousComponent = { ...aFields[0] };
              targetComponent.left = previousComponent.left + 240;
              targetComponent.top = previousComponent.top;
            } else if (index > halfLength) {
              var previousComponent = { ...aFields[index - 1] };
              targetComponent.left = previousComponent.left;
              targetComponent.top = previousComponent.top + 50;
            } else {
              var previousComponent = { ...aFields[index - 1] };
              targetComponent.top = previousComponent.top + 50;
            }
          }
          targetComponent.uniqueId = `idpage2Input${aFields.length}`;
        }
        var fieldID =
          targetComponent.Component === "Input"
            ? "idInputFieldName"
            : "idInputTextLabel";
        var modifiedProperty = {
          ...targetComponent.properties.find(
            (oProperty) => oProperty.id === fieldID
          ),
          value: oSearchField.value,
        };
        aFields.push({
          ...targetComponent,
          properties: targetComponent.properties.map((prop) =>
            prop.id === fieldID ? modifiedProperty : prop
          ),
        });
      });

      aSearchFields.forEach((oSearchField, index) => {
        var oLabelComponent = {
          ...aComponentList.find(
            (oComponent) => oComponent.Component === "Label"
          ),
        };

        if (oLabelComponent) {
          if (index !== 0) {
            if (index % 2 !== 0) {
              var previousComponent = { ...aSearch[index - 1] };
              oLabelComponent.left = previousComponent.left + 180;
              oLabelComponent.top = previousComponent.top;
            } else {
              oLabelComponent.top += 50;
            }
          }
          oLabelComponent.uniqueId = `idpage1Label${aSearchLabels.length}`;
          var modifiedLabelProperty = {
            ...oLabelComponent.properties.find(
              (oProperty) => oProperty.id === "idInputTextLabel"
            ),
            value: oSearchField.value,
          };
          aSearchLabels.push({
            ...oLabelComponent,
            properties: oLabelComponent.properties.map((prop) =>
              prop.id === "idInputTextLabel" ? modifiedLabelProperty : prop
            ),
          });
        }
        var targetComponent = aComponentList.find(
          (oComponent) => oComponent.Component === "Input"
        );
        targetComponent = { ...targetComponent };
        if (targetComponent.Component === "Input") {
          if (index !== 0) {
            if (index % 2 !== 0) {
              var previousComponent = { ...aSearch[index - 1] };
              targetComponent.left = previousComponent.left + 240;
              targetComponent.top = previousComponent.top;
            } else {
              targetComponent.top += 50;
            }
            targetComponent.uniqueId = `idpage1Input${aSearch.length}`;
          }
        }
        var fieldID =
          targetComponent.Component === "Input"
            ? "idInputFieldName"
            : "idInputTextLabel";

        var modifiedProperty = {
          ...targetComponent.properties.find(
            (oProperty) => oProperty.id === fieldID
          ),
          value: oSearchField.value,
        };
        aSearch.push({
          ...targetComponent,
          properties: targetComponent.properties.map((prop) =>
            prop.id === fieldID ? modifiedProperty : prop
          ),
        });
      });

      var aUIComponents = [
        ...aSearch,
        ...aSearchLabels,
        TablelComponent,
        ...aPage1ButtonList,
      ];
      var secondPageComponents = [
        ...aFields,
        ...aFormLabels,
        ...aPage2ButtonList,
      ];
      var oTempAppJSON = { ...oAppJson };
      oTempAppJSON[oSelectedPage.id].UIComponents = aUIComponents;
      var secondpage = {
        id: "page2",
        name: aFormFields.pageName,
        UIComponents: secondPageComponents,
      };
      oTempAppJSON.page2 = secondpage;
      oTempAppJSON["page2"].UIComponents = secondPageComponents;
      updateWizardAppData(oTempAppJSON);
    }
  }

  /**
   * update the version of wizard app
   * @param {object} oLogic | oTempAppJSON
   */
  function updateWizardAppData(oTempAppJSON) {
    Server.post(
      "/editor/wizard",
      {
        APP_ID: APPID,
        UIDATA: oTempAppJSON,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    )
      .then(function (oResponse) {
        setAppJSON(oTempAppJSON);
        setUIData([...oTempAppJSON[oSelectedPage.id].UIComponents]);
        setOpenWizardDialog(false);
      })
      .catch(function (oError) {
        if (oError.response.data) {
          setErrorMessage(oError.response.data.Message);
        } else {
          setErrorMessage(oError.response.data.Message);
        }
        setOpenSnackBar(true);
      });
  }


  return (
    <div id="idStudioMainPageBackground" className="studio_mainPage-Background">
      <div
        id="idStudioMainpageHeader"
        className="studio_mainPage-header"
        ref={containerRef}
      >
        <div
          id="idStudioMainpageLogoAppStudio"
          className="studio_mainPage-logo-Profile"
        ></div>
        <div
          id="idStudioMainpageLogoAppStudio"
          className="studio_mainPage-logo-AppStudio"
        >
          <img
            id="idAppStudioLogo"
            src={AppStudioLogo}
            alt="AppStudio"
            width="45px"
            height="45px"
          />
        </div>
        <TitleBar app={APPDESC} project={ProjectDesc} userId={USERID} />
      </div>
      <Menu
        userId={USERID}
        page={oSelectedPage}
        addPage={addPage}
        deletePage={deletePage}
        selectPage={handleSelectPage}
        addForm={addForm}
        selectForm={handleSelectForm}
        deleteForm={deleteForm}
        select={handleSelectMenuItem}
        selectedItem={sSelectedMenuItem}
        appJson={oAppJson}
      />
      <MenuBar
        userId={USERID}
        page={oSelectedPage}
        logic={oSelectedLogic}
        validation={oSelectedValidationLogic}
        isMobileSize={isMobileSize}
        handleChangeToggle={handleChangeToggle}
        componentAlign={componentAlign}
        multiDelete={handleDeleteMultiComponent}
        selectPage={handleSelectPage}
        onSelectLogic={handleSelectLogic}
        onSelectValidationLogic={handleSelectValidationLogic}
        updateComponentProperty={handleupdateComponentProperty}
        updateAllComponentsProperty={handleUpdateAllComponentsProperty}
        handleOpenWizard={setOpenWizardDialog}
      />
      <OperationDialog
        open={openDialog}
        userId={USERID}
        close={handleClose}
        updateState={updateAppStates}
        enableClose={bEnableClose}
        error={handleError}
      />
      <WizardDialog
        open={bOpenWizardDialog}
        handleCancel={setOpenWizardDialog}
        aSchemaFieldsData={aSchemaFieldsData}
        setSchemaFieldsData={setSchemaFieldsData}
        setTableFieldsData={setTableFieldsData}
        aTableFieldsData={aTableFieldsData}
        sSelectTemplateCard={sSelectTemplateCard}
        setSelectTemplateCard={setSelectTemplateCard}
        setSearchFields={setSearchFields}
        aSearchFields={aSearchFields}
        setFormFields={setFormFields}
        aFormFields={aFormFields}
        handlecreate={handleCreateWizardAPP}
      />

      <div id="idStudioInnerControl">
        <div
          id="idStudioRightControls"
          className="studio_mainPage-right-control"
        >
          <Stack direction="row" spacing={3}>
            <IconButton size="small" style={{ color: "white" }}>
              <PlayCircleOutlineRoundedIcon onClick={onClickPreview} />
            </IconButton>
            <IconButton
              size="small"
              onClick={handleOpenOperation}
              style={{ color: "white" }}
            >
              <FolderOpenRoundedIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={handleOpenPublishDialog}
              style={{ color: "white" }}
            >
              <PublishRoundedIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={handleOpenDeployDialog}
              style={{ color: "white" }}
            >
              <CloudUploadIcon />
            </IconButton>
          </Stack>
        </div>
      </div>
      <ResolutionDialog
        open={bResolutionDialog}
        onProceed={handleChangeResolution}
        onClose={handleCloseResolution}
      />
      <MobileViewDialog
        open={bMobileViewDialog}
        USERID={USERID}
        setLoading={setLoading}
        onBoth={handleImportMobileData}
        onProceed={handleAdaptMobileResolution}
        onClose={handleCloseResolution}
      />
      <LogicDialog
        open={bOpenLogicDialog}
        tabValue={0}
        selectedComponent={oSelectedComponent}
        events={aEvents}
        appJson={oAppJson}
        onCreate={handleCreateLogic}
        onClose={handleLogicClose}
      />
      <PublishDialog
        open={bOpenPublishDialog}
        close={handleClosePublishDialog}
        CID={CID}
        APPDESC={APPDESC}
        changeDesc={handleChangeDesc}
        APPID={APPID}
        USERID={USERID}
        groups={aGroups}
        UIData={aUIData}
        appJson={oAppJson}
        error={handleError}
      />
      <DeployDialog
        open={bOpenDeployDialog}
        close={handleCloseDeployDialog}
        CID={CID}
        APPDESC={APPDESC}
        changeDesc={handleChangeDesc}
        APPID={APPID}
        USERID={USERID}
        groups={aGroups}
        UIData={aUIData}
        appJson={oAppJson}
        error={handleError}
      />
      <div id="idStudioMainpageWorkspace" className="studio_mainPage-workspace">
      <div
          className={
            isMobileSize
              ? "studio_mainPage-StyleLogic-Mobile"
              : sSelectedMenuItem !== "form"
              ? "studio_mainPage-StyleLogic-row"
              : "studio_mainPage-StyleLogic-Formrow"
          }
        >
          <div
            id="idDropZone"
            className={
              isMobileSize
                ? "studio_mainPage-mobile_view"
                : sSelectedMenuItem !== "form"
                ? "studio_mainPage-dropZone"
                : "studio_mainPage-FormdropZone"
            }
            onDrop={handleDropComponent}
            onDragOver={(e) => e.preventDefault()}
            tabIndex="0"
            style={isMobileSize ? { height: "640px", width: "375px" } : {}}
          >
            {bLoading && (
              <div className="overlay">
                <ScaleLoader
                  color={"#033146"}
                  loading={bLoading}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  height={25}
                  width={6}
                />
              </div>
            )}
            <canvas id="idCanvasGuide" className="studio_mainpage-canvas" />
            <RenderComponents
              id="idRenderComponent"
              componentModel={aUIData}
              update={handleUpdateComponent}
              onSelect={handleOnSelect}
              onDelete={handleDeleteComponent}
              onMouseMove={handleMouseMove}
              visible={sSelectedMenuItem === "designer"}
              page={oSelectedPage}
            />
            <RenderForm
              id="idRenderFormComponent"
              componentModel={aUIData}
              update={handleUpdateFormComponent}
              onSelect={handleOnSelect}
              onDelete={handleDeleteFormComponent}
              onMouseMove={handleMouseMove}
              visible={sSelectedMenuItem === "form"}
            />
            <RenderDatabase
              id="idRenderFormComponent"
              componentModel={aDatabaseList}
              onDelete={handleDeleteDataBaseList}
              onMouseMove={handleMouseMove}
              visible={sSelectedMenuItem === "data"}
            />
            <RenderLogicOverview
              visible={sSelectedMenuItem === "workflow" && !bRenderLogicFlow}
              componentModel={aUIData}
              onSelect={handleSelectComponentLogic}
            />
            <RenderLogicDetails
              id="idRenderLogicDetails"
              visible={sSelectedMenuItem === "workflow"}
              renderFlow={bRenderLogicFlow}
              selectedLogicNodes={aSelectedLogicNodes}
              edges={aEdges}
              selectedComponent={oSelectedComponent}
              onNodesChange={handleLogicNodeChange}
              onNodeUpdate={updateNodeToComponent}
              onSelect={handleOnSelectNode}
              onDelete={handleOnDeleteNode}
              onEdgesChange={handleEdgeChange}
              onConnect={handleConnect}
              selectLogic={oSelectedLogic}
              updateprompt={handleupdatePrompt}
              UIData={aUIData}
              reSelect={handleSelectLogic}
              UIdata={aUIData}
            />
            <RenderValidationDetails
              visible={sSelectedMenuItem === "validation"}
              renderFlow={bRenderValidationFlow}
              selectedLogicNodes={aSelectedValidationLogicNodes}
              edges={aValidationEdges}
              selectedComponent={oSelectedComponent}
              onNodesChange={handleValidationLogicNodeChange}
              onNodeUpdate={updateValidationNodeToComponent}
              onSelect={handleOnSelectValidationNode}
              onDelete={handleOnDeleteValidationNode}
              onEdgesChange={handleValidationEdgeChange}
              onConnect={handleValidationConnect}
            />
          </div>
          <div className="studio_mainPage-spaceContainer"></div>
          {sErrorMessage && (
            <Snackbar
              open={bOpenSnackBar}
              autoHideDuration={6000}
              onClose={() => setOpenSnackBar(false)}
            >
              <Alert
                onClose={() => setErrorMessage("")}
                severity="error"
                sx={{ width: "100%" }}
              >
                {sErrorMessage}
              </Alert>
            </Snackbar>
          )}
        </div>
      </div>
      <div
        id="idStudioMainpageStyleLogicContainer"
        className="studio_mainPage-StyleLogic-Container"
      >
        <PropertiesMenu
          selectedMenuItem={sSelectedMenuItem}
          selectedComponent={oSelectedComponent}
          selectedLogicComponent={oSelectedLogicComponent}
          CID={CID}
          userId={USERID}
          UIData={aUIData}
          appJson={oAppJson}
          selectedValidationComponent={oSelectedValidationLogicComponent}
          onUpdateProperty={handleUpdateComponent}
          onUpdateForm={handleUpdateFormComponent}
          onUpdateLogicProperty={handleUpdateLogicComponent}
          error={handleError}
          selectedpage={oSelectedPage}
          selectLogic={oSelectedLogic}
          APPID={APPID}
          loader={setLoading}
          auth={aAuthObjects}
        />
        {brenderLogicValidateContainer ? (
          <ValidationContainer
            createValidationLogic={handleCreateValidationLogic}
            selectedComponent={oSelectedComponent}
            selectLogic={oSelectedLogic}
            activeValidationLogic={oSelectedValidationLogic}
            onSelectValidationLogic={handleSelectValidationLogic}
            onDeleteValidationLogic={handleDeleteValidationLogic}
          />
        ) : (
          <LogicContainer
            selectedComponent={oSelectedComponent}
            onSelectLogic={handleSelectLogic}
            addLogic={handleAddLogic}
            onDeleteLogic={handleDeleteLogic}
          />
        )}
      </div>
    </div>
  );
}

export default StudioMainPage;
