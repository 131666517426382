import React, { useState } from "react";
import "./ForgotPassword.css";
import AppStudioLogo from "./images/AppStudio.png";
import Server from "../../serverDetails/server";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

export default function ForgotPassword() {
  let navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { CID,USERID } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(password === confirmPassword){
        try {
            await Server.post(`/editor/signup`, {
                CID : CID,
                USERID,
                Password: password,
                updatePassword: true
            })
            .then((res) => {
                if (res.data.Message === 'Password Updated Successfully') {
                  navigate('/')
                } else {
                  setErrorMessage("Error occured")
                }
              })
        } catch (error) {
            setErrorMessage("Error occured")
        }
    }else{
        alert("Password and Confirm Password are not Same")
    }
  }

  return (
    <div className="app_studio-login-Background">
      <div className="app_studio-login-Dialog">
        <form className="app_studio-login-form" onSubmit={handleSubmit}>
          <div className="app_studio-logo-AppStudio">
            <img
              src={AppStudioLogo}
              alt="AppStudio"
              width="156.43px"
              height="32.01px"
            />
          </div>
          <div className="app_studio-subLoginForm">
            <p>Password :</p>
            <input
              id="idPassword"
              className="app_studio-LoginPage-UserTextField"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <p>Confirm Password :</p>
            <input
              id="idConfirmPassword"
              className="app_studio-LoginPage-UserTextField"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <p></p>

            {errorMessage && (
              <p className="app_studio-error-message"> {errorMessage} </p>
            )}

            <div className="app_studio-DivLoginButton">
              <button
                className="app_studio-LoginButton"
                type="submit"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
