/**
 *
 * @param {array} aProperties
 * @param {object} oParams
 */

export const CalculateArithematicOperations = (aProperties, oParams) => {
  var oData = {};
  var oMapping = aProperties.find(function (oProperty) {
    return oProperty.id === 'idCalculator';
  }).value;
  var formula = aProperties.find(function (oProperty) {
    return oProperty.id === 'idTitleInput';
  }).value;
  oMapping.forEach(function (oMappingProperty) {
    oParams.forEach(function (oProperty) {
      if (oMappingProperty.fieldName === oProperty.field) {
        oData[oMappingProperty.mappingValue] = oProperty.value;
      }
    });
  });
  function getDesiredFormula(formula, oData) {
    var updateFormula = formula;
    for (var key in oData) {
      if (key in oData) {
        var keyRegex = new RegExp(key, 'g');
        updateFormula = updateFormula.replace(keyRegex, `oData.${key}`);
      }
    }
    return updateFormula;
  }
  if (Object.keys(oData).length > 0) {
    var updateFormula = getDesiredFormula(formula, oData);
    const result = eval(updateFormula);
    if (oParams.TableData) {
      oParams.TableData[formula] = result;
    } else {
      oParams.TableData = { [formula]: result };
    }
  }
};
