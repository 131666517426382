import React, { useState, useEffect } from "react";
import "./LoginPage.css";
import AppLogo from "./images/logobg.png";
import Server from "../../serverDetails/server";
import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function LoginPage() {
  const [bLoading, setLoading] = useState(false);
  const [sErrorMessage, setErrorMessage] = useState('');
  const [bSnackBar, setSnackBar] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const response = await Server.get(`/verifytoken`, {
            headers: {
              Authorization: token,
            },
          });
          if (response.data.Message === "Token is valid") {
            navigate("/StudioPage", {
              state: { USERID: response.data.decoded.USERID },
            });
          }
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
          setSnackBar(true);
        }
        localStorage.removeItem("token");
        localStorage.removeItem("CID");
        localStorage.removeItem("USERID");
        localStorage.removeItem("URL");
        navigate("/");
      }
    };
    verifyToken();
  }, []);

  /**
   * OnClick Event handle for Login button
   * @param {object} oEvent | Event
   */
  function onClickLoginButton(oEvent) {
    oEvent.preventDefault();
    var sUserId = document
      .getElementById("idLoginPageUserId")
      .value.toUpperCase();
    var sPassword = document.getElementById("idLoginPagePassword").value;
    var CID = document.getElementById("idLoginPageCID").value.toUpperCase();
    validateUserInput(sUserId, sPassword, CID);
  }

  /**
   * Validate username and password and populate error message
   * @param {string} sUserId | Username
   * @param {string} sPassword | Password
   */
  async function validateUserInput(sUserId, sPassword, sCID) {
    if (!sCID || !sUserId || !sPassword) {
      setErrorMessage("*Username and password cannot be empty");
      setSnackBar(true);
    } else {
      try {
        setLoading(true);
        const response = await Server.post("/editor/login", {
          CID: sCID,
          USERID: sUserId,
          Password: sPassword,
        });
        setLoading(false);
        if (response.status !== 200) {
          setErrorMessage(response.data.Message);
          setSnackBar(true);
        } else {
          localStorage.setItem("CID", response.data.CID);
          localStorage.setItem("token", response.data.token);
          if (response.data.ADMIN) {
            localStorage.setItem("ADMIN", response.data.ADMIN);
          }
          navigate("/StudioPage", {
            state: { USERID: response.data.USERID },
          });
        }
      } catch (oError) {
        if (oError.response.data) {
          setErrorMessage(oError.response.data.Message);
          setSnackBar(true);
        } else {
          setErrorMessage(oError.response.message);
          setSnackBar(true);
        }
        setLoading(false);
      }
    }
  }

  const handleForgotPassword = async () => {
    var sUserId = document
      .getElementById("idLoginPageUserId")
      .value.toUpperCase();
    var sCID = document.getElementById("idLoginPageCID").value.toUpperCase();
    if (sCID !== "" && sUserId !== "") {
      setLoading(true);
      await Server.post(`/sendemail`, {
        CID: sCID,
        USERID: sUserId,
        subject: "Forgot Password",
        SITE: "EDITOR",
        link: `${process.env.REACT_APP_FORGOT_PASSSWORD}/ForgotPassword/${sCID}`,
      })
        .then((res) => {
          setLoading(false);
          if (res.data.message === "Mail sent Successfully") {
            setErrorMessage("Check Your Registered Email");
            setSnackBar(true);
          } else {
            setErrorMessage(res.data.message);
            setSnackBar(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage(err.message);
          setSnackBar(true);
        });
    } else {
      setErrorMessage("CID and USERID cannot Be empty");
      setSnackBar(true);
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(false);
  };

  return (
    <div className="editor_login_body">
      <div className="editor-login-busyindicator">
        {bLoading && (
          <div className="editor-login-overlay">
            <ScaleLoader
              color={"#033146"}
              loading={bLoading}
              aria-label="Loading Spinner"
              data-testid="loader"
              height={25}
              width={6}
            />
          </div>
        )}
        <Snackbar
          open={bSnackBar}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackBar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="error"
            sx={{ width: '100%' }}
          >
            {`${sErrorMessage}`}
          </Alert>
        </Snackbar>
      </div>
      <div className="editor_login-container">
        <form className="editor_login-form">
          <img src={AppLogo} alt="CloudZudio Logo" className="editor_logo" />
          <div className="editor_login-input-group">
            <label for="CID">CID</label>
            <input
              type="text"
              id="idLoginPageCID"
              name="CID"
              required
            />
          </div>
          <div className="editor_login-input-group">
            <label for="username">Username</label>
            <input
              type="text"
              id="idLoginPageUserId"
              name="username"
              required
            />
          </div>
          <div className="editor_login-input-group">
            <label for="password">Password</label>
            <input
              type="password"
              id="idLoginPagePassword"
              name="password"
              required
            />
          </div>
          <div className="editor-login-form-footer">
            <a
              href="#"
              className="editor-login-forgot-password"
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </a>
            <button type="submit" onClick={onClickLoginButton}>
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
