import React, { useState, useEffect } from "react";
//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// Material ui component
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import Server from "../../../../../serverDetails/server";

//Dialog Components
import Box from "@mui/material/Box";

export const AppNavigationDialog = (props) => {
  const [aApps, setApps] = useState([]);
  const [aData, setData] = useState([]);
  const [aPages, setPages] = useState([]);
  const [sSelectedPage, setSelectedPage] = useState("");
  const [sSelectedApp, setSelectedApp] = useState("");
  const [sSelectedAppID, setSelectedAppID] = useState("");


  if (
    props.oAppNavigationData &&
    Object.keys(props.oAppNavigationData).length > 0 &&
    !sSelectedPage &&
    !sSelectedApp
  ) {
    const { pageID, APP_DESC, APPID, pages } = props.oAppNavigationData;
    setSelectedPage(pageID);
    setSelectedApp(APP_DESC);
    setSelectedAppID(APPID);
    setPages([...pages]);
  }

  useEffect(() => {
    Server.get('/editor/apps',{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.data.Apps) {
          setApps(response.data.Apps);
        }
      })
      .catch(function (oError) {
        if (oError.response.data) {
          props.error(oError.response.data.Message);
        } else {
          props.error(oError.message);
        }
      });
  },[]);

  function handleSelectApp(oEvent) {
    var oApp = aApps.find(function (oApp) {
      return oApp.APP_DESC === oEvent.target.value;
    });

    setSelectedApp(oApp.APP_DESC);
    setSelectedAppID(oApp.APPID);
    var aPages = [];
    Object.keys(oApp.UIDATA).forEach((oPage) => {
      if (oPage !== "forms") {
        var oNewPage = {};
        oNewPage.id = oPage;
        oNewPage.name = oApp.UIDATA[oPage].name;
        aPages.push(oNewPage);
      }
    });
    if (aPages.length > 0) {
      setPages(aPages);
    }
  }

  function handleSelectPage(oEvent) {
    var sSelectedPage = oEvent.target.value;
    setSelectedPage(sSelectedPage);
  }


  function handleUpdate() {
    props.update({
      pageID: sSelectedPage,
      pages: aPages,
      APPID: sSelectedAppID,
      APP_DESC: sSelectedApp,
    });
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>App Navigation</DialogTitle>
        <DialogContent>
          <Box>
            <br></br>
            <TextField
              required
              label="Appilication Name"
              select
              value={sSelectedApp}
              onChange={handleSelectApp}
              sx={{ minWidth: 450 }}
            >
              {aApps.map((app) => (
                <MenuItem
                  key={app.APPID}
                  name={app.APP_DESC}
                  value={app.APP_DESC}
                >
                  {app.APP_DESC}
                </MenuItem>
              ))}
            </TextField>
            <br></br>
            <br></br>
            <TextField
              required
              label="Pages"
              select
              onChange={handleSelectPage}
              value={sSelectedPage}
              sx={{ minWidth: 450 }}
            >
              {aPages.map((page) => (
                <MenuItem key={page.id} name={page.name} value={page.id}>
                  {page.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
