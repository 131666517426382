import Server from '../../../../../serverDetails/server';

export const InsertDB = (
  aProperties,
  oParams,
  oLogicDatas,
  aPromises
) => {
  var oInsertedData = [];
  var bCheckMultipleData;
  let sAdminProperty = false;
  var bTableData = aProperties.find(
    (oProperty) => oProperty.id === "idInsertTableData"
  )?.value;

  for (let i = 0; i < 1; i++) {
    if (Array.isArray(oParams[i])) {
      bCheckMultipleData = true;
    } else {
      bCheckMultipleData = false;
    }
  }

  if (bTableData && oParams.TableData) {
    oParams.TableData.forEach(function (oData) {
      var oNewData = {};
      for (const key in oData) {
        oNewData[key] = oData[key];
      }
      oInsertedData.push(oNewData);
    });
  } else {
    if (bCheckMultipleData) {
      oParams.forEach(function (param) {
        var oNewData = {};
        param.forEach(function (oParam) {
          oNewData[oParam.field.trim()] = oParam.value;
        });
        oInsertedData.push(oNewData);
      });
    } else {
      var oNewData = {};
      oParams.forEach(function (params) {
        oNewData[params.field.trim()] = params.value;
      });
      oInsertedData.push(oNewData);
    }
  }

  var sTableValue;
  aProperties.forEach(function (oProperties) {
    if (oProperties.id === "idInsertDBName") {
      sTableValue = oProperties.value;
    }
  });

  var updateDB = aProperties.find(function (oProperty) {
    return oProperty.id === "idUpdateDB";
  }).value;

  var DBProperty = aProperties.find(function (oProperty) {
    return oProperty.id === "idDBTableProperties";
  });

  var DBCalculationsProperty = aProperties.find(function (oProperty) {
    return oProperty.id === "idDBCalculationProperties";
  });

  if (localStorage.getItem("ADMIN")) {
    sAdminProperty = aProperties.find(function (oProperty) {
      return oProperty.id === "idAdmin";
    })?.value;
  }

  var aDBProperty = DBProperty.value;
  var aCalculatedDBProperty = DBCalculationsProperty.value;
  var oSchema = {};
  var oDBProperties = {
    key: [],
    autoGenerate: "",
    pattern: "",
    ItemKey: [],
    items: [],
    calculation: aCalculatedDBProperty,
  };
  aDBProperty.forEach((oField) => {
    oInsertedData.forEach(function (oData) {
      for (const sKeys in oData) {
        if (sKeys === oField.value) {
          if (oData[sKeys] !== "") {
            if (oField.dataType === "Number") {
              oData[sKeys] = +oData[sKeys];
            }
          }
        }
      }
    });
  });

  const transformData = (data, properties) => {
    const keyMapping = properties.reduce((acc, prop) => {
      acc[prop.value] = prop.FieldName;
      return acc;
    }, {});
    return data.map((item) => {
      return Object.keys(item).reduce((acc, key) => {
        const newKey = keyMapping[key] || key;
        acc[newKey] = item[key];
        return acc;
      }, {});
    });
  };

  if (DBProperty.value && "FieldName" in DBProperty.value[0]) {
    oInsertedData = transformData(oInsertedData, DBProperty.value);
  }

  function getInsideArraySchema(sArrayTableName) {
    var oInsideSchema = {};
    oLogicDatas.aCurrentPage.forEach(function (oComponent) {
      if (oComponent.Component === "Table") {
        var sTableName = oComponent.properties.find(function (oProperty) {
          return oProperty.id === "idInputTableName";
        });
        if (sTableName.value === sArrayTableName) {
          var tableDatatypes = oComponent.properties.find(function (oProperty) {
            return oProperty.id === "idColumnConfig";
          }).value;
          tableDatatypes.forEach(function (oProperty) {
            if (oProperty.dataType === "datetime-local") {
              oInsideSchema[oProperty.fieldName] = { type: "date" };
            } else {
              oInsideSchema[oProperty.fieldName] = { type: oProperty.dataType };
            }
          });
        }
      }
    });
    return oInsideSchema;
  }
  var oSubSchema = {};
  aDBProperty.forEach((oField) => {
    if (oField.key === "X") {
      if(oField.FieldName){
        oDBProperties.key.push(oField.FieldName.trim());
      }else {
        oDBProperties.key.push(oField.value.trim());
      }
    }
    if (oField.auto === "X") {
      if(oField.FieldName){
        oDBProperties.autoGenerate = oField.FieldName.trim();
      }else {
        oDBProperties.autoGenerate = oField.value.trim();
      }
      if (oField.pattern !== "") {
        let match = oField.pattern.match(/\{(.*?)\}/);
        if (match && match[1] === "CID") {
          let CID = localStorage.getItem("CID");
          oDBProperties.pattern = oField.pattern.replace(/\{CID\}/, CID);
        }else{
          oDBProperties.pattern = oField.pattern;
        }
      }
      if (oField.autoField) {
        oDBProperties.autoField = oField.autoField.trim();
      }
    }
    if (oField.Series === "X") {
      oDBProperties.Series = true;
    }
    if (oField.ItemKey === "X") {
      var oNewData;
      if(oField.FieldName){
        oNewData = `${oField.itemName.trim()}.${oField.FieldName.trim()}`;
      }else {
        oNewData = `${oField.itemName.trim()}.${oField.value.trim()}`;
      }
      oDBProperties.ItemKey.push(oNewData);
    }
    if (oField.item === "X") {
      var oNewData;
      if(oField.FieldName){
        oNewData = `${oField.itemName.trim()}.${oField.FieldName.trim()}`;
      }else {
        oNewData = `${oField.itemName.trim()}.${oField.value.trim()}`;
      }
      oDBProperties.items.push(oNewData);
    }
    if (oField.dataType) {
      if (oField.dataType === "File") {
        let sDocumentField;
        if (oField.FieldName) {
          sDocumentField =
            oField.item === "X"
              ? `${oField.itemName.trim()}.${oField.FieldName.trim()}`
              : oField.FieldName;
        } else {
          sDocumentField =
            oField.item === "X"
              ? `${oField.itemName.trim()}.${oField.value.trim()}`
              : oField.value;
        }
        oDBProperties["documentField"] = sDocumentField;
      }
      if (oField.dataType === "Array" && oField.item !== "X") {
        var oInsideSchema = getInsideArraySchema(oField.value.trim());
        oSchema[oField.value.trim()] = [oInsideSchema];
      } else {
        if (oField.item !== "X") {
          oSchema[oField.value.trim()] = { type: oField.dataType };
        } else {
          oSubSchema[oField.value.trim()] = { type: oField.dataType };
        }
      }
    }
  });

  const handleConvertSchema = (oCurrentSchema) => {
    const oConvertedSchema = {};
    Object.keys(oCurrentSchema).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(oCurrentSchema[key], "type")) {
        oConvertedSchema[key] = oCurrentSchema[key];
      } else {
        oConvertedSchema[key] = [handleConvertSchema(oCurrentSchema[key])];
      }
    });
    return oConvertedSchema;
  };

  var oPostData = {
    TABLE_NAME: sTableValue,
    DATA: oInsertedData,
    PROPERTIES: oDBProperties,
    Update: updateDB,
    ADMINAPP: sAdminProperty,
    APP_ID:oLogicDatas.APPID,
  };

  oLogicDatas.bBusyIndicator(true)
  aPromises.push(
    new Promise((resolve) => {
      Server.post('/logics/db/insert', oPostData,{
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(function (oResult) {
        oLogicDatas.bBusyIndicator(false)
        if(oResult.status === 201){
          oParams.TableData = oResult.data.DATA;
          resolve(oResult.data);
        }else{
          oLogicDatas.iErrorCode(oResult.status);
          oLogicDatas.sErrorMessage(oResult.data.message);
          oLogicDatas.bSnackBar(true);
        }
      })
      .catch(function (oError) {
        oLogicDatas.bBusyIndicator(false)
        oLogicDatas.iErrorCode(oError.response.status);
        oLogicDatas.sErrorMessage(oError.response.data.message);
        oLogicDatas.bSnackBar(true);
      })
    })
  );
};
