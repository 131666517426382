export function fetchTargetComponentValues(
  oTargetComponent,
  sOperation,
  aSelectionArrayFields,
  aUIData
) {
  var aFieldsData = [];
  var oFieldProperty = oTargetComponent.properties.find(function (oComponent) {
    return oComponent.id === "idFetchJoin";
  });

  var oApiProperty = oTargetComponent.properties.find(function (oComponent) {
    return oComponent.id === "idApiPayloadConfig";
  });
  
  var oDeepFieldProperty = oTargetComponent.properties.find(function (oComponent) {
    return oComponent.id === "idDeepFetchJoin";
  });
  var oSplitColumnsProperty = oTargetComponent.properties.find(
    function (oComponent) {
      return oComponent.id === "idDropDownSplitColumns";
    }
  );
  var oMrpProperty = oTargetComponent.properties.find(function (oComponent) {
    return oComponent.id === "idMRPRequiredFields";
  });
  var oArithematicProperty = oTargetComponent.properties.find(
    function (oComponent) {
      return oComponent.id === "idCalculator";
    }
  );
  var oTableArithematicProperty = oTargetComponent.properties.find(
    function (oComponent) {
      return oComponent.id === "idTableCalculator";
    }
  );
  var oFetchInputsProperty = oTargetComponent.properties.find(
    function (oComponent) {
      return oComponent.id === "idFetchInputComponents";
    }
  );
  var oTableSelectionProperty = oTargetComponent.properties.find(
    function (oComponent) {
      return oComponent.id === "idDropDownTable";
    }
  );
  var oInsertDbFields = oTargetComponent.properties.find(function (oComponent) {
    return oComponent.id === "idDBTableProperties";
  });
  var oCodeEditorOutput = oTargetComponent.properties.find(
    function (oComponent) {
      return oComponent.id === "idparameterdefinition";
    }
  );
  var oMapInputFields = oTargetComponent.properties.find(function (oComponent) {
    return oComponent.id === "idMapInputFields";
  });

  if (oFieldProperty||oDeepFieldProperty) {
    if (
      sOperation === "MapTableData" ||
      sOperation === "MapSingleData" ||
      sOperation === "DropDownMap" ||
      sOperation === "ValueHelpDialog" ||
      sOperation === "MapGanttChartData" ||
      sOperation === "MapBarChart" ||
      sOperation === "MapComponentVisibility" ||
      sOperation === "MapG_GanttChartData" ||
      sOperation === "FormMapData"||
      sOperation === "MapSendEmail"||
      sOperation === "MapViewConfig"
    ) {
      oFieldProperty =oFieldProperty?oFieldProperty:oDeepFieldProperty
      const allSelectionFields = oFieldProperty.value["collections"].flatMap(
        (item) => item.selectionFields
      );
      aFieldsData = allSelectionFields;
    } else if (sOperation === "TableArithematicCalculator") {
      oFieldProperty.value["collections"].forEach((collection) => {
        collection.selectionFields.forEach((fieldName) => {
          aFieldsData.push({
            fieldName: fieldName,
            operator: "",
          });
        });
      });
    }
  }
  if (oSplitColumnsProperty) {
    var aArrayColumnsData = aSelectionArrayFields[oSplitColumnsProperty.value];
    for (let oProperty in aArrayColumnsData) {
      if (
        sOperation === "MapTableData" ||
        sOperation === "MapGanttChartData" ||
        sOperation === "MapBarChart" ||
        sOperation === "MapG_GanttChartData" ||
        sOperation === "FormMapData" ||
        sOperation === "ValueHelpDialog" || 
        sOperation === "MapViewConfig"
      ) {
        aFieldsData.push(aArrayColumnsData[oProperty]);
      } else if (sOperation === "TableArithematicCalculator") {
        aFieldsData.push({
          fieldName: aArrayColumnsData[oProperty],
          operator: "",
        });
      }
    }
  }
  if (oTableArithematicProperty) {
    var aValue = oTableArithematicProperty.value.columnFields;
    for (var oTableProperty in aValue) {
      aFieldsData.push(aValue[oTableProperty].fieldName);
    }
  }
  if (oArithematicProperty) {
    var oArithematicFormulaProperty = oTargetComponent.properties.find(
      function (oComponent) {
        return oComponent.id === "idTitleInput";
      }
    );
    aFieldsData.push(oArithematicFormulaProperty.value);
  }
  if (oMrpProperty) {
    aFieldsData.push(
      "NumberOfPlannedOrderCreated",
      "PurchaseRequisition",
      "Inventory",
      "NumberOfPurchaseRequistionCreated"
    );
  }
  if(oApiProperty){
    aFieldsData = oTargetComponent.properties.find(oComponent => oComponent.id === "idApiPayloadConfig")?.fields;
  }
  if (oTableSelectionProperty && !oTableArithematicProperty) {
    var oColumnsProperty;
    var sTableID = oTableSelectionProperty.value;
    aUIData.forEach(function (oComponent) {
      if (
        oComponent.Component === "Table" &&
        oComponent.uniqueId === sTableID
      ) {
        oColumnsProperty = oComponent.properties.find(function (oProperty) {
          return oProperty.id === "idColumnConfig";
        });
      }
    });
    if (oColumnsProperty) {
      oColumnsProperty.value.forEach(function (oColumDetails) {
        aFieldsData.push(oColumDetails.fieldName);
      });
    }
  }
  if (oFetchInputsProperty && oFetchInputsProperty.value.length > 0) {
    var aInputsFields = [" "];
    oFetchInputsProperty.value.forEach(function (sValue) {
      aInputsFields.push(sValue);
    });
    aFieldsData = aInputsFields;
  }
  if (!oFieldProperty) {
    var oParameterProperty = oTargetComponent.properties.find(
      function (oComponent) {
        return oComponent.id === "idGetParameters";
      }
    );
    if (oParameterProperty && oParameterProperty.value.length > 0) {
      var aInputs = [" "];
      oParameterProperty.value.forEach(function (oParam) {
        aInputs.push(oParam.parameterName);
      });
      aFieldsData = aInputs;
    }
  }
  if (oInsertDbFields) {
    if (sOperation === "MapSingleData" || sOperation === "MapSendEmail") {
      const allSelectionFields = oInsertDbFields.value.flatMap(
        (item) => item.value
      );
      aFieldsData = allSelectionFields;
    }
  }
  if(oCodeEditorOutput){
    var aInputsFields = [" "];
    oCodeEditorOutput.value.forEach(function (sValue) {
      if(sValue.Result === "Output"){
        aInputsFields.push(sValue.Parameters);
      }
    });
    aFieldsData = aInputsFields;
  }
  if (oMapInputFields) {
    var aInputsFields = [];
    if (Object.keys(oMapInputFields.value).length > 0) {
      Object.keys(oMapInputFields.value).forEach((keyField) => {
        aInputsFields.push(oMapInputFields.value[keyField].value);
      });
    }
    aFieldsData = aInputsFields;
  }
  return aFieldsData;
}
