import React from "react";
import MapViewComponent from "./esri";

const ArcGISMap = ({
  aTargetLocations,
  bLocationTrack,
  oComponentModel,
  positionChange,
}) => {
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <MapViewComponent
        graphicsData={aTargetLocations}
        bLocationTrack={bLocationTrack}
        oComponentModel={oComponentModel}
        positionChange={positionChange}
      />
    </div>
  );
};

export default ArcGISMap;