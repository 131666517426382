import React from "react";
import { useState } from "react";
import { AIprompt } from "../../../../dialog/aiPrompt/AIprompt";

//React flow library components
import ReactFlow, { Controls, Background, ReactFlowProvider } from "reactflow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

//CSS
import "reactflow/dist/style.css";
import "./RenderLogicFlow.css";

export default function RenderLogicFlow(props) {
  const [bPrompt, setPrompt] = useState(false);
  var aNodes = [];

  if (!props.visible) {
    return;
  }

  if (props.nodes) {
    aNodes = props.nodes;
  }

  /**
   * Event handler for drag end
   */
  function handleDragEnd() {
    props.update(props.nodes);
  }

  /**
   * Event handler on node click
   * @param {event} oEvent
   * @param {object} oNode
   */
  function handleNodeClick(oEvent, oNode) {
    props.select(oNode.id);
  }

  /**
   * Event handler for delete node
   * @param {array} nodes | deleted nodes
   */
  function handleDelete(nodes) {
    props.delete(nodes);
  }

  /**
   * Event handler for change in edges
   * @param {object} changes | Edge details
   */
  function handleEdgesChange(changes) {
    props.onEdgesChange(changes);
  }

  /**
   * Event handler during edge connect
   * @param {object} params | parameters during edge connect
   */
  function handleConnect(params) {
    props.onConnect(params);
  }

  function handleOpenPrompt(){
    setPrompt(true);
  }

  return (
    <>
      <ReactFlowProvider>
        <div className="container">
          <div className="promptDesign">
            <FontAwesomeIcon icon={faComment} size="xl" onClick={handleOpenPrompt}/>
          </div>
        </div>
        <ReactFlow
          nodes={aNodes}
          onNodesChange={props.change}
          onNodeDragStop={handleDragEnd}
          onNodeClick={handleNodeClick}
          onNodesDelete={handleDelete}
          edges={props.edges}
          onEdgesChange={handleEdgesChange}
          onConnect={handleConnect}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>
      <AIprompt
        open={bPrompt}
        close={setPrompt}
        selectedComponent={props.selectedComponent}
        selectLogic={props.selectLogic}
        updateprompt={props.updateprompt}
        onConnect={handleConnect}
        UIData={props.UIData}
        reSelect = {props.reSelect}
        UIdata={props.UIdata}
      />
    </>
  );
}
