import React, { useState, useEffect } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { RenderComponents } from './renderComponents/RenderComponents';
import { FooterComponents } from './footer/Footer';
import { useParams, useLocation } from 'react-router-dom';
import AppLogo from './images/logo.png';
//CSS
import './PreviewPage.css';
//Material UI
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

//Server
import Server from '../../../serverDetails/server';
var reload = {};

export default function PreviewPage(props) {
  const [ahistoryPages, setHistoryPages] = useState([]);
  const [aUIData, setUIData] = useState([]);
  const [APPDESC, setAppDesc] = useState('');
  const [sAPP_ID, setAppID] = useState('');
  const [sPage_ID, setPageID] = useState('');
  const [bOpenMessage, setOpenMessage] = useState(false);
  const [sMessage, setMessage] = useState('');
  const [oAppJSON, setAppJSON] = useState({});
  const [oCurrentPage, setCurrentPage] = useState({});
  const [iSelectedTab, setSelectedTab] = useState(0);
  const [sSelectedTab, setSelectedTabName] = useState('');
  const [aParameters, setParamaters] = useState([]);
  const [bLoading, setLoading] = useState(false);

  const [openValueHelpField, setOpenValueHelpField] = useState(false);
  const [ovalueHelpData, setValueHelpData] = useState({});
  const [ovalueColumnData, setValueColumnData] = useState({});
  const [svalueHelpDataTable, setValueHelpDataTable] = useState('');
  const [aValidationMessages, setValidationMessages] = useState([]);
  const [bVisibleErrorIcon, setVisibleErrorIcon] = useState(false);

  const [sErrorMessage, setErrorMessage] = useState('');
  const [iErrorCode, setErrorCode] = useState();
  const [bSnackBar, setSnackBar] = useState(false);

  const location = useLocation();
  var USERID;
  var APPID;
  var pageID;
  var bHeaderBar = true;
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;
  const { APP_ID, sPageID } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject = {};
  for (const [key, value] of queryParams.entries()) {
    queryParamsObject[key] = value;
  }

  var aPreviewProps = {
    message: {
      value: bOpenMessage,
      setOpenMessage: setOpenMessage,
      setMessage: setMessage
    },
    inputHelpField: {
      value: openValueHelpField,
      setOpenValueHelpField: setOpenValueHelpField,
      setValueHelpData: setValueHelpData,
      setValueColumnData: setValueColumnData,
      setValueHelpDataTable: setValueHelpDataTable
    }
  };

  window.addEventListener('load', () => {
    var oPreviewRenderer = document.getElementById('idPreviewRenderer');
    reload = {};
    for (var i = 0; i < oPreviewRenderer.childNodes.length; i++) {
      if (oPreviewRenderer.childNodes[i].id.includes('idTabs')) {
        oPreviewRenderer.childNodes[i].childNodes[0].childNodes[0].click();
      }
    }
  });

  if (location.state && location.state.USERID) {
    USERID = location.state.USERID;
    APPID = location.state.APP_ID;
    pageID = sPageID;
    if (
      APP_ID === location.pathname.split('/')[2] &&
      sPageID !== sPage_ID &&
      oAppJSON &&
      Object.keys(oAppJSON).length > 0 &&
      oAppJSON[sPageID]
    ) {
      var oDefaultPage = oAppJSON[sPageID];
      setCurrentPage(oDefaultPage);
      setPageID(sPageID);
      updateTabsState(oDefaultPage.UIComponents);
      reload = {};
    } else if (APPID !== sAPP_ID && sAPP_ID !== '') {
      if (location.state.OPERATION === 'Launchpad') {
        _useEffect();
        location.state.OPERATION = '';
      }
    }
  } else if (props.USERID) {
    USERID = props.USERID;
    APPID = props.APP_ID
    if (!APPID) {
      APPID = localStorage.getItem('D_APPID');
    }
    bHeaderBar = false;
  } else if (location.state === null && APP_ID && sPageID) {
    USERID = localStorage.USERID;
  } else {
    navigate('/');
  }

  /**
   * Execute while rendering StudioPage component
   */
  useEffect(() => {
    if (!localStorage.stopReload || localStorage.stopReload === 'false') {
    } else if (location.state === null && !props.DASHBOARD) {
      const queryParamsString = queryParams
        ? `?${new URLSearchParams(queryParams).toString()}`
        : '';
      navigate(`/PreviewPage/${APP_ID}/${sPageID}${queryParamsString}`, {
        state: { USERID: USERID, APP_ID: APP_ID, pageID: sPageID }
      });
      window.location.reload();
    } else {
      _useEffect();
    }
  }, []);

  if (!localStorage.stopReload || localStorage.stopReload === 'false') {
    localStorage.setItem('stopReload', true);
    window.location.reload();
    return;
  }

  function _useEffect() {
    setLoading(true);

    if (APPID) {
      fetchData(`/publish/${APPID}`, handleAppResponse);
    } else if (location.state && location.state.USERID) {
      USERID = location.state.USERID;
      fetchData(`/editor/version`, handleVersionResponse);
    }

    function fetchData(endpoint, responseHandler) {
      Server.get(endpoint, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(responseHandler)
        .catch(handleError);
    }

    function handleAppResponse(oResponse) {
      if (oResponse.data && oResponse.status === 201) {
        const oAPP_Data = oResponse.data.App;
        if (screenWidth <= 768) {
          oAPP_Data.UIDATA = oAPP_Data.MOBILE_DATA ? oAPP_Data.MOBILE_DATA: oAPP_Data.UIDATA
        }
        const oState = {
          APPID: oAPP_Data.APP_ID,
          APP_DESC: oAPP_Data.APP_DESC,
          APPJson: oAPP_Data.UIDATA
        };
        updateAppStates(oState);
        setLoading(false);
        reload = {};
      }
    }

    function handleVersionResponse(oResponse) {
      if (oResponse.data.UserVersion) {
        const oVersion = oResponse.data.UserVersion;
        if (screenWidth <= 768) {
          oVersion.UIDATA = oVersion.MOBILE_DATA ? oVersion.MOBILE_DATA: oVersion.UIDATA
        }
        const oState = {
          APPID: oVersion.APPID,
          APP_DESC: oVersion.APP_DESC,
          APPJson: oVersion.UIDATA
        };
        setLoading(false);
        updateAppStates(oState);
      }
    }

    function handleError(oError) {
      setErrorCode(oError.response.status);
      setErrorMessage(oError.response.data.Message);
      setSnackBar(true);
      setLoading(false);
    }
  }

  /**
   * Event handler to close message
   */
  function handleClose() {
    setOpenMessage(false);
  }

  /**
   * Update appilication state
   * @param {object} oAppState | Appilication state
   */
  function updateAppStates(oAppState) {
    if (oAppState) {
      setAppDesc(oAppState.APP_DESC);
      setAppID(oAppState.APPID);
      setAppJSON(oAppState.APPJson);
      // var oDefaultPage = oAppState.APPJson[Object.keys(oAppState.APPJson)[0]];
      var oDefaultPage = sPageID ?oAppState.APPJson[sPageID]:oAppState.APPJson[Object.keys(oAppState.APPJson)[0]]
      setCurrentPage(oDefaultPage);
      setHistoryPages([oDefaultPage.id]);
      setUIData([...oDefaultPage.UIComponents]);
      updateTabsState([...oDefaultPage.UIComponents]);
    }
  }

  function updateTabsState(aUIData) {
    var oTab = aUIData.find(function (oUIData) {
      return oUIData.Component === 'Tabs';
    });
    if (oTab) {
      var oPropertyTabs = oTab.properties.find(function (oProperty) {
        return oProperty.id === 'idTabNames';
      });
      var sSelectedTab = oPropertyTabs.value[iSelectedTab];
      var aSelectedTabComponents = getSelectedTabComponents(
        sSelectedTab,
        aUIData
      );
      setSelectedTabName(sSelectedTab);
      setUIData(aSelectedTabComponents);
      setSelectedTab(0);
    } else {
      setUIData(aUIData);
    }
  }

  function handleChangeTab(sSelectedTab) {
    var aUIData = oCurrentPage.UIComponents;
    var aSelectedTabComponents = getSelectedTabComponents(
      sSelectedTab,
      aUIData
    );
    setSelectedTabName(sSelectedTab);
    setUIData([...aSelectedTabComponents]);
    setSelectedTab(iSelectedTab);
  }

  function getSelectedTabComponents(sSelectedTab, aUIData) {
    var oTab = aUIData.find(function (oUIData) {
      return oUIData.Component === 'Tabs';
    });

    var oTabComponents = oTab.properties.find(function (oProperty) {
      return oProperty.id === 'idTabDisplayConfig';
    });
    var aTabComponentList = oTabComponents.value[sSelectedTab];
    var aTabComponentModel = [];
    aTabComponentModel.push(oTab);
    aTabComponentList.forEach(function (oTabComponent) {
      if (oTabComponent.visible) {
        var oUIComponent = aUIData.find(function (oComponent) {
          return oComponent.uniqueId === oTabComponent.uniqueID;
        });
        aTabComponentModel.push(oUIComponent);
      }
    });
    return aTabComponentModel;
  }

  /**
   * Event Handler to update the Navigated Page Data
   * @param {string} sPageID
   * @param {array}  aParameters
   */
  function handleNavigate(sPageID, aParameters, sAPPID) {
    if (sAPPID && sPageID) {
      var queryParams = {};
      aParameters.forEach(function (oParam) {
        queryParams[oParam.field] = oParam.value;
      });
      const queryParamsString = queryParams
        ? `?${new URLSearchParams(queryParams).toString()}`
        : '';
      if (!bHeaderBar) {
        navigate(`/PreviewPage/${sAPPID}/${sPageID}${queryParamsString}`, {
          state: {
            USERID: USERID,
            OPERATION: 'Launchpad',
            APP_ID: sAPPID,
            pageID: sPageID,
            bHeaderBar:false,
          }
        });

      } else {
        location.state.OPERATION = 'Launchpad';
        navigate(`/PreviewPage/${sAPPID}/${sPageID}${queryParamsString}`, {
          state: {
            USERID: USERID,
            OPERATION: location.state.OPERATION,
            APP_ID: sAPPID,
            pageID: sPageID
          }
        });
      }
      
    }
    if (sPageID && !sAPPID) {
      var queryParams = {};
      const oStateObject = { USERID, pageID: sPageID };
      aParameters.forEach(function (oParam) {
        if(oParam.state){
          queryParams[oParam.field] = 'statevalue';
          oStateObject[oParam.field] = oParam.value;
        }else{
          queryParams[oParam.field] = oParam.value;
        }
      });
      const queryParamsString = queryParams
        ? `?${new URLSearchParams(queryParams).toString()}`
        : '';
      let history = [...ahistoryPages, sPageID];
      setHistoryPages(history);
      var aTempUIData = [...oAppJSON[sPageID].UIComponents];
      updateTabsState(aTempUIData);
      setCurrentPage({ ...oAppJSON[sPageID] });
      if (location.state.OPERATION) {
        oStateObject.OPERATION = location.state.OPERATION
        oStateObject.APP_ID = APP_ID;
      }
      navigate(`/PreviewPage/${sAPP_ID}/${sPageID}${queryParamsString}`, {
        state: oStateObject
      });
    }
  }

  /**
   * Event handler change input from user
   * @param {string} stext | UserInput
   * @param {number} id   | inputFiled id
   * @param {Array} aUIData |all Components In RenderPage
   */
  function handelChangeInput(stext, id, aUIData) {
    var aTabComponentModel = aUIData;
    aTabComponentModel.forEach(function (oComponent) {
      if (oComponent.uniqueId === id) {
        return (oComponent.UserValue = stext);
      }
    });
    setUIData([...aTabComponentModel]);
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handlelogonavigate = () => {
    navigate('/HomePage')
  }

  return (
    <div id="idPreviewRenderer" className="Preview-Background">
      {bHeaderBar ? (
        <div className="Preview-header">
          <div className="Preview-Logo">
            <div>
              <a
                href={`http://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/HomePage`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(event) => {
                  if (event.button === 0) {
                    event.preventDefault();
                    handlelogonavigate();
                  }
                }}
              >
                <img src={AppLogo} alt="AppLaunch" className="logo" />
              </a>
              <span style={{ position: 'absolute', top: '6px' }}>
                {APPDESC}
              </span>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div id="PreviewRenderer" className="Preview-RendererSpace">
        <div className="previewpage-busyindicator">
          {bLoading && (
            <div className="overlay">
              <ScaleLoader
                color={'#033146'}
                loading={bLoading}
                aria-label="Loading Spinner"
                data-testid="loader"
                height={25}
                width={6}
              />
            </div>
          )}
        </div>
        <Snackbar
          open={bSnackBar}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackBar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="error"
            sx={{ width: '100%' }}
          >
            {`${iErrorCode} - ${sErrorMessage}`}
          </Alert>
        </Snackbar>
      </div>
      <RenderComponents
        UIData={aUIData}
        currentPage={oCurrentPage}
        userId={USERID}
        APPID={sAPP_ID}
        prevProps={aPreviewProps}
        setSelectedTab={sSelectedTab}
        onChange={handelChangeInput}
        changeTab={handleChangeTab}
        resetUI={setUIData}
        resetAppUI={setAppJSON}
        navigate={handleNavigate}
        reload={reload}
        queryParams={queryParamsObject}
        paramters={aParameters}
        valueHelpColumnData={ovalueColumnData}
        valueHelpData={ovalueHelpData}
        valueHelpDataTable={svalueHelpDataTable}
        openClose={openValueHelpField}
        allUIData={oAppJSON}
        setValidationMessages={setValidationMessages}
        setVisibleErrorIcon={setVisibleErrorIcon}
      />
      {bHeaderBar && (
        <FooterComponents
          openValidationMessage={bVisibleErrorIcon}
          setVisibleErrorIcon={setVisibleErrorIcon}
          aValidationMessages={aValidationMessages}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={bOpenMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        message={sMessage}
        action={action}
      />
    </div>
  );
}
