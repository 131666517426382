export const MapLocationData = (aProperties, oParams, oLogicDatas) => {
  let aData = [];
  var aRowData = [];
  var sMapLocationUniqueId = aProperties.find(function (oProperty) {
    return oProperty.id === "idDropDownMapView";
  }).value;
  var oMappingView = aProperties.find(function (oProperty) {
    return oProperty.id === "idMapViewFields";
  }).value;
  if (!Array.isArray(oParams.TableData)) {
    if(oParams.TableData){
      aData.push(oParams.TableData);
    }else{
      aData = oParams;
    }
  } else {
    aData = oParams.TableData;
  }
  if (aData.length > 0) {
    aData.forEach(function (oData) {
      let oRowData = {};
      for (var props in oData) {
        for (var rowprops in oMappingView) {
          if (oMappingView[rowprops] === props) {
            oRowData[rowprops] = oData[props];
          }
        }
      }
      aRowData.push(oRowData);
    });
    var oComponent = oLogicDatas.aCurrentPage.find(function (oComponentModel) {
      return oComponentModel.uniqueId === sMapLocationUniqueId;
    });
    oComponent.center = aRowData;
    oLogicDatas.resetUI([...oLogicDatas.aUIData]);
  }
};
