export const MapG_GanttChart = (aProperties, oParams, oLogicDatas) => {
  var aParams = [];
  if (!Array.isArray(oParams.TableData)) {
    aParams.push(oParams.TableData);
  } else {
    aParams = oParams.TableData;
  }

  if (aParams) {
    const sChartName = aProperties.find(
      (oProperty) => oProperty.id === "idDropDownGanttChart"
    ).value;
    const oDatas = aProperties.find(
      (oProperty) => oProperty.id === "idMapG_GanttFields"
    ).value;
    var sID = oDatas.TaskData.id;
    var aRows = [];
    var oMapingDatas = [
      { name: "Task ID", value: "id" },
      { name: "Task Name", value: "nameValue" },
      { name: "Resource", value: "resource" },
      { name: "Start Date", value: "start" },
      { name: "End Date", value: "end" },
      { name: "Duration", value: "duration" },
      { name: "Percent Complete", value: "progress" },
      { name: "Dependencies", value: "dependencies" },
      { name: "BarValue", value: "barvalue" },
    ];
    var aTasks = [
      [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
        { type: "string", label: "BarValue" },
      ],
    ];
    if (oDatas) {
      aParams.forEach(function (oValues) {
        var oTempRows = {};
        Object.keys(oDatas).forEach((key) => {
          switch (key) {
            case "TaskData":
              var oTaskData = handleTaskData(oDatas, key, oValues);
              oTempRows = { ...oTempRows, ...oTaskData };
              break;

            case "Percentage":
              var oPercentageData = handlePercentage(oDatas, key, oValues);
              oTempRows = { ...oTempRows, ...oPercentageData };
              break;

            case "Legend":
              var oLegend = handleLegend(oDatas, key, oValues);
              oTempRows = { ...oTempRows, ...oLegend };
              break;
            default:
              break;
          }
        });
        aRows.push(oTempRows);
      });
      if (aRows) {
        aTasks = handleArrangeData(aRows, aTasks, oMapingDatas);
      }
      oLogicDatas.aUIData.forEach((oComponent) => {
        if (oComponent.Component === "G_Gantt") {
          if (oComponent.uniqueId === sChartName) {
            var aSortedData = formatTask(aTasks);
            const oIdIndexMap = {};
            for(let i=1; i<aSortedData.length; i++){
              oIdIndexMap[aSortedData[i][0]] = i - 1;
            }
            aParams.sort((a, b) => {
              const indexA = oIdIndexMap[a[sID]];
              const indexB = oIdIndexMap[b[sID]];
              return indexA - indexB;
            });
            oComponent.AllData = aParams;
            oComponent.Data = aSortedData;
          }
        }
      });
      oLogicDatas.resetUI([...oLogicDatas.aUIData]);
    }
  }
};

function formatTask(aTasks) {
  const aHeaders = aTasks[0];
  let sortedData = aTasks.slice(1).sort((a, b) => {
    const idComparison = a[0].localeCompare(b[0]);
    if (idComparison !== 0) return idComparison;
    return new Date(a[3]).getTime() - new Date(b[3]).getTime();
  });
  sortedData = sortedData.sort((a, b) => {
    const startDateComparison =
      new Date(a[3]).getTime() - new Date(b[3]).getTime();
    return startDateComparison;
  });
  sortedData.unshift(aHeaders);
  return sortedData;
}

function formatOutput(data, format) {
  let output = "";
  let currentPlaceholder = "";
  let inPlaceholder = false;

  for (let i = 0; i < format.length; i++) {
    const char = format[i];
    if (
      char === "[" ||
      char === "]" ||
      char === "/" ||
      char === "-" ||
      char === "{" ||
      char === "}"
    ) {
      if (inPlaceholder) {
        output += data[currentPlaceholder];
        output += char;
        currentPlaceholder = "";
        inPlaceholder = false;
      }
    } else {
      if (!inPlaceholder) {
        inPlaceholder = true;
      }
      currentPlaceholder += char;
    }
  }

  if (inPlaceholder) {
    output += data[currentPlaceholder];
  }

  return output;
}

/** Function to Set Task
 *
 * @param {*} oDatas
 * @param {*} key
 * @param {*} oValues
 * @returns
 */
function handleTaskData(oDatas, key, oValues) {
  var otempData = {};
  Object.keys(oDatas[key]).forEach((skey) => {
    if (skey === "Title") {
      otempData[skey] = oDatas[key][skey];
    } else if (skey === "name") {
      otempData.nameValue = formatOutput(oValues, oDatas[key][skey]);
    } else if (skey === "start" || skey === "end") {
      otempData[skey] = oValues[oDatas[key][skey]];
    } else if (skey === "dependencies") {
      if (oValues[oDatas[key][skey]]) {
        otempData[skey] = oValues[oDatas[key][skey]];
      } else {
        otempData[skey] = "";
      }
    } else if (skey === "BarValue"){
      const aBarValues = oDatas[key][skey].split(',');
      var sBarValue = '';
      aBarValues.forEach(oKey => {
        sBarValue += `${oValues[oKey]}/`;
      });
      sBarValue = sBarValue.slice(0, -1);
      otempData[skey] = sBarValue;
    } else {
      otempData[skey] = oValues[oDatas[key][skey]];
    }
  });
  return otempData;
}

/** Caluculate Percentage based on current and total value
 *
 * @param {*} oDatas
 * @param {*} key
 * @param {*} oValues
 * @returns
 */
function handlePercentage(oDatas, key, oValues) {
  var otempData = {};
  otempData.progress =
    (oValues[oDatas[key].CurrentValue] / oValues[oDatas[key].TotalValue]) * 100;
  return otempData;
}

/**  Colour for Bar
 *
 * @param {*} oDatas
 * @param {*} key
 * @param {*} oValues
 * @returns
 */
function handleLegend(oDatas, key, oValues) {
  var otempData = {};
  var sFieldName = oDatas[key].FieldName;
  var sFieldValue = oValues[sFieldName];
  otempData.resource = sFieldValue;
  return otempData;
}

function handleDate(dateTimeString) {
  var dateTimeComponents = dateTimeString.split("T");
  var dateComponents = dateTimeComponents[0].split("-");
  var timeComponents = dateTimeComponents[1].split(":");
  var milliseconds = 0;

  var year = parseInt(dateComponents[0]);
  var month = parseInt(dateComponents[1]) - 1;
  var day = parseInt(dateComponents[2]);

  var hour = parseInt(timeComponents[0]);
  var minute = parseInt(timeComponents[1]);
  var second = parseInt(timeComponents[2]);

  return new Date(year, month, day, hour, minute, second, milliseconds);
}

function handleArrangeData(aRows, aTasks, oMapingDatas) {
  aRows.forEach(function (oDatas) {
    var aSubArray = [];
    oMapingDatas.forEach(function (sKey) {
      switch (sKey.value) {
        case "id":
          var sStringValue = oDatas[sKey.value].toString();
          aSubArray.push(sStringValue);
          break;
        case "nameValue":
          aSubArray.push(oDatas[sKey.value]);
          break;
        case "resource":
          aSubArray.push(oDatas[sKey.value]);
          break;
        case "start":
          var dStartDate = handleDate(oDatas[sKey.value]);
          aSubArray.push(dStartDate);
          break;
        case "end":
          var dEndDate = handleDate(oDatas[sKey.value]);
          aSubArray.push(dEndDate);
          break;
        case "duration":
          aSubArray.push(null);
          break;
        case "progress":
          aSubArray.push(oDatas[sKey.value]);
          break;
        case "dependencies":
          if (oDatas[sKey.value] === "") {
            aSubArray.push(null);
          } else {
            aSubArray.push(oDatas[sKey.value]);
          }
          break;
        case "barvalue":
          aSubArray.push(oDatas[`BarValue`]);
          break;
        default:
          break;
      }
    });
    aTasks.push(aSubArray);
  });
  return aTasks;
}
