import React from "react";
import { useNavigate } from "react-router-dom";
import "./TitleBar.css";

//Material UI
import Stack from "@mui/material/Stack";
import AirplayRoundedIcon from "@mui/icons-material/AirplayRounded";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AppShortcutRoundedIcon from "@mui/icons-material/AppShortcutRounded";

export const TitleBar = (props) => {
  let navigate = useNavigate();
  function handleLogOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("CID");
    localStorage.removeItem("ADMIN");
    navigate("/");
  }
  return (
    <div className="studio_mainPage-title-control">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "6rem",
            width:"90%",
            paddingTop:"5px",
          }}
        >
          <div style={{ display: "flex" }}>
            <IconButton size="small" sx={{ color: "white" }}>
              <AirplayRoundedIcon />
            </IconButton>
            <div className="studio_mainPage-titleLabel">
              <Typography variant="button">Project Name :</Typography>
            </div>
            <div className="studio_mainPage-titleValue">
              <Typography variant="subtitle1">{props.project}</Typography>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <IconButton size="small" sx={{ color: "white" }}>
              <AppShortcutRoundedIcon />
            </IconButton>
            <div className="studio_mainPage-titleLabel">
              <Typography variant="button">Application Name :</Typography>
            </div>
            <div className="studio_mainPage-titleValue">
              <Typography variant="subtitle1">{props.app}</Typography>
            </div>
          </div>
        </div>
        <div>
          <div className="studio_mainPage-Header_logout">
            <h4 onClick={handleLogOut}>LogOut</h4>
          </div>
        </div>
      </div>
    </div>
  );
};
