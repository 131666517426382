import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export const ApiProperties = (props) => {
  const handleEvent = (event, index, skey) => {
    const { value } = event.target;
    const oUpdatedRows = [...props.rows];
    oUpdatedRows[index][skey] = value;
  };

  function handleUpdate(){
    props.update(props.rows)
  }

  return (
    <div>
      <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
        <DialogTitle>Paramter Definition</DialogTitle>
        <DialogContent>
          <div>
            <Box>
              <table
                id="idTableConfiguration"
                className="RenderComponent-Table"
              >
                <thead>
                  <tr>
                    <th className="RenderComponent-Table-th">Paramter</th>
                    <th className="RenderComponent-Table-th">Value</th>
                    <th className="RenderComponent-Table-th">DataType</th>
                  </tr>
                </thead>
                <tbody>
                  {props.rows.map((row, index) => (
                    <tr key={index}>
                      <td className="RenderComponent-Table-td">
                        <input
                          className="RenderComponent-Table-input"
                          Value={row.Parameter}
                          readOnly
                        />
                      </td>
                      <td className="RenderComponent-Table-td">
                        <select
                          className="RenderComponent-Table-input"
                          defaultValue={row.Value}
                          onChange={(oEvent) => {
                            handleEvent(oEvent, index, "Value");
                          }}
                        >
                          <option disabled value="">
                            Select Value
                          </option>
                          {props.inputs.map((option, idx) => (
                            <option key={idx} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="RenderComponent-Table-td">
                        <select
                          className="RenderComponent-Table-input"
                          defaultValue={row.Datatype}
                          onChange={(oEvent) => {
                            handleEvent(oEvent, index, "Datatype");
                          }}
                        >
                          <option disabled value="">
                            Select Value
                          </option>
                          <option value="Number">Number</option>
                          <option value="String">String</option>
                          <option value="Date">Date</option>
                          <option value="Array">Array</option>
                          <option value="Object">Object</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
