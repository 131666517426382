//Server
import Server from "../../../serverDetails/server";

/**
 * Request to retrive user version
 * @param {string} sUserId | User id
 * @returns {Promise} 
 */
export function _retrieveUserVersion(sUserId) {
    return Server.get('/editor/version',
    {
        headers:{
            Authorization:localStorage.getItem("token")
        }
    });
}