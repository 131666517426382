import React from "react";
import Box from "@mui/material/Box";

export const ArrayWhereConditionDialog = (props) => {
  const handleEvent = (event, index, skey) => {
    const { value } = event.target;
    const oUpdatedRows = [...props.rows.ArrayWhereConditions];
    oUpdatedRows[index][skey] = value;
    props.update({ ...props.rows, ArrayWhereConditions: oUpdatedRows });
  };

  return (
    <Box>
      <table id="idTableConfiguration" className="RenderComponent-Table" style={{width:"30%"}}>
        <thead>
          <tr>
            <th className="RenderComponent-Table-th" >Value</th>
          </tr>
        </thead>
        <tbody>
          {props.rows.ArrayWhereConditions &&
            props.rows.ArrayWhereConditions.map((row, index) => (
              <tr key={index}>
                <td className="RenderComponent-Table-td">
                  {props.inputs.length > 0 && (
                    <select
                      className="RenderComponent-Table-input"
                      value={row.value}
                      name={index}
                      onChange={(oEvent) => {
                        handleEvent(oEvent, index, "value");
                      }}
                    >
                      {props.inputs.map(function (input) {
                        return (
                          <option key={input} value={input}>
                            {input}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Box>
  );
};
