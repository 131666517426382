import Server from "../../../../../serverDetails/server";

export const DynamicApi = (aProperties, oParams, aPromises, oLogicDatas) => {
  var sAPPID = aProperties.find((oProp) => oProp.id === "idApiList")?.value;
  var sRoute = aProperties.find((oProp) => oProp.id === "idRouteList")?.value;
  var sMethod = aProperties.find((oProp) => oProp.id === "idMethod")?.value;

  var aPayload = aProperties.find(
    (oProp) => oProp.id === "idApiPayloadConfig"
  )?.value;

  if (oParams) {
    const oValueLookup = oParams.reduce((acc, item) => {
      acc[item.field] = item.value;
      return acc;
    }, {});
    aPayload = aPayload.map((item) => {
      return {
        ...item,
        Value: oValueLookup[item.Value] || item.Value,
      };
    });
  }

  const typecastValue = (value, datatype) => {
    switch (datatype) {
      case "Number":
        return Number(value);
      case "String":
        return String(value);
      case "Boolean":
        return value.toLowerCase() === "true";
      default:
        return value;
    }
  };
  const updatedData = aPayload.reduce((acc, item) => {
    acc[item.Parameter] = typecastValue(item.Value, item.Datatype);
    return acc;
  }, {});

  if (sMethod === "GET") {
    const queryString = new URLSearchParams(updatedData).toString();
    const fullUrl = `/api/${sAPPID}${sRoute}?${queryString}`;
    oLogicDatas.bBusyIndicator(true);
    aPromises.push(
        Server.get(fullUrl, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
          .then(function (oResult) {
            oLogicDatas.bBusyIndicator(false);
            if (oResult.status === 201) {
              if(oResult.data.Message){
                oParams.Message = oResult.data.Message
              }
              oParams.TableData = oResult.data.Data;
            }
          })
          .catch(function (oError) {
            oLogicDatas.bBusyIndicator(false);
            oLogicDatas.iErrorCode(oError.response.status);
            oLogicDatas.sErrorMessage(oError.response.data.message);
            oLogicDatas.bSnackBar(true);
          })
    );
  } else if (sMethod === "POST") {
    oLogicDatas.bBusyIndicator(true);
    aPromises.push(
        Server.post(`/api/${sAPPID}${sRoute}`, updatedData, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
          .then(function (oResult) {
            oLogicDatas.bBusyIndicator(false);
            if (oResult.status === 201) {
              if(oResult.data.Message){
                oParams.Message = oResult.data.Message
              }
              oParams.TableData = oResult.data.Data;
            } else {
            }
          })
          .catch(function (oError) {
            oLogicDatas.bBusyIndicator(false);
            oLogicDatas.iErrorCode(oError.response.status);
            oLogicDatas.sErrorMessage(oError.response.data.message);
            oLogicDatas.bSnackBar(true);
          })
    );
  } else if (sMethod === "DELETE") {
    oLogicDatas.bBusyIndicator(true);
    aPromises.push(
        Server.delete(`/api/${sAPPID}${sRoute}`, {
          data: updatedData,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
          .then(function (oResult) {
            oLogicDatas.bBusyIndicator(false);
            if (oResult.status === 201) {
              if(oResult.data.Message){
                oParams.Message = oResult.data.Message
              }
              oParams.TableData = oResult.data.Data;
            } else {
            }
          })
          .catch(function (oError) {
            oLogicDatas.bBusyIndicator(false);
            oLogicDatas.iErrorCode(oError.response.status);
            oLogicDatas.sErrorMessage(oError.response.data.message);
            oLogicDatas.bSnackBar(true);
          })
    );
  }
};
