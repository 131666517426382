import React from "react";

//Material ui components
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export const CreateValidationLogic = (props) => {
  var sValidationLogicName = "";
  /**
   * Event handler for event change
   * @param {event} oEvent | Event
   */
  function handleChangeEvent(oEvent) {
    sValidationLogicName = oEvent.target.value;
  }

  /**
   * Submit handler
   */
  function handleCreate() {
    props.submit(props.validationLogicId, sValidationLogicName);
  }

  /**
   * Close dialog
   */
  function handleClose() {
    props.close();
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Create New Validation Logic</DialogTitle>
        <DialogContent>
          <TextField
            required
            margin="dense"
            id="idValidationname"
            label="Validation Name"
            fullWidth
            variant="standard"
            sx={{ minWidth: 450 }}
            onChange={handleChangeEvent}
          />
          <br></br>
          <br></br>
          <TextField
            required
            margin="dense"
            id="idValidationLogicid"
            label="ValidationLogic id"
            fullWidth
            disabled
            variant="standard"
            sx={{ maxWidth: 500 }}
            value={props.validationLogicId}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={handleCreate}>
            Create
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
