import React, { useState,useEffect } from "react";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";

import Server from "../../../../../serverDetails/server";

export const CollectionDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  const [achooseOptions, setchooseOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [oArrayFieldsData, setOArrayFieldsData] = useState({});

  useEffect(() => {
    props.rows.collections.forEach((oDatas, index) => {
      const options = oDatas.selectionFields.map((element) => ({
        value: element,
        label: element,
      }));
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [index]: options,
      }));
    });
  }, [props.rows]);

  const handleCollectionName = (event, index, sKey) => {
    // Handle collection name change
    var value;
    if (sKey === "selectSingle") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    const updatedRows = [...props.rows.collections];
    updatedRows[index][sKey] = value;
    props.update({ ...props.rows, collections: updatedRows });
  };

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...aSelectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  function handleIncreaseRow() {
    const updatedRows = [...props.rows.collections];
    updatedRows.push({
      collectionName: "",
      selectionFields: [],
      selectSingle: "",
    });
    props.update({ ...props.rows, collections: updatedRows });
    setSelectedRows([...aSelectedRows, false]);
  }

  function handleRemoveRow() {
    const updatedRows = props.rows.collections.filter(
      (_, index) => !aSelectedRows[index]
    );
    props.update({ ...props.rows, collections: updatedRows });
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  const handleFetchDatas = async (index) => {
    var table = props.rows.collections[index].collectionName;
    if (table) {
      Server.get(`/tablefields/${table}/${props.admin}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then(function (response) {
        if (response.data.fields) {
          const datas = response.data.fields;
          const options = Object.keys(datas).map((key) => ({
            value: key,
            label: key,
          }));
          setchooseOptions((prevOptions) => ({
            ...prevOptions,
            [index]: options,
          }));
          setOArrayFieldsData((prevData) => {
            const updateArrayFields = (oData, sElement, oNewData) => {
              oNewData[sElement] = [];
              const processNestedArrays = (nestedArray, prefix = '') => {
                for (const nestedKey in nestedArray[0]) {
                  const currentKey = prefix ? `${nestedKey}` : nestedKey;
                  if (Array.isArray(nestedArray[0][nestedKey])) {
                    processNestedArrays(nestedArray[0][nestedKey], currentKey);
                  } else {
                    oNewData[sElement].push(currentKey);
                  }
                }
              };
              for (const key in oData[0]) {
                if (Array.isArray(oData[0][key])) {
                  processNestedArrays(oData[0][key], key);
                } else {
                  oNewData[sElement].push(key);
                }
              }
            };
          
            const oNewData = { ...prevData };
          
            for (const element in datas) {
              if (Array.isArray(datas[element])) {
                updateArrayFields(datas[element], element, oNewData);
              }
            }
            return oNewData;
          });
        }
      });
    }
  };

  const handleSelectChange = (index, options) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [index]: options,
    }));
    const values = options.map((option) => option.value);
    const latestoption = values[values.length-1]
    const updatedRows = [...props.rows.collections];
    updatedRows[index].selectionFields = values;
    if (
      oArrayFieldsData !== undefined &&
      oArrayFieldsData[latestoption] &&
      !props.arrayFields[latestoption]
    ) {
      props.updateArrayFields((prevState) => ({
        ...prevState,
        [latestoption]: oArrayFieldsData[latestoption],
      }));
    }else{
      props.updateNonArrayFields((prevstate) => ([
        ...prevstate,
        latestoption
      ]));
    }
    props.update({ ...props.rows, collections: updatedRows });
  };

  return (
    <div>
      <IconButton>
        <AddIcon
          onClick={handleIncreaseRow}
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      {props.rows.collections.length > 1 ? (
        <IconButton>
          <RemoveIcon
            onClick={handleRemoveRow}
            baseClassName="fas"
            className="fa-plus-circle"
            color="secondary"
          />
        </IconButton>
      ) : (
        ""
      )}
      <Box>
        <table id="idTableConfiguration" className="RenderComponent-Table">
          <thead>
            <tr>
              <th></th>
              <th className="RenderComponent-Table-th">Collection Name</th>
              <th className="RenderComponent-Table-th">Selection Fields</th>
              <th className="RenderComponent-Table-th">Select Single</th>
            </tr>
          </thead>
          <tbody>
            {props.rows.collections.map((row, index) => (
              <tr key={index}>
                <td className="RenderComponent-Table-td">
                  <input
                    type="checkbox"
                    checked={aSelectedRows[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    className="RenderComponent-Table-input"
                    value={row.collectionName}
                    onChange={(event) =>
                      handleCollectionName(event, index, "collectionName")
                    }
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <Select
                    isMulti
                    options={achooseOptions[index] || []}
                    value={selectedOptions[index] || []}
                    onMenuOpen={() => handleFetchDatas(index)}
                    onChange={(selectedOptions)=>handleSelectChange(index,selectedOptions)}
                  />
                </td>
                <td
                  className="RenderComponent-Table-td"
                  style={{ textAlign: "center" }}
                >
                  <input
                    type="checkbox"
                    style={{ height: "20px", width: "30px" }}
                    value={row.selectSingle}
                    onChange={(event) => {
                      handleCollectionName(event, index, "selectSingle");
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </div>
  );
};
