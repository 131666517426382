import React, { useState, useEffect } from "react";
import MonacoEditor from "react-monaco-editor";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import ScaleLoader from "react-spinners/ScaleLoader";
import PromptGenerator from "../../GPT/promptGenerator";
import fetchTableListWithFields from "../logics/dbOperations/fetchDataBaseList/fetchDataBaseList";
import { formatLogicComponent } from "./formatLogicComponent/formatLogicComponent";

import "./AIPrompt.css";
import { DBFieldsList } from "./menu/DBFieldsList/DBFieldsList";
import { TutorList } from "./menu/TutorList/TutorList";

export const AIprompt = (props) => {
  const [message, setMessage] = useState("");
  const [oResponse, setResponse] = useState();
  const [editorContent, setEditorContent] = useState(
    JSON.stringify(oResponse, null, 2)
  );
  const [bLoading, setLoading] = useState(false);
  const [aDataBaselist, setDataBaselist] = useState([]);

  var previousMessages = [];
  if (props.selectLogic && props.selectLogic.prompt) {
    previousMessages = props.selectLogic.prompt;
  }

  const flattenFields = (data) => {
    const flatArray = [];

    const traverse = (obj, path = "") => {
      if (typeof obj !== "object" || obj === null) {
        flatArray.push({ path, type: obj });
        return;
      }
      if (Array.isArray(obj)) {
        obj.forEach((item, index) => {
          traverse(item, `${path}`);
        });
      } else {
        Object.entries(obj).forEach(([key, value]) => {
          const newPath = path ? `${path}.${key}` : key;
          if (
            typeof value === "object" &&
            value !== null &&
            !Array.isArray(value) &&
            value.type
          ) {
            flatArray.push({ path: newPath, type: value.type });
          } else {
            traverse(value, newPath);
          }
        });
      }
    };
    traverse(data);
    return flatArray;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (props.open) {
        const aDBList = await fetchTableListWithFields();
        const flattened = aDBList.slice(1).map(({ tableName, fields }) => ({
          tableName,
          fields: flattenFields(fields),
        }));
        setDataBaselist(flattened);
      }
    };

    fetchData();
  }, [props.open]);

  function handleJsonChange(newJson) {
    setEditorContent(newJson);
    try {
      const updatedResponse = JSON.parse(newJson);
      setResponse(updatedResponse);
    } catch (error) {
      console.log(error);
    }
  }

  function handleInputChange(event) {
    setMessage(event.target.value);
  }

  function handleMenuPromptMessage(sPrompt) {
    setMessage(sPrompt);
  }

  async function responseFlowConverterFromJson(messageContent) {
    const logicComponentList = require("../../model/workflow.json");
    let parentLogicComponent;
    if (
      (messageContent.components && messageContent.components.length > 0) ||
      (messageContent.Logic && messageContent.Logic.length > 0)
    ) {
      if (
        messageContent.Logic[0].ComponentID === "onClick" ||
        messageContent.Logic[0].ComponentID === "onLoad" ||
        messageContent.Logic[0].ComponentID === "onEnter"
      ) {
        if (props.selectedComponent && props.selectLogic) {
          props.selectedComponent.Logic.forEach((element) => {
            if (element.id === props.selectLogic.id) {
              parentLogicComponent = element.component[0];
              if (element.component.length > 1 && element.edges.length >= 1) {
                element.edges = [];
                element.component.splice(1);
              }
            }
          });
        }
        let targets = messageContent.Logic[0].next;
        executeRecursiveLogic(
          logicComponentList,
          targets,
          parentLogicComponent
        );
      }
    }
    await props.reSelect(props.selectLogic);
  }

  function executeRecursiveLogic(logicComponentList, targets, parentComponent) {
    for (let i = 0; i < targets.length; i++) {
      let oTargetComponent = targets[i];
      let logicComponent = formatLogicComponent(
        logicComponentList,
        oTargetComponent,
        props.selectedComponent,
        props.selectLogic
      );
      if (props.selectedComponent && props.selectLogic) {
        props.selectedComponent.Logic.forEach((element) => {
          if (element.id === props.selectLogic.id) {
            const offsetX = 175;
            const offsetY = i === 0 ? 0 : 50;
            logicComponent.position.x = parentComponent.position.x + offsetX;
            logicComponent.position.y = parentComponent.position.y + offsetY;
            let edge = {
              source: parentComponent.id,
              sourceHandle: null,
              target: logicComponent.id,
              targetHandle: null,
            };
            element.component.push(logicComponent);
            props.onConnect(edge);
          }
        });
      }
      if (oTargetComponent.next && oTargetComponent.next.length > 0) {
        executeRecursiveLogic(
          logicComponentList,
          oTargetComponent.next,
          logicComponent
        );
      }
      props.updateprompt(props.selectedComponent);
    }
  }

  async function handleGenerateFlow() {
    await responseFlowConverterFromJson(oResponse);
    props.updateprompt(props.selectedComponent);
    handleCancel();
  }

  async function handleSend() {
    try {
      if (message && message !== "") {
        if (oResponse) {
          setResponse({});
        }
        if (props.selectedComponent && props.selectLogic) {
          props.selectedComponent.Logic.forEach((element) => {
            if (element.id === props.selectLogic.id) {
              if (element.prompt) {
                element.prompt = [message];
              } else {
                element.prompt = [];
                element.prompt.push(message);
              }
            }
          });
        }
        let promtResponseJson = await PromptGenerator({
          message: message,
          UIData: props.UIdata,
          setLoading,
        });
        setResponse(promtResponseJson);
        setEditorContent(JSON.stringify(promtResponseJson, null, 2));
      } else {
        handleCancel();
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleCancel() {
    props.close(false);
  }

  const options = {
    selectOnLineNumbers: true,
    fontSize: 16,
    language: "json",
    validationOptions: {
      lint: {
        enable: true,
      },
      validate: true,
      schemas: [
        {
          uri: "https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-configure-json-defaults",
          fileMatch: ["*"],
        },
      ],
    },
  };

  return (
    <Dialog open={props.open} onClose={handleCancel} maxWidth="lg" fullWidth >
      <DialogTitle>AI Prompt</DialogTitle>
      <DialogContent className="dialogContentWrapper">
        <div className="menuComponentsWrapper">
          <div className="menuList">
            <DBFieldsList aDataBaselist={aDataBaselist} />
          </div>
          <div className="monacoEditorWrapper">
            <div className="monacoEditor">
              <MonacoEditor
                width="100%"
                height="100%"
                language="javascript"
                theme="vs-dark"
                value={editorContent}
                options={options}
                onChange={handleJsonChange}
              />
            </div>
            <div className="textFieldWrapper">
              <TextField
                fullWidth
                value={previousMessages[0]}
                disabled
                style={{ height: "30px" }}
              />
            </div>
          </div>
          <div className="logictutor">
            <TutorList handleClick={handleMenuPromptMessage} />
          </div>
        </div>
        <div className="promptSection">
          <TextField
            label="Type your prompt"
            fullWidth
            value={message}
            onChange={handleInputChange}
            style={{ height: "40px" }}
          />
          <Button onClick={handleSend} className="sendButton">
            <SendIcon />
          </Button>
        </div>
        {bLoading && (
          <div className="overlay">
            <ScaleLoader
              color={"#033146"}
              loading={bLoading}
              aria-label="Loading Spinner"
              data-testid="loader"
              height={25}
              width={6}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGenerateFlow}>Generate</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
