import React, { useState } from "react";
//Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
//Dialog Components
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const MappingParamsDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  var aRowsData = [];

  /**
   * Event handler for CheckBox
   * @param {Number} iIndex
   */
  const handleCheckboxChange = (iIndex) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[iIndex] = !aNewSelectedRows[iIndex];
    setSelectedRows(aNewSelectedRows);
  };

  /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    var aTemp = [...props.mappingParamsData];
    aTemp.push({
      parameterName: "",
      parameterValue: "",
      tableSelection: false,
      arraySelection: false,
      mappingValue: "",
      graphField: "",
    });
    props.setMappingParams(aTemp);
    setSelectedRows([...aSelectedRows, false]);
  }

  /**
   * Event handler for Remove Rows
   */
  function handleDecreaseRow() {
    var aTemp = [...props.mappingParamsData];
    const aUpdatedRows = aTemp.filter((_, index) => !aSelectedRows[index]);
    props.setMappingParams(aUpdatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  if (props.mappingParamsData && props.mappingParamsData.length > 0) {
    for (let i = 0; i < props.mappingParamsData.length; i++) {
      aRowsData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={aSelectedRows[i]}
              onChange={() => handleCheckboxChange(i)}
            />
          </td>
          <td className="RenderComponent-Table-td">
            <input
              className="RenderComponent-Table-input"
              value={props.mappingParamsData[i].parameterName}
              onChange={(oEvent) => {
                handleChangeParameter(oEvent, i);
              }}
              disabled={props.mappingParamsData[i].arraySelection}
            />
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) => handleChangeParameterValue(oEvent, i)}
              required
              value={props.mappingParamsData[i].parameterValue}
              className="column-title"
            >
              <option value="">Select </option>
              {props.inputFields &&
                props.inputFields.map((sValidationField, index) => {
                  return (
                    <option value={sValidationField.uniqueID} key={index}>
                      {sValidationField.value}
                    </option>
                  );
                })}
            </select>
          </td>
          <td className="RenderComponent-Table-td">
            <input
              style={{marginLeft:"30px",width:"20px",height:"20px"}}
              type="checkbox"
              checked={props.mappingParamsData[i].tableSelection}
              onChange={(oEvent) => {
                handleChangeTableSelection(oEvent, i);
              }}
            />
          </td>
          <td className="RenderComponent-Table-td">
            <input
              style={{marginLeft:"30px",width:"20px",height:"20px"}}
              type="checkbox"
              checked={props.mappingParamsData[i].arraySelection}
              onChange={(oEvent) => {
                handleChangeArraySelection(oEvent, i);
              }}
            />
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) => handleChangeMappingValue(oEvent, i)}
              required
              value={props.mappingParamsData[i].mappingValue}
              className="column-title"
              disabled={props.mappingParamsData[i].arraySelection}
            >
              <option value="">Select </option>
              {props.mappingParamsData[i].columnFields &&
                props.mappingParamsData[i].columnFields.map(
                  (sColumnField, index) => {
                    return (
                      <option value={sColumnField.fieldName} key={index}>
                        {sColumnField.fieldName}
                      </option>
                    );
                  }
                )}
            </select>
          </td>
          <td className="RenderComponent-Table-td">
            <input
              className="RenderComponent-Table-input"
              value={props.mappingParamsData[i].graphField}
              onChange={(oEvent) => {
                handleChangeGraphParameter(oEvent, i);
              }}
            />
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler for Fields
   * @param {*} oEvent
   * @param {Number} iIndex
   * @param {string} skey
   */
  function handleChangeParameter(oEvent, iIndex) {
    var aTemp = [...props.mappingParamsData];
    aTemp[iIndex].parameterName = oEvent.target.value;
    props.setMappingParams(aTemp);
  }

  function handleChangeMappingValue(oEvent, iIndex) {
    var aTemp = [...props.mappingParamsData];
    aTemp[iIndex].mappingValue = oEvent.target.value;
    props.setMappingParams(aTemp);
  }

  function handleChangeTableSelection(oEvent, iIndex) {
    var aTemp = [...props.mappingParamsData];
    aTemp[iIndex].tableSelection = oEvent.target.checked;
    props.setMappingParams(aTemp);
  }

  function handleChangeArraySelection(oEvent, iIndex) {
    var aTemp = [...props.mappingParamsData];
    aTemp[iIndex].arraySelection = oEvent.target.checked;
    aTemp[iIndex].parameterName = 'StateID'
    props.setMappingParams(aTemp);
  } 

  function handleChangeParameterValue(oEvent, iIndex) {
    var aTemp = [...props.mappingParamsData];
    aTemp[iIndex].parameterValue = oEvent.target.value;
    var oColumn = props.inputFields.find(function (oInputField) {
      return (
        oInputField.uniqueID === oEvent.target.value && oInputField.columns
      );
    });
    if (oColumn) {
      aTemp[iIndex].columnFields = oColumn.columns;
    } else {
      aTemp[iIndex].columnFields = [];
    }
    props.setMappingParams(aTemp);
  }

  function handleChangeGraphParameter(oEvent, iIndex) {
    var aTemp = [...props.mappingParamsData];
    aTemp[iIndex].graphField = oEvent.target.value;
    props.setMappingParams(aTemp);
  }
  /**
   * Event handler for Update
   */
  const handleSubmit = () => {
    props.update(props.mappingParamsData);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        PaperProps={{
          sx: {
            width: 900,
            maxHeight: 900,
          },
        }}
      >
        <DialogTitle>
          Mapping Params
          <IconButton onClick={handleIncreaseRow}>
            <AddIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="primary"
            />
          </IconButton>
          <IconButton onClick={handleDecreaseRow}>
            <RemoveIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="secondary"
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <table id="idTableConfiguration" className="RenderComponent-Table">
            <thead>
              <tr>
                <th></th>
                <th className="RenderComponent-Table-th">Parameter Name</th>
                <th className="RenderComponent-Table-th">Mapping Value</th>
                <th className="RenderComponent-Table-th">Table Selection</th>
                <th className="RenderComponent-Table-th">Array Selection</th>
                <th className="RenderComponent-Table-th">Value</th>
                <th className="RenderComponent-Table-th">Graph Field</th>
              </tr>
            </thead>
            <tbody>{aRowsData}</tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
