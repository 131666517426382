import React, { useState} from "react";
import Select from "react-select";

import './SearchFields.css';

export const SearchFieldsDialog = (props) => {
  const [aSelectedOptionFields, setSelectedOptionFields] = useState([]);
  const [achooseOptions, setchooseOptions] = useState([]);

  function handleFetchDatas() {
    if (achooseOptions.length < props.aSchemaFieldsData.length) {
      var temp = [...achooseOptions];
      props.aSchemaFieldsData.forEach((oRowData, index) => {
        const options = {
          value: oRowData.inputFieldName,
          label: oRowData.inputFieldName,
        };
        temp.push(options);
      });
      setchooseOptions([...temp]);
    }
  }

  const handleSelectChange = (options) => {
    setSelectedOptionFields([...options]);
    props.setSearchFields([...options])

  }

  return (
    <div>
      <Select
        isMulti
        options={achooseOptions || []}
        value={props.selectedSearchFields || []}
        onMenuOpen={() => handleFetchDatas()}
        className="custom-select"
        onChange={(aSelectedOptionFields)=>handleSelectChange(aSelectedOptionFields)}
      />
    </div>
  );
};
