import React, { useState, useEffect } from "react";
import "./RenderDesigner.css";
import styled from "styled-components";
import { loadModules } from "esri-loader";
import AlignmentGuides from "react-alignment-guides";

// Material UI
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

//Google-Charts
import { Chart } from "react-google-charts";
import { FrappeGantt } from "frappe-gantt-react";

//Utility files
import { createResizeDivs } from "../../utilities/resizeUtils";
import ArcGISMap from "./Argsimap";

export default function RenderComponents(props) {
  const [sGanttView, setGanttView] = useState("Day");
  const [aAlign, setAlign] = useState([]);
  const [iRefreshKey, setRefreshKey] = useState(0);
  var aTabs = [];

  /**  Event Listener Fuction to Catch the Key Press
   *
   * @param {*} oEvent
   */
  const handleKeyDown = (oEvent) => {
    if (oEvent.code === "Delete" || oEvent.code === "Backspace") {
      const sMatchId = oEvent.target.id.match(/\d+/);
      const iComponentPosition = sMatchId ? parseInt(sMatchId[0], 10) : null;
      props.onDelete(aComponentModel[iComponentPosition]);
    } else if (oEvent.code) {
      const sMatchId = oEvent.target.id.match(/\d+/);
      const iComponentPosition = sMatchId ? parseInt(sMatchId[0], 10) : null;
      props.onMouseMove(oEvent.code, aComponentModel[iComponentPosition]);
    }
  };

  /** Event Listener Function to catch the click
   *
   * @param {*} oEvent
   */
  const handleClick = (oEvent) => {
    const sMatchId = oEvent.target.id.match(/\d+/);
    const iComponentPosition = sMatchId ? parseInt(sMatchId[0], 10) : null;
    props.onSelect(aComponentModel[iComponentPosition]);
  };

  /**  setting up the event listeners for the components
   *
   * @returns
   */
  const setupEventListeners = () => {
    const alignmentGuides = document.querySelector(".alignment-guides");
    if (alignmentGuides) {
      alignmentGuides.addEventListener("click", handleClick);
      alignmentGuides.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (alignmentGuides) {
        alignmentGuides.removeEventListener("click", handleClick);
        alignmentGuides.removeEventListener("keydown", handleKeyDown);
      }
    };
  };

  useEffect(() => {
    if (
      !props ||
      !props.componentModel ||
      props.componentModel.length < 1 ||
      !props.visible
    ) {
      return;
    }
    const EventListeners = setupEventListeners();
    return EventListeners;
  }, [props.componentModel, props.visible]);

  if (
    !props ||
    !props.componentModel ||
    props.componentModel.length < 1 ||
    !props.visible
  ) {
    return;
  }

  var aRenderComponents = [];
  var aAlignmentComponents = [];
  var aComponentModel = props.componentModel;

  aComponentModel.forEach(function (oComponentModel) {
    aTabs = [];
    aRenderComponents.push(renderComponent(oComponentModel, aComponentModel));
    aAlignmentComponents.push({
      ...aRenderComponents[aRenderComponents.length - 1].props.style,
      id: aRenderComponents[aRenderComponents.length - 1].props.id,
    });
  });

  /**  Function to change the position of alignmnet boxes
   *
   * @param {*} aArray1
   * @param {*} aArray2
   * @returns
   */
  function handleAlignmentBoxes(aArray1, aArray2) {
    const aKeys = ["left", "top", "width", "height"];
    if (aArray1.length !== aArray2.length) {
      return false;
    }
    for (let i = 0; i < aArray1.length; i++) {
      for (const key of aKeys) {
        if (aArray1[i][key] !== aArray2[i][key]) {
          return false;
        }
      }
    }
    return true;
  }

  if (!handleAlignmentBoxes(aAlignmentComponents, aAlign)) {
    setAlign(aAlignmentComponents);
  }

  /** Function to change the key of Alignment Guide
   *
   * @param {*} aArray1
   * @param {*} aArray2
   * @returns
   */
  function handleAlignmentBoxKey(aArray1, aArray2) {
    const aKeys = ["left", "top", "width", "height"];
    let iTotalMismatchSets = 0;
    if (aArray1.length !== aArray2.length) {
      return false;
    }
    for (let i = 0; i < aArray1.length; i++) {
      let bIsMismatch = false;
      for (const key of aKeys) {
        if (aArray1[i][key] !== aArray2[i][key]) {
          bIsMismatch = true;
          break;
        }
      }
      if (bIsMismatch) {
        iTotalMismatchSets++;
        if (iTotalMismatchSets > 1) {
          return false;
        }
      }
    }
    return iTotalMismatchSets > 1 ? false : true;
  }
  if (!handleAlignmentBoxKey(aAlignmentComponents, aAlign)) {
    setRefreshKey((prevKey) => prevKey + 1);
  }

  /**
   * Create child component of dynamic component
   * @param {string} sDiv | Type div
   * @param {string} sText | Text inside the component
   * @returns
   */
  function createChild(sDiv, sText) {
    if (sDiv === "input") {
      return null;
    }
    return sText;
  }

  /**
   * OnClick activate Resize and dragging
   * @param {event} oEvent
   * @param {object} oComponentModel
   */
  function handleClickComponent(oEvent, oComponentModel) {
    var oElement = oEvent.currentTarget;
    createResizeDivs(oElement, oComponentModel, props.update);
    props.onSelect(oComponentModel);
  }

  /**
   * on drag component update the props & server data
   * @param {event} oEvent | drag event
   */
  function handleDragStart(oEvent) {
    oEvent.dataTransfer.setData(
      "text",
      oEvent.currentTarget.id.replace("div", "")
    );
    const rect = oEvent.currentTarget.getBoundingClientRect();
    var offsetX = oEvent.clientX - rect.x;
    var offsetY = oEvent.clientY - rect.y;
    var offSet = {
      offsetX: offsetX,
      offsetY: offsetY,
    };
    oEvent.dataTransfer.setData("json", JSON.stringify(offSet)); //Transfer the dragged component to the studimainpage
  }

  /**
   * on press delete , delete the component
   * @param {event} oEvent
   * @param {object} oComponentModel
   */
  function onMouseKeyPress(oEvent, oComponentModel) {
    if (oEvent.code === "Delete" || oEvent.code === "Backspace") {
      props.onDelete(oComponentModel);
    } else if (oEvent.code) {
      props.onMouseMove(oEvent.code, oComponentModel);
    }
  }

  /**
   * Render default table component
   * @param {integer} iRow | Number of Rows
   * @param {integer} iColumn | Number of column
   * @param {array} aColumnDesc | array of column description
   * @returns
   */
  function getDefaultTable(
    iRow,
    iColumn,
    aColumnDesc,
    sTableTitle,
    bToolBar,
    bTabelSelection,
    bEditable,
    bAddRow
  ) {
    const rows = [];
    const columns = [];
    const rowData = [];
    if (bTabelSelection) {
      rowData.push(
        <td className="RenderComponent-Table-td">
          <input type="checkbox" className="" />
        </td>
      );
    }
    for (let i = 0; i < iColumn; i++) {
      if (aColumnDesc[i] && aColumnDesc[i].visibleColumn){
        rowData.push(<td className="RenderComponent-Table-td">Row {i + 1}</td>);
      }
    }
    for (let i = 0; i < iRow; i++) {
      rows.push(<tr className="RenderComponent-Table-tr">{rowData}</tr>);
    }
    if (bTabelSelection) {
      columns.push(
        <th className="RenderComponent-Table-th">
          {bTabelSelection && <input type="checkbox" className="" />}
        </th>
      );
    }

    if (aColumnDesc.length > 0) {
      for (let i = 0; i < iColumn; i++) {
      if ( aColumnDesc[i] && aColumnDesc[i].visibleColumn) {
        columns.push(
          <th className="RenderComponent-Table-th">
            {aColumnDesc[i] ? aColumnDesc[i].fieldName : ""}
          </th>
        );
      }
      }
    }

    return (
      <div>
        {getTableToolbar(bToolBar, sTableTitle, bEditable, bAddRow)}
        <table className="RenderComponent-Table">
          <tr>{columns}</tr>
          {rows}
        </table>
      </div>
    );
  }

  function getTableToolbar(bToolBar, sTableTitle, bEditable, bAddRow) {
    return (
      <div>
        {bToolBar ? (
          <div className="RenderComponent-toolbar">
            <h4>{sTableTitle}</h4>
            <div>
              {bEditable && bAddRow ? (
                <>
                  <IconButton sx={{ marginTop: "0.5rem" }}>
                    <AddIcon
                      baseClassName="fas"
                      className="fa-plus-circle"
                      color="primary"
                    />
                  </IconButton>
                  <IconButton sx={{ marginTop: "0.5rem" }}>
                    <RemoveIcon
                      baseClassName="fas"
                      className="fa-plus-circle"
                      color="secondary"
                    />
                  </IconButton>
                </>
              ) : (
                ""
              )}
              <IconButton sx={{ marginTop: "1.2rem", marginRight: "1rem" }}>
                <SettingsIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  /**
   * Render tabs
   * @param {array} aData
   * @param {object} oOptions
   * @param {boolean} bColumnsChart
   * @returns
   */
  function getDefaultChart(aData, oOptions, sChartName) {
    return (
      <div className="RenderComponent-BarChart">
        <Chart
          chartType={sChartName}
          width="100%"
          height="100%"
          data={aData}
          options={oOptions}
        />
      </div>
    );
  }

  /**
   * Render Gantt Chart
   * @param {array} tasks
   * @param {String} sViewMode
   * @returns
   */
  function getDefaultGanttChart(aTasks) {
    const handleChangeView = (event) => {
      const sView = event.target.value;
      setGanttView(sView);
    };
    var aLegend = [
      {
        FieldValue: "Legend1",
        custom_class: "Blue",
      },
      {
        FieldValue: "Legend2",
        custom_class: "Red",
      },
    ];
    const EmptyState = styled.div`
      background: #f7f7f7;
      color: rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;

      h1,
      h2 {
        margin: 5px;
      }
    `;
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <select
              onChange={handleChangeView}
              style={{ width: "120px", height: "25px" }}
            >
              <option value="Day">Day</option>
              <option value="Week">Week</option>
              <option value="Month">Month</option>
            </select>
          </div>
          <div className="gantt-legend-container">
            {aLegend.map((legend, index) => (
              <div key={index} className="gantt-legend-item">
                <div
                  className={`gantt-legend-dot gantt-${legend.custom_class}`}
                />
                <div className="gantt-legend-text">{legend.FieldValue}</div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          {aTasks.length > 0 ? (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <div style={{ marginBottom: "30px", marginTop: "10px" }}>
                  Machines
                </div>
                {aTasks.map((task) => (
                  <div key={task.id} style={{ marginBottom: "20px" }}>
                    {task.name}
                  </div>
                ))}
              </div>
              <FrappeGantt
                tasks={aTasks}
                viewMode={sGanttView}
                onClick={(task) => console.log(task)}
                onDateChange={(task, start, end) =>
                  console.log(task, start, end)
                }
                onProgressChange={(task, progress) =>
                  console.log(task, progress)
                }
                onTasksChange={(tasks) => console.log(tasks)}
              />
            </div>
          ) : (
            <EmptyState>
              <h1>No data available</h1>
              <h3>Generate some tasks</h3>
            </EmptyState>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <button className="gantt_btn">Update</button>
        </div>
      </div>
    );
  }

  function getDefaultGoogleGanttChart(aTasks) {
    var aLegend = [
      {
        FieldValue: "Legend1",
        custom_class: "Blue",
      },
      {
        FieldValue: "Legend2",
        custom_class: "Red",
      },
    ];
    const EmptyState = styled.div`
      background: #f7f7f7;
      color: rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;

      h1,
      h2 {
        margin: 5px;
      }
    `;
    const options = {
      height: 400,
      gantt: {
        trackHeight: 30
      }
    };
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
          </div>
          <div className="gantt-legend-container">
            {aLegend.map((legend, index) => (
              <div key={index} className="gantt-legend-item">
                <div
                  className={`gantt-legend-dot gantt-${legend.custom_class}`}
                />
                <div className="gantt-legend-text">{legend.FieldValue}</div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          {aTasks.length > 1 ? (
            <div style={{ display: "flex" }}>
              <Chart
                width="100%"
                height="400px"
                chartType="Gantt"
                loader={<div>Loading Chart</div>}
                data={aTasks}
                options={options}
                rootProps={{ "data-testid": "2" }}
                // click={handleClick}
                // select={handleSelect}
                chartEvents={[
                  {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                      const chart = chartWrapper.getChart();
                      const selection = chart.getSelection();

                      console.log(selection);
                    },
                  },
                ]}
              />
            </div>
          ) : (
            <EmptyState>
              <h1>No data available</h1>
              <h3>Generate some tasks</h3>
            </EmptyState>
          )}
        </div>
      </div>
    );
  }

  /**
   * Render tab component
   * @param {integer} iTabs | Number of tabs
   * @param {array} aTabNames | Array of tab names
   * @param {object} oComponentModel | oComponentModel
   * @returns
   */
  function getDefaultTabs(iTabs, aTabNames, oComponentModel) {
    for (var i = 0; i < iTabs; i++) {
      aTabs.push(
        <div
          id={oComponentModel.uniqueId + "div_" + "idTab" + i}
          className="RenderComponent-tabDiv"
          onClick={(oEvent) => {
            handleTabClick(oEvent, iTabs, oComponentModel);
          }}
        >
          <div className="RenderComponent-wrapper">
            <label
              id={oComponentModel.uniqueId + "div_" + "idTabLabel" + i}
              className="RenderComponent-label"
            >
              {aTabNames[i]}
            </label>
          </div>
          <div className="RenderComponent-Select"></div>
        </div>
      );
    }
    return (
      <div
        id={oComponentModel.uniqueId + "div"}
        className="RenderComponent-tabs"
        tabIndex="0"
        onKeyDown={(oEvent) => {
          onMouseKeyPress(oEvent, oComponentModel);
        }}
      >
        {aTabs}
      </div>
    );
  }

  /**
   * Render selected tab during click
   * @param {object} oEvent
   * @param {integer} iTabs
   * @param {object} oComponentModel
   * @param {string} tabName
   */
  function handleTabClick(oEvent, iTabs, oComponentModel, tabName) {
    refreshClickedTabs(iTabs, oComponentModel.uniqueId);
    oEvent.target.style.color = "rgb(0, 95, 106)";
    oEvent.currentTarget.style.borderBottom = "solid rgb(0, 95, 106)";
    hideOtherTabElements(
      oEvent.currentTarget.textContent,
      oComponentModel,
      aAlign,
      aRenderComponents
    );
  }

  /**
   * Event handler to hide other tab components
   * @param {string} tabName
   * @param {object} oComponentModel
   */
  function hideOtherTabElements(
    tabName,
    oComponentModel,
    aAlign,
    aRenderComponents
  ) {
    var oTabDisplayProperty = oComponentModel.properties.find(
      function (oProperty) {
        return oProperty.id === "idTabDisplayConfig";
      }
    );
    if (Object.keys(oTabDisplayProperty.value).length > 0) {
      oTabDisplayProperty.value[tabName].forEach(function (oTabComponent) {
        var oComponent = document.getElementById(
          oTabComponent.uniqueID + "div"
        );
        var sID = oTabComponent.uniqueID + "div";
        var iPosition = aRenderComponents.findIndex(
          (item) => item.props.id === sID
        );
        var ID = `box${iPosition}`;
        var oAlignmentComponent = document.getElementById(ID);
        if (oComponent) {
          if (!oTabComponent.visible) {
            if (oAlignmentComponent) {
              oAlignmentComponent.style.display = "none";
            }
            oComponent.style.display = "none";
          } else {
            if (oAlignmentComponent) {
              oAlignmentComponent.style.display = "";
            }
            oComponent.style.display = "";
          }
        }
      });
    }
  }

  /**
   * Refresh clicked tabs
   * @param {integer} iTabs
   * @param {string} sUniqueID
   */
  function refreshClickedTabs(iTabs, sUniqueID) {
    for (var i = 0; i < iTabs; i++) {
      document.getElementById(
        sUniqueID + "div_" + "idTabLabel" + i
      ).style.color = "";
      document.getElementById(
        sUniqueID + "div_" + "idTab" + i
      ).style.borderBottom = "";
    }
  }

  /**  Function to update the position of Component while draging
   *
   * @param {*} oEvent
   * @param {*} sID
   * @param {*} oBoxData
   */
  const handleAlignmentPosition = (oEvent, sID, oBoxData) => {
    if (sID.includes("box")) {
      const sMatchId = sID.match(/\d+/);
      const iComponentPosition = sMatchId ? parseInt(sMatchId[0], 10) : null;
      aComponentModel[iComponentPosition].left = oBoxData.x;
      aComponentModel[iComponentPosition].top = oBoxData.y;
      props.update(aComponentModel[iComponentPosition], true);
    }
  };

  /** Function to update the position of component in db after drag end
   *
   * @param {*} oEvent
   * @param {*} sID
   * @param {*} oBoxData
   */
  const handleAligmentEnd = (oEvent, sID, oBoxData) => {
    if (sID.includes("box")) {
      const sMatchId = sID.match(/\d+/);
      const iComponentPosition = sMatchId ? parseInt(sMatchId[0], 10) : null;
      aComponentModel[iComponentPosition].left = oBoxData.x;
      aComponentModel[iComponentPosition].top = oBoxData.y;
      props.update(aComponentModel[iComponentPosition]);
    }
  };

  /** Fuction to update the size of component while resizing
   *
   * @param {*} oEvent
   * @param {*} oBoxData
   */
  const handleAligmentsize = (oEvent, oBoxData) => {
    const sMatchId = oBoxData.node.id.match(/\d+/);
    const iComponentPosition = sMatchId ? parseInt(sMatchId[0], 10) : null;
    aComponentModel[iComponentPosition].DivHeight = oBoxData.height;
    aComponentModel[iComponentPosition].DivWidth = oBoxData.width;
    aComponentModel[iComponentPosition].top = oBoxData.top;
    aComponentModel[iComponentPosition].left = oBoxData.left;
    props.update(aComponentModel[iComponentPosition]);
  };

  /**
   * Render component from component model
   * @param {object} oComponentModel
   * @returns
   */
  function renderComponent(oComponentModel) {
    var sDiv = oComponentModel.div;
    var sText = oComponentModel.Component;
    var CSS = { ...oComponentModel.css };
    var sButtonClassName;
    var sType = null;
    var bReadOnly = false;

    //Properties Renderer
    if (oComponentModel.properties && oComponentModel.properties.length > 0) {
      var oTextProperty = oComponentModel.properties.find(function (oProperty) {
        return oProperty.title === "Text";
      });

      if (oTextProperty) {
        sText = oTextProperty.value;
      }

      var oTypeProperty = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idInputTypeButton";
      });

      var sPlaceholder = oComponentModel.properties.find(function (oProperty){
        return oProperty.id === "idInputPlaceholderName";
      })?.value;

      if (oTypeProperty) {
        var oSelectedType = oTypeProperty.options.find(function (oOption) {
          return oOption.value === oTypeProperty.value;
        });
        sButtonClassName = `btn_${oSelectedType.value}`;
      }

      //Label bold

      var oLabelStyle = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idInputStyleLabel";
      });

      if (oLabelStyle) {
        CSS.fontWeight = oLabelStyle.value;
      }

      //Size
      var oSize = oComponentModel.properties.find(function (oProperty) {
        return oProperty.title === "Size";
      });

      if (oSize) {
        CSS.fontSize = oSize.value;
      }

      //Type
      var oType = oComponentModel.properties.find(function (oProperty) {
        return oProperty.title === "Type";
      });

      if (oType) {
        sType = oType.value;
      }

      var oReadOnly = oComponentModel.properties.find(function (oProperty) {
        return oProperty.title === "ReadOnly";
      });

      if (oReadOnly) {
        bReadOnly = oReadOnly.value;
      }
    }

    //Alignment
    var sLeft = oComponentModel.left;
    var sTop = oComponentModel.top;
    var sDivWidth = oComponentModel.DivWidth;
    var sDivHeight = oComponentModel.DivHeight;

    var oResizer = CSS.resizer;
    var sId = oComponentModel.uniqueId;

    var oComponent;

    if (sDiv === "table") {
      var iRowVisible = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idVisibleRows";
      });
      var iColumnVisible = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idVisibleColumns";
        }
      );
      var oColumnConfig = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idColumnConfig";
      });
      var sTableTitle = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTableTitle";
      });
      var bToolBar = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTableToolBar";
      });
      var bTabelSelection = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idTableSelection";
        }
      );
      var bEditable = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idEditableTable";
      });
      var bAddRow = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTableAddRows";
      });
      if (bAddRow !== undefined) {
        bAddRow = bAddRow.value;
      } else {
        bAddRow = false;
      }
      oComponent = getDefaultTable(
        iRowVisible.value,
        iColumnVisible.value,
        oColumnConfig.value,
        sTableTitle.value,
        bToolBar.value,
        bTabelSelection.value,
        bEditable.value,
        bAddRow
      );
    } else if (sDiv === "barchart") {
      var sBarchartTitle = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idBarChartName";
        }
      );
      var sXAxisFieldName = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idXAxisFieldName";
        }
      );
      var sYAxisFieldName = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idYAxisFieldName";
        }
      );
      var bColumnsChart = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idColumnsChart";
      });
      var bStacked = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idStackedBarChart";
      });
      var sLegendPosition = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idLegendPosition";
        }
      );
      const aData = [
        ["City", "Category1", "Category2"],
        ["Value1", 100, 200],
        ["Values2", 100, 200],
      ];
      const oOptions = {
        title: sBarchartTitle.value,
        chartArea: { width: "50%" },
        bar: { groupWidth: "70%" },
        isStacked: bStacked.value,
        hAxis: {
          title: sXAxisFieldName.value,
          minValue: 0,
        },
        vAxis: {
          title: sYAxisFieldName.value,
        },
        legend: { position: sLegendPosition.value },
      };
      var sChartName = "BarChart";
      if (bColumnsChart.value) {
        sChartName = "ColumnChart";
      }
      var oComponent = getDefaultChart(aData, oOptions, sChartName);
    } else if (sDiv === "piechart") {
      var sPiechartTitle = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idPieChartName";
        }
      );
      var sLegendPosition = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idLegendPosition";
        }
      );
      const data = [
        ["Category", "Hours per Day"],
        ["Value1", 11],
        ["Value2", 2],
        ["Value3", 2],
      ];

      const options = {
        title: sPiechartTitle.value,
        pieStartAngle: 100,
        legend: sLegendPosition.value,
      };
      var sChartName = "PieChart";
      var oComponent = getDefaultChart(data, options, sChartName);
    } else if (sDiv === "Gantt") {
      const aTasks = [
        {
          id: "Task 1",
          name: "Writing",
          start: "2023-11-28",
          end: "2023-11-30",
          progress: 10,
          dependencies: "",
        },
        {
          id: "Task 2",
          name: "Reding",
          start: "2023-12-05",
          end: "2023-12-09",
          progress: 20,
          dependencies: "Task 1",
        },
        {
          id: "Task 3",
          name: "Redesign website",
          start: "2023-12-11",
          end: "2023-12-15",
          progress: 0,
          dependencies: "Task 2, Task 1",
        },
      ];
      oComponent = getDefaultGanttChart(aTasks);
    } else if (sDiv === "G_Gantt") {
      const aTasks = [
        [
          { type: "string", label: "Task ID" },
          { type: "string", label: "Task Name" },
          { type: "string", label: "Resource" },
          { type: "date", label: "Start Date" },
          { type: "date", label: "End Date" },
          { type: "number", label: "Duration" },
          { type: "number", label: "Percent Complete" },
          { type: "string", label: "Dependencies" },
        ],
        [
          "id_0",
          "Task One",
          "spring1",
          new Date(2024, 2, 22, 18, 0, 0),
          new Date(2024, 2, 23, 0, 0, 0),
          null,
          80,
          null,
        ],
        [
          "id_1",
          "Task two",
          "spring2",
          new Date(2024, 2, 22, 8, 0, 0),
          new Date(2024, 2, 22, 17, 0, 0),
          null,
          80,
          null,
        ],
        [
          "id_2",
          "Task Three",
          "spring3",
          new Date(2024, 2, 22, 8, 0, 0),
          new Date(2024, 2, 23, 17, 0, 0),
          null,
          80,
          null,
        ],
      ];
      oComponent = getDefaultGoogleGanttChart(aTasks);
    } else if (sDiv === "tabs") {
      var iTabs = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTabNumber";
      });
      var oPropertyTabs = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTabNames";
      });
      oComponent = getDefaultTabs(
        iTabs.value,
        oPropertyTabs.value,
        oComponentModel
      );
      //handleTabClick(null, oPropertyTabs.value, oComponentModel);
    } else if(sDiv === 'mapLocation'){
      oComponent = <ArcGISMap center={[-118.80500, 34.02700]} zoom={13} />
    }
    else {
      if (sDiv === "select") {
        var aOptions = [];
        var aChildElement = [];
        var oPropertyOptions = oComponentModel.properties.find(
          function (oProperty) {
            return oProperty.id === "idInputOptions";
          }
        );
        aOptions = oPropertyOptions.value.split("\n");
        aOptions.forEach(function (sOption, i) {
          aChildElement.push(
            React.createElement(
              "option",
              { key: "option" + i, value: sOption },
              sOption
            )
          );
        });

        oComponent = React.createElement(
          sDiv,
          {
            id: sId,
            key: sId,
            style: CSS,
            type: sType,
            className: "input-select",
            disabled: false,
          },
          aChildElement
        );
      } else if (sDiv === "input" && sType === "InputHelp") {
        var input = React.createElement(
          sDiv,
          {
            className: "input-fields",
            id: sId,
            key: sId,
            style: CSS,
            placeholder:sPlaceholder,
            type: "input",
            readOnly: bReadOnly,
            value: "Input Help",
          },
          createChild(sDiv, sText)
        );
        var help = React.createElement(
          "button",
          {
            style: {
              width: "20px",
              background: "none",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
              border: "none",
              cursor: "pointer",
              marginLeft: "-25px",
            },
          },
          " ?"
        );
        oComponent = React.createElement(
          "div",
          {
            style: {
              width: "auto",
              display: "flex",
              height: "100%",
              alignContent: "center",
            },
          },
          input,
          help
        );
      } else if(sDiv === "button"){
        oComponent = React.createElement(
          sDiv,
          {
            id: sId,
            className:
              sDiv === "button"
                ? sButtonClassName
                : "",
            key: sId,
            style: CSS,
            type: sType,
          },
          createChild(sDiv, sText)
        );
      } else {
        oComponent = React.createElement(
          sDiv,
          {
            id: sId,
            className:
              sDiv === "input"
                ? "input-fields"
                : sDiv === "button"
                ? sButtonClassName
                : "",
            key: sId,
            placeholder:
            sDiv === "input"
              ? sPlaceholder
              : "",
            style: CSS,
            type: sType,
            readOnly: true,
          },
          createChild(sDiv, sText)
        );
      }
    }

    //Mainlogic to reder the dropzone
    oComponent = (
      <div
        id={sId + "div"}
        //key={sId + "div"}
        className="RenderComponent-Resizeable"
        style={{
          left: sLeft,
          top: sTop,
          width: sDivWidth,
          height: sDivHeight,
          zIndex: 50,
        }}
        draggable="true"
        onDragStart={handleDragStart}
        onDragOver={(e) => e.preventDefault()}
        onKeyDown={(oEvent) => {
          onMouseKeyPress(oEvent, oComponentModel);
        }}
        tabIndex="0"
        onClick={(oEvent) => {
          handleClickComponent(oEvent, oComponentModel);
        }}
      >
        <div
          id="idResizeTopLeft"
          className="RenderComponent-Resizer RenderComponent-Top-Left"
          style={{ top: oResizer.top, left: oResizer.left, display: "none" }}
          onClick={(oEvent) => {
            oEvent.stopPropagation();
          }}
        />
        <div
          id="idResizeTopRight"
          className="RenderComponent-Resizer RenderComponent-Top-Right"
          style={{ top: oResizer.top, right: oResizer.right, display: "none" }}
          onClick={(oEvent) => {
            oEvent.stopPropagation();
          }}
        />
        <div
          id="idResizeBottomLeft"
          className="RenderComponent-Resizer RenderComponent-Bottom-Left"
          style={{
            bottom: oResizer.bottom,
            left: oResizer.left,
            display: "none",
          }}
          onClick={(oEvent) => {
            oEvent.stopPropagation();
          }}
        />
        <div
          id="idResizeBottomRight"
          className="RenderComponent-Resizer RenderComponent-Botton-Right"
          style={{
            bottom: oResizer.bottom,
            right: oResizer.right,
            display: "none",
          }}
          onClick={(oEvent) => {
            oEvent.stopPropagation();
          }}
        />

        {oComponent}
      </div>
    );

    if (sDiv === "table") {
      oComponent.props.style.border = "1px solid";
    }

    return oComponent;
  }

  return (
    <>
      {aRenderComponents}
      <AlignmentGuides
        key={iRefreshKey}
        boxes={aAlign}
        boxStyle={{ background: "transparent", cursor: "pointer" }}
        onDrag={(oEvent, boxData) => {
          const sBoxId = oEvent.target.id;
          handleAlignmentPosition(oEvent, sBoxId, boxData);
        }}
        onDragEnd={(oEvent, boxData) => {
          const sBoxId = oEvent.target.id;
          handleAligmentEnd(oEvent, sBoxId, boxData);
        }}
        onResizeEnd={(oEvent, boxData) => {
          handleAligmentsize(oEvent, boxData);
        }}
        className="alignment-guides"
        style={{
          background: "transparent",
          width: "300%",
          height: "200%",
          position: "static",
        }}
      />
    </>
  );
}
