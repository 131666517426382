import React, { useState ,useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const ReturnArrayParameter = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  const [aMethodData, setMethodData] = useState([]);

  useEffect(()=>{
    if(props.arrayFields && props.arrayFields.fields){
      const transformedFields = props.arrayFields.fields.map(field => ({ FieldName: field }));
      setMethodData(transformedFields);
    }else{
      setMethodData([]);
    }
  },[props.open])

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...aSelectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  function handleRemoveRow() {
    const updatedRows = aMethodData.filter((_, index) => !aSelectedRows[index]);
    setMethodData(updatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  function handleIncreaseRow() {
    const updatedRows = [...aMethodData];
    updatedRows.push({
      FieldName: "",
    });
    setMethodData(updatedRows);
    setSelectedRows([...aSelectedRows, false]);
  }

  function handleEventChange(event, index, sKey) {
    const updatedRows = [...aMethodData];
    updatedRows[index][sKey] = event.target.value;
    setMethodData(updatedRows);
  }

  function handleUpdate() {
    const aFields = aMethodData.map((oData) => oData.FieldName);
    props.update(aFields);
  }

  return (
    <Dialog
      open={props.open}
      maxWidth="sm"
      fullWidth={true}
      sx={{ minHeight: "300px" }}
    >
      <DialogTitle>{"Array Fields"}</DialogTitle>
      <DialogContent>
        <Box>
          <IconButton>
            <AddIcon color="primary" onClick={handleIncreaseRow} />
          </IconButton>
          <IconButton>
            <RemoveIcon color="secondary" onClick={handleRemoveRow} />
          </IconButton>
          <table id="idTableConfiguration" className="RenderComponent-Table">
            <thead>
              <tr>
                <th></th>
                <th>Fields</th>
              </tr>
            </thead>
            <tbody>
              {aMethodData.map((row, index) => (
                <tr key={index}>
                  <td className="RenderComponent-Table-td">
                    <input
                      type="checkbox"
                      checked={aSelectedRows[index]}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </td>
                  <td className="RenderComponent-Table-td">
                    <input
                      className="RenderComponent-Table-input"
                      type="text"
                      defaultValue={row.FieldName}
                      onChange={(event) =>
                        handleEventChange(event, index, "FieldName")
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdate}>Update</Button>
        <Button onClick={props.close}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
