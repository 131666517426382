import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import "./collection.css";
import { APIFields } from "./APIFields";
import Select from "react-select";
import SettingsIcon from "@mui/icons-material/Settings";
import { WhereConditionDialog } from "./WhereConditionDialog";

import Server from "../../../serverDetails/server";

export const APICollection = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  const [achooseOptions, setchooseOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedOptionsData, setSelectedOptionsData] = useState({});
  const [bOpenSelectionFields, setSelectionFields] = useState(false);
  const [iIndex, setIndex] = useState();
  const [aDataFields, setADataFields] = useState({});
  const [bWhereConditionDialog, setWhereConditionDialog] = useState(false);
  const [aData, setData] = useState({});

  const options = [
    { value: "create", label: "Create" },
    { value: "display", label: "Display" },
    { value: "delete", label: "Delete" },
  ];

  useEffect(() => {
    props.rows.collections.forEach((oDatas, index) => {
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [index]: oDatas.Fields,
      }));
      const mappedValues = oDatas.values.map((value) =>
        options.find((option) => option.value === value)
      );
      setSelectedOptionsData((prevOptions) => ({
        ...prevOptions,
        [index]: mappedValues,
      }));
    });
  }, [props.rows]);

  function transformData(data, parent = null) {
    function processObject(obj, parent = null) {
      return Object.entries(obj).map(([key, value]) => {
        if (Array.isArray(value)) {
          var subtask = value.map((item) => processObject(item, key));
          subtask = subtask[0];
          return { Name: key, Type: "Array", subtask, parent };
        } else if (
          typeof value === "object" &&
          value !== null &&
          value.type === undefined
        ) {
          var nestedTasks = processObject(value, key);
          return { Name: key, Type: "Object", subtasks: nestedTasks, parent };
        } else {
          return { Name: key, Type: value.type, checked: false, parent };
        }
      });
    }

    return processObject(data, parent);
  }

  const retriveCheckFields = (aTransformData, index) => {
    const traverseAndCheck = (data, pathParts) => {
      if (pathParts.length === 0) {
        return;
      }

      const currentPart = pathParts.shift();

      for (let item of data) {
        if (item.Name === currentPart) {
          if (pathParts.length === 0) {
            item.checked = true;
          } else if (item.subtask) {
            traverseAndCheck(item.subtask, pathParts);
          }
        }
      }
    };

    for (let sOptionField of selectedOptions[index]) {
      const pathParts = sOptionField.split(".");

      if (pathParts.length === 1) {
        const result = aTransformData.find(
          (oTransformField) => oTransformField.Name === sOptionField
        );
        if (result) {
          result.checked = true;
        }
      } else {
        traverseAndCheck(aTransformData, pathParts);
      }
    }

    return aTransformData;
  };
  const handleCollectionName = (event, index, sKey) => {
    var value;
    if (sKey === "values") {
      value = event;
    } else {
      value = event.target.value;
    }
    const updatedRows = [...props.rows.collections];
    updatedRows[index][sKey] = value;
    props.update({ ...props.rows, collections: updatedRows });
  };

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...aSelectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  function handleIncreaseRow() {
    const updatedRows = [...props.rows.collections];
    updatedRows.push({
      tableName: "",
      Fields: [],
      values: [],
      condition:[]
    });
    props.update({ ...props.rows, collections: updatedRows });
    setSelectedRows([...aSelectedRows, false]);
  }

  function handleRemoveRow() {
    const updatedRows = props.rows.collections.filter(
      (_, index) => !aSelectedRows[index]
    );
    props.update({ ...props.rows, collections: updatedRows });
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  const handleFetchDatas = async (index) => {
    var table = props.rows.collections[index].tableName;
    setIndex(index);
    if (table && !achooseOptions[index]) {
      Server.get(`/tablefields/${table}/false`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then(function (response) {
        if (response.data.fields) {
          const datas = response.data.fields;
          let aTempDataFields = transformData(datas);
          if (selectedOptions[index].length > 0) {
            aTempDataFields = retriveCheckFields(aTempDataFields, index);
          }
          setADataFields((prevOptions) => ({
            ...prevOptions,
            [index]: aTempDataFields,
          }));
          setchooseOptions((prevOptions) => ({
            ...prevOptions,
            [index]: datas,
          }));
          setSelectionFields(true);
        }
      });
    } else {
      setSelectionFields(true);
    }
  };

  const handleSelectChange = (options) => {
    const updatedRows = [...props.rows.collections];
    updatedRows[iIndex].Fields = options;
    props.update({ ...props.rows, collections: updatedRows });
    setSelectionFields(false);
  };

  const handleSettingsClick = (index, row) => {
    setIndex(index);
    setData(row)
    setWhereConditionDialog(true);
  };

  const handleCancel = () => {
    setSelectionFields(false);
    setWhereConditionDialog(false)
  };

  function handleUpdateCondition(){
    const updatedRows = [...props.rows.collections];
    updatedRows[iIndex].condition = aData.condition;
    props.update({ ...props.rows, collections: updatedRows });
  }

  return (
    <div>
      <IconButton>
        <AddIcon
          onClick={handleIncreaseRow}
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      {props.rows.collections.length > 1 ? (
        <IconButton>
          <RemoveIcon
            onClick={handleRemoveRow}
            baseClassName="fas"
            className="fa-plus-circle"
            color="secondary"
          />
        </IconButton>
      ) : (
        ""
      )}
      <Box>
        <table id="idTableConfiguration" className="RenderComponent-Table">
          <thead>
            <tr>
              <th></th>
              <th className="RenderComponent-Table-th">Collection Name</th>
              <th className="RenderComponent-Table-th">Selection Fields</th>
              <th className="RenderComponent-Table-th">Select Values</th>
              <th className="RenderComponent-Table-th">Conditions</th>
            </tr>
          </thead>
          <tbody>
            {props.rows.collections.map((row, index) => (
              <tr key={index}>
                <td className="RenderComponent-Table-td">
                  <input
                    type="checkbox"
                    checked={aSelectedRows[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    className="RenderComponent-Table-input"
                    value={row.tableName}
                    onChange={(event) =>
                      handleCollectionName(event, index, "tableName")
                    }
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <div
                    className="RenderComponent-Table-wrapper"
                    onClick={() => handleFetchDatas(index)}
                  >
                    <textarea
                      className="RenderComponent-Table-input array-input"
                      value={selectedOptions[index]}
                      onChange={(event) =>
                        handleCollectionName(event, index, "tableName")
                      }
                    />
                  </div>
                </td>
                <td
                  className="RenderComponent-Table-td"
                  style={{ textAlign: "center" }}
                >
                  <Select
                    isMulti
                    options={options}
                    value={selectedOptionsData[index] || []}
                    onChange={(selectedOptionsdata) => {
                      const values = selectedOptionsdata
                        ? selectedOptionsdata.map((option) => option.value)
                        : [];
                      handleCollectionName(values, index, "values");
                    }}
                  />
                </td>
                <td
                  className="RenderComponent-Table-td"
                  style={{ textAlign: "center" }}
                >
                  <SettingsIcon
                    style={{ cursor: "pointer" }}
                    onClick={(event) => handleSettingsClick(index, row)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <APIFields
        open={bOpenSelectionFields}
        handleClose={handleCancel}
        aDataFields={aDataFields}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        iIndex={iIndex}
        update={handleSelectChange}
        setADataFields={setADataFields}
      />
      <WhereConditionDialog
       open={bWhereConditionDialog}
       close={handleCancel}
       data={aData}
       update={setData}
       updateData={handleUpdateCondition}
      />
    </div>
  );
};
