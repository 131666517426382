import React,{useState} from "react";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

//Dialog Components
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";

export const MapSingleData = (props) => {
  const [aArrayState, setArrayState] = useState([]);
  var aTempSelectControl = [];
  var aDefaultValue = [];
  var aDefaultArrayValue = [];
  var aMapFields = props.mapping;

  if (props.outputFields.length > 0) {
    for (var i = 0; i < props.outputFields.length; i++) {
      if(props.mapping[props.outputFields[i].name]){
        if(props.mapping[props.outputFields[i].name].array){
          aDefaultValue.push(props.mapping[props.outputFields[i].name].array)
        }else if(props.mapping[props.outputFields[i].name].value){
          aDefaultValue.push(props.mapping[props.outputFields[i].name].value)
        }else{
          aDefaultValue.push("");
        }
      }else{
        aDefaultValue.push("");
      }
    }
  }
  if (props.outputFields.length > 0) {
    for (var i = 0; i < props.outputFields.length; i++) {
      if(props.mapping[props.outputFields[i].name]){
        if(props.mapping[props.outputFields[i].name].array){
          if(props.mapping[props.outputFields[i].name].value){
            aDefaultArrayValue.push(props.mapping[props.outputFields[i].name].value)
          }else{
            aDefaultArrayValue.push("");
          }
        }else{
          aDefaultArrayValue.push("");
        }
      }else{
        aDefaultArrayValue.push("");
      }
    }
  }

  if (props.outputFields.length > 0) {
    for (var i = 0; i < props.outputFields.length; i++) {
      aTempSelectControl.push(
        <div id={"idField" + i}>
          <br></br>
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <TextField
            select
            id={i}
            label={props.outputFields[i].name}
            sx={{ minWidth: 450 }}
            onChange={(oEvent) => {
              handleChange(oEvent);
            }}
            name={props.outputFields[i].name}
            defaultValue={aDefaultValue[i]}    
          >
            {props.inputFields.map((oField) => (
              <MenuItem key={oField} name={oField} value={oField}>
                {oField}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id={i}
            label={props.outputFields[i].name}
            sx={{ minWidth: 350 }}
            onChange={(oEvent) => {
              handleChange(oEvent);
            }}
            name={props.outputFields[i].name}
            defaultValue={aDefaultArrayValue[i]}
          >
          </TextField>
          </div>
        </div>
      );
    }
  }

  /**
   * Event handler for change
   */
  function handleChange(oEvent) {
    var oOutput = props.outputFields.find((oOutput) => {
      return oOutput.name === oEvent.target.name;
    });
    if(props.arraymapping && Object.keys(props.arraymapping).length > 0){
      var bKey = false;
      for(const sKey in props.arraymapping){
        if(oEvent.target.value === sKey){
          bKey = true;
        }
      }
      if(bKey){
        if (oOutput) {
          aMapFields[oEvent.target.name] = {
            id: oOutput.id,
            array: oEvent.target.value,
          };
        }
      }else{
        if (oOutput) {
          aMapFields[oEvent.target.name] = {
            ...aMapFields[oEvent.target.name],
            id: oOutput.id,
            value: oEvent.target.value,
          };
        }
      }
    }else{
      if (oOutput) {
        aMapFields[oEvent.target.name] = {
          id: oOutput.id,
          value: oEvent.target.value,
        };
      }
    }
  }

  /**
   * Event handler for update
   * @param {*} oEvent
   */
  function handleUpdate() {
    props.update(aMapFields);
  }

  return (
    <div>
      <Dialog open={props.open} maxWidth="md" fullWidth={true}>
        <DialogTitle>Map Data to Table</DialogTitle>
        <DialogContent>
          <FormGroup>{aTempSelectControl}</FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
