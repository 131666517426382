import React from "react";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

//Dialog Components
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";

export const MapViewConfig = (props) => {
  var aMapViewFields = props.mapView;
  var aTempSelectControl = [];

  if (props.mapView && Object.keys(aMapViewFields).length > 0) {
    for (var i = 0; i < Object.keys(aMapViewFields).length; i++) {
      let field = Object.keys(aMapViewFields)[i];
      aTempSelectControl.push(
        <div id={"idField" + i}>
          <br></br>
          <TextField
            select
            id={i}
            label={field}
            sx={{ minWidth: 450 }}
            onChange={(oEvent) => {
              handleChange(oEvent);
            }}
            name={field}
            defaultValue={props.mapView[field]}
          >
            {props.sourceFields.map((oField) => (
              <MenuItem key={oField} name={oField} value={oField}>
                {oField}
              </MenuItem>
            ))}
          </TextField>
        </div>
      );
    }
  }

  /**
   * Event handler for change
   */
  function handleChange(oEvent) {
    aMapViewFields[oEvent.target.name] = oEvent.target.value;
  }

  /**
   * Event handler for update
   * @param {*} oEvent
   */
  function handleUpdate(oEvent) {
    props.update(aMapViewFields);
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Map Data To Location Map</DialogTitle>
        <DialogContent>
          <FormGroup>{aTempSelectControl}</FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
