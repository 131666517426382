export const handleBinaryDataFromBackend = async (binaryData, filename) => {
  function base64ToArrayBuffer(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  function getFileType(arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    const magicNumbers = {
      "25504446": "application/pdf", // PDF
      "89504E47": "image/png",       // PNG
      "47494638": "image/gif",       // GIF
      "FFD8FFE0": "image/jpeg",        // JPEG
      "52494646": "image/png",       // AVI
      "494433": "audio/mpeg",        // MP3
    };

    const hexString = uint8Array
    .subarray(0, 4)
    .reduce((acc, byte) => acc + byte.toString(16).padStart(2, "0"), "").toUpperCase();

  for (const [signature, mimeType] of Object.entries(magicNumbers)) {
      if (hexString.startsWith(signature)) {
        return mimeType;
      }
    }

    return "application/octet-stream";
  }

  const arrayBuffer = base64ToArrayBuffer(binaryData);
  const filetype = getFileType(arrayBuffer);
  const blob = new Blob([arrayBuffer], { type: filetype });
  const blobUrl = URL.createObjectURL(blob);
  return { blobUrl, filetype };
};
