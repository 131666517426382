import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ListItemText, Collapse } from "@mui/material";
import { styled } from '@mui/system';

export function TutorList(props) {
  const [openTutor, setOpenTutor] = useState(-1);
  const oTutorData = require("../../../../GPT/TutorFile.json");
  const aTutorKeys = Object.keys(oTutorData);

  const handleTutorClick = (index) => {
    setOpenTutor(openTutor === index ? -1 : index);
  };
  const CustomPaper = styled('div')(({ theme }) => ({
    height: 400,
  }));
  return (
    <CustomPaper>
      <MenuList>
        {aTutorKeys.length > 0 &&
          aTutorKeys.map((oComponent, index) => (
            <div key={index}>
              <MenuItem
                onClick={() => handleTutorClick(index)}
                className="boldMenuItem blueMenuItem pointerCursor"
              >
                <ListItemText primary={oComponent}/>
                {/* {openTutor === index ? <ExpandLess /> : <ExpandMore />} */}
              </MenuItem>
              <Collapse in={openTutor === index} timeout="auto" unmountOnExit>
                <MenuList>
                  {Object.entries(oTutorData[oComponent]).map(
                    ([FirstlevelField, fieldData], fieldIndex) => (
                      <div key={fieldIndex}>
                        <MenuItem style={{ paddingLeft: "24px" }}>
                          <ListItemText
                            onClick={() => props.handleClick(fieldData)}
                            primary={fieldData}
                            className="fieldName"
                          />
                        </MenuItem>
                      </div>
                    )
                  )}
                </MenuList>
              </Collapse>
            </div>
          ))}
      </MenuList>
    </CustomPaper>
  );
}
