import React, { useState } from "react";
//Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
//Dialog Components
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const LoopConfigDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  var aRowsData = [];
  /**
   * Event handler for CheckBox
   * @param {Number} iIndex
   */
  const handleCheckboxChange = (iIndex) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[iIndex] = !aNewSelectedRows[iIndex];
    setSelectedRows(aNewSelectedRows);
  };

  /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    var aTemp = [...props.data];
    aTemp.push({
      id: "",
      value: "",
    });
    props.updateData(aTemp);
    setSelectedRows([...aSelectedRows, false]);
  }

  /**
   * Event handler for Remove Rows
   */
  function handleDecreaseRow() {
    var aTemp = [...props.data];
    const aUpdatedRows = aTemp.filter((_, index) => !aSelectedRows[index]);
    props.updateData(aUpdatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  if (props.data && props.data.length > 0) {
    for (let i = 0; i < props.data.length; i++) {
      aRowsData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={aSelectedRows[i]}
              onChange={() => handleCheckboxChange(i)}
            />
          </td>
          <td className="RenderComponent-Table-td">
            <select
              className="RenderComponent-Table-input"
              defaultValue={props.data[i].value}
              onChange={(oEvent) => {
                handleChangeParameter(oEvent, i);
              }}
            >
              <option disabled value="">
                Select Component
              </option>
              {props.fields &&
                props.fields.map((field, index) => (
                  <option key={index} value={field.value}>
                    {field.value}
                  </option>
                ))}
            </select>
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler for Fields
   * @param {*} oEvent
   * @param {Number} iIndex
   * @param {string} skey
   */
  function handleChangeParameter(oEvent, iIndex) {
    var aTemp = [...props.data];
    const sValue = oEvent.target.value;
    const sUniqueID = props.fields.find(
    (datas) => datas.value === sValue
    ).id;
    aTemp[iIndex]['id'] = sUniqueID;
    aTemp[iIndex]['value'] = sValue;

    props.updateData(aTemp);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        PaperProps={{
          sx: {
            width: 900,
            maxHeight: 900,
          },
        }}
      >
        <DialogTitle>
          Mapping Components
          <IconButton onClick={handleIncreaseRow}>
            <AddIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="primary"
            />
          </IconButton>
          <IconButton onClick={handleDecreaseRow}>
            <RemoveIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="secondary"
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <table id="idTableConfiguration" className="RenderComponent-Table">
            <thead>
              <tr>
                <th></th>
                <th className="RenderComponent-Table-th">
                  Component Name
                </th>
              </tr>
            </thead>
            <tbody>{aRowsData}</tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.update}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
