import React from 'react';

//Material UI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';

export const TabDisplayConfig = (props) => {

    if (!props.open & props.tabValues.length < 1) {
        return;
    }

    /**
     * Event handler for update
     * @param {*} oEvent 
     */
    function handleUpdate() {
        props.update();
    }

    /**
     * 
     * @param {*} oEvent Event handler for tab change
     * @param {*} newValue 
     */
    function handleChangeTab(oEvent, newValue) {
        props.changeTab(newValue);
    }

    return (

        <div>
            <Dialog
                open={props.open}>
                <DialogTitle>
                    Configure Column Description
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={props.selectedTab}
                            onChange={handleChangeTab}>
                            {props.tabValues.map((oTab) => (<Tab value={oTab} label={oTab} />))}
                        </Tabs>
                    </Box>
                    <FormGroup>
                        {props.dynamicComponets}
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdate}>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}