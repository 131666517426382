import React from "react";

//Material UI Components
import Box from "@mui/material/Box";
import "../visibility.css";

export const ComponentVisibility = (props) => {
  var aRowsData = [];

  if (props.oVisibilityData.components.length > 0) {
    for (let i = 0; i < props.oVisibilityData.components.length; i++) {
      aRowsData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              className="RenderComponent-Table-input"
              value={props.oVisibilityData.components[i].componentName}
              disabled
            />
          </td>
          <td
            className="RenderComponent-Table-td"
            style={{ textAlign: "center" }}
          >
            <input
              type="checkbox"
              style={{ height: "15px", width: "20px" }}
              checked={props.oVisibilityData.components[i].visible}
              onChange={(oEvent) => {
                handleChangeVisible(oEvent, i);
              }}
            />
          </td>
          <td
            className="RenderComponent-Table-td"
            style={{ textAlign: "center" }}
          >
            <input
              type="checkbox"
              style={{ height: "15px", width: "20px" }}
              checked={props.oVisibilityData.components[i].readOnly}
              onChange={(oEvent) => {
                handleChangeReadOnly(oEvent, i);
              }}
            />
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler for Visible
   * @param {*} oEvent
   * @param {Number} i
   */
  function handleChangeVisible(oEvent, i) {
    var aTempData = [...props.oVisibilityData.components];
    aTempData[i].visible = oEvent.target.checked;
    props.update({ ...props.oVisibilityData, components: aTempData });
  }

  /**
   * Event handler  for ReadOnly
   * @param {*} oEvent
   * @param {Number} i
   */
  function handleChangeReadOnly(oEvent, i) {
    var aTempData = [...props.oVisibilityData.components];
    aTempData[i].readOnly = oEvent.target.checked;
    props.update({ ...props.oVisibilityData, components: aTempData });
  }

  return (
    <div className="ComponentVisibility-render">
      <Box>
        <table
          id="idTableConfiguration"
          className="RenderComponent-Table ComponentVisibility-border "
        >
          <tr>
            <th className="RenderComponent-Table-th">Component</th>
            <th className="RenderComponent-Table-th">Visible</th>
            <th className="RenderComponent-Table-th">Read Only</th>
          </tr>
          {aRowsData}
        </table>
      </Box>
    </div>
  );
};
