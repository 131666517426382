import React from 'react';
// import "./RenderLogicDetails.css";

//Components
import RenderValidationFlow from './renderValidationFlow/RenderValidationFlow';

export default function RenderValidationDetails(props) {

    if (!props.visible) {
        return;
    }

    return (
        <RenderValidationFlow
            visible={props.renderFlow}
            nodes={props.selectedLogicNodes}
            change={props.onNodesChange}
            update={props.onNodeUpdate}
            select={props.onSelect}
            delete={props.onDelete}
            edges={props.edges}
            onEdgesChange={props.onEdgesChange}
            onConnect={props.onConnect} />
    )
}