import React from "react";
import styled from "styled-components";
import "./GantChart.css";
import { Chart } from "react-google-charts";

export const GetDefaultG_GanttChart = (
  oComponentModel,
  oProperties,
  handleOnClick,
  aUIComponents
) => {
  var sDiv = oComponentModel.div;
  var oComponent;

  function handleClickGraph(event, iIndex) {
    oComponentModel.index = iIndex;
    handleOnClick(event, oComponentModel, aUIComponents);
  }

  /**  Function to draw Graph
   *
   * @param {*} aTasks
   * @param {*} sChartName
   * @param {*} sViewMode
   * @param {*} oTimeChange
   * @param {*} aLegend
   * @returns
   */
  function getDefaultGanttChart(aTasks, sChartName, aLegend) {
    const EmptyState = styled.div`
      background: #f7f7f7;
      color: rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;

      h1,
      h2 {
        margin: 5px;
      }
    `;
    const options = {
      height: 400,
      gantt: {
        trackHeight: 30,
      },
    };

    function addMarker(container, textValue, index, callback) {
      const chartElements = container.getElementsByTagName("svg");
      let svg, gantt;

      if (chartElements.length > 0) {
        svg = chartElements[0];
      }
      if (!svg) return;
      var iGanttElement = 1;

      Array.prototype.forEach.call(
        container.getElementsByTagName("rect"),
        (element) => {
          if (!gantt && element.getAttribute("x") !== "0") {
            if (iGanttElement === index) {
              gantt = element;
            } else {
              iGanttElement += 1;
            }
          }
        }
      );
      if (!gantt) return;

      const xCoord = parseFloat(gantt.getAttribute("x"));
      var yCoord = parseFloat(gantt.getAttribute("y"));
      yCoord += 18;

      const markerText = document.createElementNS(svg.namespaceURI, "text");
      markerText.setAttribute("x", xCoord);
      markerText.setAttribute("y", yCoord);
      markerText.setAttribute("fill", "#FFFFFF");
      markerText.setAttribute("font-size", "13px");
      markerText.setAttribute("font-weight", "bold");
      markerText.setAttribute("z-index", "0");
      markerText.setAttribute("cursor", "default");
      markerText.setAttribute("id", index);
      markerText.textContent = textValue;

      markerText.addEventListener("click", function (event) {
        if (callback && typeof callback === "function") {
          callback(event);
        }
      });

      svg.appendChild(markerText);
    }

    function markerClickCallback(event) {
      const iMarkerId = event.target.getAttribute("id") - 1;
      handleClickGraph(event, iMarkerId);
    }

    return (
      <div style={{ border: "1px solid black", overflow:"auto"}}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: "20px", fontWeight: "500" }}>{aLegend}</div>
        </div>
        <div style={{ marginTop: "10px" }}>
          {aTasks.length > 1 ? (
            <div style={{ display: "flex" }}>
              <Chart
                width="100%"
                height="400px"
                chartType="Gantt"
                key={sChartName}
                loader={<div>Loading Chart</div>}
                data={aTasks}
                options={options}
                rootProps={{ "data-testid": "2" }}
                chartEvents={[
                  {
                    eventName: "ready",
                    callback: ({ chartWrapper }) => {
                      const chart = chartWrapper.getChart();
                      if (chart) {
                        for (let i = 1; i < aTasks.length; i++) {
                          addMarker(
                            document.getElementById(chart.container.id),
                            aTasks[i][8],
                            i,
                            markerClickCallback
                          );
                        }
                      }
                    },
                  },
                  {
                    eventName: "select",
                    callback: ({ chartWrapper, event }) => {
                      const chart = chartWrapper.getChart();
                      const selection = chart.getSelection();
                      handleClickGraph(event, selection[0].row);
                    },
                  },
                ]}
              />
            </div>
          ) : (
            <EmptyState>
              <h1>No data available</h1>
              <h3>Generate some tasks</h3>
            </EmptyState>
          )}
        </div>
      </div>
    );
  }

  if (sDiv === "G_Gantt") {
    var sChartName = oComponentModel.properties.find(
      (oProperty) => oProperty.id === "idGoogleGanttChartName"
    ).value;
    var aLegend = 'Graph';
    if (oProperties) {
      if (Object.keys(oProperties).length > 0) {
        aLegend = oProperties.TaskData.Title;
      }
    }
    var aTasks = [];
    if (oComponentModel.Data.length > 0) {
      aTasks = oComponentModel.Data;

    }
    oComponent = getDefaultGanttChart(aTasks, sChartName, aLegend);
    return oComponent;
  } else {
    return null;
  }
};
