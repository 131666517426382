import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SettingsIcon from "@mui/icons-material/Settings";

import { WhereConditionDialog } from "./WhereConditionDialog";

export const SecurityDialog = (props) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [fieldsDialogOpen, setFieldsDialogOpen] = useState(false);
  const [bWhereConditionDialog, setWhereConditionDialog] = useState(false);
  const [aData, setData] = useState({})

  const options = [
    { value: "create", label: "Create" },
    { value: "display", label: "Display" },
    { value: "delete", label: "Delete" },
  ];

  useEffect(() => {
    if (props.data && props.data.values) {
      setSelectedValue(props.data.values);
    }
    if(props.data){
      setData(props.data)
    }
  }, [props.open]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleUpdate = async () => {
    if (props.data) {
      props.data.values = selectedValue;
      await props.update(props.data);
    }
    props.close();
  };

  const handleUpdateCondition = () => {
    props.data.condition = aData.condition;
  }

  const handleSettingsClick = () => {
    setFieldsDialogOpen(true);
  };

  const handleWhereSettingClick = () =>{
    setWhereConditionDialog(true)
  }

  const handleFieldsDialogClose = () => {
    setFieldsDialogOpen(false);
    setWhereConditionDialog(false)
  };

  return (
    <div>
      <Dialog maxWidth="md" fullWidth={true} open={props.open}>
        <DialogTitle>Security Configuration</DialogTitle>
        <DialogContent>
          <Box>
            <table id="idTableConfiguration" className="RenderComponent-Table">
              <thead>
                <tr>
                  <th className="RenderComponent-Table-th">Collection Name</th>
                  <th className="RenderComponent-Table-th">Fields</th>
                  <th className="RenderComponent-Table-th">Value</th>
                  <th className="RenderComponent-Table-th">Condition</th>
                </tr>
              </thead>
              <tbody>
                {props.data && (
                  <tr>
                    <td className="RenderComponent-Table-td">
                      {props.data.tableName}
                    </td>
                    <td className="RenderComponent-Table-td">
                      <SettingsIcon
                        style={{ cursor: "pointer" }}
                        onClick={handleSettingsClick}
                      />
                    </td>
                    <td className="RenderComponent-Table-td">
                      <select value={selectedValue} onChange={handleChange} className="RenderComponent-Table-td">
                        <option value="" disabled>
                          Select an option
                        </option>
                        {options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="RenderComponent-Table-td">
                      <SettingsIcon
                        style={{ cursor: "pointer" }}
                        onClick={handleWhereSettingClick}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={fieldsDialogOpen}
        onClose={handleFieldsDialogClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Fields</DialogTitle>
        <DialogContent>
          <ul>
            {props.data && props.data.Fields && props.data.Fields.map((field, index) => (
              <li key={index}>{field}</li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFieldsDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <WhereConditionDialog
       open={bWhereConditionDialog}
       close={handleFieldsDialogClose}
       data={aData}
       update={setData}
       updateData={handleUpdateCondition}
      />
    </div>
  );
};