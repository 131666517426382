export const MapGanttChart = (aProperties, oParams, oLogicDatas) => {
  var aParams = [];
  if (!Array.isArray(oParams.TableData)) {
    aParams.push(oParams.TableData);
  } else {
    aParams = oParams.TableData;
  }
  if (aParams) {
    const sChartName = aProperties.find(
      (oProperty) => oProperty.id === "idDropDownGanttChart"
    ).value;
    const oDatas = aProperties.find(
      (oProperty) => oProperty.id === "idMapGanttFields"
    ).value;

    var aRows = [];
    if (oDatas) {
      aParams.forEach(function (oValues) {
        var oTempRows = {};
        Object.keys(oDatas).forEach((key) => {
          switch (key) {
            case "TaskData":
              var oTaskData = handleTaskData(oDatas, key, oValues);
              oTempRows = { ...oTempRows, ...oTaskData };
              break;

            case "Percentage":
              var oPercentageData = handlePercentage(oDatas, key, oValues);
              oTempRows = { ...oTempRows, ...oPercentageData };
              break;

            case "Legend":
              var oLegend = handleLegend(oDatas, key, oValues);
              oTempRows = { ...oTempRows, ...oLegend };
              break;

            case "Popup":
              var oPopup = handlePopup(oDatas, key, oValues);
              oTempRows.Popup = { ...oPopup };
              break;
            case "TimeChange":
              var oTimeChange = handleTimeChange(oDatas, key, oValues);
              oTempRows = { ...oTempRows, ...oTimeChange};
              break;
            default:
              break;
          }
        });
        aRows.push(oTempRows);
      });
      oLogicDatas.aUIData.forEach((oComponent) => {
        if (oComponent.Component === "Gantt") {
          if (oComponent.uniqueId === sChartName) {
            oComponent.Data = aRows;
          }
        }
      });
      oLogicDatas.resetUI([...oLogicDatas.aUIData]);
    }
  }
};

/** Function to Set Task
 *
 * @param {*} oDatas
 * @param {*} key
 * @param {*} oValues
 * @returns
 */
function handleTaskData(oDatas, key, oValues) {
  var otempData = {};
  Object.keys(oDatas[key]).forEach((skey) => {
    if (skey === "Title") {
      otempData[skey] = oDatas[key][skey];
    } else if (skey === "BarValue") {
      const pattern = /\{([^}]+)\}/g;
      const matches = [...oDatas[key][skey].matchAll(pattern)].map(
        (match) => match[1]
      );
      const sConcatenatedString = matches
        .map((oValues1) => oValues[oValues1])
        .join("/");
      otempData.name = sConcatenatedString;
    } else if (skey === "name") {
      otempData.nameValue = oValues[oDatas[key][skey]];
    } else if (skey === "start" || skey === "end") {
      var dDate = new Date(oValues[oDatas[key][skey]]);
      dDate.setHours(dDate.getHours());
      otempData[skey] = dDate;
    } else if (skey === "dependencies") {
      if (oValues[oDatas[key][skey]]) {
        otempData[skey] = oValues[oDatas[key][skey]];
      } else {
        otempData[skey] = "";
      }
    } else {
      otempData[skey] = oValues[oDatas[key][skey]];
    }
  });
  return otempData;
}

/** Caluculate Percentage based on current and total value
 *
 * @param {*} oDatas
 * @param {*} key
 * @param {*} oValues
 * @returns
 */
function handlePercentage(oDatas, key, oValues) {
  var otempData = {};
  otempData.progress =
    (oValues[oDatas[key].CurrentValue] / oValues[oDatas[key].TotalValue]) * 100;
  return otempData;
}

/** Handle Key Value for Data to Update In DB
 *
 * @param {*} oDatas
 * @param {*} key
 * @param {*} oValues
 * @returns
 */
function handleTimeChange(oDatas, key, oValues) {
  var otempData = {};
  if (oDatas[key].ItemKeys) {
    var aItemKeys = oDatas[key].ItemKeys.split(",").map((item) =>
      item.split(".").pop()
    );
    aItemKeys.forEach(function (sKeys) {
      for (const keys in oValues) {
        if (keys === sKeys) {
          otempData[keys] = oValues[keys];
        }
      }
    });
  }
  return otempData;
}

/**  Colour for Bar
 *
 * @param {*} oDatas
 * @param {*} key
 * @param {*} oValues
 * @returns
 */
function handleLegend(oDatas, key, oValues) {
  var otempData = {};
  var sFieldName = oDatas[key].FieldName;
  var sFieldValue = oValues[sFieldName];
  oDatas[key].Values.forEach(function (oData) {
    if (oData.FieldValue === sFieldValue) {
      otempData.custom_class = oData.custom_class;
    }
  });
  return otempData;
}

/** Customized Popup Data
 *
 * @param {*} oDatas
 * @param {*} key
 * @param {*} oValues
 * @returns
 */
function handlePopup(oDatas, key, oValues) {
  /** Format the Date based on selected Type
   *
   * @param {*} date
   * @param {*} format
   * @returns
   */
  const formatDate = (dDate, sFormat) => {
    const sDay = String(dDate.getDate()).padStart(2, "0");
    const month = String(dDate.getMonth() + 1).padStart(2, "0");
    const year = dDate.getFullYear();

    switch (sFormat) {
      case "DD/MM/YYYY":
        return `${sDay}/${month}/${year}`;
      case "DD/MON/YYYY":
        var sMonth = new Intl.DateTimeFormat("en", { month: "short" }).format(
          dDate
        );
        return `${sDay}/${sMonth}/${year}`;
      case "MON/DD/YYYY":
        var sMonth1 = new Intl.DateTimeFormat("en", {
          month: "short",
        }).format(dDate);
        return `${sMonth1}/${sDay}/${year}`;
      case "MON DD,YYYY":
        var sMonth2 = new Intl.DateTimeFormat("en", {
          month: "short",
        }).format(dDate);
        return `${sMonth2} ${sDay},${year}`;
      default:
        return "";
    }
  };

  /** Format the time based on selected type
   *
   * @param {*} tTime
   * @param {*} sFormat
   * @returns
   */
  const formatTime = (tTime, sFormat) => {
    let [iHours, iMinutes ] = tTime.split(/[:.]/).map(Number);
    const period = iHours >= 12 ? "pm" : "am";

    if (sFormat === "12 HRS") {
      iHours = iHours % 12 || 12;
      return `${iHours.toString().padStart(2, "0")} : ${iMinutes
        .toString()
        .padStart(2, "0")} ${period}`;
    } else if (sFormat === "24 HRS") {
      return `${iHours.toString().padStart(2, "0")} : ${iMinutes
        .toString()
        .padStart(2, "0")}`;
    }
    return "";
  };

  var otempData = {};
  oDatas[key].forEach(function (oData) {
    if (oData.Type === "Date") {
      if (oValues[oData.Value].includes("T")) {
        const dFormatDate = formatDate(
          new Date(oValues[oData.Value].split("T")[0]),
          oData.Format
        );
        otempData[oData.FieldName] = dFormatDate;
      } else {
        otempData[oData.FieldName] = oValues[oData.Value];
      }
    } else if (oData.Type === "Time") {
      if (oValues[oData.Value].includes("T")) {
        const tFormattedTime = formatTime(
          oValues[oData.Value].split("T")[1],
          oData.Format
        );
        otempData[oData.FieldName] = tFormattedTime;
      } else {
        otempData[oData.FieldName] = oValues[oData.Value];
      }
    } else {
      otempData[oData.FieldName] = oValues[oData.Value];
    }
  });
  return otempData;
}
