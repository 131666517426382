import React, { useState} from "react";


// Material ui component
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const SchemaDialog = (props) => {
  const [sCollectionName, setCollectionName] = useState();
  const [aSelectedRows, setSelectedRows] = useState([]);
  let aRowsData = [];

  const aDataTypes = ["String", "Number", "Date"];

  /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    var aTemp = [...props.aSchemaFieldsData];
    aTemp.push({
        fieldName:"",
        inputFieldName:"",
        dataType:""
    });
    props.setSchemaFieldsData(aTemp);
    setSelectedRows([...aSelectedRows, false]);
  }

  /**
   * Event handler for Remove Rows
   */
  function handleDecreaseRow() {
    var aTemp = [...props.aSchemaFieldsData];
    const aUpdatedRows = aTemp.filter((_, index) => !aSelectedRows[index]);
    props.setSchemaFieldsData(aUpdatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  /**
   * Event handler for CheckBox
   * @param {Number} iIndex
   */
  const handleCheckboxChange = (iIndex) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[iIndex] = !aNewSelectedRows[iIndex];
    setSelectedRows(aNewSelectedRows);
  };

  

  if (props.aSchemaFieldsData.length > 0) {
    for (let i = 0; i < props.aSchemaFieldsData.length; i++) {
      aRowsData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={aSelectedRows[i]}
              onChange={() => handleCheckboxChange(i)}
            />
          </td>
          <td className="RenderComponent-Table-td">
            <input
              type="text"
              value={props.aSchemaFieldsData[i].fieldName}
              onChange={(oEvent) => handleFieldsChange(oEvent, "fieldName", i)}
              className="column-title"
            />
          </td>
          <td className="RenderComponent-Table-td">
            <input
              type="text"
              value={props.aSchemaFieldsData[i].inputFieldName}
              onChange={(oEvent) =>
                handleFieldsChange(oEvent, "inputFieldName", i)
              }
              className="column-title"
            />
          </td>
          <td className="td-space">
            <select
            value={props.aSchemaFieldsData[i].dataType}
              onChange={(oEvent) => handleFieldsChange(oEvent, "dataType", i)}
              required
              className="column-title"
            >
              <option value="">Select </option>
              {aDataTypes.map((sDataType, index) => {
                return (
                  <option value={sDataType} key={index}>
                    {sDataType}
                  </option>
                );
              })}
            </select>
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler for Fields
   * @param {*} oEvent
   * @param {Number} iIndex
   * @param {string} skey
   */
  const handleFieldsChange = (oEvent, skey, iIndex) => {
      var tempUserInputsData = [...props.aSchemaFieldsData];
      tempUserInputsData[iIndex][skey] = oEvent.target.value;
      props.setSchemaFieldsData(tempUserInputsData);
  };

  function handleChangeCollectionName(oEvent){
    setCollectionName(oEvent.target.value)

  }

  return (
    <div>
      <TextField
        required
        margin="dense"
        id="name"
        label="Collection Name"
        fullWidth
        variant="outlined"
        value={sCollectionName}
        onChange={(oEvent)=>handleChangeCollectionName(oEvent)}
        style={{ backgroundColor: 'white' }}
      />
      <br></br>
      <IconButton onClick={handleIncreaseRow}>
        <AddIcon
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      <IconButton onClick={handleDecreaseRow}>
        <RemoveIcon
          baseClassName="fas"
          className="fa-plus-circle"
          color="secondary"
        />
      </IconButton>
      <table id="idTableConfiguration" className="RenderComponent-Table">
        <thead>
          <tr>
            <th></th>
            <th className="RenderComponent-Table-th">Field Name</th>
            <th className="RenderComponent-Table-th">Input Field Name</th>
            <th className="RenderComponent-Table-th">Data Type</th>
          </tr>
        </thead>
        <tbody>{aRowsData}</tbody>
      </table>
    </div>
  );
};
