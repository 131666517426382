import React, { useState} from "react";
import "./RenderDatabase.css";
import { CreateDB } from "../../dialog/logics/dbOperations/createDB/CreateDB";
import { TreeGridComponent} from "@syncfusion/ej2-react-treegrid";

export default function RenderDatabase(props) {
  const [bCreateDB, setCreateDB] = useState(false);
  const [sCollection, setcollectionname] = useState("");
  var aTabs = [];
  if (
    !props ||
    !props.componentModel ||
    props.componentModel.length < 1 ||
    !props.visible
  ) {
    return;
  }

  var aRenderComponents = [];
  var aComponentModel = props.componentModel;

  aComponentModel.forEach(function (oComponentModel) {
    aTabs = [];
    aRenderComponents.push(renderComponent(oComponentModel));
  });

  function handleFindType(oFields, aData) {
    var aType = [];
    aData.forEach(function (sData) {
      if (
        typeof oFields[sData] === "object" &&
        oFields[sData].hasOwnProperty("type")
      ) {
        aType.push(oFields[sData].type);
      } else {
        aType.push(typeof oFields[sData]);
      }
    });
    return aType;
  }

  const handleTableDoubleClick = (sCollectionName) => {
    setcollectionname(sCollectionName);
    setCreateDB(true);
  };

  function transformData(data) {
    function processObject(obj) {
      return Object.entries(obj).map(([key, value]) => {
        if (Array.isArray(value)) {
          var subtask = value.map(item => processObject(item));
          subtask = subtask[0];
          return { Name: key, Type: 'Array', subtask };
        } else {
          return { Name: key, Type: value.type };
        }
      });
    }
    
    return processObject(data);
  }
  

  function handleCreateList(oComponentModel) {
    const aDataList = Object.keys(oComponentModel.oComponent.fields);
    var aType = [];
    aType = handleFindType(oComponentModel.oComponent.fields, aDataList);
    var aDataFields = transformData(oComponentModel.oComponent.fields)
    return (
      <div onDoubleClick={()=>{handleTableDoubleClick(oComponentModel.oComponent.tableName)}}>
        <h4>{oComponentModel.oComponent.tableName}</h4>
        <TreeGridComponent
          style={{ width: "400px",minHeight:"fit-content",maxHeight:"300px",overflowY:"auto",overflowX:"auto" }}
          dataSource={aDataFields}
          childMapping="subtask"
          treeColumnIndex={0}
        >
        </TreeGridComponent>
      </div>
    );
  }

  /**
   * on press delete , delete the component
   * @param {event} oEvent
   * @param {object} oComponentModel
   */
  function onMouseKeyPress(oEvent, oComponentModel) {
    if (oEvent.code === "Delete" || oEvent.code === "Backspace") {
      props.onDelete(oComponentModel);
    } else if (oEvent.code) {
      props.onMouseMove(oEvent.code, oComponentModel);
    }
  }

  /**
   * on drag component update the props & server data
   * @param {event} oEvent | drag event
   */
  function handleDragStart(oEvent) {
    oEvent.dataTransfer.setData(
      "text",
      oEvent.currentTarget.id.replace("div", "")
    );
    const rect = oEvent.currentTarget.getBoundingClientRect();
    var offsetX = oEvent.clientX - rect.x;
    var offsetY = oEvent.clientY - rect.y;
    var offSet = {
      offsetX: offsetX,
      offsetY: offsetY,
    };
    oEvent.dataTransfer.setData("json", JSON.stringify(offSet)); //Transfer the dragged component to the studimainpage
  }

  /**
   * Render component from component model
   * @param {object} oComponentModel
   * @returns
   */
  function renderComponent(oComponentModel) {
    //Alignment
    var sLeft = oComponentModel.left;
    var sTop = oComponentModel.top;
    var sDivWidth = oComponentModel.DivWidth;
    var sDivHeight = oComponentModel.DivHeight;
    var oResizer = oComponentModel.CSS.resizer;
    var sId = oComponentModel.uniqueId;
    var oComponent = handleCreateList(oComponentModel);

    //Mainlogic to reder the dropzone
    oComponent = (
      <div
        id={sId + "div"}
        className="RenderComponent-Resizeable"
        style={{ left: sLeft, top: sTop, width: sDivWidth, height: sDivHeight }}
        draggable="true"
        onDragStart={handleDragStart}
        onDragOver={(e) => e.preventDefault()}
        tabIndex="0"
        onKeyDown={(oEvent) => {
          onMouseKeyPress(oEvent, oComponentModel);
        }}
      >
        <div
          id="idResizeTopLeft"
          className="RenderComponent-Resizer RenderComponent-Top-Left"
          style={{ top: oResizer.top, left: oResizer.left, display: "none" }}
          onClick={(oEvent) => {
            oEvent.stopPropagation();
          }}
        />
        <div
          id="idResizeTopRight"
          className="RenderComponent-Resizer RenderComponent-Top-Right"
          style={{ top: oResizer.top, right: oResizer.right, display: "none" }}
          onClick={(oEvent) => {
            oEvent.stopPropagation();
          }}
        />
        <div
          id="idResizeBottomLeft"
          className="RenderComponent-Resizer RenderComponent-Bottom-Left"
          style={{
            bottom: oResizer.bottom,
            left: oResizer.left,
            display: "none",
          }}
          onClick={(oEvent) => {
            oEvent.stopPropagation();
          }}
        />
        <div
          id="idResizeBottomRight"
          className="RenderComponent-Resizer RenderComponent-Botton-Right"
          style={{
            bottom: oResizer.bottom,
            right: oResizer.right,
            display: "none",
          }}
          onClick={(oEvent) => {
            oEvent.stopPropagation();
          }}
        />

        {oComponent}
      </div>
    );

    return oComponent;
  }

  return (
    <>
      {aRenderComponents}
      <CreateDB
        open={bCreateDB}
        close={setCreateDB}
        collection={sCollection}
      />
    </>
  );
}
