export const TableSelectionFields = (aProperties, oParams, aUIData)=> {
  var aCheckValues;
  var aValues;
  var aData = [];
  const sTableID = aProperties.find(function (oProperty) {
    return oProperty.id === "idDropDownTable";
  });
  const bEnableSingle = aProperties.find(function (oProperty) {
    return oProperty.id === "idEnableSingle";
  }).value;

  let sField;
  aUIData.forEach(function (oComponent) {
    if (
      oComponent.Component === "Table" &&
      oComponent.uniqueId === sTableID.value &&
      oComponent.checkValues.length > 0
    ) {
      aCheckValues = oComponent.checkValues;
      aValues = oComponent.value;
      sField = oComponent.properties.find(oProperty=>oProperty.id === "idInputTableName")?.value;
    }
  });
  if (aCheckValues && aValues) {
    aCheckValues.forEach(function (oCheckValue, index) {
      if (oCheckValue.all && aValues.rows[index]) {
        aData.push(aValues.rows[index]);
      }
    });
    if (bEnableSingle && aData.length > 0) {
      var aTableKeys = Object.keys(aData[0]);
      if (aTableKeys.length > 0) {
        if (!oParams.mapSingle) {
          oParams.mapSingle = {};
        }
        aTableKeys.forEach(function (oData) {
          oParams.mapSingle[oData] = aData[0][oData];
        });
      }
    } else {
      oParams.push({ field: sField, value: aData });
    }
  }
}
