import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';

export const TimeChangeDialog = (props) => {

  const aColumns =[
    {label: "StartDate", value:"start"},
    {label: "EndDate", value:"end"}
  ]

  /** Update the Input data
   * 
   * @param {*} oEvent 
   * @param {*} sKey 
   */
  const handleChangeEvent = (oEvent, sKey) => {
    if (sKey === "EnableUpdate") {
        const { checked } = oEvent.target;
        const oUpdatedRows = props.oData.TimeChange;
        oUpdatedRows[sKey] = checked;
        props.updateData({ ...props.oData, TimeChange: oUpdatedRows });
    } else {
      const { value, name } = oEvent.target;
      const oUpdatedRows = props.oData.TimeChange;
      oUpdatedRows[name] = value;
      props.updateData({ ...props.oData, TimeChange: oUpdatedRows });
    }
  };

  var aTempSelectControl = [];
    if (props.inputFields) {
      for (var i = 0; i < aColumns.length; i++) {
        aTempSelectControl.push(
          <div id={"idField" + i}>
            <br></br>
            <TextField
              select
              id={i}
              label={aColumns[i].label}
              sx={{ minWidth: 450 }}
              onChange={(oEvent) => {
                handleChangeEvent(oEvent);
              }}
              name={aColumns[i].value}
              defaultValue={
                props.oData ? props.oData.TimeChange[aColumns[i].value] : ""
              }
            >
              {props.inputFields.map((oField) => (
                <MenuItem key={oField} name={oField} value={oField}>
                  {oField}
                </MenuItem>
              ))}
            </TextField>
          </div>
        );
      }
    }

  return (
    <>
      <TextField
        label="TableName"
        name="TableName"
        defaultValue={props.oData ? props.oData.TimeChange.TableName : ""}
        sx={{ minWidth: 450 }}
        onChange={(oEvent) => {
          handleChangeEvent(oEvent, "TableName");
        }}
      />{" "}
      <label
        htmlFor="enableUpdate"
        style={{ marginRight: "20px", marginLeft: "100px" }}
      >
        Enable Update
        <input
          type="checkbox"
          id="enableUpdate"
          defaultChecked={props.oData ? props.oData.TimeChange.EnableUpdate : false}
          onChange={(oEvent) => {
            handleChangeEvent(oEvent, "EnableUpdate");
          }}
        />
      </label>
      <br />
      <br />
      <TextField
        label="Keys"
        name="Keys"
        defaultValue={props.oData ? props.oData.TimeChange.Keys : ""}
        sx={{ minWidth: 450 }}
        onChange={(oEvent) => {
          handleChangeEvent(oEvent, "Keys");
        }}
      />{" "}
      <br />
      <br />
      <TextField
        label="Items"
        name="Items"
        defaultValue={props.oData ? props.oData.TimeChange.Items : ""}
        sx={{ minWidth: 450 }}
        onChange={(oEvent) => {
          handleChangeEvent(oEvent, "Items");
        }}
      />{" "}
      <br />
      <br />
      <TextField
        label="ItemKeys"
        name="ItemKeys"
        defaultValue={props.oData ? props.oData.TimeChange.ItemKeys : ""}
        sx={{ minWidth: 450 }}
        onChange={(oEvent) => {
          handleChangeEvent(oEvent, "ItemKeys");
        }}
      />{" "}
      <br />
      <br />
      {aTempSelectControl}
    </>
  );
};
