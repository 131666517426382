import React, { useState} from "react";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

//Dialog
import { OpenDialog } from "../openDialog/OpenDialog";
import { CreateDialog } from "../createDialog/CreateDialog";

//Server
import Server from "../../../serverDetails/server";

export const OperationDialog = (props) => {
  //Constants and State declaration
  const [iTabValue, setTabValue] = React.useState(0);
  const [sSelectedProject, setselectedProject] = useState("");
  const [sSelectedProjectID, setSelectedProjectID] = useState("");
  const [sSelectedFeature, setselectedFeature] = useState("");
  const [sSelectedFeatureID, setSelectedFeatureID] = useState("");
  const [sSelectedApp, setSelectedApp] = useState("");
  const [sSelectedAppID, setSelectedAppID] = useState("");
  const [bSelectedDashBoard, setSelectedDashBoard] = useState(false);

  //Variable Decalaration
  var oAppDetails = {
    APP_DESC: sSelectedApp,
    APPID: sSelectedAppID,
    CDESC: sSelectedProject,
    CID: sSelectedProjectID,
    DASHBOARD: bSelectedDashBoard,
    FEATUREID:sSelectedFeatureID,
    userId: props.userId,
    APP_JSON: {},
  };

  /**
   * Event handler while changing Tab
   * @param {event} oEvent | Tab event
   * @param {integer} newValue | selected tab
   */
  function handleTabChange(oEvent, newValue) {
    setTabValue(newValue);
    setselectedProject("");
    setSelectedProjectID("");
    setSelectedApp("");
    setSelectedAppID("");
  }

  /**
   * Even handler while selecting project
   * @param {string} sSelecteProject | Selected Project
   * @param {string} sSelectedProjectId | Selected Project ID
   */
  function handleSelectProject(sSelecteProject, sSelectedProjectId) {
    setselectedProject(sSelecteProject);
    setSelectedProjectID(sSelectedProjectId);
  }

    /**
   * Even handler while Selecting Feature for system
   * @param {string} sFeature | Selected Project
   * @param {string} sFeatureId | Selected Project ID
   */
    function handleSelectFeature(sFeature, sFeatureId) {
      setselectedFeature(sFeature);
      setSelectedFeatureID(sFeatureId);
    }

  /**
   * Even handler while selecting appilication
   * @param {string} sSelectedApp | Appilication Project
   * @param {string} sSelectedAppID | Appilication Project ID
   */
  function handleSelectApp(sSelectedApp, sSelectedAppID) {
    setSelectedApp(sSelectedApp);
    setSelectedAppID(sSelectedAppID);
  }

  /**
   * Update app description on typing
   * @param {event} oEvent | input event
   */
  function handleAppNameChange(oEvent) {
    oAppDetails.APP_DESC = oEvent.target.value;
  }

  /**
   * handle checkbox for dashboard
   * @param {event} oEvent | input event
   */
  function handleCheckBox(oEvent, operation) {
    if (operation === "DashBoard") {
      oAppDetails.DASHBOARD = oEvent.target.checked;
    } else if (operation === "AdminApp") {
      oAppDetails.ADMINAPP = oEvent.target.checked;
    }
  }

  /**
   * Handler to create new appilication
   * @param {event} oEvent
   */
  function handleCreate(oEvent) {
    if (oAppDetails.APP_DESC && oAppDetails.CDESC) {
      Server.post(
        "/editor/version",
        {
          CDESC: oAppDetails.CDESC,
          APP_DESC: oAppDetails.APP_DESC,
          DASHBOARD: oAppDetails.DASHBOARD,
          ADMINAPP:oAppDetails.ADMINAPP,
          FEATUREID:oAppDetails.FEATUREID,
          OPERATION:"Create",
        },{
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          setSelectedAppID(response.data.APPID);
          oAppDetails.APPID = response.data.APPID;
          props.updateState(oAppDetails);
          props.close();
        })
        .catch(function (oError) {
          if (oError.response.data) {
            props.error(oError.response.data.Message);
          } else {
            props.error(oError.message);
          }
        });
    }
  }

  /**
   * Event handler to close the operation dialog
   */
  function handleCancel() {
    setTabValue(0);
    props.close();
  }

  /**
   * Event handler to open an appilication
   * @param {event} oEvent
   */
  function handleOpen(oEvent) {
    if (oAppDetails.APP_DESC && oAppDetails.CDESC) {
      Server.get(
        `/editor/app/${oAppDetails.APPID}`,{
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
        .then(function (oResult) {
          oAppDetails.UIDATA = oResult.data.UIDATA;
          oAppDetails.APP_JSON=oResult.data.UIDATA;
          Server.post(
            "/editor/version",
            {
              CDESC: oAppDetails.CDESC,
              APP_DESC: oAppDetails.APP_DESC,
              APP_ID: oAppDetails.APPID,
              OPERATION:"Open",
            },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
            .then(function (response) {
              setSelectedAppID(response.data.APPID);
              // oAppDetails.APP_JSON=response.data.UIDATA;
              props.updateState(oAppDetails);
              setTabValue(0);
              props.close();
            })
            .catch(function (oError) {
              if (oError.response.data) {
                props.error(oError.response.data.Message);
              } else {
                props.error(oError.message);
              }
            });
        })
        .catch(function (oError) {
          if (oError.response.data) {
            props.error(oError.response.data.Message);
          } else {
            props.error(oError.message);
          }
        });
    }
  }

  return (
    <div>
      <Dialog open={props.open} onBackdropClick="false" disableEscapeKeyDown>
        <DialogTitle>Please choose an operation</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={iTabValue} onChange={handleTabChange}>
              <Tab label="Create" />
              <Tab label="Open" />
            </Tabs>
          </Box>
          <br></br>
          {iTabValue === 0 && (
            <CreateDialog
              userid={props.userId}
              select={handleSelectProject}
              selectedProject={sSelectedProject}
              selectFeature={handleSelectFeature}
              selectedFeature={sSelectedFeature}
              appNameChange={handleAppNameChange}
              selectDashBoard={handleCheckBox}
              error={props.error}
            />
          )}
          {iTabValue === 1 && (
            <OpenDialog
              userid={props.userId}
              selectApp={handleSelectApp}
              selectProject={handleSelectProject}
              selectedProject={sSelectedProject}
              selectFeature={handleSelectFeature}
              selectedFeature={sSelectedFeature}
              selectedApp={sSelectedApp}
              error={props.error}
            />
          )}
        </DialogContent>
        <DialogActions>
          {iTabValue === 0 && (
            <Button type="submit" onClick={handleCreate}>
              Create
            </Button>
          )}
          {iTabValue === 1 && (
            <Button type="submit" onClick={handleOpen}>
              Open
            </Button>
          )}
          {props.enableClose && (
            <Button onClick={handleCancel} clear={props.enableClose}>
              Cancel
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
