import React, { useState } from "react";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";

import Server from "../../../../../serverDetails/server";
import { ConditionDialog } from "./ConditionDialog";

export const JoinConditionDialog = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [bopenCondition, setopenCondition] = useState(false);
  const [oCondition, setCondition] = useState({});

  const handleEvent = (event, index, skey) => {
    const { value } = event.target;

    const updatedRows = [...props.rows.joinConditions];
    updatedRows[index][skey] = value;
    props.update({ ...props.rows, joinConditions: updatedRows });
  };

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  function handleIncreaseRow() {
    const updatedRows = [...props.rows.joinConditions];
    updatedRows.push({
      joinName: "",
      firstTable: "",
      secondTable: "",
      condition : "",
      fields : [
        {
          firstTableField: "",
          secondTableField: ""
        },
      ],
    });
    props.update({ ...props.rows, joinConditions: updatedRows });
    setSelectedRows([...selectedRows, false]);
  }

  function handleRemoveRow() {
    const updatedRows = props.rows.joinConditions.filter(
      (_, index) => !selectedRows[index]
    );
    props.update({ ...props.rows, joinConditions: updatedRows });
    setSelectedRows(selectedRows.filter((_, index) => !selectedRows[index]));
  }

  const openConditionDialog = (condition) => {
    setopenCondition(true);
    console.log(condition);
    setCondition(condition);
  };
  function handlecloseDialog() {
    setopenCondition(false);
  }

  const updateCondition = (updatedConditions) => {
    // Update the conditions in the specific array
    const updatedRows = [...props.rows.joinConditions];
    const indexToUpdate = updatedRows.findIndex(
      (row) => row.fields === oCondition
    );
    updatedRows[indexToUpdate].fields = updatedConditions;
    props.update({ ...props.rows, joinConditions: updatedRows });
  };
  return (
    <div>
      <IconButton>
        <AddIcon
          onClick={handleIncreaseRow}
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      {props.rows.joinConditions.length > 1 ? (
        <IconButton>
          <RemoveIcon
            onClick={handleRemoveRow}
            baseClassName="fas"
            className="fa-plus-circle"
            color="secondary"
          />
        </IconButton>
      ) : (
        ""
      )}
      <Box>
        <table id="idTableConfiguration" className="RenderComponent-Table">
          <thead>
            <tr>
              <th></th>
              <th className="RenderComponent-Table-th">Join Name</th>
              <th className="RenderComponent-Table-th">First Table</th>
              <th className="RenderComponent-Table-th">Second Table</th>
              <th className="RenderComponent-Table-th">Fields</th>
              <th className="RenderComponent-Table-th">Condition</th>
            </tr>
          </thead>
          <tbody>
            {props.rows.joinConditions.map((row, index) => (
              <tr key={index}>
                <td className="RenderComponent-Table-td">
                  <input
                    type="checkbox"
                    checked={selectedRows[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    type="text"
                    className="RenderComponent-Table-input"
                    value={row.joinName}
                    onChange={(event) => handleEvent(event, index, "joinName")}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    type="text"
                    className="RenderComponent-Table-input"
                    value={row.firstTable}
                    onChange={(event) =>
                      handleEvent(event, index, "firstTable")
                    }
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    type="text"
                    className="RenderComponent-Table-input"
                    value={row.secondTable}
                    onChange={(event) =>
                      handleEvent(event, index, "secondTable")
                    }
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  <SettingsIcon
                    onClick={() => openConditionDialog(row.fields)}
                    style={{ cursor: "pointer" }}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <select
                    className="RenderComponent-Table-input"
                    value={row.condition}
                    onChange={(event) => {
                      handleEvent(event, index, "condition");
                    }}
                  >
                    <option value="" disabled>
                      Select Condition
                    </option>
                    <option value="AND">AND</option>
                    <option value="OR">OR</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <ConditionDialog
        open={bopenCondition}
        close={handlecloseDialog}
        datas={oCondition}
        updateCondition={updateCondition}
      />
    </div>
  );
};
