import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { DialogTitle } from '@mui/material';

export const ResolutionDialog = (props) => {

    function handleChangeResolution() {
        props.onProceed();
    }

    function handleCloseResolution() {
        props.onClose();
    }
    return (
        <Dialog open={props.open}>
            <DialogTitle> Screen Resolution </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    The project was developed under different screen resolution do you want to convert ?
                    *we recommend not to change until you can't proceed with same resolution
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleChangeResolution}>
                    Proceed
                </Button>
                <Button onClick={handleCloseResolution} autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}