import { FetchDB } from "./../logicUtils/databaseLogic/FetchDB";
import { FetchInputs } from "./../logicUtils/fetchLogics/FetchInputs";
import { CodeExecutor } from "./../logicUtils/codeLogic/CodeExecutor";
import { TableSelectionFields } from "./../logicUtils/tableSelection/TableSelectionFields";

 export function executeValidationLogicFlow(oValidationData) {
  var oNextTarget = [];
  var oParams = [];
  for (var i = 0; i < oValidationData.aFlow.length; i++) {
    for (var j = 0; j < oValidationData.aFlow[i].target.length; j++) {
      oNextTarget = oValidationData.aFlow.find(function (oFlow) {
        return oFlow.id === oValidationData.aFlow[i].target[j].id;
      });
      executeTarget(
        oValidationData.aFlow[i].target[j],
        oValidationData,
        oParams,
        oValidationData.aPromises,
        oNextTarget,
        oValidationData.aCurrentPage,
        oValidationData.aMessages,
        oValidationData
      );
    }
  }
  if (oValidationData.aPromises.length === 0 && oValidationData.aMessages.length > 0) {
    return oValidationData.aMessages;
  } else if (oValidationData.aPromises.length === 0){
    return true;
  }
}

async function executeTarget(
  oTarget,
  oValidationData,
  oParams,
  aPromises,
  oNextTarget,
  aCurrentPage,
  aMessages,
  bBusyIndicator
) {
  switch (oTarget.target) {
    case "idUserInputs":
      checkUserInputs(oTarget.properties, aCurrentPage, aMessages);
      break;
    case "idFetchInputs":
      FetchInputs(oTarget.properties, oParams, aCurrentPage);
      break;
    case "idTableSelection":
      TableSelectionFields(oTarget.properties, oParams, oValidationData.aUIData);
      break;
    case "idFetchDB":
      FetchDB(
        oTarget.properties,
        oValidationData,
        oParams,
        aPromises,
        oNextTarget
      );
      break;
    case "idCodeEditor":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          CodeExecutor(oTarget.properties, oParams, oValidationData);
        });
      } else {
        CodeExecutor(oTarget.properties, oParams, oValidationData);
      }
      break;
    case "idDynamicValidation":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          checkDynamicValidation(oTarget.properties, oParams, oValidationData);
        });
      } else {
        checkDynamicValidation(oTarget.properties, oParams, oValidationData);
      }
      break;
    default:
      return;
  }
}

function checkUserInputs(aProperties, aCurrentPage, aMessages) {
  var aUserInputs = aProperties.find(function (oProperty) {
    return oProperty.id === "idUserInputsFields";
  });
  if (aUserInputs.value.length > 0) {
    aUserInputs.value.forEach(function (oUserInputData) {
      var sInputField = oUserInputData["inputField"];
      var sFieldUniqueID = oUserInputData["inputFieldId"];
      var aInputs = fetchInputs(aCurrentPage, sInputField, sFieldUniqueID);
      if (aInputs !== undefined) {
        checkConditons(oUserInputData, aInputs[0].value, aMessages);
      }
    });
  }
}

function checkConditons(oUserData, value, aMessages) {
  let minValue;
  let maxValue;
  switch (oUserData.validationType) {
    case "Should not be null":
      if (value === null || value === "") {
        aMessages.push({
          fieldName: oUserData.inputField,
          message: oUserData.message,
          messageType: oUserData.messageType,
        });
      }
      break;
    case "Value should be between":
      minValue = parseInt(oUserData.from, 10);
      maxValue = parseInt(oUserData.to, 10);
      if (value < minValue || value > maxValue) {
        aMessages.push({
          fieldName: oUserData.inputField,
          message: oUserData.message,
          messageType: oUserData.messageType,
        });
      }
      break;
    case "Array must contain at least one item":
      if (value.rows.length === 0) {
        aMessages.push({
          fieldName: oUserData.inputField,
          message: oUserData.message,
          messageType: oUserData.messageType,
        });
      }
      break;
    case "Array Value Not Empty":
      try {
        if (value.rows.length > 0) {
          value.rows.forEach(function (oData) {
            if (oUserData.dataType === "Number") {
              if (
                parseInt(oData[oUserData.arrayField]) === "" ||
                parseInt(oData[oUserData.arrayField]) <= 0
              ) {
                aMessages.push({
                  fieldName: `${oUserData.inputField}.${oUserData.arrayField}`,
                  message: oUserData.message,
                  messageType: oUserData.messageType,
                });
                throw "BreakException";
              }
            } else {
              if (
                oData[oUserData.arrayField] &&
                oData[oUserData.arrayField] === ""
              ) {
                aMessages.push({
                  fieldName: `${oUserData.inputField}.${oUserData.arrayField}`,
                  message: oUserData.message,
                  messageType: oUserData.messageType,
                });
                throw "BreakException";
              }
            }
          });
        }
      } catch (e) {
        if (e !== "BreakException") throw e;
      }
      break;
    default:
      return;
  }
}

function fetchInputs(aCurrentPage, sInputField, sFieldUniqueID) {
  var oParams = [];
  aCurrentPage.forEach(function (oComponent) {
    if (
      oComponent.Component === "Input" ||
      (oComponent.Component === "DropDown" &&
        oComponent.uniqueId === sFieldUniqueID)
    ) {
      var sData = oComponent.properties.find((oProperty) => {
        return (
          oProperty.id === "idInputFieldName" && oProperty.value === sInputField
        );
      });
      if (sData) {
        var sType = oComponent.properties.find((oProperty) => {
          return oProperty.id === "idInputTypeInput";
        });
        if (sType) {
          switch (sType.value) {
            case "Number":
              if (
                oComponent.UserValue === "" ||
                oComponent.UserValue === undefined
              ) {
                oParams.push({
                  field: sInputField,
                  value: null,
                });
              } else {
                oParams.push({
                  field: sInputField,
                  value: +oComponent.UserValue,
                });
              }
              break;
            default:
              oParams.push({
                field: sInputField,
                value: oComponent.UserValue,
              });
              break;
          }
        } else {
          oParams.push({
            field: sInputField,
            value: oComponent.UserValue,
          });
        }
      }
    } else if (
      oComponent.Component === "Table" &&
      oComponent.uniqueId === sFieldUniqueID
    ) {
      var bTableSelection = oComponent.properties.find(
        (oProperty) => oProperty.id === "idTableSelection"
      ).value;
      var bTableAddRows = oComponent.properties.find(
        (oProperty) => oProperty.id === "idTableAddRows"
      ).value;

      if (bTableSelection && !bTableAddRows) {
        const aFilteredRows = [];
        oComponent.checkValues.forEach((value, index) => {
          if (value && value.all === true && oComponent.value.rows[index]) {
            aFilteredRows.push(oComponent.value.rows[index]);
          }
        });
        const values = { rows: aFilteredRows };
        oParams.push({
          field: sInputField,
          value: values,
        });
      }else{
        oParams.push({
          field: sInputField,
          value: oComponent.value,
        });
      }
    }
  });
  if (oParams.length > 0) {
    return oParams;
  }
}

function checkDynamicValidation(aProperties, oParams, oValidationData) {
  var aValidationValue = aProperties.find(
    (oProperty) => oProperty.id === "idValidationMessageFields"
  ).value;
  if (Array.isArray(oParams)) {
    switch (aValidationValue[0].dataType) {
      case "Boolean":
        if (
          oParams[aValidationValue[0].inputField] !==
          JSON.parse(aValidationValue[0].value)
        ) {
          oValidationData.aMessages.push({
            message: aValidationValue[0].message,
            messageType: aValidationValue[0].messageType,
          });
        }
        break;
      case "Number":
        if (
          oParams[aValidationValue[0].inputField] ===
          JSON.parse(aValidationValue[0].value)
        ) {
          return true;
        }
        if (
          oParams[0].field === aValidationValue[0].inputField &&
          oParams[0].value === JSON.parse(aValidationValue[0].value)
        ) {
          return true;
        } else {
          oValidationData.aMessages.push({
            message: aValidationValue[0].message,
            messageType: aValidationValue[0].messageType,
          });
        }
        break;
      case "String":
        if (
          oParams[aValidationValue[0].inputField] ===
          aValidationValue[0].value
        ) {
          return true;
        }
        if (
          oParams[0].field === aValidationValue[0].inputField &&
          oParams[0].value === aValidationValue[0].value
        ) {
          return true;
        } else {
          oValidationData.aMessages.push({
            message: aValidationValue[0].message,
            messageType: aValidationValue[0].messageType,
          });
        }
        break;
      default:
        return null;
    }
  }
}
