import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { convertUIDataForPreview } from "../../utilities/resolution_conversion";
import { executeLogicFlow } from "../../utilities/logicUtils/executeLogicFlow";
import { ValueHelpDialog } from "../../dialogs/valueHelpDialog/valueHelpDialog";
import { VisibleColumnsDialog } from "../../dialogs/visibleColumnsDialog/visibleColumnsDialog";
import { ExecuteConfirmation } from "../../dialogs/confirmation/ExecuteConfirmation";
import { executeValidationLogicFlow } from "../../utilities/validationUtils/executeValidationFlow";
import { getDefaultChartComponent } from "./charts/getDefaultChartComponent";
import { GetDefaultGanttChart } from "./charts/GetDefaultGanttChart";
import { GetDefaultG_GanttChart } from "./charts/GetDefaultG_GanttChart";
import Select from "react-select";
import CustomFileUploader from "./customFileUploader";
import ArcGISMap from "./map/Argsimap";

import "./RenderComponent.css";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faCloudArrowDown,faTrashCan,faPen} from '@fortawesome/free-solid-svg-icons';
import { faSquareCaretRight,faSquarePlus } from '@fortawesome/free-regular-svg-icons';

//OLD IMPORTS
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";

import ScaleLoader from "react-spinners/ScaleLoader";
let previewRenderMobileView;
export const RenderComponents = (props) => {
  const [ovalueHelpColumnData, setvalueHelpColumnData] = useState({});
  const [aValueHelpTableData, setvalueHelpTableData] = useState([]);
  const [sValueHelpTableName, setvalueHelpTableName] = useState("");
  const [bValueHelpMultiSelect, setValueHelpMultiSelect] = useState(false);
  const [bOpenVisibleColumnsDialog, setOpenVisibleColumnsDialog] =
    useState(false);
  const [aTableColumnsDesc, setTableColumnsDesc] = useState([]);
  const [oPage, setPage] = useState({});
  const [bLoading, setLoading] = useState(false);

  const [bOpenValueHelpDialog, setOpenValueHelpDialog] = useState(false);
  const [sInputID, setInputID] = useState("");
  const [sValueHelpType, setValueHelpType] = useState("");
  const [oTableOnClick, setTableOnClick] = useState({});
  const [sView, setView] = useState("Day");
  const [sSelectedTask, setSelectedTask] = useState(null);
  const [bOpenConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [sConfirmationMessage, setConfirmationMessage] = useState();
  const [sConfirmationResponse, setConfirmationResponse] = useState(null);
  const [bEnableOk, setEnableOk] = useState(false);

  const [sErrorMessage, setErrorMessage] = useState("");
  const [iErrorCode, setErrorCode] = useState();
  const [bSnackBar, setSnackBar] = useState(false);
  const [sMessageType, setMessageType] = useState("error");
  const [sortBy, setSortBy] = useState({ order: "" });
  const [selectedOptions, setSelectedOptions] = useState({});

  const [iMapSeconds, setMapSeconds] = useState(10);
  const [bLoadFunction, setLoadFunction] = useState(false);
  const [oMapComponent, setMapComponent] = useState({});

  const containerRef = useRef(null);
  const location = useLocation();

  const [oParent, setParent] = useState({
    sId: "",
    selectedRow: "",
    ColumnName: "",
    type: "",
    value: "",
  });
  var totalPages;
  var aRenderComponents = [];
  var aTabs = [];

  const getIconByAction = (action) => {
    switch (action) {
      case 'Create':
        return faPlus;
      case 'Export':
        return faCloudArrowDown;
      case 'Delete':
        return faTrashCan;
      case 'Update':
        return faPen;
      default:
        return null;
    }
  };
  const getIconColor = (sType) => {
    const blackTypes = ['Cancel', 'Action', 'ValueHelp'];
    return blackTypes.includes(sType) ? 'black' : 'white';
  };

  useEffect(() => {
    setSelectedOptions({});
  }, [props.queryParams]);

  useEffect(() => {
    const interval = iMapSeconds * 1000;
    if(bLoadFunction){
      const intervalId = setInterval(() => {
        handleOnTrackPosition(oMapComponent,props.UIData);
      }, interval);
      return () => clearInterval(intervalId);
    }
  }, [iMapSeconds,bLoadFunction,handleOnTrackPosition]);

  const handleConfirmationDialog = async (message, bEnableConfirm) => {
    return new Promise((resolve) => {
      setConfirmationMessage(message);
      setEnableOk(bEnableConfirm);
      setOpenConfirmationDialog(true);

      const handleClose = async (userResponse) => {
        resolve({
          success: userResponse,
          message: userResponse
            ? "Operation successful"
            : "Operation cancelled",
        });
        setOpenConfirmationDialog(false);
        setConfirmationResponse(null);
      };

      setConfirmationResponse(() => handleClose);
    });
  };
  const closeConfirmationDialog = (bValue) => {
    setOpenConfirmationDialog(false);
    if (sConfirmationResponse) {
      sConfirmationResponse(bValue);
    }
    setConfirmationMessage("");
  };

  if (props.UIData.length > 0) {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768 && !previewRenderMobileView) {
      previewRenderMobileView = document
        .getElementById("PreviewRenderer")
        .getBoundingClientRect();
    }
    var aUIConvertedUIData = convertUIDataForPreview(
      props.UIData,
      previewRenderMobileView
    );
    aUIConvertedUIData.forEach(function (oComponentModel) {
      aRenderComponents.push(
        renderComponent(oComponentModel, aUIConvertedUIData)
      );
    });
    if (aTabs.length > 0) {
      aTabs[0].props.onClick();
      if (
        aTabs[0].props.children[0].props.children.props.children ===
        props.setSelectedTab
      ) {
        selectDefaultTabs(aTabs.length);
      }
    }
    props.UIData.forEach(function (oComponent) {
      if (
        oComponent.Component === "Table" &&
        !props.reload[oComponent.uniqueId]
      ) {
        props.reload[oComponent.uniqueId] = true;
        oComponent.Events.forEach(function (oEvent) {
          if (oEvent.event === "OnLoad") {
            handleOnLoad(oComponent, props.UIData);
          }
        });
        oComponent.Logic.forEach(function (oLogic) {
          if (oLogic.event === "onClick") {
            setTableOnClick((prevState) => ({
              ...prevState,
              [oComponent.uniqueId]: true,
            }));
          }
        });
        var id = oComponent.uniqueId;
        setPage((prevState) => ({
          ...prevState,
          [id]: { currentPage: 1 },
        }));
      }
      if (
        oComponent.Component === "Gantt" &&
        !props.reload[oComponent.uniqueId]
      ) {
        props.reload[oComponent.uniqueId] = true;
        oComponent.Events.forEach(function (oEvent) {
          if (oEvent.event === "OnLoad") {
            handleOnLoad(oComponent, props.UIData);
          }
        });
      }
      if (
        oComponent.Component === "G_Gantt" &&
        !props.reload[oComponent.uniqueId]
      ) {
        props.reload[oComponent.uniqueId] = true;
        oComponent.Events.forEach(function (oEvent) {
          if (oEvent.event === "OnLoad") {
            handleOnLoad(oComponent, props.UIData);
          }
        });
      }
      if (
        oComponent.Component === "DropDown" &&
        !props.reload[oComponent.uniqueId]
      ) {
        props.reload[oComponent.uniqueId] = true;
        var bDBValue = oComponent.properties.find(function (oProperties) {
          return oProperties.id === "idCheckboxDBData";
        });
        if (bDBValue.value) {
          oComponent.Events.forEach(function (oEvent) {
            if (oEvent.event === "OnLoad") {
              handleOnLoad(oComponent, props.UIData);
            }
          });
        }
      }

      if (
        oComponent.Component === "Input" &&
        !props.reload[oComponent.uniqueId]
      ) {
        props.reload[oComponent.uniqueId] = true;
        oComponent.Events.forEach(function (oEvent) {
          if (oEvent.event === "OnLoad") {
            handleOnLoad(oComponent, props.UIData);
          }
        });
      }
      if (
        oComponent.Component === "BarChart" &&
        !props.reload[oComponent.uniqueId]
      ) {
        props.reload[oComponent.uniqueId] = true;
        oComponent.Events.forEach(function (oEvent) {
          if (oEvent.event === "OnLoad") {
            handleOnLoad(oComponent, props.UIData);
          }
        });
      }
      if (
        oComponent.Component === "PieChart" &&
        !props.reload[oComponent.uniqueId]
      ) {
        props.reload[oComponent.uniqueId] = true;
        oComponent.Events.forEach(function (oEvent) {
          if (oEvent.event === "OnLoad") {
            handleOnLoad(oComponent, props.UIData);
          }
        });
      }
    });
  }

  //Close the ValueHelp amd VisibleColumn Dialog
  function handleClose() {
    setOpenValueHelpDialog(false);
    setOpenVisibleColumnsDialog(false);
  }
  /**
   * Event handler to load initialy
   * @param {*} oComponentModel
   * @param {*} aUIComponents
   * @returns
   */
  function handleOnLoad(oComponentModel, aUIComponents) {
    var aLogics = oComponentModel.Logic;
    var oLogicComponent = aLogics.find(function (oLogic) {
      return oLogic.component[0].id === "idOnLoadEvent";
    });
    if (!oLogicComponent) {
      return;
    }
    var aEdges = oLogicComponent.edges;
    var aComponents = oLogicComponent.component;
    var atargets = [];
    const aMessages = [];
    var aPromises = [];
    var aValidations = oLogicComponent.validations;
    if (aValidations && aValidations.length > 0) {
      aValidations.forEach(function (oValidationLogic) {
        if (oValidationLogic.component[0].id === oLogicComponent.id) {
          var aValidationTargets = getValidationTargets(
            oValidationLogic,
            oLogicComponent
          );
          const oValidationData = {
            aFlow: aValidationTargets,
            aCurrentPage: props.currentPage.UIComponents,
            aMessages,
            APPID: props.APPID,
            bBusyIndicator: setLoading,
            aPromises,
          };
          executeValidationLogicFlow(oValidationData);
        }
      });
    }
    Promise.allSettled(aPromises).then(() => {
      if (aMessages.length <= 0) {
        if (oLogicComponent) {
          aComponents.forEach(function (oComponent, index) {
            atargets[index] = {
              id: oComponent.id,
              source: oComponent.data.id,
              target: [],
            };
            aEdges.forEach(function (oEdge) {
              if (oEdge.source === oComponent.id) {
                var oTargetComponent = getComponentId(
                  oEdge.target,
                  aComponents
                );
                if (oTargetComponent) {
                  atargets[index].target.push({
                    id: oEdge.target,
                    target: oTargetComponent.data.id,
                    properties: oTargetComponent.properties,
                  });
                }
              }
            });
          });
          const oLogicDatas = {
            aFlow: atargets,
            APPID: props.APPID,
            sUniqueID: oComponentModel.uniqueId,
            aPreviewProps: props.prevProps,
            aUIData: aUIComponents,
            USERID: props.userId,
            fnCallback: props.refresh,
            bOnLoad: false,
            resetUI: props.resetUI,
            resetAppUI: props.resetAppUI,
            fnNavigate: props.navigate,
            oQueryParameters: props.queryParams,
            aParameters: props.paramters,
            aCurrentPage: props.currentPage.UIComponents,
            allUIData: props.allUIData,
            confirmation: handleConfirmationDialog,
            bSnackBar: setSnackBar,
            sErrorMessage: setErrorMessage,
            setMessageType: setMessageType,
            iErrorCode: setErrorCode,
            bBusyIndicator: setLoading,
            oLocation: location,
            valueHelpDialog: handleDialogConfig,
            fFunctionCall: handleFunctionRecall,
            validationmessage: props.setValidationMessages,
            validationdialog: props.setVisibleErrorIcon,
          };
          executeLogicFlow(oLogicDatas);
          props.setVisibleErrorIcon(false);
        }
      } else {
        props.setValidationMessages(aMessages);
        props.setVisibleErrorIcon(true);
      }
    });
  }

  /**
   * Event handler on click of preview component
   * @param {event} oEvent | event
   * @param {object} oComponentModel | component model
   */
  function handleOnClick(
    oEvent,
    oComponentModel,
    aUIComponents,
    oTableValueHelp
  ) {
    var aLogics = oComponentModel.Logic;
    var oLogicComponent = aLogics.find(function (oLogic) {
      return oLogic.component[0].id === "idonClickEvent";
    });
    if (!oLogicComponent) {
      return;
    }
    const aMessages = [];
    var aPromises = [];
    var aValidations = oLogicComponent.validations;
    if (aValidations && aValidations.length > 0) {
      aValidations.forEach(function (oValidationLogic) {
        if (oValidationLogic.component[0].id === oLogicComponent.id) {
          var aValidationTargets = getValidationTargets(
            oValidationLogic,
            oLogicComponent
          );
          const oValidationData = {
            aFlow: aValidationTargets,
            aCurrentPage: props.currentPage.UIComponents,
            aMessages,
            bBusyIndicator: setLoading,
            aPromises,
            APPID: props.APPID,
            aUIData: aUIComponents,
          };
          executeValidationLogicFlow(oValidationData);
        }
      });
    }
    Promise.allSettled(aPromises).then(() => {
      if (aMessages.length <= 0) {
        var aEdges = oLogicComponent.edges;
        var aComponents = oLogicComponent.component;
        var atargets = [];
        if (oLogicComponent) {
          aComponents.forEach(function (oComponent, index) {
            atargets[index] = {};
            atargets[index].id = oComponent.id;
            atargets[index].source = oComponent.data.id;
            atargets[index].target = [];
            aEdges.forEach(function (oEdge) {
              if (oEdge.source === oComponent.id) {
                var oTargetComponent = getComponentId(
                  oEdge.target,
                  aComponents
                );
                if (oTargetComponent) {
                  atargets[index].target.push({
                    id: oEdge.target,
                    target: oTargetComponent.data.id,
                    properties: oTargetComponent.properties,
                  });
                }
              }
            });
          });
          const oLogicDatas = {
            aFlow: atargets,
            APPID: props.APPID,
            sUniqueID: oComponentModel.uniqueId,
            aPreviewProps: props.prevProps,
            aUIData: aUIComponents,
            USERID: props.userId,
            fnCallback: props.refresh,
            bOnLoad: false,
            resetUI: props.resetUI,
            resetAppUI: props.resetAppUI,
            fnNavigate: props.navigate,
            aParameters: props.paramters,
            oQueryParameters: props.queryParams,
            aCurrentPage: props.currentPage.UIComponents,
            allUIData: props.allUIData,
            confirmation: handleConfirmationDialog,
            bSnackBar: setSnackBar,
            sErrorMessage: setErrorMessage,
            setMessageType: setMessageType,
            iErrorCode: setErrorCode,
            bBusyIndicator: setLoading,
            oLocation: location,
            valueHelpDialog: handleDialogConfig,
            valueHelpTable: oTableValueHelp,
            fFunctionCall: handleFunctionRecall,
            validationmessage: props.setValidationMessages,
            validationdialog: props.setVisibleErrorIcon,
          };
          executeLogicFlow(oLogicDatas);
          props.setVisibleErrorIcon(false);
        }
      } else if (aMessages.length > 0) {
        props.setValidationMessages(aMessages);
        props.setVisibleErrorIcon(true);
      }
    });
  }

  /**
   * Event handler on click of preview component
   * @param {event} oEvent | event
   * @param {object} oComponentModel | component model
   */
  function handleOnWatchPosition(oComponentModel, aUIComponents, position) {
    var aLogics = oComponentModel.Logic;
    var oLogicComponent = aLogics.find(function (oLogic) {
      return oLogic.component[0].id === "idonWatchPositionEvent";
    });
    if (!oLogicComponent) {
      return;
    }
    const aMessages = [];
    var aPromises = [];
    var aValidations = oLogicComponent.validations;
    if (aValidations && aValidations.length > 0) {
      aValidations.forEach(function (oValidationLogic) {
        if (oValidationLogic.component[0].id === oLogicComponent.id) {
          var aValidationTargets = getValidationTargets(
            oValidationLogic,
            oLogicComponent
          );
          const oValidationData = {
            aFlow: aValidationTargets,
            aCurrentPage: props.currentPage.UIComponents,
            aMessages,
            bBusyIndicator: setLoading,
            aPromises,
            APPID: props.APPID,
            aUIData: aUIComponents,
          };
          executeValidationLogicFlow(oValidationData);
        }
      });
    }
    Promise.allSettled(aPromises).then(() => {
      if (aMessages.length <= 0) {
        var aEdges = oLogicComponent.edges;
        var aComponents = oLogicComponent.component;
        var atargets = [];
        if (oLogicComponent) {
          aComponents.forEach(function (oComponent, index) {
            atargets[index] = {};
            atargets[index].id = oComponent.id;
            atargets[index].source = oComponent.data.id;
            atargets[index].target = [];
            aEdges.forEach(function (oEdge) {
              if (oEdge.source === oComponent.id) {
                var oTargetComponent = getComponentId(
                  oEdge.target,
                  aComponents
                );
                if (oTargetComponent) {
                  atargets[index].target.push({
                    id: oEdge.target,
                    target: oTargetComponent.data.id,
                    properties: oTargetComponent.properties,
                  });
                }
              }
            });
          });
          const oLogicDatas = {
            aFlow: atargets,
            APPID: props.APPID,
            sUniqueID: oComponentModel.uniqueId,
            aPreviewProps: props.prevProps,
            aUIData: aUIComponents,
            USERID: props.userId,
            fnCallback: props.refresh,
            bOnLoad: false,
            fnNavigate: props.navigate,
            aParameters: props.paramters,
            oQueryParameters: props.queryParams,
            aCurrentPage: props.currentPage.UIComponents,
            confirmation: handleConfirmationDialog,
            bSnackBar: setSnackBar,
            sErrorMessage: setErrorMessage,
            setMessageType: setMessageType,
            iErrorCode: setErrorCode,
            bBusyIndicator: setLoading,
            oLocation: location,
            resetUI: props.resetUI,
            oMapPosition: position,
            fFunctionCall: handleFunctionRecall,
            validationmessage: props.setValidationMessages,
            validationdialog: props.setVisibleErrorIcon,
          };
          executeLogicFlow(oLogicDatas);
          props.setVisibleErrorIcon(false);
        }
      } else if (aMessages.length > 0) {
        props.setValidationMessages(aMessages);
        props.setVisibleErrorIcon(true);
      }
    });
  }

  function handleCallOnWachPosition(oComponentModel, oPosition) {
    handleOnWatchPosition(oComponentModel, props.UIData, oPosition);
  }

  /**
   * Event handler on click of preview component
   * @param {event} oEvent | event
   * @param {object} oComponentModel | component model
   */
  function handleOnTrackPosition(oComponentModel, aUIComponents) {
    var aLogics = oComponentModel.Logic;
    var oLogicComponent = aLogics.find(function (oLogic) {
      return oLogic.component[0].id === "idonTrackPositionEvent";
    });
    if (!oLogicComponent) {
      return;
    }
    const aMessages = [];
    var aPromises = [];
    var aValidations = oLogicComponent.validations;
    if (aValidations && aValidations.length > 0) {
      aValidations.forEach(function (oValidationLogic) {
        if (oValidationLogic.component[0].id === oLogicComponent.id) {
          var aValidationTargets = getValidationTargets(
            oValidationLogic,
            oLogicComponent
          );
          const oValidationData = {
            aFlow: aValidationTargets,
            aCurrentPage: props.currentPage.UIComponents,
            aMessages,
            bBusyIndicator: setLoading,
            aPromises,
            APPID: props.APPID,
            aUIData: aUIComponents,
          };
          executeValidationLogicFlow(oValidationData);
        }
      });
    }
    Promise.allSettled(aPromises).then(() => {
      if (aMessages.length <= 0) {
        var aEdges = oLogicComponent.edges;
        var aComponents = oLogicComponent.component;
        var atargets = [];
        if (oLogicComponent) {
          aComponents.forEach(function (oComponent, index) {
            atargets[index] = {};
            atargets[index].id = oComponent.id;
            atargets[index].source = oComponent.data.id;
            atargets[index].target = [];
            aEdges.forEach(function (oEdge) {
              if (oEdge.source === oComponent.id) {
                var oTargetComponent = getComponentId(
                  oEdge.target,
                  aComponents
                );
                if (oTargetComponent) {
                  atargets[index].target.push({
                    id: oEdge.target,
                    target: oTargetComponent.data.id,
                    properties: oTargetComponent.properties,
                  });
                }
              }
            });
          });
          const oLogicDatas = {
            aFlow: atargets,
            APPID: props.APPID,
            sUniqueID: oComponentModel.uniqueId,
            aPreviewProps: props.prevProps,
            aUIData: aUIComponents,
            resetUI: props.resetUI,
            USERID: props.userId,
            fnCallback: props.refresh,
            bOnLoad: false,
            fnNavigate: props.navigate,
            aParameters: props.paramters,
            oQueryParameters: props.queryParams,
            aCurrentPage: props.currentPage.UIComponents,
            confirmation: handleConfirmationDialog,
            bSnackBar: setSnackBar,
            sErrorMessage: setErrorMessage,
            setMessageType: setMessageType,
            iErrorCode: setErrorCode,
            bBusyIndicator: setLoading,
            fFunctionCall: handleFunctionRecall,
            validationmessage: props.setValidationMessages,
            validationdialog: props.setVisibleErrorIcon,
          };
          executeLogicFlow(oLogicDatas);
          props.setVisibleErrorIcon(false);
        }
      } else if (aMessages.length > 0) {
        props.setValidationMessages(aMessages);
        props.setVisibleErrorIcon(true);
      }
    });
  }

  /**
   * Event handler on Enter of preview component
   * @param {event} oEvent | event
   * @param {object} oComponentModel | component model
   */
  function handleOnEnter(oEvent, oComponentModel, aUIComponents) {
    var aLogics = oComponentModel.Logic;
    var oLogicComponent = aLogics.find(function (oLogic) {
      return oLogic.component[0].id === "idonEnterEvent";
    });
    if (!oLogicComponent) {
      return;
    }
    const aMessages = [];
    var aPromises = [];
    var aValidations = oLogicComponent.validations;
    if (aValidations && aValidations.length > 0) {
      aValidations.forEach(function (oValidationLogic) {
        if (oValidationLogic.component[0].id === oLogicComponent.id) {
          var aValidationTargets = getValidationTargets(
            oValidationLogic,
            oLogicComponent
          );
          const oValidationData = {
            aFlow: aValidationTargets,
            aCurrentPage: props.currentPage.UIComponents,
            aMessages,
            bBusyIndicator: setLoading,
            aPromises,
            APPID: props.APPID,
            aUIData: aUIComponents,
          };
          executeValidationLogicFlow(oValidationData);
        }
      });
    }
    Promise.allSettled(aPromises).then(() => {
      if (aMessages.length <= 0) {
        var aEdges = oLogicComponent.edges;
        var aComponents = oLogicComponent.component;
        var atargets = [];
        if (oLogicComponent) {
          aComponents.forEach(function (oComponent, index) {
            atargets[index] = {};
            atargets[index].id = oComponent.id;
            atargets[index].source = oComponent.data.id;
            atargets[index].target = [];
            aEdges.forEach(function (oEdge) {
              if (oEdge.source === oComponent.id) {
                var oTargetComponent = getComponentId(
                  oEdge.target,
                  aComponents
                );
                if (oTargetComponent) {
                  atargets[index].target.push({
                    id: oEdge.target,
                    target: oTargetComponent.data.id,
                    properties: oTargetComponent.properties,
                  });
                }
              }
            });
          });
          const oLogicDatas = {
            aFlow: atargets,
            APPID: props.APPID,
            sUniqueID: oComponentModel.uniqueId,
            aPreviewProps: props.prevProps,
            aUIData: aUIComponents,
            USERID: props.userId,
            fnCallback: props.refresh,
            bOnLoad: false,
            resetUI: props.resetUI,
            resetAppUI: props.resetAppUI,
            fnNavigate: props.navigate,
            aParameters: props.paramters,
            oQueryParameters: props.queryParams,
            aCurrentPage: props.currentPage.UIComponents,
            allUIData: props.allUIData,
            confirmation: handleConfirmationDialog,
            bSnackBar: setSnackBar,
            sErrorMessage: setErrorMessage,
            setMessageType: setMessageType,
            iErrorCode: setErrorCode,
            bBusyIndicator: setLoading,
            oLocation: location,
            valueHelpDialog: handleDialogConfig,
            fFunctionCall: handleFunctionRecall,
            validationmessage: props.setValidationMessages,
            validationdialog: props.setVisibleErrorIcon,
          };
          executeLogicFlow(oLogicDatas);
          props.setVisibleErrorIcon(false);
        }
      } else if (aMessages.length > 0) {
        props.setValidationMessages(aMessages);
        props.setVisibleErrorIcon(true);
      }
    });
  }

  /**
   * Event handler on change of preview component logic
   * @param {event} oEvent | event
   * @param {object} oComponentModel | component model
   */
  function handleOnChangeExecuteLogic(oEvent, oComponentModel, aUIComponents) {
    var aLogics = oComponentModel.Logic;
    var oLogicComponent = aLogics.find(function (oLogic) {
      return oLogic.component[0].id === "idonChangeEvent";
    });
    if (!oLogicComponent) {
      return;
    }
    const aMessages = [];
    var aPromises = [];
    var aValidations = oLogicComponent.validations;
    if (aValidations && aValidations.length > 0) {
      aValidations.forEach(function (oValidationLogic) {
        if (oValidationLogic.component[0].id === oLogicComponent.id) {
          var aValidationTargets = getValidationTargets(
            oValidationLogic,
            oLogicComponent
          );
          const oValidationData = {
            aFlow: aValidationTargets,
            aCurrentPage: props.currentPage.UIComponents,
            aMessages,
            bBusyIndicator: setLoading,
            APPID: props.APPID,
            aPromises,
          };
          executeValidationLogicFlow(oValidationData);
        }
      });
    }
    Promise.allSettled(aPromises).then(() => {
      if (aMessages.length <= 0) {
        var aEdges = oLogicComponent.edges;
        var aComponents = oLogicComponent.component;
        var atargets = [];
        if (oLogicComponent) {
          aComponents.forEach(function (oComponent, index) {
            atargets[index] = {};
            atargets[index].id = oComponent.id;
            atargets[index].source = oComponent.data.id;
            atargets[index].target = [];
            aEdges.forEach(function (oEdge) {
              if (oEdge.source === oComponent.id) {
                var oTargetComponent = getComponentId(
                  oEdge.target,
                  aComponents
                );
                atargets[index].target.push({
                  id: oEdge.target,
                  target: oTargetComponent.data.id,
                  properties: oTargetComponent.properties,
                });
              }
            });
          });
          const oLogicDatas = {
            aFlow: atargets,
            APPID: props.APPID,
            sUniqueID: oComponentModel.uniqueId,
            aPreviewProps: props.prevProps,
            aUIData: aUIComponents,
            USERID: props.userId,
            fnCallback: props.refresh,
            bOnLoad: false,
            resetUI: props.resetUI,
            resetAppUI: props.resetAppUI,
            fnNavigate: props.navigate,
            aParameters: props.paramters,
            oQueryParameters: props.queryParams,
            aCurrentPage: props.currentPage.UIComponents,
            allUIData: props.allUIData,
            confirmation: handleConfirmationDialog,
            bSnackBar: setSnackBar,
            sErrorMessage: setErrorMessage,
            setMessageType: setMessageType,
            iErrorCode: setErrorCode,
            bBusyIndicator: setLoading,
            oLocation: location,
            fFunctionCall: handleFunctionRecall,
            validationmessage: props.setValidationMessages,
            validationdialog: props.setVisibleErrorIcon,
          };
          executeLogicFlow(oLogicDatas);
          props.setVisibleErrorIcon(false);
        }
      } else {
        props.setValidationMessages(aMessages);
        props.setVisibleErrorIcon(true);
      }
    });
  }

  function getValidationTargets(oValidationLogicComponent, oLogicComponent) {
    if (!oValidationLogicComponent) {
      return;
    }
    var aEdges = oValidationLogicComponent.edges;
    var aComponents = oValidationLogicComponent.component;
    var atargets = [];
    if (oValidationLogicComponent) {
      aComponents.forEach(function (oComponent, index) {
        atargets[index] = {};
        atargets[index].source = oComponent.data.id;
        atargets[index].target = [];
        aEdges.forEach(function (oEdge) {
          if (oEdge.source === oComponent.id) {
            var oTargetComponent = getComponentId(oEdge.target, aComponents);
            atargets[index].target.push({
              target: oTargetComponent.data.id,
              properties: oTargetComponent.properties,
            });
          }
        });
      });
      return atargets;
    }
  }
  /**
   * Event handler on change preview component
   * @param {event} oEvent | event
   * @param {object} oComponentModel | component model
   * @param {array} aUIComponents | array of components
   * @returns
   */
  function handleOnChange(oEvent, oComponentModel, aUIComponents) {
    props.onChange(
      oEvent.currentTarget.value,
      oComponentModel.uniqueId,
      aUIComponents
    );
  }

  function handleFunctionRecall(sUniqueID, sFunction) {
    var oComponent = props.currentPage.UIComponents.find(
      (oComp) => oComp.uniqueId === sUniqueID
    );
    if (sFunction === "OnClick") {
      handleOnClick("", oComponent, props.UIData);
    } else if (sFunction === "OnChange") {
      handleOnChangeExecuteLogic("", oComponent, props.UIData);
    } else if (sFunction === "OnEnter") {
      handleOnEnter("", oComponent, props.UIData);
    } else if (sFunction === "OnLoad") {
      handleOnLoad(oComponent, props.UIData);
    }
  }

  function handleValueHelpLogic(id) {
    var aTabComponentModel = props.UIData;
    var oComponent = aTabComponentModel.find((oComp) => oComp.uniqueId === id);
    var bOnChangeLogic = false;
    oComponent.Logic.forEach(function (oLogic) {
      if (oLogic.event === "onChange") {
        bOnChangeLogic = true;
      }
    });
    if (bOnChangeLogic) {
      handleOnChangeExecuteLogic("", oComponent, props.UIData);
    }
  }

  /**
   * Get the component id
   * @param {string} sID | component id
   * @param {array} aComponents | array of components
   * @returns
   */
  function getComponentId(sID, aComponents) {
    var oComponent = aComponents.find(function (oComponent) {
      return oComponent.id === sID;
    });
    return oComponent;
  }

  /**
   * Create child component of dynamic component
   * @param {string} sDiv | Type div
   * @param {string} sText | Text inside the component
   * @returns
   */
  function createChild(sDiv, sText) {
    if (sDiv === "input") {
      return null;
    }
    return sText;
  }

  function handleDialogConfig(
    oProperty,
    sID,
    oEvent,
    ColumnName,
    value,
    bExecute
  ) {
    switch (oProperty) {
      case "Table":
        var oComponent = props.UIData.find(function (oComponentModel) {
          return oComponentModel.uniqueId === sID;
        });
        if (
          bExecute &&
          oComponent.valueHelpData !== undefined &&
          Object.keys(oComponent.valueHelpData).length > 0
        ) {
          var aValueHelpData = oComponent.valueHelpData;
          var oHelpTableData = aValueHelpData[0];
          if (oHelpTableData) {
            setOpenValueHelpDialog(true);
            var columnData = oHelpTableData.valueHelpColumnData;
            var helpData = oHelpTableData.valueHelpData;
            var dataTable = oHelpTableData.valueHelpDataTable;
            setvalueHelpColumnData({ ...columnData });
            setvalueHelpTableData([...helpData]);
            setvalueHelpTableName(dataTable);
            setValueHelpType(oProperty);
            setParent({
              sId: sID,
              selectedRow: oEvent.target.id,
              ColumnName: ColumnName,
              type: ColumnName.dataType,
              value: value,
            });
          }
        } else {
          let oTableEvent = {
            sId: sID,
            tableEvent: oEvent,
            selectedRow: oEvent.currentTarget.id,
            ColumnName: ColumnName,
            type: ColumnName.dataType,
            value: value,
          };
          handleOnClick(oEvent, oComponent, props.UIData, oTableEvent);
        }
        break;
      case "Input":
        var oComponent = props.UIData.find(function (oComponentModel) {
          return oComponentModel.uniqueId === sID;
        });
        if (
          oComponent.valueHelpData !== undefined &&
          Object.keys(oComponent.valueHelpData).length > 0
        ) {
          setOpenValueHelpDialog(true);
          var valueHelpData = oComponent.valueHelpData[0];
          var columnData = valueHelpData.valueHelpColumnData;
          var helpData = valueHelpData.valueHelpData;
          var dataTable = valueHelpData.valueHelpDataTable;
          var bMultiSelect = false;
          const aLogicComponent = oComponent.Logic[0].component;
          const oFoundLogic = aLogicComponent.find(
            (oLogic) => oLogic.data.id === "idValueHelpData"
          );
          if (oFoundLogic) {
            const oProperty = oFoundLogic.properties.find(
              (oProp) => oProp.id === "idvaluehelpmultiselect"
            );
            if (oProperty) {
              bMultiSelect = oProperty.value;
            }
          }
          if (bMultiSelect) {
            setValueHelpMultiSelect(true);
          }
          setvalueHelpColumnData({ ...columnData });
          setvalueHelpTableData([...helpData]);
          setvalueHelpTableName(dataTable);
          setValueHelpType(oProperty);
          setInputID(sID);
        }

        break;
      default:
        return null;
    }
  }

  function handleVisibleColumnsDialogConfig(oEvent, sId, aColumnDesc) {
    setOpenVisibleColumnsDialog(true);
    setTableColumnsDesc([...aColumnDesc]);
  }

  function getDropDownValues(aColumnDesc) {
    var columnOptions = {};
    aColumnDesc.forEach(function (eachDescProperty, index) {
      if (
        eachDescProperty.inputType === "dropDown" ||
        eachDescProperty.inputType === "Mselect"
      ) {
        var dropDownOptions = eachDescProperty.defaultValue.split(",");
        columnOptions[index] = { dropDownOptions };
      }
    });
    return columnOptions;
  }

  /**
   * format TableData Into Pages
   * @param {integer} iRow
   * @param {Object} value
   * @param {string} sId
   * @param {integer} iPageSize
   * @param {boolean} bEditable
   * @returns
   */
  function formatTableDataIntoPages(iRow, value, sId, iPageSize, bEditable) {
    var oReturnData = {};
    if (value.rows.length >= 1 && oPage[sId]) {
      totalPages = Math.ceil(value.rows.length / iPageSize);
      oReturnData.row = value.rows.length;
    } else if (value.rows.length === 0) {
      oReturnData.row = 0;
    } else {
      oReturnData.row = iRow;
    }
    return oReturnData;
  }

  /**
   * get TablePage Rows
   * @param {integer} iPageSize
   * @param {Array} rows
   * @param {string} sId
   * @returns
   */
  function getTablePageRows(iPageSize, rows, sId) {
    var iCurrentPage = oPage[sId].currentPage;
    const iStartIndex = (iCurrentPage - 1) * iPageSize;
    const iEndIndex = iStartIndex + iPageSize;
    const aCurrentData = rows.slice(iStartIndex, iEndIndex);
    return aCurrentData;
  }

  /**
   * handle change pages
   * @param {string} sId
   * @param {string} sAction
   */
  function handleChangePages(sId, sAction) {
    switch (sAction) {
      case "previous":
        var iCurrentPage = oPage[sId].currentPage;
        setPage((prevState) => ({
          ...prevState,
          [sId]: { ...prevState, currentPage: iCurrentPage - 1 },
        }));
        break;
      case "next":
        var iCurrentPage = oPage[sId].currentPage;
        setPage((prevState) => ({
          ...prevState,
          [sId]: { currentPage: iCurrentPage + 1 },
        }));
        break;
      case "first":
        setPage((prevState) => ({
          ...prevState,
          [sId]: { ...prevState, currentPage: 1 },
        }));
        break;
      case "last":
        setPage((prevState) => ({
          ...prevState,
          [sId]: { ...prevState, currentPage: totalPages },
        }));
        break;
      default:
        return null;
    }
  }

  /**
   * handle Table selection
   * @param {event} oEvent
   * @param {array} checkValues
   * @param {string} sTableID
   * @param {string} sColumnHead
   */
  function handleChangeTableCheckBox(
    oEvent,
    aCheckValues,
    sTableID,
    sColumnHead
  ) {
    var data = oEvent.currentTarget.checked;
    if (sColumnHead) {
      for (var i = 0; i < aCheckValues.length; i++) {
        aCheckValues[i].all = data;
      }
    } else {
      var iRowNumber = oEvent.currentTarget.id;
      aCheckValues[iRowNumber]["all"] = data;
    }
    var oTableComponent = props.UIData.find((oComponentModel) => {
      return (
        oComponentModel.Component === "Table" &&
        oComponentModel.uniqueId === sTableID
      );
    });
    oTableComponent.checkValues = aCheckValues;
    props.resetUI([...props.UIData]);
  }

  /**
   * Render table
   * @param {string} sId
   * @param {integer} iRow
   * @param {integer} iColumn
   * @param {array} aColumnDesc
   * @returns
   */
  function getDefaultTable(oDefaultTableData) {
    var {
      sId,
      iRow,
      iColumn,
      iPageSize,
      aColumnDesc,
      bEditable,
      value,
      checkValues,
      dropDownOptions,
      sTableTitle,
      bToolBar,
      bTabelSelection,
      oComponentModel,
      bAddRow,
      sSortingColumn,
      sSortingOrder,
    } = oDefaultTableData;

    const oMultiSelect = {};
    for (const key in dropDownOptions) {
      if (Object.hasOwnProperty.call(dropDownOptions, key)) {
        const options = dropDownOptions[key].dropDownOptions.map((option) => ({
          label: option,
          value: option,
        }));
        oMultiSelect[key] = { dropDownOptions: options };
      }
    }

    const rows = [];
    const columns = [];
    var rowData = [];
    var aDividedRows = [];
    var oPageData = formatTableDataIntoPages(
      iRow,
      value,
      sId,
      iPageSize,
      bEditable
    );
    var aRowLockingData = oComponentModel.RowLocking;
    iRow = oPageData.row;
    for (var j = 0; j < iRow; j++) {
      if (bTabelSelection) {
        rowData.push(
          <td className="RenderComponent-Table-td">
            <input
              id={j}
              type="checkbox"
              className="checkbox-input"
              checked={checkValues[j].all}
              onChange={(oEvent) =>
                handleChangeTableCheckBox(oEvent, checkValues, sId)
              }
            />
          </td>
        );
      }
      for (let i = 0; i < iColumn; i++) {
        var sRowData = "";
        if (aColumnDesc[i].visibleColumn) {
          if (
            value &&
            value.rows &&
            value.rows[j] &&
            value.rows.length <= iRow
          ) {
            if (!bEditable && aColumnDesc[i].dataType === "Date") {
              if (value.rows[j][aColumnDesc[i].fieldName]) {
                const [year, month, day] =
                  value.rows[j][aColumnDesc[i].fieldName].split("-");
                sRowData = `${day}-${month}-${year}`;
              } else {
                sRowData = value.rows[j][aColumnDesc[i].fieldName];
              }
            }  else if (aColumnDesc[i].dataType === "datetime-local") {
              if (value.rows[j][aColumnDesc[i].fieldName]) {
                const dateTimeString = value.rows[j][aColumnDesc[i].fieldName];
                const date = new Date(dateTimeString);
                const hours = date.getHours() % 12 || 12;
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const ampm = date.getHours() >= 12 ? 'pm' : 'am';
                sRowData = `${hours}:${minutes} ${ampm}`;
              } else {
                sRowData = value.rows[j][aColumnDesc[i].fieldName];
              }
            } else {
              sRowData = value.rows[j][aColumnDesc[i].fieldName];
              sRowData =
                typeof sRowData === "boolean" ? sRowData.toString() : sRowData;
            }
          }

          if (aColumnDesc[i].inputType === "Mselect") {
            if (!selectedOptions[j] && sRowData) {
              const sRowDataArray = sRowData.includes(",")
                ? sRowData.split(",")
                : [sRowData];
              const oLabels = sRowDataArray.map((data) => ({
                label: data,
                value: data,
              }));
              const iTempValue = j;
              setSelectedOptions((prevSelectedOptions) => ({
                ...prevSelectedOptions,
                [iTempValue]: oLabels,
              }));
            }
          }
          if (
            aRowLockingData &&
            aRowLockingData.length > 0 &&
            value.rows[j][aRowLockingData[0].columnField] ===
              aRowLockingData[0].conditionValue
          ) {
            if (sRowData instanceof Date) {
              let srowformatteddate = sRowData.toISOString().split("T")[0];
              let [year, month, day] = srowformatteddate.split("-");
              let formattedDate = `${day}-${month}-${year}`;
              sRowData = formattedDate;
            }
            rowData.push(
              <td className="RenderComponent-Table-td">
                <label>{sRowData}</label>
              </td>
            );
          } else if (
            aRowLockingData &&
            aRowLockingData.length > 0 &&
            value.rows[j][aRowLockingData[0].columnField] !== "" &&
            value.rows[j][aRowLockingData[0].columnField] <
              aRowLockingData[0].conditionValue
          ) {
            if (sRowData instanceof Date) {
              let srowformatteddate = sRowData.toISOString().split("T")[0];
              let [year, month, day] = srowformatteddate.split("-");
              let formattedDate = `${day}-${month}-${year}`;
              sRowData = formattedDate;
            }
            rowData.push(
              <td className="RenderComponent-Table-td">
                <label>{sRowData}</label>
              </td>
            );
          } else if (oComponentModel.NonEditable) {
            rowData.push(
              <td className="RenderComponent-Table-td">
                <label>{sRowData}</label>
              </td>
            );
          } else {
            rowData.push(
              <td className="RenderComponent-Table-td">
                {aColumnDesc[i].inputType === "inputHelp"
                  ? bEditable &&
                    !aColumnDesc[i].readOnly && (
                      <div class="input-group">
                        <input
                          id={j}
                          type="input"
                          className="input-field"
                          value={sRowData}
                          onChange={(oEvent) =>
                            handleRowOnChangeData(
                              oEvent,
                              aColumnDesc[i],
                              value,
                              sId,
                              oComponentModel
                            )
                          }
                        />
                        <button
                          id={j}
                          type="button"
                          class="input-button"
                          onClick={(oEvent) => {
                            handleDialogConfig(
                              "Table",
                              sId,
                              oEvent,
                              aColumnDesc[i],
                              value
                            );
                          }}
                        >
                          ?
                        </button>
                      </div>
                    )
                  : aColumnDesc[i].inputType === "dropDown"
                  ? bEditable &&
                    !aColumnDesc[i].readOnly && (
                      <select
                        id={j}
                        className="RenderComponent-Table-input"
                        style={{ height: "90%" }}
                        value={sRowData}
                        onChange={(oEvent) =>
                          handleRowOnChangeData(
                            oEvent,
                            aColumnDesc[i],
                            value,
                            sId,
                            oComponentModel
                          )
                        }
                      >
                        {dropDownOptions[i] &&
                          dropDownOptions[i].dropDownOptions.map(
                            (value, index) => {
                              return (
                                <option key={index} value={value}>
                                  {value}
                                </option>
                              );
                            }
                          )}
                      </select>
                    )
                  : aColumnDesc[i].inputType === "Mselect"
                  ? bEditable &&
                    !aColumnDesc[i].readOnly && (
                      <Select
                        name={j}
                        isMulti
                        value={selectedOptions[j] || []}
                        options={oMultiSelect[i].dropDownOptions || []}
                        onChange={(selectedOptions, oEvent) =>
                          handleRowOnChangeData(
                            oEvent,
                            aColumnDesc[i],
                            value,
                            sId,
                            oComponentModel,
                            selectedOptions
                          )
                        }
                      />
                    )
                  : aColumnDesc[i].dataType === "file"
                  ? bEditable &&
                    !aColumnDesc[i].readOnly && (
                      <a
                        href={`${sRowData}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Download File
                      </a>
                    )
                  : bEditable &&
                    !aColumnDesc[i].readOnly && (
                      <input
                        id={j}
                        type={aColumnDesc[i].dataType}
                        className="RenderComponent-Table-input"
                        value={sRowData}
                        onChange={(oEvent) =>
                          handleRowOnChangeData(
                            oEvent,
                            aColumnDesc[i],
                            value,
                            sId,
                            oComponentModel
                          )
                        }
                      />
                    )}
                {bEditable && aColumnDesc[i].readOnly && (
                  <label>{sRowData}</label>
                )}
                {!bEditable && aColumnDesc[i].dataType === "file" ? (
                  <a
                    href={`${sRowData}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Download File
                  </a>
                ) : (
                  !bEditable && <label>{sRowData}</label>
                )}
              </td>
            );
          }
        }
      }
      rows.push(
        <tr
          className="RenderComponent-Table-tr"
          id={j}
          onClick={(oEvent) =>
            bEditable
              ? undefined
              : handleTableRowOnClick(oEvent, oComponentModel)
          }
        >
          {rowData}
        </tr>
      );
      rowData = [];
    }
    if (bTabelSelection) {
      columns.push(
        <th className="RenderComponent-Table-th">
          {bTabelSelection && (
            <input
              type="checkbox"
              className="checkbox-input"
              onChange={(oEvent) =>
                handleChangeTableCheckBox(
                  oEvent,
                  checkValues,
                  sId,
                  "columnHead"
                )
              }
            />
          )}
        </th>
      );
    }
    for (let i = 0; i < iColumn; i++) {
      if (aColumnDesc[i].visibleColumn) {
        columns.push(
          <th
            className={
              sSortingColumn.value === aColumnDesc[i].fieldName
                ? "RenderComponent-Table-th sorting-coloumn"
                : "RenderComponent-Table-th"
            }
            onClick={() =>
              sSortingColumn.value
                ? handleTableSortData(
                    aColumnDesc[i].fieldName,
                    sSortingOrder,
                    value,
                    oComponentModel
                  )
                : undefined
            }
          >
            {aColumnDesc[i] ? aColumnDesc[i].fieldName : ""}
            {sSortingColumn.value === aColumnDesc[i].fieldName && (
              <span
                className={`arrow-icon ${
                  sortBy.order === "Asc" ? "Asc" : "Desc"
                }`}
              >
                &#x25B2;&#xFE0E;
              </span>
            )}
          </th>
        );
      }
    }
    if (rows.length > iPageSize) {
      aDividedRows = getTablePageRows(iPageSize, rows, sId);
    } else if (rows.length === 0) {
      var aDefaultRow = [];
      for (let i = 0; i < iColumn; i++) {
        if (aColumnDesc[i].visibleColumn) {
          if (bTabelSelection && i == 0) {
            aDefaultRow.push(
              <td className="RenderComponent-Table-td">
                <label></label>
              </td>
            );
          }
          aDefaultRow.push(
            <td className="RenderComponent-Table-td">
              <label>{(sRowData = "No Data Avaliable here")}</label>
            </td>
          );
        }
      }
      aDividedRows = (
        <tr className="RenderComponent-Table-tr">{aDefaultRow}</tr>
      );
    } else {
      aDividedRows = rows;
    }
    var startIndex;
    var endIndex;
    if(oPage[sId] && oPage[sId].currentPage){
      startIndex = (oPage[sId].currentPage - 1) * iPageSize + 1;
      endIndex = Math.min(oPage[sId].currentPage * iPageSize, value.rows.length);
    }
    return (
      <div className="table_header">
        {bToolBar ? (
          <div className="RenderComponent-toolbar">
            <h5>
              {sTableTitle} ({value.rows.length})
            </h5>
            <div>
              {bEditable && bAddRow ? (
                <>
                  <IconButton
                    onClick={(oEvent) => handleIncreaseRow(oDefaultTableData)}
                  >
                    <AddIcon
                      baseClassName="fas"
                      className="fa-plus-circle"
                      color="primary"
                    />
                  </IconButton>
                  <IconButton
                    onClick={(oEvent) => handleDecreaseRow(oDefaultTableData)}
                  >
                    <RemoveIcon
                      baseClassName="fas"
                      className="fa-plus-circle"
                      color="secondary"
                    />
                  </IconButton>
                </>
              ) : (
                ""
              )}
              <IconButton
                sx={{ marginRight: "1rem" }}
                onClick={(oEvent) => {
                  handleVisibleColumnsDialogConfig(oEvent, sId, aColumnDesc);
                }}
              >
                <SettingsIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </div>
          </div>
        ) : (
          ""
        )}
        <table
          id={sId}
          className={`RenderComponent-Table ${
            oTableOnClick[sId] ? "RenderComponent-Table-onClick" : ""
          }`}
        >
          <tr>{columns}</tr>
          {aDividedRows}
        </table>
        <div className="RenderComponent-footer">
          <div className="table-footer-container" style={{color:"gray"}}>
            {(oPage[sId] && oPage[sId].currentPage ) && (value.rows.length > iPageSize || rows.length > iPageSize) ? (
              <h4>{startIndex} to {endIndex} of {rows.length}</h4>
            ):("")}
          </div>
          <div>
            {(oPage[sId] && oPage[sId].currentPage ) && (value.rows.length > iPageSize || rows.length > iPageSize) ? (
              <div className="table-footer-container">
                <FontAwesomeIcon
                  className={`table_footer_icon ${oPage[sId].currentPage === 1 ? 'disabled' : ''}`}
                  onClick={() => handleChangePages(sId, "first")}
                  icon={faSquareCaretRight}
                  rotation={180}
                  size="xl"
                />
                <h4 className="Pages-value">
                  {oPage[sId].currentPage}/{totalPages}
                </h4>
                <FontAwesomeIcon
                  icon={faSquareCaretRight}
                  size="xl"
                  className={`table_footer_icon ${oPage[sId].currentPage === totalPages ? 'disabled' : ''}`}
                  onClick={() => handleChangePages(sId, "next")}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }

  // previous pagination format
  // <div>
  //               <IconButton
  //                 className="table_footer_icon"
  //                 onClick={() => handleChangePages(sId, "first")}
  //                 disabled={oPage[sId].currentPage === 1}
  //               >
  //                 <KeyboardDoubleArrowLeftIcon
  //                   baseClassName="fas"
  //                   className="fa-plus-circle"
  //                   color="inherit"
  //                   size="small"
  //                 />
  //               </IconButton>

  //               <IconButton
  //                 onClick={() => handleChangePages(sId, "previous")}
  //                 disabled={oPage[sId].currentPage === 1}
  //               >
  //                 <KeyboardArrowLeftIcon
  //                   baseClassName="fas"
  //                   className="fa-plus-circle"
  //                   color="inherit"
  //                   size="small"
  //                 />
  //               </IconButton>
  //               <span className="Pages-value">
  //                 {oPage[sId].currentPage}/{totalPages}
  //               </span>

  //               <IconButton
  //                 onClick={() => handleChangePages(sId, "next")}
  //                 disabled={oPage[sId].currentPage === totalPages}
  //               >
  //                 <KeyboardArrowRightIcon
  //                   baseClassName="fas"
  //                   className="fa-plus-circle"
  //                   color="inherit"
  //                   size="small"
  //                 />
  //               </IconButton>
  //               <IconButton
  //                 className="table_footer_icon"
  //                 onClick={() => handleChangePages(sId, "last")}
  //                 disabled={oPage[sId].currentPage === totalPages}
  //               >
  //                 <KeyboardDoubleArrowRightIcon
  //                   baseClassName="fas"
  //                   className="fa-plus-circle"
  //                   color="inherit"
  //                   size="small"
  //                 />
  //               </IconButton>
  // </div>

  /**
   *handle Table data sorted
   * @param {string} sFieldName
   * @param {string} sSortingOrder
   * @param {array} value
   * @param {object} oComponentModel
   * @returns
   */
  function handleTableSortData(
    sFieldName,
    sSortingOrder,
    value,
    oComponentModel,
    bDefaultSort
  ) {
    if (!bDefaultSort) {
      const newOrder = sortBy.order === "Asc" ? "Desc" : "Asc";
      setSortBy({ order: newOrder });
    }
    const { rows } = value;
    const sortedRows = [...rows].sort((a, b) => {
      const aValue = a[sFieldName];
      const bValue = b[sFieldName];

      const isNumeric = !isNaN(parseFloat(aValue)) && isFinite(aValue);
      if (isNumeric) {
        return sortBy.order === "Asc" ? aValue - bValue : bValue - aValue;
      }

      const dateA = parseDateString(aValue);
      const dateB = parseDateString(bValue);
      if (dateA && dateB) {
        return sortBy.order === "Asc" ? dateA - dateB : dateB - dateA;
      }

      return sortBy.order === "Asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    oComponentModel.value.rows = sortedRows;
  }

  function parseDateString(dateString) {
    if (dateString && typeof dateString === "string") {
      const match = dateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);
      if (match) {
        const [, year, month, day] = match;
        return new Date(year, month - 1, day);
      } else {
        return null;
      }
    }
    return null;
  }

  /**
   *handle row on change
   * @param {event} oEvent
   * @param {object} ColumnName
   * @param {object} value
   * @param {string} sTableID
   * @returns
   */
  function handleRowOnChangeData(
    oEvent,
    ColumnName,
    value,
    sTableID,
    oComponentModel,
    options
  ) {
    var data;
    var iRowNumber;
    if (ColumnName.inputType === "Mselect") {
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [oEvent.name]: options,
      }));
      const values = options.map((option) => option.value);
      data = values.join(",");
      iRowNumber = oEvent.name;
    } else {
      data = oEvent.currentTarget.value;
      iRowNumber = oEvent.currentTarget.id;
    }
    iRowNumber = parseInt(iRowNumber, 10);
    if (!value) {
      value = { rows: [] };
      for (var i = 0; i <= iRowNumber; i++) {
        if (i === +iRowNumber) {
          value.rows.push({});
          value.rows[iRowNumber][ColumnName.fieldName] = data;
        } else {
          value.rows.push({});
        }
      }
    } else if (+iRowNumber + 1 > value.rows.length) {
      value.rows.push({});
      value.rows[iRowNumber][ColumnName.fieldName] = data;
    } else {
      value.rows[iRowNumber][ColumnName.fieldName] = data;
    }
    var oTableComponent = props.UIData.find((oData) => {
      return oData.Component === "Table" && oData.uniqueId === sTableID;
    });
    oTableComponent.value = value;
    var oChangeLogic = oComponentModel.Logic.find(function (oLogic) {
      return oLogic.event === "onChange";
    });
    if (oChangeLogic) {
      handleOnChangeExecuteLogic(oEvent, oComponentModel, props.UIData);
    }

    props.resetUI([...props.UIData]);
  }

  function handleRowData(
    targetValue,
    selectedRow,
    ColumnName,
    value,
    sTableID,
    MultiSelect
  ) {
    var data = targetValue;
    if (MultiSelect) {
      var oTableComponent = props.UIData.find((oData) => {
        return oData.Component === "Table" && oData.uniqueId === sTableID;
      });
      oTableComponent.value.rows = data;
    } else {
      var iRowNumber = selectedRow;
      if (!value) {
        value = { rows: [] };
        for (var i = 0; i <= iRowNumber; i++) {
          if (i === +iRowNumber) {
            value.rows.push({});
            value.rows[iRowNumber][ColumnName] = data;
          } else {
            value.rows.push({});
          }
        }
      } else if (+iRowNumber + 1 > value.rows.length) {
        value.rows.push({});
        value.rows[iRowNumber][ColumnName] = data;
      } else {
        value.rows[iRowNumber][ColumnName] = data;
      }
      var oTableComponent = props.UIData.find((oData) => {
        return oData.Component === "Table" && oData.uniqueId === sTableID;
      });
      oTableComponent.value = value;
    }
    props.resetUI([...props.UIData]);
  }

  /**
   *handle row on Click
   * @param {event} oEvent
   * @param {string} sId
   * @returns
   */
  function handleTableRowOnClick(oEvent, oComponentModel) {
    oComponentModel.index = parseInt(oEvent.currentTarget.id);
    handleOnClick(oEvent, oComponentModel, props.UIData);
  }

  /**
   *get next page value
   * @param {string} sId
   * @returns
   */
  function getPageValue(sId) {
    var sPageValue;
    props.currentPage.UIComponents.find(function (oComponent) {
      if (oComponent.Component === "Table" && oComponent.uniqueId === sId) {
        oComponent.Logic.forEach((oLogic) => {
          if (oLogic.event === "onClick") {
            oLogic.component.forEach((oLogicComponent) => {
              if (oLogicComponent.data.id === "idNavigation") {
                oLogicComponent.properties.find((oProperty) => {
                  if (oProperty.id === "idPageList") {
                    sPageValue = oProperty.value;
                  }
                });
              }
            });
          }
        });
      }
    });
    return sPageValue;
  }

  /**
   * handle increase Table rows
   * @param {object} oDefaultTableData
   */
  function handleIncreaseRow(oDefaultTableData) {
    var value = oDefaultTableData.value;
    var oAddedRow = {};
    oDefaultTableData.aColumnDesc.forEach(function (oColumnDecription) {
      if (
        oColumnDecription.dataType === "Number" &&
        oColumnDecription.sequenceNumber &&
        oColumnDecription.defaultValue
      ) {
        oAddedRow[oColumnDecription.fieldName] =
          +oColumnDecription.defaultValue + value.rows.length;
      } else {
        oAddedRow[oColumnDecription.fieldName] = "";
      }
    });
    value.rows.push(oAddedRow);

    oDefaultTableData.checkValues.push({ all: "" });
    var oTableComponent = props.UIData.find((oComponentModel) => {
      return (
        oComponentModel.Component === "Table" &&
        oComponentModel.uniqueId === oDefaultTableData.sId
      );
    });
    oTableComponent.rows += 1;
    oTableComponent.value = value;
    var iTotalPages = Math.ceil(
      oTableComponent.value.rows.length / oDefaultTableData.iPageSize
    );
    var iCurrentPage = oPage[oDefaultTableData.sId].currentPage;
    if (iTotalPages === iCurrentPage) {
      setPage((prevState) => ({
        ...prevState,
        [oDefaultTableData.sId]: { ...prevState, currentPage: iCurrentPage },
      }));
    } else {
      setPage((prevState) => ({
        ...prevState,
        [oDefaultTableData.sId]: {
          ...prevState,
          currentPage: iCurrentPage + 1,
        },
      }));
    }
    props.resetUI([...props.UIData]);
  }

  /**
   * handle decrease Table rows
   * @param {object} oDefaultTableData
   */
  function handleDecreaseRow(oDefaultTableData) {
    var aSelectedRows = [];
    oDefaultTableData.checkValues.forEach(function (oCheckValue, index) {
      if (oCheckValue.all) {
        aSelectedRows.push(index);
      }
    });
    var aRecheckValues = oDefaultTableData.checkValues.filter(
      (oCheckValueRow) => !oCheckValueRow.all
    );
    if (aSelectedRows.length > 0) {
      var oTableComponent = props.UIData.find((oComponentModel) => {
        return (
          oComponentModel.Component === "Table" &&
          oComponentModel.uniqueId === oDefaultTableData.sId
        );
      });
      aSelectedRows.sort((a, b) => b - a);
      for (let i of aSelectedRows) {
        if (
          oTableComponent.RowLocking &&
          oTableComponent.RowLocking.length > 0
        ) {
          let aRowLockingData = oTableComponent.RowLocking;
          if (
            oTableComponent.value.rows[i][aRowLockingData[0].columnField] ===
            aRowLockingData[0].conditionValue
          ) {
            setSnackBar(true);
            setErrorCode(1001);
            setErrorMessage("You can't delete this row");
          } else if (
            oTableComponent.value.rows[i][aRowLockingData[0].columnField] !==
              "" &&
            oTableComponent.value.rows[i][aRowLockingData[0].columnField] <
              aRowLockingData[0].conditionValue
          ) {
            setSnackBar(true);
            setErrorCode(1001);
            setErrorMessage("You can't delete this row");
          } else {
            oTableComponent.value.rows.splice(i, 1);
          }
        } else {
          oTableComponent.value.rows.splice(i, 1);
        }
      }
      oTableComponent.rows -= aSelectedRows.length;
      oTableComponent.checkValues = aRecheckValues;

      oDefaultTableData.aColumnDesc.forEach(function (oColumnDecription) {
        if (
          oColumnDecription.dataType === "Number" &&
          oColumnDecription.sequenceNumber &&
          oColumnDecription.defaultValue
        ) {
          oTableComponent.value.rows.forEach(function (oEachValueRow, index) {
            if (index === 0) {
              oEachValueRow[oColumnDecription.fieldName] =
                +oColumnDecription.defaultValue;
            } else {
              oEachValueRow[oColumnDecription.fieldName] =
                +oColumnDecription.defaultValue + index;
            }
          });
        }
      });
      var iTotalPages = Math.ceil(
        oTableComponent.value.rows.length / oDefaultTableData.iPageSize
      );
      var iCurrentPage = oPage[oDefaultTableData.sId].currentPage;
      if (iTotalPages === iCurrentPage) {
        setPage((prevState) => ({
          ...prevState,
          [oDefaultTableData.sId]: { ...prevState, currentPage: iCurrentPage },
        }));
      } else if (iCurrentPage < iTotalPages) {
        setPage((prevState) => ({
          ...prevState,
          [oDefaultTableData.sId]: { ...prevState, currentPage: iCurrentPage },
        }));
      } else {
        setPage((prevState) => ({
          ...prevState,
          [oDefaultTableData.sId]: {
            ...prevState,
            currentPage: iCurrentPage - 1,
          },
        }));
      }
      var oChangeLogic = oTableComponent.Logic.find(function (oLogic) {
        return oLogic.event === "onChange";
      });
      if (oChangeLogic) {
        handleOnChangeExecuteLogic(null, oTableComponent, props.UIData);
      }
      props.resetUI([...props.UIData]);
    }
  }

  /**
   * Render tabs
   * @param {integer} iTabs
   * @param {array} aTabNames
   * @param {object} oComponentModel
   * @returns
   */
  function getDefaultTabs(iTabs, aTabNames, oComponentModel) {
    for (var i = 0; i < iTabs; i++) {
      aTabs.push(
        i === 0 ? (
          <div
            id={"idTab" + i}
            ref={React.createRef()}
            style={{ borderBottom: "solid #0854a0", color: "#0854a0" }}
            className="RenderComponent-tabDiv"
            onClick={(oEvent) => {
              handleTabClick(oEvent, iTabs, oComponentModel);
            }}
          >
            <div className="RenderComponent-wrapper">
              <label id={"idTabLabel" + i} className="RenderComponent-label">
                {aTabNames[i]}
              </label>
            </div>
            <div className="RenderComponent-Select"></div>
          </div>
        ) : (
          <div
            id={"idTab" + i}
            ref={React.createRef()}
            className="RenderComponent-tabDiv"
            onClick={(oEvent) => {
              handleTabClick(oEvent, iTabs, oComponentModel);
            }}
          >
            <div className="RenderComponent-wrapper">
              <label id={"idTabLabel" + i} className="RenderComponent-label">
                {aTabNames[i]}
              </label>
            </div>
            <div className="RenderComponent-Select"></div>
          </div>
        )
      );
    }
    return (
      <div className="RenderComponent-tabs" tabIndex="0">
        {aTabs}
      </div>
    );
  }

  function handleTabClick(oEvent, iTabs, oComponentModel, tabName) {
    if (oEvent) {
      props.changeTab(oEvent.currentTarget.textContent);
      refreshClickedTabs(iTabs);
      oEvent.target.style.color = "#0854a0";
      oEvent.currentTarget.style.borderBottom = "solid #0854a0";
      //hideOtherTabElements(oEvent.currentTarget.textContent, oComponentModel);
    }
  }

  // /**
  //  * Hide other tab components
  //  * @param {tabname} tabName
  //  * @param {object} oComponentModel
  //  */
  // function hideOtherTabElements(tabName, oComponentModel) {
  //     var oTabDisplayProperty = oComponentModel.properties.find(function (oProperty) {
  //         return oProperty.id === "idTabDisplayConfig";
  //     });
  //     oTabDisplayProperty.value[tabName].forEach(function (oTabComponent) {
  //         var oComponent = document.getElementById(oTabComponent.uniqueID + "div");
  //         if (!oTabComponent.visible) {
  //             oComponent.style.display = "none";
  //         } else {
  //             oComponent.style.display = "";
  //         }

  //     })
  // }

  /**
   * Refreshed clicked tabs
   * @param {integer} iTabs
   */
  function refreshClickedTabs(iTabs) {
    for (var i = 0; i < iTabs; i++) {
      document.getElementById("idTabLabel" + i).style.color = "";
      document.getElementById("idTab" + i).style.borderBottom = "";
    }
  }

  /**
   * refresh clicked tabs
   * @param {integer} iTabs
   */
  function selectDefaultTabs(iTabs) {
    if (document.getElementById("idTabLabel" + 0)) {
      for (var i = 0; i < iTabs; i++) {
        if (i == 0) {
          document.getElementById("idTabLabel" + i).style.color = "#0854a0";
          document.getElementById("idTab" + i).style.borderBottom =
            "solid #0854a0";
        } else {
          if (document.getElementById("idTabLabel" + i)) {
            document.getElementById("idTabLabel" + i).style.color = "";
            document.getElementById("idTab" + i).style.borderBottom = "";
          }
        }
      }
    }
  }

  /**
   * Render component
   * @param {object} oComponentModel
   * @returns
   */
  function renderComponent(oComponentModel, aUIComponents) {
    var { div: sDiv, Component: sText, UserValue } = oComponentModel;
    var CSS = { ...oComponentModel.css };
    var sType = null;
    var bReadOnly = false;
    var sValue;
    var bVisible = true;
    var sButtonClassName;
    let iTabIndex;
    let oDefaultTextAreaProperty;
    if (
      oComponentModel.Component === "Button" ||
      oComponentModel.Component === "Input" ||
      oComponentModel.Component === "DropDown"
    ) {
      iTabIndex = oComponentModel.properties.find(
        (oProperty) => oProperty.id === "idTabIndex"
      )?.value;
    }

    if (oComponentModel.Component === "Input") {
      oDefaultTextAreaProperty = oComponentModel.properties.find(
        (oProperty) => oProperty.id === "idInputTextArea"
      );
      var oDefaultInputValueProperty = oComponentModel.properties.find(
        (oProperty) => oProperty.id === "idInputDefault"
      );
      var oDefaultInputTypeProperty = oComponentModel.properties.find(
        (oProperty) => oProperty.id === "idInputTypeInput"
      );
      if (oDefaultInputValueProperty) {
        oComponentModel.inputDefault = oDefaultInputValueProperty;
      }
      oComponentModel.properties = oComponentModel.properties.filter(
        (oProperty) => oProperty.id !== "idInputDefault"
      );
      var sPlaceholder = oComponentModel.properties.find(function (oProperty){
        return oProperty.id === "idInputPlaceholderName";
      })?.value;

      var sDefaultInputValue = oDefaultInputValueProperty
        ? oDefaultInputValueProperty.value
        : null;
      var sDefaultInputType = oDefaultInputTypeProperty
        ? oDefaultInputTypeProperty.value
        : null;

      if (sDefaultInputValue) {
        if (sDefaultInputValue === "Today") {
          var dtoday = new Date();
          var dformattedToday;
          if (sDefaultInputType === "Date") {
            dformattedToday = dtoday.toISOString().split("T")[0];
            oComponentModel.UserValue = dformattedToday;
            UserValue = dformattedToday;
          } else {
            dformattedToday = dtoday.toISOString().slice(0, 16);
            oComponentModel.UserValue = dformattedToday;
            UserValue = dformattedToday;
          }
        } else if (sDefaultInputValue === "Tomorrow") {
          var dtomorrow = new Date();
          var dformattedTomorrow;
          if (sDefaultInputType === "Date") {
            dtomorrow.setDate(new Date().getDate() + 1);
            dformattedTomorrow = dtomorrow.toISOString().split("T")[0];
            oComponentModel.UserValue = dformattedTomorrow;
            UserValue = dformattedTomorrow;
          } else {
            dtomorrow.setDate(new Date().getDate() + 1);
            dformattedTomorrow = dtomorrow.toISOString().slice(0, 16);
            oComponentModel.UserValue = dformattedTomorrow;
            UserValue = dformattedTomorrow;
          }
        } else if (sDefaultInputValue === "{USERID}") {
          oComponentModel.UserValue = props.userId;
          UserValue = props.userId;
        } else {
          oComponentModel.UserValue = sDefaultInputValue;
          UserValue = sDefaultInputValue;
        }
      }
      var sInputDefault = oComponentModel.inputDefault;
      if (
        sInputDefault &&
        UserValue &&
        (sInputDefault.value === "Today" || sInputDefault.value === "Tomorrow")
      ) {
        var dateObject = new Date(UserValue);
        var dFormattedDate = dateObject.toISOString().split("T")[0];
        sValue = dFormattedDate;
      } else {
        sValue = UserValue;
      }
    } else if (oComponentModel.Component === "DropDown") {
      var oDefaultValueProperty = oComponentModel.properties.find(
        (oProperty) => oProperty.id === "idDropDownDefault"
      )?.value;
      if (oComponentModel.UserValue) {
        sValue = oComponentModel.UserValue;
      } else if (oDefaultValueProperty) {
        sValue = oDefaultValueProperty;
        oComponentModel.UserValue = sValue;
      } else if (
        (oDefaultValueProperty == undefined || oDefaultValueProperty == "") &&
        UserValue === ""
      ) {
        sValue = UserValue;
      }
    }
    //Properties
    if (oComponentModel.properties && oComponentModel.properties.length > 0) {
      var oTextProperty = oComponentModel.properties.find(function (oProperty) {
        return oProperty.title === "Text";
      });
      if (oTextProperty) {
        sText = oTextProperty.value;
      }

      var oTypeProperty = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idInputTypeButton";
      });

      var sButtonIcon = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idButtonIcon";
      })?.value;

      if (oTypeProperty) {
        var oSelectedType = oTypeProperty.options.find(function (oOption) {
          return oOption.value === oTypeProperty.value;
        });
        sButtonClassName = `btn_${oSelectedType.value}`;
      }

      //Label style

      var oLabelStyle = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idInputStyleLabel";
      });

      if (oLabelStyle) {
        CSS.fontWeight = oLabelStyle.value;
      }

      //Size
      var oSize = oComponentModel.properties.find(function (oProperty) {
        return oProperty.title === "Size";
      });

      if (oSize) {
        CSS.fontSize = oSize.value;
      }

      //Type
      var oType = oComponentModel.properties.find(function (oProperty) {
        return oProperty.title === "Type";
      });

      if (oType) {
        sType = oType.value;
      }

      var oReadOnly = oComponentModel.properties.find(function (oProperty) {
        return oProperty.title === "ReadOnly";
      });

      if (oReadOnly) {
        bReadOnly = oReadOnly.value;
      }

      if (oComponentModel.readOnly) {
        bReadOnly = oComponentModel.readOnly;
      }
      var oHiddenProperty = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.title === "Hidden";
        }
      );
      if (oHiddenProperty && oHiddenProperty.value) {
        if (oComponentModel.visible) {
          bVisible = true;
        } else {
          bVisible = false;
        }
      } else {
        var bVisible = oComponentModel.visible;
        if (bVisible === undefined) {
          bVisible = true;
        }
      }
    }

    var {
      left: sLeft,
      top: sTop,
      DivWidth: sDivWidth,
      DivHeight: sDivHeight,
      uniqueId: sId,
    } = oComponentModel;
    var oComponent;

    if (sDiv === "table") {
      var value = oComponentModel.value;
      var checkValues = oComponentModel.checkValues;
      var iRowVisible = oComponentModel.rows;
      var iColumnVisible = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idVisibleColumns";
        }
      );
      var oPageSize = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTablePageSize";
      });
      var oColumnConfig = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idColumnConfig";
      });
      var bEditable = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idEditableTable";
      });
      var sTableTitle = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTableTitle";
      });
      var bToolBar = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTableToolBar";
      });
      var bTabelSelection = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idTableSelection";
        }
      );
      var bAddRow = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTableAddRows";
      });
      var sSortingColumn = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idTableSortingField";
        }
      );
      var sSortingOrder = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTableSortingOrder";
      });
      if (bAddRow !== undefined) {
        bAddRow = bAddRow.value;
      } else {
        bAddRow = false;
      }
      if (bEditable.value && value.rows.length === 0) {
        var dDate = new Date();
        var dformattedToday;
        var dformattedTomorrow;
        oColumnConfig.value.forEach(function (oDatas) {
          if (oDatas.dataType === "Date") {
            if (oDatas.defaultValue === "Today") {
              dformattedToday = dDate.toISOString().split("T")[0];
              oDatas.defaultValue = dformattedToday;
            } else {
              dDate.setDate(new Date().getDate() + 1);
              dformattedTomorrow = dDate.toISOString().split("T")[0];
              oDatas.defaultValue = dformattedTomorrow;
            }
          } else if (oDatas.dataType === "datetime-local") {
            if (oDatas.defaultValue === "Today") {
              dformattedToday = dDate.toISOString().slice(0, 16);
              oDatas.defaultValue = dformattedToday;
            } else {
              dDate.setDate(new Date().getDate() + 1);
              dformattedTomorrow = dDate.toISOString().slice(0, 16);
              oDatas.defaultValue = dformattedTomorrow;
            }
          }
        });
      } else if (!bEditable.value && value.rows.length > 0) {
      }
      if (bTabelSelection.value) {
        if (checkValues.length < value.rows.length) {
          for (var i = 0; i < value.rows.length; i++) {
            checkValues.push({});
            checkValues[i]["all"] = "";
          }
        } else if (
          bEditable.value &&
          value.rows.length <= 1 &&
          checkValues.length === 0
        ) {
          for (var i = 0; i < iRowVisible; i++) {
            checkValues.push({});
            checkValues[i]["all"] = "";
          }
        }
      }
      if (sSortingOrder.value && !sortBy.order && sSortingColumn.value) {
        const { rows } = value;
        const sortedRows = [...rows].sort((a, b) => {
          const aValue = a[sSortingColumn.value];
          const bValue = b[sSortingColumn.value];
          if (aValue && bValue) {
            const isNumeric = !isNaN(parseFloat(aValue)) && isFinite(aValue);
            if (isNumeric) {
              return sSortingOrder.value === "Asc"
                ? aValue - bValue
                : bValue - aValue;
            }

            const dateA = parseDateString(aValue);
            const dateB = parseDateString(bValue);
            if (dateA && dateB) {
              return sSortingOrder.value === "Asc"
                ? dateA - dateB
                : dateB - dateA;
            }

            return sSortingOrder.value === "Asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          }
        });

        value.rows = sortedRows;
        setSortBy({ order: sSortingOrder.value });
      } else if (sSortingOrder.value && sortBy.order && sSortingColumn.value) {
        let bDefaultSort = true;
        handleTableSortData(
          sSortingColumn.value,
          sSortingOrder,
          value,
          oComponentModel,
          bDefaultSort
        );
      }
      oComponentModel.value = value;
      oComponentModel.checkValues = checkValues;
      var dropDownOptions = getDropDownValues(oColumnConfig.value);
      var oDefaultTableData = {
        sId: sId,
        iRow: iRowVisible,
        iColumn: iColumnVisible.value,
        iPageSize: +oPageSize.value,
        aColumnDesc: oColumnConfig.value,
        bEditable: bEditable.value,
        value: value,
        checkValues: checkValues,
        dropDownOptions: dropDownOptions,
        sTableTitle: sTableTitle.value,
        bToolBar: bToolBar.value,
        bTabelSelection: bTabelSelection.value,
        oComponentModel: oComponentModel,
        bAddRow: bAddRow,
        sSortingColumn: sSortingColumn,
        sSortingOrder: sSortingOrder.value,
      };
      oComponent = getDefaultTable(oDefaultTableData);
    } else if (sDiv === "barchart") {
      var oComponent = getDefaultChartComponent(
        oComponentModel,
        handleOnClick,
        aUIComponents
      );
    } else if (sDiv === "piechart") {
      var oComponent = getDefaultChartComponent(
        oComponentModel,
        handleOnClick,
        aUIComponents
      );
    } else if (sDiv === "Gantt") {
      var oProperties = {};

      //set selected view
      const handleChangeView = (event) => {
        const sView1 = event.target.value;
        setView(sView1);
      };

      //set selected task for popup
      const handleChangeTask = (task) => {
        setSelectedTask(task);
      };

      //FindLogic Property
      const findGanttProperties = (oComponentModel) => {
        const idMapGanttFields = oComponentModel.Logic.flatMap(
          (allLogics) => allLogics.component
        )
          .find((allComponent) => allComponent.data.id === "idMapGanttChart")
          ?.properties?.find((oProps) => oProps.id === "idMapGanttFields");

        return idMapGanttFields ? idMapGanttFields.value : null;
      };
      oProperties = findGanttProperties(oComponentModel);
      var oComponent = GetDefaultGanttChart(
        oComponentModel,
        handleChangeView,
        sView,
        oProperties,
        props.userId,
        sSelectedTask,
        handleChangeTask,
        containerRef
      );
    } else if (sDiv === "G_Gantt") {
      var oProperties = {};
      const findGanttProperties = (oComponentModel) => {
        const idMapGanttFields = oComponentModel.Logic.flatMap(
          (allLogics) => allLogics.component
        )
          .find(
            (allComponent) => allComponent.data.id === "idMapGoogleGanttChart"
          )
          ?.properties?.find((oProps) => oProps.id === "idMapG_GanttFields");

        return idMapGanttFields ? idMapGanttFields.value : null;
      };
      oProperties = findGanttProperties(oComponentModel);
      var oComponent = GetDefaultG_GanttChart(
        oComponentModel,
        oProperties,
        handleOnClick,
        aUIComponents
      );
    } else if (sDiv === "tabs") {
      var iTabs = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTabNumber";
      });
      var oPropertyTabs = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idTabNames";
      });
      oComponent = getDefaultTabs(
        iTabs.value,
        oPropertyTabs.value,
        oComponentModel
      );
    } else if (sDiv === "mapLocation") {
      let targetLocation;
      if (oComponentModel.center) {
        targetLocation = oComponentModel.center;
      }
      var bLocationTrack = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idLocationTrack";
        }
      )?.value;
      var iSeconds = oComponentModel.properties.find(function (oProperty) {
        return oProperty.id === "idSeconds";
      })?.value;
      iSeconds = parseInt(iSeconds, 10) || 0;
      if (iMapSeconds !== iSeconds) {
        setMapSeconds(iSeconds);
      }
      if (oComponentModel.Logic.length > 0) {
        const isOnTrackPositionAvailable = oComponentModel.Logic.some(
          (item) => item.event === "onTrackPosition"
        );
        if (bLoadFunction !== isOnTrackPositionAvailable) {
          setLoadFunction(isOnTrackPositionAvailable);
        }
        if (oMapComponent !== oComponentModel) {
          setMapComponent(oComponentModel);
        }
      }
      oComponent = (
        <ArcGISMap
          aTargetLocations={targetLocation}
          bLocationTrack={bLocationTrack}
          oComponentModel={oComponentModel}
          positionChange={handleCallOnWachPosition}
        />
      );
    } else if (sDiv === "select") {
      var aOptions = [];
      var aChildElement = [];
      var oPropertyOptions = oComponentModel.properties.find(
        function (oProperty) {
          return oProperty.id === "idInputOptions";
        }
      );
      aOptions = oPropertyOptions.value.split("\n");
      if (oComponentModel.value && oComponentModel.value !== "") {
        aOptions = oComponentModel.value.split("\n");
      }

      aOptions.forEach(function (sOption, i) {
        aChildElement.push(
          React.createElement(
            "option",
            { key: "option" + i, value: sOption },
            sOption
          )
        );
      });
      var sDynamicDiv = bReadOnly ? "input" : sDiv;
      oComponent = React.createElement(
        sDiv,
        {
          id: sId,
          key: sId,
          type: sType,
          value: sValue,
          tabIndex: iTabIndex,
          disabled: bReadOnly,
          style: { ...CSS, display: bVisible ? "block" : "none" },
          className: "input-select",
          onChange: (oEvent) => {
            handleOnChange(oEvent, oComponentModel, aUIComponents);
            handleOnChangeExecuteLogic(oEvent, oComponentModel, aUIComponents);
          },
        },
        aChildElement
      );
    } else if (sDiv === "input" && sType === "InputHelp") {
      var input = React.createElement(
        sDiv,
        {
          className: "input-fields",
          id: sId,
          key: sId,
          style: CSS,
          type: sType,
          placeholder:sPlaceholder,
          value: sValue,
          readOnly: bReadOnly,
          onChange: (oEvent) => {
            handleOnChange(oEvent, oComponentModel, aUIComponents);
          },
          ...(sDiv === "input"
            ? {
                onKeyDown: (oEvent) => {
                  if (oEvent.key === "Enter") {
                    handleOnEnter(oEvent, oComponentModel, aUIComponents);
                  }
                },
              }
            : {}),
        },
        createChild(sDiv, sText)
      );
      var help = React.createElement(
        "button",
        {
          tabIndex: iTabIndex,
          onClick: (oEvent) => {
            handleOnClick(oEvent, oComponentModel, aUIComponents);
          },
          style: {
            width: "20px",
            background: "none",
            color: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "5px",
            border: "none",
            cursor: "pointer",
            marginLeft: "-25px",
          },
        },
        " ?"
      );
      oComponent = React.createElement(
        "div",
        {
          style: {
            width: "auto",
            height: "100%",
            display: "flex",
            alignContent: "center",
          },
        },
        input,
        help
      );
    } else if (sDiv === "button" && sType === "ValueHelp") {
      oComponent = React.createElement(
        sDiv,
        {
          id: sId,
          key: sId,
          tabIndex: iTabIndex,
          readOnly: bReadOnly,
          className:
            sDiv === "input"
              ? bReadOnly
                ? "input-fields read-only"
                : "input-fields"
              : sDiv === "button"
              ? sButtonClassName
              : "",
          value: sValue,
          style: { ...CSS, display: bVisible ? "block" : "none" },
          onClick: (oEvent) => {
            handleOnClick(oEvent, oComponentModel, aUIComponents);
            handleDialogConfig("Input", sId);
          },
        },
        createChild(sDiv, sText)
      );
    } else if (
      sDiv === "input" &&
      oDefaultTextAreaProperty &&
      oDefaultTextAreaProperty.value
    ) {
      oComponent = React.createElement(
        "textarea",
        {
          id: sId,
          key: sId,
          type: sType,
          tabIndex: iTabIndex,
          readOnly: bReadOnly,
          className: bReadOnly ? "input-fields read-only" : "input-fields",
          value: sValue,
          style: { ...CSS, display: bVisible ? "block" : "none" },
          onChange: (oEvent) => {
            handleOnChange(oEvent, oComponentModel, aUIComponents);
            handleOnChangeExecuteLogic(oEvent, oComponentModel, aUIComponents);
          },
          ...(sDiv === "input"
            ? {
                onKeyDown: (oEvent) => {
                  if (oEvent.key === "Enter") {
                    handleOnEnter(oEvent, oComponentModel, aUIComponents);
                  }
                },
              }
            : {}),
        },
        createChild(sDiv, sText)
      );
    } else if (sDiv === "input" && sType === "file") {
      let componetProperties = { sId, bVisible, bReadOnly, CSS };
      oComponent = (
        <CustomFileUploader
          componetProperties={componetProperties}
          oComponentModel={oComponentModel}
          aUIComponents={aUIComponents}
        />
      );
    }  else if(sDiv === "button"){
        const icon = getIconByAction(sButtonIcon);
        const iconColor = getIconColor(sType);
        oComponent = React.createElement(
          sDiv,
          {
            id: sId,
            key: sId,
            type: sType,
            tabIndex: sDiv !== "label" ? iTabIndex : undefined,
            className: sDiv === "button" ? sButtonClassName : "",
            value: sValue,
            style: {
              ...CSS,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            },
            onClick: (oEvent) => {
              handleOnClick(oEvent, oComponentModel, aUIComponents);
            },
          },
          <>
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                size="lg"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  marginRight:"8px",
                  color: iconColor,
                }}
              />
            )}
            <span style={{flex: 1, textAlign: "center" }}>
              {createChild(sDiv, sText)}
            </span>
          </>
        );
    } else {
      oComponent = React.createElement(
        sDiv,
        {
          id: sId,
          key: sId,
          type: sType,
          tabIndex: sDiv !== "label" ? iTabIndex : undefined,
          readOnly: bReadOnly,
          placeholder:
            sDiv === "input"
            ? sPlaceholder
            : "",
          className:
            sDiv === "input"
              ? bReadOnly
                ? "input-fields read-only"
                : "input-fields"
              : sDiv === "button"
              ? sButtonClassName
              : "",
          value: sValue,
          style: { ...CSS, display: bVisible ? "block" : "none" },
          onClick: (oEvent) => {
            handleOnClick(oEvent, oComponentModel, aUIComponents);
          },
          onChange: (oEvent) => {
            handleOnChange(oEvent, oComponentModel, aUIComponents);
            handleOnChangeExecuteLogic(oEvent, oComponentModel, aUIComponents);
          },
          ...(sDiv === "input"
            ? {
                onKeyDown: (oEvent) => {
                  if (oEvent.key === "Enter") {
                    handleOnEnter(oEvent, oComponentModel, aUIComponents);
                  }
                },
              }
            : {}),
        },
        createChild(sDiv, sText)
      );
    }

    //Mainlogic to reder the dropzone
    oComponent = (
      <>
        <div
          id={sId + "div"}
          className="RenderComponent-Resizeable"
          style={{
            left: sLeft,
            top: sTop,
            width: sDivWidth,
            height: sDivHeight,
          }}
        >
          {oComponent}
        </div>
      </>
    );

    if (sDiv === "table") {
      var oTableComponent = oComponent.props.children;
      if (oTableComponent) {
        oTableComponent.props.style.border = "1px solid rgba(128, 128, 128, 1)";
        oTableComponent.props.style.borderRadius = "10px";
      }
    }

    return oComponent;
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar(false);
  };

  return (
    <div>
      <div className="rendercomponent-busyindicator">
        {bLoading && (
          <div className="overlay">
            <ScaleLoader
              color={"#033146"}
              loading={bLoading}
              aria-label="Loading Spinner"
              data-testid="loader"
              height={25}
              width={6}
            />
          </div>
        )}
      </div>
      <Snackbar
        open={bSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackBar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity={sMessageType}
          sx={{ width: "100%" }}
        >
          {`${iErrorCode} - ${sErrorMessage}`}
        </Alert>
      </Snackbar>
      <ExecuteConfirmation
        open={bOpenConfirmationDialog}
        message={sConfirmationMessage}
        close={closeConfirmationDialog}
        confirm={bEnableOk}
      />
      <ValueHelpDialog
        open={bOpenValueHelpDialog}
        oColumnData={ovalueHelpColumnData}
        avalueHelpData={aValueHelpTableData}
        sTableName={sValueHelpTableName}
        handleClose={handleClose}
        parent={oParent}
        sValueHelpType={sValueHelpType}
        handleTable={handleRowData}
        sInputID={sInputID}
        UIData={props.UIData}
        onChange={props.onChange}
        oLogic={handleValueHelpLogic}
        multiSelect={bValueHelpMultiSelect}
      />
      <VisibleColumnsDialog
        open={bOpenVisibleColumnsDialog}
        handleClose={handleClose}
        aColumnsDesc={aTableColumnsDesc}
      />
      {aRenderComponents}
    </div>
  );
};
