/**
 * converstion for preview
 * @param {array} | ComponentModel
 */
export function convertUIDataForPreview(aUIData, previewRenderMobileView) {
  var oPreviewRendere;
  if (previewRenderMobileView) {
    oPreviewRendere = previewRenderMobileView;
  } else {
    oPreviewRendere = document
      .getElementById('PreviewRenderer')
      .getBoundingClientRect();
  }

  aUIData.forEach(function (oComponentModel) {
    // iPercentage = 1;
    // //Width
    // if (iScreenWidth > oComponentModel.ScreenWidth) {
    //     iPercentage = ((iScreenWidth - oComponentModel.ScreenWidth) / iScreenWidth) * 100;

    // } else {
    //     //iPercentage = ((oComponentModel.ScreenWidth - iScreenWidth) / oComponentModel.ScreenWidth) * 100;
    //     iPercentage = ((iScreenWidth - oComponentModel.ScreenWidth) / iScreenWidth) * 100;
    //     iPercentage = iPercentage / 2;
    // }

    // if (iPercentage !== 1) {
    //     oComponentModel.DivWidth = oComponentModel.DivWidth * (1 + (iPercentage / 100));
    // }
    // //oComponentModel.DivWidth = oComponentModel.DivWidth * (1 + (iPercentage / 100));
    // //Height
    // if (iScreenHeight > oComponentModel.ScreenHeight) {
    //     iPercentage = ((iScreenHeight - oComponentModel.ScreenHeight) / iScreenHeight) * 100;

    // } else {
    //     //iPercentage = ((oComponentModel.ScreenHeight - iScreenHeight) / oComponentModel.ScreenHeight) * 100;
    //     iPercentage = ((iScreenHeight - oComponentModel.ScreenHeight) / iScreenHeight) * 100;
    //     iPercentage = iPercentage / 2;
    // }

    // if (iPercentage !== 1) {
    //     oComponentModel.DivHeight = oComponentModel.DivHeight * (1 + (iPercentage / 100));
    // }
    var buttonWidthInitial = oComponentModel.DivWidth;
    var buttonHeighInitial = oComponentModel.DivHeight;
    var ScreenWidthInitial = oComponentModel.DropZoneWidth;
    var ScreenHeightInitial = oComponentModel.DropZoneHeight;
    var ScreenHeightFinal = oPreviewRendere.height;
    var ScreenWidthFinal = oPreviewRendere.width;
    var buttonWidthFinal =
      (buttonWidthInitial * ScreenWidthFinal) / ScreenWidthInitial;
    var buttonHeightFinal =
      (buttonHeighInitial * ScreenHeightFinal) / ScreenHeightInitial;
    oComponentModel.DivWidth = buttonWidthFinal;
    oComponentModel.DivHeight = buttonHeightFinal;

    // oComponentModel.DivWidth = oComponentModel.DivWidth * (1 + (iPercentage / 100));
    // oComponentModel.DivHeight = oComponentModel.DivHeight * (1 + (iPercentage / 100));
    //Left
    oComponentModel.left =
      oPreviewRendere.width /
      (oComponentModel.DropZoneWidth /
        (oComponentModel.left - oComponentModel.DropZoneLeft));
    oComponentModel.left = oComponentModel.left + oPreviewRendere.left;

    //top
    oComponentModel.top =
      oPreviewRendere.height /
      (oComponentModel.DropZoneHeight /
        (oComponentModel.top - oComponentModel.DropZoneTop));
    oComponentModel.top = oComponentModel.top + oPreviewRendere.top;

    oComponentModel.DropZoneWidth = oPreviewRendere.width;
    oComponentModel.DropZoneLeft = oPreviewRendere.left;
    oComponentModel.DropZoneHeight = oPreviewRendere.height;
    oComponentModel.DropZoneTop = oPreviewRendere.top;
  });

  return aUIData;
}
