import React, { useState, useEffect } from 'react';

//Material UI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';

export const MrpCalculator = (props) => {
    const [sMaterial, setMaterial] = useState("");
    const [smaterialMaster, setMaterialMaster] = useState("");
    const [sSalesOrder, setSalesOrder] = useState("");
    const [sInventory, setInventory] = useState("");
    const [sPurchaseRequisition, setPurchaseRequisition] = useState("");
    const [sPlannedOrder, setPlannedOrder] = useState("");
    
    const [selectTab, setSelectTab] = useState("General");
    var aFields = [];
    var tabNames=["General","MaterialMaster","SalesOrder","Inventory","PurchaseRequisition","PlannedOrder"]
    var oUserInput={};

 /**
 * render Fields respective selecTab
 * @param {string} selectTab | current Select Tab Name
 * @param {array} aFields| array of label,inputField respect Tab
 */   
function buildTabFileds(selectTab){
    switch(selectTab) {
        case "General":
            aFields.push(
                    <><FormLabel sx={{ marginRight: "20px" }}>Material:</FormLabel>
                            <select  onChange={(oEvent)=>setMaterial(oEvent.currentTarget.value)} value={sMaterial} className="propertiesValue-Select"
                        >
                            {props.inputFieldNames.length>0 && props.inputFieldNames.map(function (sOption) {
                                return <option key={sOption.value} value={sOption.value}>{sOption.value}</option>;
                            })}
                        </select></>)
            
            break;
        case "MaterialMaster":
            aFields.push(<>
                        <FormLabel sx={{ marginRight: "20px" }}>{selectTab}:</FormLabel>
                        <input  onChange={(oEvent)=>setMaterialMaster(oEvent.currentTarget.value)}  value={smaterialMaster} className="propertiesValue-Input" />
                         </>)
                break;
        case "SalesOrder":
            aFields.push(<>
                        <FormLabel sx={{ marginRight: "20px" }}>{selectTab}:</FormLabel>
                        <input  onChange={(oEvent)=>setSalesOrder(oEvent.currentTarget.value)} value={sSalesOrder} className="propertiesValue-Input" />
                        </>)
                break;
        case "Inventory":
            aFields.push(<>
                        <FormLabel sx={{ marginRight: "20px" }}>{selectTab}:</FormLabel>
                        <input  onChange={(oEvent)=>setInventory(oEvent.currentTarget.value)} value={sInventory} className="propertiesValue-Input" />
                        </>)
                break;
        case "PurchaseRequisition":
                aFields.push(<>
                            <FormLabel sx={{ marginRight: "20px" }}>{selectTab}:</FormLabel>
                            <input  onChange={(oEvent)=>setPurchaseRequisition(oEvent.currentTarget.value)} value={sPurchaseRequisition} className="propertiesValue-Input" />
                            </>)
                break;
        case "PlannedOrder":
            aFields.push(<>
                        <FormLabel sx={{ marginRight: "20px" }}>{selectTab}:</FormLabel>
                        <input  onChange={(oEvent)=>setPlannedOrder(oEvent.currentTarget.value)} value={sPlannedOrder} className="propertiesValue-Input" />
                        </>)
                    break;
        default:
            return null;
    }
}


oUserInput={
      SalesOrder: {
          table: sSalesOrder,
        },
        Inventory: {
          table: sInventory,
        },
        MaterialMaster: {
          table: smaterialMaster,
        },
        PurchaseRequisition: {
          table: sPurchaseRequisition,
        },
        PlannedOrder: {
            table: sPlannedOrder,
          },
        material: sMaterial
}
useEffect(()=>{  
    if(Object.keys(props.userSelectInput).length>0){
        var materialInputName=props.userSelectInput.material;
        if( !sMaterial && materialInputName !==""){
            setMaterial(materialInputName)
        }
       
        var inventoryTableName=props.userSelectInput.Inventory.table;
        if( !sInventory && inventoryTableName !==""){
            setInventory(inventoryTableName)
        }
        var salesOrderTableName=props.userSelectInput.SalesOrder.table
        if( !sSalesOrder && salesOrderTableName!==""){
            setSalesOrder(salesOrderTableName)
        }
        var materialMasterTableName=props.userSelectInput.MaterialMaster.table
        if( !smaterialMaster && materialMasterTableName!==""){
            setMaterialMaster(materialMasterTableName)
        }
        var purchaseTableName=props.userSelectInput.PurchaseRequisition.table
        if( !sPurchaseRequisition && purchaseTableName!==""){
            setPurchaseRequisition(purchaseTableName)
        }
        var plannedTableName=props.userSelectInput.PlannedOrder.table
        if( !sPlannedOrder && plannedTableName!==""){
            setPlannedOrder(plannedTableName)
        }
        
    }  
},[props.userSelectInput, sMaterial, sInventory, sSalesOrder, smaterialMaster, sPurchaseRequisition, sPlannedOrder] )


    /**
     * Event handler for changeTab
     */
    function handleChangeTab(oEvent,val) {
       setSelectTab(val)
    }

    /**
     * Event handler for update
     * @param {*} oEvent 
     */
    function handleUpdate(oEvent) {
        props.update(oUserInput);
    }

    return (

        <div>
            <Dialog 
             PaperProps={{
                sx: {
                  maxWidth: 900,
                  maxHeight: 900
                }
              }}
                open={props.open}>
                <DialogTitle>
                    Mrp configuration
                </DialogTitle>
                <DialogContent>
                    <Box
                         sx={{ borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs 
                            value={selectTab}
                            onChange={handleChangeTab}>
                            {tabNames.map((oTab) => (<Tab  value={oTab }  label={oTab} />))}
                        </Tabs>
                    </Box>
                    <Box 
                        sx={{ marginTop: "3%" }}
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center" >

                        {aFields}
                        {buildTabFileds(selectTab)}
                    </Box>   
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button onClick={props.close}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}