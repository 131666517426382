import React from "react";

//Material UI Components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export const ConfirmDialog = (props) => {
  /**
   * Event handler to cancel the delete operation
   */
  function handleCancel() {
    props.cancel();
  }

  /**
   * Event handler to delete confirmation
   */
  function handleConfirm() {
    props.confirm();
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>
        {"Delete "}{props.logicType} - {props.logic.label} {"?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Do you want to delete the {props.logicType} ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleConfirm}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};
