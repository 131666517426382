export const MapComponentVisibility = (aProperties, oParams, oLogicDatas) => {
  var aData;
  var aFilteredData;

  if
  (Array.isArray(oParams.aRetriveData)) {
    aData = oParams.aRetriveData;
  
  } else if (oParams.mapSingle){
    aData=[]
    aData.push(oParams.mapSingle)
  }
  else if (Array.isArray(oParams)) {
    var aFormattedData = [];
    oParams.forEach(function (param) {
      var oNewData = {};
      oNewData[param.field] = param.value;
      aFormattedData.push(oNewData);
    });
    if (aFormattedData.length) {
      aData = aFormattedData;
    }
  }

  var oMapProperty = aProperties.find(function (oProperty) {
    return oProperty.id === "idMapComonentsVisibility";
  });
  var oPageDropDown = aProperties.find(function (oProperty) {
    return oProperty.id === "idPageList";
  });
  var aSelecedPageUIData =
    oLogicDatas.allUIData[oPageDropDown.value].UIComponents;
  var aWhereConditions = oMapProperty.value.whereConditions;
  aWhereConditions.forEach(function (oCondition) {
    switch (oCondition.type) {
      case "Number":
        oCondition.value = +oCondition.value;
        break;
      case "Date":
        oCondition.value = new Date(oCondition.value);
        break;
      default:
        break;
    }
  });
  if (aData && aWhereConditions.length > 0) {
    aFilteredData = aData.filter((oItem) =>
      executeConditions(oItem, aWhereConditions)
    );
  }

  if (aWhereConditions.length < 1 || (aFilteredData && aFilteredData.length > 0)) {
    aSelecedPageUIData.forEach(function (oComponentModel) {
      oMapProperty.value.components.forEach(function (oMapComponent) {
        if (oComponentModel.uniqueId === oMapComponent.componentId) {
          oComponentModel.visible = oMapComponent.visible;
          if (oComponentModel.Component === "Table") {
            var oColumnConfig = oComponentModel.properties.find(
              function (oProperty) {
                return oProperty.id === "idColumnConfig";
              }
            );
              oComponentModel.NonEditable = oMapComponent.readOnly
                
          } else {
            oComponentModel.readOnly = oMapComponent.readOnly;
          }
        }
      });
    });
  }
  oLogicDatas.resetAppUI({...oLogicDatas.allUIData});
};

/**
 * Execute all Conditions
 * @param {object} oItem
 * @param {Array} aConditions
 * @returns
 */
function executeConditions(oItem, aConditions) {
  let bResult = false;

  for (let i = 0; i < aConditions.length; i++) {
    const { field, condition, value, type} = aConditions[i];
    const bCurrentConditionSatisfied = checkCondition(
      oItem,
      field,
      condition,
      value,
      type
    );

    if (i === 0) {
      bResult = bCurrentConditionSatisfied;
    } else {
      if (aConditions[i - 1].logicCondition === "OR") {
        bResult = bResult || bCurrentConditionSatisfied;
      } else {
        bResult = bResult && bCurrentConditionSatisfied;
      }
    }
  }

  return bResult;
}

/**
 * checking each Condition
 * @param {object} oItem
 * @param {string} field
 * @param {string} condition
 * @param {string} type
 * @returns
 */
function checkCondition(oItem, field, condition, value, type) {
  const fieldValue = oItem[field];
  switch (condition) {
    case "$eq":
      return fieldValue === value;
    case "$ne":
      if (fieldValue !== value) {
        return true;
      }
      break;
    case "$lt":
      if (fieldValue < value) {
        return true;
      }
      break;
    case "$gt":
      if (fieldValue > value) {
        return true;
      }
      break;
    case "$gte":
      if (fieldValue >= value) {
        return true;
      }
      break;
    case "$lte":
      if (fieldValue <= value) {
        return true;
      }
      break;
    case "$icontains":
      if (typeof fieldValue === 'string' && typeof value === 'string') {
        if (fieldValue.toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
      }else{
        return false
      }
      break;
    case "$not_null":
        if (fieldValue !== '' && fieldValue !==null && fieldValue !==undefined) {
          return true;
        }
        break;
    case "$isnull":
        if (oItem[field]===undefined && value ==='') {
          return true;
        }
        break; 
    default:
      return false;
  }
}
