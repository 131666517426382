import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const WhereConditionDialog = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (index) => {
    const aNewSelectedRows = [...selectedRows];
    aNewSelectedRows[index] = !aNewSelectedRows[index];
    setSelectedRows(aNewSelectedRows);
  };

  const aConditions = [
    { value: "", label: "" },
    { value: "$eq", label: "Equal To" },
    { value: "$ne", label: "Not Equal To" },
    { value: "$gt", label: "Greater Than" },
    { value: "$lt", label: "Less Than" },
    { value: "$gte", label: "Greater Than or Equal To" },
    { value: "$lte", label: "Less Than or Equal To" },
    { value: "$contains", label: "Like" },
    { value: "$between", label: "Between" }
  ];

  const aDateConditions = [
    { value: "", label: "" },
    { value: "Current Date" },
    { value: "One Week from Current Date" },
    { value: "End of the Current Month" },
    { value: "Beginning of the Current Month" },
    { value: "Beginning of the year" },
    { value: "End of the Current year" },
  ];

  const handleEvent = (event, index, skey) => {
    const { value } = event.target;
    const oUpdatedRows = [...props.rows.whereConditions];
    oUpdatedRows[index][skey] = value;
    props.update({ ...props.rows, whereConditions: oUpdatedRows });
  };

  function handleIncreaseRow() {
    const aUpdatedRows = [...props.rows.whereConditions];
    aUpdatedRows.push({
      field: "",
      condition: "",
      value: "",
      hardcoded: "",
      from: "",
      to: "",
      type: "",
    });
    props.update({ ...props.rows, whereConditions: aUpdatedRows });
    setSelectedRows([...selectedRows, false]);
  }

  function handleRemoveRow() {
    const aUpdatedRows = props.rows.whereConditions.filter(
      (_, index) => !selectedRows[index]
    );
    props.update({ ...props.rows, whereConditions: aUpdatedRows });
    setSelectedRows(selectedRows.filter((_, index) => !selectedRows[index]));
  }

  return (
    <div>
      <IconButton>
        <AddIcon
          onClick={handleIncreaseRow}
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      <IconButton>
        <RemoveIcon
          onClick={handleRemoveRow}
          baseClassName="fas"
          className="fa-plus-circle"
          color="secondary"
        />
      </IconButton>
      <Box>
        <table id="idTableConfiguration" className="RenderComponent-Table">
          <thead>
            <tr>
              <th></th>
              <th className="RenderComponent-Table-th">Field</th>
              <th className="RenderComponent-Table-th">Condition</th>
              <th className="RenderComponent-Table-th">Value</th>
              <th className="RenderComponent-Table-th">HardCoded</th>
              <th className="RenderComponent-Table-th">From</th>
              <th className="RenderComponent-Table-th">To</th>
              <th className="RenderComponent-Table-th">Type</th>
            </tr>
          </thead>
          <tbody>
            {props.rows.whereConditions.map((row, index) => (
              <tr key={index}>
                <td className="RenderComponent-Table-td">
                  <input
                    type="checkbox"
                    checked={selectedRows[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    name={index}
                    className="RenderComponent-Table-input"
                    value={row.field}
                    onChange={(oEvent) => {
                      handleEvent(oEvent, index, "field");
                    }}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <select
                    className="RenderComponent-Table-input"
                    value={row.condition}
                    onChange={(event) => {
                      handleEvent(event, index, "condition");
                    }}
                  >
                    {aConditions.map((odatas) => (
                      <option key={odatas.value} value={odatas.value}>
                        {odatas.label}
                      </option>
                    ))}
                    {props.inputs.map(function (input) {
                        return (
                          <option key={input} value={input}>
                            {input}
                          </option>
                        );
                    })}
                  </select>
                </td>
                <td className="RenderComponent-Table-td">
                  {props.inputs.length > 0 ? (
                    <select
                      className="RenderComponent-Table-input"
                      value={row.value}
                      name={index}
                      disabled={row.hardcoded || row.from? true : false}
                      onChange={(oEvent) => {
                        handleEvent(oEvent, index, "value");
                      }}
                    >
                      {props.inputs.map(function (input) {
                        return (
                          <option key={input} value={input}>
                            {input}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <input
                      name={index}
                      className="RenderComponent-Table-input"
                      value={row.value}
                      onChange={(oEvent) => {
                        handleEvent(oEvent, index, "value");
                      }}
                    />
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    name={index}
                    className="RenderComponent-Table-input"
                    value={row.hardcoded}
                    disabled={row.from ? true : false}
                    onChange={(oEvent) => {
                      handleEvent(oEvent, index, "hardcoded");
                    }}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  {row.type === 'Date' ? (
                    <select
                      className="RenderComponent-Table-input"
                      value={row.from}
                      onChange={(event) => {
                        handleEvent(event, index, "from");
                      }}
                    >
                      {aDateConditions.map((odatas) => (
                        <option key={odatas.value} value={odatas.value}>
                          {odatas.value}
                        </option>
                      ))}
                      {props.inputs.map(function (input) {
                        return (
                          <option key={input} value={input}>
                            {input}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <input
                      name={index}
                      className="RenderComponent-Table-input"
                      type={row.type}
                      value={row.from}
                      onChange={(oEvent) => {
                        handleEvent(oEvent, index, "from");
                      }}
                    />
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  {row.type === 'Date'? (
                    <select
                      className="RenderComponent-Table-input"
                      value={row.to}
                      onChange={(event) => {
                        handleEvent(event, index, "to");
                      }}
                    >
                      {aDateConditions.map((odatas) => (
                        <option key={odatas.value} value={odatas.value}>
                          {odatas.value}
                        </option>
                      ))}
                      {props.inputs.map(function (input) {
                        return (
                          <option key={input} value={input}>
                            {input}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <input
                      name={index}
                      className="RenderComponent-Table-input"
                      type={row.type}
                      value={row.to}
                      onChange={(oEvent) => {
                        handleEvent(oEvent, index, "to");
                      }}
                    />
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  <select
                    className="RenderComponent-Table-input"
                    value={row.type}
                    onChange={(event) => {
                      handleEvent(event, index, "type");
                    }}
                  >
                    <option value="" disabled>
                      Select Type
                    </option>
                    <option value="Number">Number</option>
                    <option value="Date">Date</option>
                    <option value="String">String</option>
                    <option value="Boolean">Boolean</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </div>
  );
};