import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const CustomFileUploader = ({
  componetProperties,
  oComponentModel,
  aUIComponents,
}) => {
  const handleFileChange = (event, oComponentModel, aUIComponents) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      oComponentModel.Files = selectedFiles;
    }

    if (selectedFiles.length > 0) {
      const previewUrls = selectedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      oComponentModel.previews = previewUrls;
    }
  };
  const handleDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      oComponentModel.Files = acceptedFiles;
    }
    const previewUrls = acceptedFiles.map((file) => URL.createObjectURL(file));
    oComponentModel.previews = previewUrls;

    try {
      if (oComponentModel.Files && oComponentModel.Files.length > 0) {
        let binaryFiles = await processFiles(oComponentModel);
        if (binaryFiles) {
          oComponentModel.binary = binaryFiles;
        }
      }
    } catch (error) {
      console.error("Error processing files:", error);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
  });

  async function processFiles(oComponent) {
    const filesWithData = [];
    for (let i = 0; i < oComponent.Files.length; i++) {
      let file = oComponent.Files[i];
      let oBinaryFile = {};
      try {
        const arrayBuffer = await file.arrayBuffer();
        const base64String = arrayBufferToBase64(arrayBuffer);
        oBinaryFile.filename = file.name;
        oBinaryFile.content = base64String;
        oBinaryFile.filetype = file.type;
        filesWithData.push(oBinaryFile);
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }

    return filesWithData;
  }

  function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  const fileDisplayElements =
    oComponentModel && oComponentModel.Files && oComponentModel.Files.length > 0
      ? React.createElement(
          "div",
          { style: { marginTop: "10px" } },
          oComponentModel.Files.map((file, index) =>
            React.createElement(
              "div",
              { key: index, style: { marginBottom: "10px" } },
              React.createElement(
                "a",
                {
                  href: oComponentModel.previews[index],
                  target: "_blank",
                  rel: "noopener noreferrer",
                  style: { textDecoration: "none", color: "blue" },
                },
                file.name
              )
            )
          )
        )
      : null;

  const dropzone = React.createElement(
    "div",
    {
      ...getRootProps({
        onDrop: (oEvent) => {
          if (oEvent.files && oEvent.files.length > 0) {
            // Handle drop event
            handleDrop(oEvent, oComponentModel, aUIComponents);
          }
        },
      }),
      style: {
        border: "2px dashed #cccccc",
        padding: "1px",
        cursor: "pointer",
        position: "relative",
        width: "100%",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    [
      React.createElement("input", {
        id: componetProperties.sId,
        key: componetProperties.sId,
        style: {
          ...componetProperties.CSS,
          display: componetProperties.bVisible ? "block" : "none",
        },
        className: componetProperties.bReadOnly
          ? "input-fields read-only"
          : "input-fields",
        ...getInputProps(),
        onClick: handleFileChange,
      }),
      React.createElement(CloudUploadIcon, {
        style: { fontSize: "40px", color: "16202a" },
      }),
    ]
  );

  // Render the component
  return React.createElement(
    "div",
    {
      style: {
        width: "auto",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
      },
    },
    dropzone,
    fileDisplayElements
  );
};

export default CustomFileUploader;
