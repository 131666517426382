import Server from "../../../../../serverDetails/server";

export const DeleteDB = (
  aProperties,
  oParams,
  aPromises,
  stopLoop,
  oLogicDatas
) => {
  let sAdminProperty = false;
  // var bConfirm = window.confirm("Are you sure you want to delete?");

  // if (bConfirm) {
    var sTableName = aProperties.find(function (oProperty) {
      return oProperty.id === "idDeleteDBName";
    }).value;

    var aConditionProperty = aProperties.find((oProperty) => {
      return oProperty.id === "idDeleteCondition";
    }).value;
    
    if(localStorage.getItem('ADMIN')){
      sAdminProperty = aProperties.find(function (oProperty) {
        return oProperty.id === "idAdmin";
      })?.value;
    }

    var bSubArray = false;

    var oDeleteCondition = aConditionProperty.map(function (oCondition) {
      var matchingValue = oParams.find(function (oValue) {
        return oCondition.value === oValue.field;
      });
      if (oCondition.subfield !== "" && oCondition.subfield !== undefined) {
        bSubArray = true;
      }
      return {
        field: oCondition.field,
        subfield: oCondition.subfield,
        operator: oCondition.operator,
        value: matchingValue ? matchingValue.value : null,
        dataType: oCondition.dataType,
      };
    });

    const operatorMap = {
      Equal: "$eq",
      "Not Equal": "$ne",
      "Less Than": "$lt",
      "Greater Than": "$gt",
    };
    var oWhere;

    if (!bSubArray && oDeleteCondition.length > 0) {
      const conditions = [];

      for (const condition of oDeleteCondition) {
        const { field, operator, value, dataType } = condition;
        let updatedValue;

        const parseDate = (dateValue) => {
          const date = new Date(dateValue);
          return date;
        };

        if (dataType === "Number") {
          updatedValue = parseInt(value, 10);
        } else if (dataType === "Date") {
          updatedValue = parseDate(value);
        } else {
          updatedValue = value;
        }

        const whereCondition = {
          [field]: { [operatorMap[operator]]: updatedValue },
        };
        conditions.push(whereCondition);
      }

      oWhere = { $and: conditions };
    } else {
      const dDate = (value) => {
        const date = new Date(value);
        return date;
      };

      oDeleteCondition.forEach((element) => {
        if (element.dataType === "Number") {
          element.value = parseInt(element.value, 10);
        } else if (element.dataType === "Date") {
          element.value = dDate(element.value);
        }
      });
    }

    var odeleteData = {
      TABLE_NAME: sTableName,
      WHERE: oWhere,
      bSubArray,
      oDeleteCondition,
      ADMINAPP:sAdminProperty,
      APP_ID:oLogicDatas.APPID
    };

    oLogicDatas.bBusyIndicator(true)
    aPromises.push(
      new Promise((resolve) => {
        Server.delete(`/logics/db/delete`, {
          data: odeleteData,
          headers: {
            Authorization: localStorage.getItem('token')
          }
        })
          .then(function (oResult) {
            oLogicDatas.bBusyIndicator(false)
            if (oResult.status === 201) {
              resolve(oResult.data);
            } else {
              oLogicDatas.iErrorCode(oResult.status);
              oLogicDatas.sErrorMessage(oResult.data.message);
              oLogicDatas.bSnackBar(true);
            }
          })
          .catch(function (oError) {
            oLogicDatas.bBusyIndicator(false)
            oLogicDatas.iErrorCode(oError.response.status);
            oLogicDatas.sErrorMessage(oError.response.data.message);
            oLogicDatas.bSnackBar(true);
          });
      })
    );
  // } else {
  //   stopLoop();
  // }
};
