
/**
 * converstion if the app is opened in different resolution
 * @param {array} | ComponentModel
 */
export function convertResolution(aUIData) {
    var oDropZoneEdges = document.getElementById("idDropZone").getBoundingClientRect();
    var iScreenWidth = window.screen.width;
    var iScreenHeight = window.screen.height;
    aUIData.forEach(function (oComponentModel) {

        var buttonWidthInitial = oComponentModel.DivWidth;
        var buttonHeighInitial = oComponentModel.DivHeight;
        var ScreenWidthInitial = oComponentModel.DropZoneWidth;
        var ScreenHeightInitial = oComponentModel.DropZoneHeight;
        var ScreenHeightFinal = oDropZoneEdges.height;
        var ScreenWidthFinal = oDropZoneEdges.width;
        var buttonWidthFinal = buttonWidthInitial * ScreenWidthFinal / ScreenWidthInitial;
        var buttonHeightFinal = buttonHeighInitial * ScreenHeightFinal / ScreenHeightInitial;
        oComponentModel.DivWidth = buttonWidthFinal;
        oComponentModel.DivHeight = buttonHeightFinal;

        //Left
        oComponentModel.left = oDropZoneEdges.width / (oComponentModel.DropZoneWidth / (oComponentModel.left - oComponentModel.DropZoneLeft));
        oComponentModel.left = oComponentModel.left + oDropZoneEdges.left;

        //top
        oComponentModel.top = oDropZoneEdges.height / (oComponentModel.DropZoneHeight / (oComponentModel.top - oComponentModel.DropZoneTop));
        oComponentModel.top = oComponentModel.top + oDropZoneEdges.top;

        oComponentModel.ScreenWidth = iScreenWidth;
        oComponentModel.ScreenHeight = iScreenHeight;
        oComponentModel.DropZoneWidth = oDropZoneEdges.width;
        oComponentModel.DropZoneLeft = oDropZoneEdges.left;
        oComponentModel.DropZoneHeight = oDropZoneEdges.height;
        oComponentModel.DropZoneTop = oDropZoneEdges.top;


    })
    return aUIData;
}

/**
 * converstion for preview
 * @param {array} | ComponentModel
 */
export function convertUIDataForPreview(aUIData) {
    var oDropZoneEdges = document.getElementById("PreviewRenderer").getBoundingClientRect();
    var iScreenWidth = window.screen.width;
    var iScreenHeight = window.screen.height;
    aUIData.forEach(function (oComponentModel) {

        var buttonWidthInitial = oComponentModel.DivWidth;
        var buttonHeighInitial = oComponentModel.DivHeight;
        var ScreenWidthInitial = oComponentModel.DropZoneWidth;
        var ScreenHeightInitial = oComponentModel.DropZoneHeight;
        var ScreenHeightFinal = oDropZoneEdges.height;
        var ScreenWidthFinal = oDropZoneEdges.width;
        var buttonWidthFinal = buttonWidthInitial * ScreenWidthFinal / ScreenWidthInitial;
        var buttonHeightFinal = buttonHeighInitial * ScreenHeightFinal / ScreenHeightInitial;
        oComponentModel.DivWidth = buttonWidthFinal;
        oComponentModel.DivHeight = buttonHeightFinal;

        //Left
        oComponentModel.left = oDropZoneEdges.width / (oComponentModel.DropZoneWidth / (oComponentModel.left - oComponentModel.DropZoneLeft));
        oComponentModel.left = oComponentModel.left + oDropZoneEdges.left;

        //top
        oComponentModel.top = oDropZoneEdges.height / (oComponentModel.DropZoneHeight / (oComponentModel.top - oComponentModel.DropZoneTop));
        oComponentModel.top = oComponentModel.top + oDropZoneEdges.top;

        oComponentModel.ScreenWidth = iScreenWidth;
        oComponentModel.ScreenHeight = iScreenHeight;
        if (oComponentModel.Component === "Tabs" || oComponentModel.Component === "Table") {
            //oComponentModel.DivWidth = oComponentModel.DivWidth + (oDropZoneEdges.width - oComponentModel.DropZoneWidth);
        }
        oComponentModel.DropZoneWidth = oDropZoneEdges.width;
        oComponentModel.DropZoneLeft = oDropZoneEdges.left;
        oComponentModel.DropZoneHeight = oDropZoneEdges.height;
        oComponentModel.DropZoneTop = oDropZoneEdges.top;

    })
    return aUIData;
}