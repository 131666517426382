export const MapBarChartData = (aProperties, oParams, oLogicDatas) => {
  const sComponentID = aProperties.find(
    (oProperty) => oProperty.id === "idDropDownBarChart"
  )?.value;
  const oComponent = oLogicDatas.aUIData.find(
    (oComponentModel) => oComponentModel.uniqueId === sComponentID
  );
  const oMapping = aProperties.find(
    (oProperty) => oProperty.id === "idMapBarChatFields"
  );
  const oDynamic = aProperties.find(
    (oProperty) => oProperty.id === "idDynamicPieChart"
  );
  const aFinalData = [];
  if (oMapping.value.length > 0) {
    if (oComponent.Component === "PieChart") {
      if (oDynamic.value) {
        oMapping.value.forEach(function (oMappingValue, index) {
          var aRowData = [];
          if (index === 0) {
            aRowData.push(oMappingValue.mappingValue);
            aRowData.push(oMappingValue.mappingValue);
            aFinalData.push(aRowData);
          } else {
            oParams.TableData.forEach(function (oData) {
              aFinalData.push([
                oData[oMappingValue.mappingValue],
                oData[oMappingValue.fieldName],
              ]);
            });
          }
        });
      } else {
        oMapping.value.forEach(function (oMappingValue, index) {
          var aRowData = [];
          aRowData.push(oMappingValue.mappingValue);
          if (index === 0) {
            aRowData.push(oMappingValue.mappingValue);
          } else {
            oParams.TableData.forEach(function (oData) {
              aRowData.push(oData[oMappingValue.fieldName]);
            });
          }
          aFinalData.push(aRowData);
        });
      }
    } else {
      const aHeadData = oMapping.value.map(
        (oMappingValue) => oMappingValue.mappingValue
      );
      aFinalData.push(aHeadData);

      oParams.TableData.forEach((oData) => {
        const aRowData = oMapping.value.map(
          (oMappingValue) => oData[oMappingValue.fieldName]
        );
        aFinalData.push(aRowData);
      });
    }
  }
  oComponent.Data = aFinalData;
  oComponent.AllData = oParams.TableData;
  oLogicDatas.resetUI([...oLogicDatas.aUIData]);
};