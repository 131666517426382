import React from "react";

export const PropertyTable = (props) => {
  function handleKeyChange(oEvent, oRowData) {
    var aTemp = [...props.data];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value) {
        if (oEvent.target.checked) {
          oData.key = "X";
        } else {
          oData.key = "";
        }
      }
    });
    props.update(aTemp);
  }

  function handleItemKeyChange(oEvent, oRowData) {
    var aTemp = [...props.data];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value) {
        if (oEvent.target.checked) {
          oData.ItemKey = "X";
        } else {
          oData.ItemKey = "";
        }
      }
    });
    props.update(aTemp);
  }

  function handleSeriesChange(oEvent, oRowData) {
    var aTemp = [...props.data];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value) {
        if (oEvent.target.checked) {
          oData.Series = "X";
        } else {
          oData.Series = "";
        }
      }
    });
    props.update(aTemp);
  }

  function handleItemChange(oEvent, oRowData) {
    var aTemp = [...props.data];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value) {
        if (oEvent.target.checked) {
          oData.item = "X";
        } else {
          oData.item = "";
          oData.itemName = "";
          oData.ItemKey = "";
          oData.autoField = "";
        }
      }
    });
    props.update(aTemp);
  }

  /**
   * Check handleAutoGenerateChange
   * @param {event} Event
   * @param {object} oRowData
   */
  function handleAutoGenerateChange(oEvent, oRowData) {
    var aTemp = [...props.data];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value && oEvent.target.checked) {
        oData.auto = "X";
      } else {
        oData.auto = "";
      }
    });
    props.update(aTemp);
  }

  function handleAutoGenerateFieldChange(oEvent, oRowData) {
    var aTemp = [...props.data];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value) {
        oData.autoField = oEvent.target.value;
      } else {
        oData.autoField = "";
      }
    });
    props.update(aTemp);
  }

  /**
   * Change handleChangeDataType
   * @param {event} Event
   * @param {object} oRowData
   */
  function handleChangeDataType(oEvent, oRowData) {
    var aTemp = [...props.data];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value) {
        oData.dataType = oEvent.currentTarget.value;
      }
    });
    props.update(aTemp);
  }

  /**
   * Change handleChangeDataType
   * @param {event} Event
   * @param {object} oRowData
   */
  function handleChangeFieldName(oEvent, oRowData) {
    var aTemp = [...props.data];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value) {
        oData.FieldName = oEvent.currentTarget.value;
      }
    });
    props.update(aTemp);
  }

  /**
   *
   * @param {*} oEvent
   * @param {*} oRowData
   */
  function handleChangePattern(oEvent, oRowData) {
    var aTemp = [...props.data];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value) {
        oData.pattern = oEvent.target.value;
      }
    });
    props.update(aTemp);
  }

  /**
   *
   * @param {*} oEvent
   * @param {*} oRowData
   */
  function handleChangeItemName(oEvent, oRowData) {
    var aTemp = [...props.data];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value) {
        oData.itemName = oEvent.target.value;
      }
    });
    props.update(aTemp);
  }

  return (
    <div style={{ maxHeight: "600px", overflowY: "auto" }}>
      <table className="RenderComponent-Table">
        <th className="RenderComponent-Table-th">Field</th>
        <th className="RenderComponent-Table-th">FieldName</th>
        <th className="RenderComponent-Table-th">Key</th>
        <th className="RenderComponent-Table-th">Item</th>
        <th className="RenderComponent-Table-th">Item Name</th>
        <th className="RenderComponent-Table-th">Item Key</th>
        <th className="RenderComponent-Table-th">AutoGenerate</th>
        <th className="RenderComponent-Table-th">Series</th>
        <th className="RenderComponent-Table-th">AG Field</th>
        <th className="RenderComponent-Table-th">Pattern</th>
        <th className="RenderComponent-Table-th">DataType</th>
        {props.data.length > 0 &&
          props.data.map((oRowData) => {
            return (
              <tr>
                <td className="RenderComponent-Table-td">{oRowData.value}</td>
                <td className="RenderComponent-Table-td">
                  {oRowData.FieldName && (
                    <input
                      type="text"
                      className="RenderComponent-Table-input"
                      defaultValue={oRowData.FieldName}
                      onChange={(oEvent) => {
                        handleChangeFieldName(oEvent, oRowData);
                      }}
                    />
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  {oRowData.key === "X" ? (
                    <input
                      type="checkbox"
                      checked
                      onChange={(oEvent) => {
                        handleKeyChange(oEvent, oRowData);
                      }}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      onChange={(oEvent) => {
                        handleKeyChange(oEvent, oRowData);
                      }}
                    />
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  {oRowData.item === "X" ? (
                    <input
                      type="checkbox"
                      checked
                      onChange={(oEvent) => {
                        handleItemChange(oEvent, oRowData);
                      }}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      onChange={(oEvent) => {
                        handleItemChange(oEvent, oRowData);
                      }}
                    />
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  {oRowData.item === "X" ? (
                    <input
                      type="text"
                      className="RenderComponent-Table-input"
                      onChange={(oEvent) => {
                        handleChangeItemName(oEvent, oRowData);
                      }}
                      value={oRowData.itemName}
                    ></input>
                  ) : (
                    <input
                      disabled
                      className="RenderComponent-Table-input"
                      value={oRowData.itemName}
                    ></input>
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  {oRowData.item === "X" && (
                    <input
                      type="checkbox"
                      checked={oRowData.ItemKey === "X"}
                      onChange={(oEvent) => {
                        handleItemKeyChange(oEvent, oRowData);
                      }}
                    />
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  {oRowData.auto === "X" ? (
                    <input
                      type="radio"
                      name="auto"
                      checked
                      value={oRowData.auto}
                      onChange={(oEvent) => {
                        handleAutoGenerateChange(oEvent, oRowData);
                      }}
                    />
                  ) : (
                    <input
                      type="radio"
                      name="auto"
                      value={oRowData.auto}
                      onChange={(oEvent) => {
                        handleAutoGenerateChange(oEvent, oRowData);
                      }}
                    />
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  {oRowData.auto === "X" && (
                    <input
                      type="checkbox"
                      checked={oRowData.Series === "X"}
                      onChange={(oEvent) => {
                        handleSeriesChange(oEvent, oRowData);
                      }}
                    />
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    type="text"
                    className="RenderComponent-Table-input"
                    readOnly={oRowData.auto !== "X" || oRowData.item !== "X"}
                    value={oRowData.autoField}
                    onChange={(oEvent) => {
                      handleAutoGenerateFieldChange(oEvent, oRowData);
                    }}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  {oRowData.auto === "X" ? (
                    <input
                      type="text"
                      className="RenderComponent-Table-input"
                      onChange={(oEvent) => {
                        handleChangePattern(oEvent, oRowData);
                      }}
                      value={oRowData.pattern}
                    ></input>
                  ) : (
                    <input
                      disabled
                      className="RenderComponent-Table-input"
                      value={oRowData.pattern}
                    ></input>
                  )}
                </td>
                <td className="RenderComponent-Table-td">
                  <select
                    id="dataType"
                    className="RenderComponent-Table-input"
                    value={oRowData.dataType ? oRowData.dataType : ""}
                    onChange={(oEvent) => {
                      handleChangeDataType(oEvent, oRowData);
                    }}
                  >
                    <option key="" value=""></option>
                    <option key="String" value="String">
                      String
                    </option>
                    <option key="Number" value="Number">
                      Number
                    </option>
                    <option key="Date" value="Date">
                      Date
                    </option>
                    <option key="Time" value="Time">
                      Time
                    </option>
                    <option key="Array" value="Array">
                      Array
                    </option>
                    <option key="Boolean" value="Boolean">
                      Boolean
                    </option>
                    <option key="File" value="File">
                      File
                    </option>
                  </select>
                </td>
              </tr>
            );
          })}
      </table>
    </div>
  );
};
