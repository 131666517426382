import React, { useState} from "react";

// Material ui component
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const TableFieldsDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  let aRowsData = [];

  const aDataTypes = ["String", "Number", "Date"];
  
  /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    var aTemp = [...props.aTableFieldsData.rows];
    aTemp.push({
      fieldName : "column",
      dataType : "String",
      visibleColumn: "true",
    });
    props.setTableFieldsData({...props.aTableFieldsData,rows:aTemp});
    setSelectedRows([...aSelectedRows, false]);
  }

  /**
   * Event handler for Remove Rows
   */
  function handleDecreaseRow() {
    var aTemp = [...props.aTableFieldsData.rows];
    const aUpdatedRows = aTemp.filter((_, index) => !aSelectedRows[index]);
    props.setTableFieldsData({...props.aTableFieldsData,rows:aUpdatedRows});
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  /**
   * Event handler for CheckBox
   * @param {Number} iIndex
   */
  const handleCheckboxChange = (iIndex) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[iIndex] = !aNewSelectedRows[iIndex];
    setSelectedRows(aNewSelectedRows);
  };

  if (props.aTableFieldsData.rows.length > 0) {
    for (let i = 0; i < props.aTableFieldsData.rows.length; i++) {
      aRowsData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={aSelectedRows[i]}
              onChange={() => handleCheckboxChange(i)}
            />
          </td>
          <td className="RenderComponent-Table-td">
            <select
              onChange={(oEvent) => handleFieldsChange(oEvent, "fieldName", i)}
              required
              value={props.aTableFieldsData.rows[i].fieldName}
              className="RenderComponent-Table-input"
            >
              <option value="">Select </option>
              {props.aSchemaFieldsData.map((sDataType, index) => {
                return (
                  <option value={sDataType.inputFieldName} key={index}>
                    {sDataType.inputFieldName}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="RenderComponent-Table-td">
            <select
              onChange={(oEvent) => handleFieldsChange(oEvent, "dataType", i)}
              required
              value={props.aTableFieldsData.rows[i].dataType}
              className="RenderComponent-Table-input"
            >
              <option value="">Select </option>
              {aDataTypes.map((sDataType, index) => {
                return (
                  <option value={sDataType} key={index}>
                    {sDataType}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              className=""
              checked={props.aTableFieldsData.rows ? props.aTableFieldsData.rows[i].visibleColumn : ""}
              onChange={(oEvent) => handleFieldsChange(oEvent,"visibleColumn",i)}
            />
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler for Fields
   * @param {*} oEvent
   * @param {Number} iIndex
   * @param {string} skey
   */
  const handleFieldsChange = (oEvent, skey, iIndex) => {
    var aTempUserInputsData = [...props.aTableFieldsData.rows];
    if (skey === "visibleColumn") {
      aTempUserInputsData[iIndex][skey] = oEvent.target.checked;
    } else {
      aTempUserInputsData[iIndex][skey] = oEvent.target.value;
    }
    props.setTableFieldsData({...props.aTableFieldsData,rows:aTempUserInputsData});
  };

  function handleChangeTableName(oEvent){
    props.setTableFieldsData({tableName:oEvent.target.value,rows:props.aTableFieldsData.rows})

  }

  return (
    <div>
      <TextField
        required
        margin="dense"
        id="name"
        label="Table Name"
        fullWidth
        variant="outlined"
        value={props.aTableFieldsData.tableName}
        onChange={(oEvent)=>handleChangeTableName(oEvent)}
        style={{ backgroundColor: 'white' }}
      />
      <IconButton onClick={handleIncreaseRow}>
        <AddIcon
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      <IconButton onClick={handleDecreaseRow}>
        <RemoveIcon
          baseClassName="fas"
          className="fa-plus-circle"
          color="secondary"
        />
      </IconButton>
      <table id="idTableConfiguration" className="RenderComponent-Table">
        <thead>
          <tr>
            <th></th>
            <th className="RenderComponent-Table-th">Column Name</th>
            <th className="RenderComponent-Table-th">Data Type</th>
            <th className="RenderComponent-Table-th">VisibleColumns</th>
          </tr>
        </thead>
        <tbody>{aRowsData}</tbody>
      </table>
    </div>
  );
};