import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ListItemText, Collapse } from "@mui/material";
import { styled } from '@mui/system';

export function DBFieldsList(props) {
  const [openIndex, setOpenIndex] = useState(-1);
  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };
  const CustomPaper = styled('div')(({ theme }) => ({
    height: 400,
    width: 200,
  }));
  return (
    <CustomPaper>
      <MenuList>
        {props.aDataBaselist.length > 0 &&
          props.aDataBaselist.map((oComponent, index) => (
            <div key={index}>
              <MenuItem
                onClick={() => handleClick(index)}
                className="boldMenuItem blueMenuItem pointerCursor"
              >
                <ListItemText primary={oComponent.tableName} />
                {openIndex === index ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                <MenuList>
                  {oComponent.fields.map((FirstlevelField, fieldIndex) => (
                    <div key={fieldIndex}>
                      <MenuItem style={{ paddingLeft: "24px" }}>
                        <ListItemText
                          primary={FirstlevelField.path}
                          className="fieldName"
                        />
                      </MenuItem>
                    </div>
                  ))}
                </MenuList>
              </Collapse>
            </div>
          ))}
      </MenuList>
    </CustomPaper>
  );
}
