import Server from "../../../../../serverDetails/server";

export const FetchDB = (
  aProperties,
  oLogicDatas,
  oParams,
  aPromises,
  oNextTarget
) => {
  /**
   * Fetch DB data
   * @param {array} aProperties
   * @param {object} oParams
   * @param {array} aPreviewProps
   * @param {arrau} aUIData
   * @param {string} USERID
   * @param {array} aPromises
   */
 let sAdminProperty = false;
  var oFields = aProperties.find(function (oProperty) {
    return oProperty.id === "idFetchJoin";
  }).value;
  var aConditions = JSON.parse(JSON.stringify(oFields.whereConditions));
  if (localStorage.getItem('ADMIN')) {
    sAdminProperty = aProperties.find(function (oProperty) {
      return oProperty.id === 'idAdmin';
    })?.value;
  }

  var aArrayCondition = oFields.ArrayWhereConditions;
  var aArrayConditionData = [];
  var bArrayCondition = false;
  if (
    aConditions.length === 0 &&
    aArrayCondition &&
    aArrayCondition[0].value !== ""
  ) {
    bArrayCondition = true;
    if (oParams.mapSingle) {
      if (
        Object.keys(oParams.mapSingle).length > 0
      ) {
        var sValue = oParams.mapSingle[aArrayCondition[0].value];
        if (sValue) {
          aArrayConditionData = sValue;
        }
      }
    }
  } else {
    aConditions.forEach((oCondition) => {
      oLogicDatas.aUIData.forEach((oUIData) => {
        if (oUIData.Component === "Input" || oUIData.Component === "DropDown") {
          var oInputProperty = oUIData.properties.find((oProperty) => {
            return oProperty.id === "idInputFieldName";
          });
          if (oInputProperty.value === oCondition.value) {
            var oComponent = document.getElementById(oUIData.uniqueId);
            if (oComponent) {
              var sUserValue = document.getElementById(oUIData.uniqueId).value;
              if(!sUserValue){
                sUserValue = oUIData['UserValue']
              }
              oCondition.userValue = sUserValue;
            }
          }
        }
      });
      if (oParams.mapSingle) {
        if (
          !oCondition.userValue &&
          Object.keys(oParams.mapSingle).length > 0
        ) {
          var sValue = oParams.mapSingle[oCondition.value];
          if (sValue) {
            oCondition.userValue = sValue;
          }
        } else if (
          oCondition.userValue &&
          Object.keys(oParams.mapSingle).length > 0
        ) {
          var sData = oParams.mapSingle[oCondition.value];
          if (sData) {
            oCondition.userValue = sData;
          }
        }
      }
    });

    aConditions.forEach(function (oCondition) {
      if (oCondition.userValue) {
        if (oCondition.userValue === "{USERID}") {
          oCondition.userValue = oLogicDatas.USERID;
        }
      } else if (oCondition.value === "{USERID}") {
        oCondition.userValue = oLogicDatas.USERID;
      }
    });
    const aValidOperators = ['$eq', '$ne', '$gt', '$lt', '$gte', '$lte', '$contains', '$between'];
    const aValidMonth = ['Current Date','End of the Current Month','Beginning of the Current Month','Beginning of the year','End of the Current year'];
    oParams.forEach(function (oParam){
      aConditions.forEach(function (oCondition) {
        if(!aValidOperators.includes(oCondition.condition)){
          if(oCondition.condition === oParam.field){
            oCondition.condition = oParam.value;
          }
        }
        if(oCondition.type === 'Date' && oCondition.value ===''){
          if(!aValidMonth.includes(oCondition.from)){
            if(oCondition.from === oParam.field){
              oCondition.from = oParam.value;
            }
          }
          if(!aValidMonth.includes(oCondition.to)){
            if(oCondition.to === oParam.field){
              oCondition.to = oParam.value;
            }
          }
        }
      })
    })
    aConditions.forEach(function (oCondition) {
      switch (oCondition.condition) {
        case 'Equal To':
          oCondition.condition = '$eq';
          break;
        case 'Not Equal To':
          oCondition.condition = '$ne';
          break;
        case 'Greater Than':
          oCondition.condition = '$gt';
          break;
        case 'Less Than':
          oCondition.condition = '$lt';
          break;
        case 'Greater Than or Equal To':
          oCondition.condition = '$gte';
          break;
        case 'Less Than or Equal To':
          oCondition.condition = '$lte';
          break;
        case 'Like':
          oCondition.condition = '$contains';
          break;
        case 'Between':
          oCondition.condition = '$between';
          break;
        default:
          break;
      }
    });
  }
  var oPostData = {
    collections: oFields.collections,
    joinCondition: oFields.joinConditions,
    WHERE: aConditions,
    bArrayCondition,
    ArrayWhere:aArrayConditionData,
    ADMINAPP:sAdminProperty,
    APP_ID:oLogicDatas.APPID
  };

  if (oNextTarget && oNextTarget.target.length > 0) {
    var ValueHelpTable = oNextTarget.target[0].properties.find(
      function (oProperty) {
        return oProperty.id === "idTitleInput";
      }
    );
  }
  if(oLogicDatas && oLogicDatas.aFlow && oLogicDatas.aFlow[0].id !== "idonTrackPositionEvent"){
      oLogicDatas.bBusyIndicator(true);
  }
  aPromises.push(
    Server.post("/logics/db/fetch", oPostData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (oResult) {
        oLogicDatas.bBusyIndicator(false);
        if (oResult.status === 201) {
          oParams.TableData = oResult.data.Data;
          oParams.aRetriveData = oResult.data.Data;
          aProperties.forEach(function (oProperty) {
            if (oProperty.id === "idFetchDBMapData" && oProperty.value !== "") {
              oParams[oProperty.value] = oResult.data.Data;
            }
          });
          if (ValueHelpTable) {
            return { id: ValueHelpTable.value, value: oParams.TableData };
          }
        } else {
          if (oResult.status === 202) {
            oParams.TableData = [];
            aProperties.forEach(function (oProperty) {
              if (
                oProperty.id === "idFetchDBMapData" &&
                oProperty.value !== ""
              ) {
                oParams[oProperty.value] = [];
              }
            });
          }
        }
      })
      .catch(function (oError) {
        oLogicDatas.bBusyIndicator(false);
        oLogicDatas.iErrorCode(oError.response.status);
        oLogicDatas.sErrorMessage(oError.response.data.message);
        oLogicDatas.bSnackBar(true);
      })
  );
};
