import React from "react";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export const SubjectConfig = (props) => {

  /**
   * Event handler  for Text Input Fields
   * @param {*} oEvent
   * @param {Number} iNewValue
   */
  function handleChange(oEvent, Subject) {
    if (Subject) {
      props.setData({
        ...props.mapMailData,
        subject: oEvent.target.value,
      });
    } else {
      props.setData({
        ...props.mapMailData,
        body: oEvent.target.value,
      });
    }
  }

  /**
   * Event handler  for CheckBoxes
   * @param {*} oEvent
   * @param {Number} iNewValue
   */
  function handleChangeTemplate(oEvent, subject) {
    if (subject) {
      props.setData({
        ...props.mapMailData,
        dynamicSubject: oEvent.currentTarget.checked,
      });
    } else {
      props.setData({
        ...props.mapMailData,
        template: oEvent.currentTarget.checked,
      });
    }
  }

  return (
    <div className="SendEmail-render">
      <FormControlLabel
        label="Template"
        control={
          <Checkbox
            checked={props.mapMailData.template}
            onChange={(oEvent) => handleChangeTemplate(oEvent, false)}
          />
        }
      />
      <FormControlLabel
        label="Dynamic Subject"
        control={
          <Checkbox
            checked={props.mapMailData.dynamicSubject}
            onChange={(oEvent) => handleChangeTemplate(oEvent, true)}
          />
        }
      />
      <br></br>
      <br></br>
      <Box>
        {props.mapMailData.dynamicSubject ? (
          <TextField
            required
            label="Choose dynamic Subject"
            select
            value={props.mapMailData.subject}
            onChange={(oEvent) => handleChange(oEvent, true)}
            sx={{ minWidth: 450 }}
          >
            {props.aSourceFields.map((oSourceField, index) => (
              <MenuItem key={index} value={oSourceField}>
                {oSourceField}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            fullWidth
            required
            margin="dense"
            id="Subject"
            value={props.mapMailData.subject}
            label="Subject"
            onChange={(oEvent) => handleChange(oEvent, true)}
          />
        )}
        <br></br>
        <br></br>
        <br></br>
        {props.mapMailData.template ? (
          <TextField
            required
            label="Choose Template"
            select
            value={props.mapMailData.body}
            onChange={(oEvent) => handleChange(oEvent, false)}
            sx={{ minWidth: 450 }}
          >
            {props.aSourceFields.map((oSourceField, index) => (
              <MenuItem key={index} value={oSourceField}>
                {oSourceField}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            fullWidth
            required
            margin="dense"
            id="Body"
            value={props.mapMailData.body}
            label="Body or Text"
            onChange={(oEvent) => handleChange(oEvent, false)}
          />
        )}

        <br></br>
        <br></br>
      </Box>
    </div>
  );
};