export const SplitColumnData = (aProperties, oParams, oLogicDatas) => {
  var sArrayTableName = aProperties.find(function (oProperty) {
    return oProperty.id === "idDropDownSplitColumns";
  });
  var aSplitLevel = aProperties
    .find((oProperty) => oProperty.id === "idSplitLevel")
    .value.split(".");
  var aInputSplit = aProperties.find(
    (oProperty) => oProperty.id === "idSplitInput"
  )?.value;
  var aParams = [];
  if (aInputSplit) {
    var checkMapTable = oLogicDatas.aFlow.find(flow => flow.source === "idFetchDBSplitColumns")?.target;
    oParams.forEach(function (oProperty) {
      if (oProperty.field === sArrayTableName.value) {
        aParams = oProperty.value;
      }
    });
    oParams.splice(0, oParams.length);
    if(checkMapTable && checkMapTable.length > 0 && (checkMapTable[0].target === 'idMapTableData' || checkMapTable[0].target ==='idMapView')){
      aParams.map((item) => {
        oParams.push(item);
      });
    }else{
      aParams.map((item) => {
        oParams.push(
          Object.keys(item).map((key) => ({ field: key, value: item[key] }))
        );
      });
    }
  } else {
    if (oParams.TableData) {
      if (!Array.isArray(oParams.TableData)) {
        oParams.TableData[sArrayTableName.value].forEach(function (oRowData) {
          aParams.push(oRowData);
        });
      } else {
        oParams.TableData.forEach(function (oData) {
          oData[sArrayTableName.value].forEach(function (oRowData) {
            let bThirdSubArray = false;
            let bFourthArray = false;
            for (const key in oRowData) {
              if (Array.isArray(oRowData[key]) && oRowData[key].length > 0) {
                if (aSplitLevel.length > 1 && aSplitLevel.includes(key)) {
                  bThirdSubArray = true;
                  oRowData[key].forEach(function (oSecondRowData) {
                    if (aSplitLevel.length > 2 && aSplitLevel.includes(key)) {
                      for (const key in oSecondRowData) {
                        if (
                          Array.isArray(oSecondRowData[key]) &&
                          oSecondRowData[key].length > 0
                        ) {
                          bFourthArray = true;
                          oSecondRowData[key].forEach(function (oThirdRowData) {
                            var oCombinedObject = {
                              ...oRowData,
                              ...oSecondRowData,
                            };
                            if (sArrayTableName.Fields) {
                              sArrayTableName.Fields.forEach(function (field) {
                                oCombinedObject[field] = oData[field];
                              });
                            }
                            Object.assign(oCombinedObject, oThirdRowData);
                            for (const sKey in oCombinedObject) {
                              if (
                                Object.prototype.hasOwnProperty.call(
                                  oCombinedObject,
                                  sKey
                                ) &&
                                Array.isArray(oCombinedObject[sKey])
                              ) {
                                delete oCombinedObject[sKey];
                              }
                            }
                            aParams.push(oCombinedObject);
                          });
                        }
                      }
                    } else {
                      var oCombinedObject = { ...oRowData };
                      if (sArrayTableName.Fields) {
                        sArrayTableName.Fields.forEach(function (field) {
                          if(oData[field] || oData[field] === 0){
                            oCombinedObject[field] = oData[field];
                          }
                        });
                      }
                      Object.assign(oCombinedObject, oSecondRowData);
                      for (const sKey in oCombinedObject) {
                        if (
                          Object.prototype.hasOwnProperty.call(
                            oCombinedObject,
                            sKey
                          ) &&
                          Array.isArray(oCombinedObject[sKey])
                        ) {
                          delete oCombinedObject[sKey];
                        }
                      }
                      aParams.push(oCombinedObject);
                    }
                  });
                }
              }
            }
            if (!bThirdSubArray) {
              var oCombinedObject = { ...oRowData };
              if (sArrayTableName.Fields) {
                sArrayTableName.Fields.forEach(function (field) {
                  if(oData[field] || oData[field] === 0){
                    oCombinedObject[field] = oData[field];
                  }
                });
              }
              aParams.push(oCombinedObject);
            }
          });
        });
      }
      oParams.TableData = aParams;
      aProperties.forEach(function (oProperty) {
        if (oProperty.id === "idSplitMapData" && oProperty.value !== "") {
          oParams[oProperty.value] = aParams;
        }
      });
    } else {
      oParams.forEach(function (oProperty) {
        if (oProperty.field === sArrayTableName.value) {
          aParams = oProperty.value;
        }
      });
      oParams.splice(0, oParams.length);
      aParams.map((item) => {
        oParams.push(
          Object.keys(item).map((key) => ({ field: key, value: item[key] }))
        );
      });
    }
  }
};
