import React from 'react';

//Material ui components
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const CreatePageDetail = (props) => {
    var sPageName = "";
    /**
     * Event handler for event change
     * @param {event} oEvent | Event
     */
    function handleChangeEvent(oEvent) {
        sPageName = oEvent.target.value
    }

    /**
     * Submit handler
     */
    function handleSubmit(){
        props.submit(props.pageId, sPageName);
    }

    /**
     * Close dialog
     */
    function handleClose(){
        props.close()
    }

    return (
        <div>
            <Dialog
                open={props.open}>
                <DialogTitle>
                    Create New Page
                </DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        margin="dense"
                        id="idPagename"
                        label="Page Name"
                        fullWidth
                        variant="standard"
                        sx={{ minWidth: 450 }}
                        onChange={handleChangeEvent}
                    />
                    <br></br>
                    <br></br>
                     <TextField
                        required
                        margin="dense"
                        id="idPageid"
                        label="Page id"
                        fullWidth
                        disabled
                        variant="standard"
                        sx={{ maxWidth: 500 }}
                        value={props.pageId}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        onClick={handleSubmit}>
                        Create
                    </Button>
                    <Button
                        onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}