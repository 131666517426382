import React, { useEffect, useRef, useState } from "react";
import "@arcgis/core/assets/esri/themes/light/main.css";
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import WebStyleSymbol from "@arcgis/core/symbols/WebStyleSymbol";
import Basemap from "@arcgis/core/Basemap";
import TileLayer from "@arcgis/core/layers/TileLayer";
import Extent from "@arcgis/core/geometry/Extent";
import { color } from "@mui/system";
import Map from "@arcgis/core/Map";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import Legend from "@arcgis/core/widgets/Legend";
import Circle from '@arcgis/core/geometry/Circle';
import { webMercatorToGeographic } from "@arcgis/core/geometry/support/webMercatorUtils";
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
const MapViewComponent = ({
  graphicsData,
  bLocationTrack,
  oComponentModel,
  positionChange,
}) => {
  const mapViewRef = useRef(null);
  const graphicsLayerRef = useRef(null);
  const legendRef = useRef(null);
  const userLayerRef = useRef(null);
  const [userPosition, setUserPosition] = useState(null);
  const [initialViewSet, setInitialViewSet] = useState(false);
  const [bRender, setrender] = useState(false)
  const markPostiton = oComponentModel.properties.find(
    (data) => data.id === "idMarkLocation"
  )?.value;

  useEffect(() => {
    const map = new Map({
      basemap: "streets-navigation-vector", // Use "streets" basemap
    });
    mapViewRef.current = new MapView({
      container: "mapViewDiv",
      map: map,
    });

    mapViewRef.current.when(() => {
      graphicsLayerRef.current = new GraphicsLayer();
      userLayerRef.current = new GraphicsLayer();
      map.add(graphicsLayerRef.current);
      map.add(userLayerRef.current);
      if (markPostiton) {
        mapViewRef.current.on("double-click", function (event) {
          event.stopPropagation();
          const { latitude, longitude } = webMercatorToGeographic(
            event.mapPoint
          );
          positionChange(oComponentModel, { latitude, longitude });
        });
        mapViewRef.current.on("double-click", ["drag"], function (event) {
          event.stopPropagation();
        });
      }
      setrender(true);
    });
  }, []);

  useEffect(() => {
    // Watch user position
    if (bLocationTrack) {
      if (graphicsData && graphicsData.length > 0) {
        let currentStopIndex = 0;
        let lat = graphicsData[0].latitude - 0.005; // Start slightly before point 1
        let lng = graphicsData[0].longitude - 0.005; // Start slightly before point 1

        const movementInterval = 500; // Interval in milliseconds
        const moveStep = 0.0001; // Smaller step for more accurate movement

        const deg2rad = (deg) => deg * (Math.PI / 180);
        const getDistance = (lat1, lon1, lat2, lon2) => {
          const R = 6371; // Radius of the earth in km
          const dLat = deg2rad(lat2 - lat1);
          const dLon = deg2rad(lon2 - lon1);
          const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) *
              Math.cos(deg2rad(lat2)) *
              Math.sin(dLon / 2) *
              Math.sin(dLon / 2);
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          return R * c; // Distance in km
        };

        const moveTowards = (
          currentLat,
          currentLng,
          targetLat,
          targetLng,
          step
        ) => {
          const dLat = targetLat - currentLat;
          const dLng = targetLng - currentLng;
          const distance = Math.sqrt(dLat * dLat + dLng * dLng);
          const ratio = step / distance;
          return {
            newLat: currentLat + dLat * ratio,
            newLng: currentLng + dLng * ratio,
          };
        };

        const intervalId = setInterval(() => {
          if (currentStopIndex >= graphicsData.length) {
            clearInterval(intervalId);
            return;
          }

          const nextStop = graphicsData[currentStopIndex];
          const distance = getDistance(
            lat,
            lng,
            nextStop.latitude,
            nextStop.longitude
          );

          if (distance < 0.015) {
            // Close enough to the destination
            currentStopIndex++;
            if (currentStopIndex >= graphicsData.length) {
              console.log("clear interval");
              clearInterval(intervalId);
              return;
            }
          }

          const { newLat, newLng } = moveTowards(
            lat,
            lng,
            nextStop.latitude,
            nextStop.longitude,
            moveStep
          );
          lat = newLat;
          lng = newLng;

          setUserPosition({ latitude: lat, longitude: lng });
          positionChange(oComponentModel, { latitude: lat, longitude: lng });
        }, movementInterval);

        return () => clearInterval(intervalId);
      }
      // if (navigator.geolocation) {
      //   const options = {
      //     enableHighAccuracy: true,
      //     timeout: 10000,
      //     maximumAge: 0,
      //   };

      //   const successCallback = (position) => {
      //     const { latitude, longitude } = position.coords;
      //     console.log(' position cords from location map',latitude,longitude)
      //     setUserPosition({ latitude, longitude });
      //     positionChange(oComponentModel, { latitude, longitude });
      //   };

      //   const errorCallback = (error) => {
      //     console.error(`Geolocation error: ${error.message}`);
      //   };

      //   navigator.geolocation.watchPosition(
      //     successCallback,
      //     errorCallback,
      //     options
      //   );
      // } else {
      //   console.log("Geolocation is not supported by this browser.");
      // }
    }
  }, [graphicsData, oComponentModel]);

  useEffect(() => {
    if (graphicsLayerRef.current && graphicsData && graphicsData.length > 0) {
      graphicsLayerRef.current.removeAll();
      // Add graphics to the layer
      const graphics = graphicsData.map((data) => {
        const { latitude, longitude, color } = data;
        const point = new Point({
          longitude,
          latitude,
        });
        if (markPostiton) {
          const webStyleSymbol = new WebStyleSymbol({
            name: "Route",
            styleUrl:
              "https://cdn.arcgis.com/sharing/rest/content/items/b117b6e14d7b429a9ea8c58a5cb6abad/data",
          });
          return new Graphic({
            geometry: point,
            symbol: webStyleSymbol,
          });
        } else {
          return new Graphic({
            geometry: point,
            symbol: new SimpleMarkerSymbol({ color: color }),
          });
        }
      });

      const textGraphics = graphicsData.map((data) => {
        const { latitude, longitude, color, label } = data;
        const point = new Point({
          longitude,
          latitude,
        });

        return new Graphic({
          geometry: point,
          symbol: new TextSymbol({
            text: label,
            color: "darkred",
            haloColor: "white",
            haloSize: 1,
            xoffset: 0,
            yoffset: 10,
            font: { size: 12, family: "sans-serif", weight: "bold" },
          }),
        });
      });

      graphics.forEach((graphic) => graphicsLayerRef.current.add(graphic));
      textGraphics.forEach((graphic) => graphicsLayerRef.current.add(graphic));

      //simulation
      if (graphicsData && graphicsData.length > 0 && userPosition) {
        const { latitude, longitude } = userPosition;
        const vehiclePoint = new Point({
          longitude,
          latitude,
        });
        graphicsData.forEach((oPoint) => {
          oPoint.radius = 100;
        });
        // const targetPoints = [
        //   { id: 1, latitude: 17.4326, longitude: 78.4071, radius: 200 },
        //   { id: 2, latitude: 17.4169, longitude: 78.4034, radius: 500 },
        //   // Add more points as needed
        // ];
        const circles = graphicsData.map((point) => {
          const circle = new Circle({
            center: { latitude: point.latitude, longitude: point.longitude },
            radius: point.radius,
          });
          const circleGraphic = new Graphic({
            geometry: circle,
            symbol: {
              type: "simple-fill",
              color: [51, 51, 204, 0.2],
              outline: {
                color: [51, 51, 204, 0.5],
                width: 2,
              },
            },
          });
          graphicsLayerRef.current.add(circleGraphic);

          const targetMarker = new Graphic({
            geometry: {
              type: "point",
              longitude: point.longitude,
              latitude: point.latitude,
            },
            symbol: {
              type: "simple-marker",
              color: "red",
              size: "12px",
            },
          });
          graphicsLayerRef.current.add(targetMarker);

          return { circle, reached: false };
        });

        // Check user position against target points' radius
        circles.forEach((circleObj) => {
          const { circle } = circleObj;
          if (geometryEngine.within(vehiclePoint, circle)) {
            if (!circleObj.reached) {
              circleObj.reached = true;
              const currentTime = new Date();
              console.log(
                `the user postion of latitude is ${vehiclePoint.latitude} and longitude is ${vehiclePoint.longitude}`
              );
              // console.log(`User reached point ${circleObj} at: ${currentTime}`);
            }
          } else {
            circleObj.reached = false;
          }
        });
      }

      // Calculate the extent
      const xCoordinates = graphics.map((g) => g.geometry.longitude);
      const yCoordinates = graphics.map((g) => g.geometry.latitude);

      // Ensure there are coordinates to calculate extent
      if (xCoordinates.length && yCoordinates.length) {
        const xmin = Math.min(...xCoordinates);
        const ymin = Math.min(...yCoordinates);
        const xmax = Math.max(...xCoordinates);
        const ymax = Math.max(...yCoordinates);

        // Add a margin to the extent for better visualization
        const margin = 0.1; // Adjust margin as needed
        const extent = new Extent({
          xmin: xmin,
          ymin: ymin,
          xmax: xmax,
          ymax: ymax,
          spatialReference: 4326,
        });

        if (!initialViewSet) {
          mapViewRef.current
            .goTo({
              target: extent,
              zoom: 5, // Set the desired zoom level
            })
            .then(() => setInitialViewSet(true))
            .catch((err) => console.error(err));
        }
      }
    }
  }, [graphicsData,bRender,userPosition]);

  useEffect(() => {
    if (userLayerRef.current && userPosition) {
      userLayerRef.current.removeAll();

      const { latitude, longitude } = userPosition;
      const point = new Point({
        longitude,
        latitude,
      });
      const userGraphic = new Graphic({
        geometry: point,
        symbol: new SimpleMarkerSymbol({
          color: "orange",
          size: "12px",
          outline: {
            color: [255, 255, 255],
            width: 2,
          },
        }),
      });

      userLayerRef.current.add(userGraphic);

    //   //simulation
      // if (graphicsData && graphicsData.length > 0) {
      //   graphicsData.forEach((oPoint) => {
      //     oPoint.radius = 200;
      //   });
      //   console.log("target", graphicsData);
      //   // const targetPoints = [
      //   //   { id: 1, latitude: 17.4326, longitude: 78.4071, radius: 200 },
      //   //   { id: 2, latitude: 17.4169, longitude: 78.4034, radius: 500 },
      //   //   // Add more points as needed
      //   // ];
      //   const circles = graphicsData.map((point) => {
      //     const circle = new Circle({
      //       center: { latitude: point.latitude, longitude: point.longitude },
      //       radius: point.radius,
      //     });
      //     const circleGraphic = new Graphic({
      //       geometry: circle,
      //       symbol: {
      //         type: "simple-fill",
      //         color: [51, 51, 204, 0.2],
      //         outline: {
      //           color: [51, 51, 204, 0.5],
      //           width: 2,
      //         },
      //       },
      //     });
      //     userLayerRef.current.add(circleGraphic);

      //     const targetMarker = new Graphic({
      //       geometry: {
      //         type: "point",
      //         longitude: point.longitude,
      //         latitude: point.latitude,
      //       },
      //       symbol: {
      //         type: "simple-marker",
      //         color: "red",
      //         size: "12px",
      //       },
      //     });
      //     userLayerRef.current.add(targetMarker);

      //     return { circle, reached: false };
      //   });

      //   //   // Check user position against target points' radius
      //   circles.forEach((circleObj) => {
      //     const { circle } = circleObj;
      //     if (geometryEngine.within(point, circle)) {
      //       if (!circleObj.reached) {
      //         circleObj.reached = true;
      //         const currentTime = new Date();
      //         console.log(
      //           `the user postion of latitude is ${point.latitude} and longitude is ${point.longitude}`
      //         );
      //         // console.log(`User reached point ${circleObj} at: ${currentTime}`);
      //         // Perform any additional logic here
      //       }
      //     } else {
      //       circleObj.reached = false;
      //     }
      //   });
      // }

      mapViewRef.current
        .goTo({
          target: point,
          zoom: 15,
        })
        .catch((err) => console.error(err));
    }
  }, [userPosition]);

  return <div id="mapViewDiv" style={{ height: "100vh", width: "100%" }}></div>;
};

export default MapViewComponent;
