import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { EmailParamsDialog } from "./EmailConfig/EmailConfig";
import { SubjectConfig} from "./SubjectConfig/SubjectConfig"

export const SendEmailDialog = (props) => {
  const [iTabValue, setTabValue] = useState(0);

  /**
   * Event handler  for Tab changes
   * @param {*} oEvent
   * @param {Number} iNewValue
   */
  function handleTabChange(oEvent, iNewValue) {
    setTabValue(iNewValue);
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Send Mail Configuration</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={iTabValue} onChange={handleTabChange}>
              <Tab label="Params" />
              <Tab label="Subject&&Text" />
            </Tabs>
          </Box>
          <br />
          {iTabValue === 0 && (
            <EmailParamsDialog
              mapMailData={props.mapMailData}
              setData = {props.setData}
              aSourceFields={props.aSourceFields}
            />
          )}
          {iTabValue === 1 && (
            <SubjectConfig
            mapMailData={props.mapMailData}
            aSourceFields={props.aSourceFields}
            setData = {props.setData}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={props.update}>
            Update
          </Button>
          <Button type="submit" onClick={props.close}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};