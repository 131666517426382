import React, { useEffect, useRef } from "react";
import '@arcgis/core/assets/esri/themes/light/main.css';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';

const ArcGISMap = ({ center = [-118.805, 34.027], zoom = 13 }) => {
  const mapRef = useRef(null);
  const mapViewRef = useRef(null);
  const graphicsLayerRef = useRef(null);

  useEffect(() => {
    const map = new Map({
      basemap: "streets-navigation-vector" // Use "streets" basemap
    });

    mapViewRef.current = new MapView({
      container: mapRef.current,
      map: map,
      center: center,
      zoom: zoom
    });

    graphicsLayerRef.current = new GraphicsLayer();
    map.add(graphicsLayerRef.current);

    const addUserLocationMarker = (latitude, longitude) => {
      const point = new Point({
        longitude,
        latitude
      });

      const markerSymbol = new SimpleMarkerSymbol({
        color: [226, 119, 40],
        outline: {
          color: [255, 255, 255],
          width: 2
        }
      });

      const pointGraphic = new Graphic({
        geometry: point,
        symbol: markerSymbol
      });

      graphicsLayerRef.current.add(pointGraphic);
      mapViewRef.current.goTo({
        target: point,
        zoom: 15
      }).catch(err => console.error(err));
    };

    const showLocation = (position) => {
      const { latitude, longitude } = position.coords;
      addUserLocationMarker(latitude, longitude);
    };

    const errorHandler = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showLocation, errorHandler);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }

    return () => {
      if (mapViewRef.current) {
        mapViewRef.current.destroy();
      }
    };
  }, [center, zoom]);

  return (
    <div
      ref={mapRef}
      style={{ width: "100%", height: "100%" }}
    ></div>
  );
};

export default ArcGISMap;
