import React from 'react';

//Material UI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

//Dialog Components
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';

export const MapTableData = (props) => {
    var aMapFields = props.mapping;
    var aFields, iVisibleColumn;
    var aTempSelectControl = [];
    if (props.properties) {
        aFields = props.properties.find(function (oProperty) {
            if (oProperty.id === "idColumnConfig") {
                return oProperty;
            }
        })
        iVisibleColumn = props.properties.find(function (oProperty) {
            if (oProperty.id === "idVisibleColumns") {
                return oProperty;
            }
        })
        if (iVisibleColumn) {
            for (var i = 0; i < iVisibleColumn.value; i++) {
                aTempSelectControl.push(
                    <div id={"idField" + i}>
                        <br></br>
                        <TextField select id={i} label={aFields.value[i].fieldName} sx={{ minWidth: 450 }}
                            onChange={(oEvent) => { handleChange(oEvent) }}
                            name={aFields.value[i].fieldName}
                            defaultValue={props.mapping[aFields.value[i].fieldName]}>

                            {props.inputFields.map((oField) => (<MenuItem
                                key={oField}
                                name={oField}
                                value={oField}>
                                {oField}
                            </MenuItem>))}
                        </TextField>
                    </div>
                )
            }
        }
    }

    /**
     * Event handler for change
     */
    function handleChange(oEvent) {
        aMapFields[oEvent.target.name] = oEvent.target.value;
    }

    /**
     * Event handler for update
     * @param {*} oEvent 
     */
    function handleUpdate(oEvent) {
        props.update(aMapFields);
    }

    return (

        <div>
            <Dialog
                open={props.open}>
                <DialogTitle>
                    Map Data to Table
                </DialogTitle>
                <DialogContent>
                    <FormGroup>
                        {aTempSelectControl}
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button onClick={props.close}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}