import React from 'react';

//Material ui components
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

export const CreateLogicDialog = (props) => {

    /**
     * Event handler for event change
     * @param {event} oEvent | Event
     */
    function handleChangeEvent(oEvent) {
        props.onChangeEvent(oEvent.target.value);
    }

    return (
        <div>
            <TextField
                required
                margin="dense"
                id="name"
                label="Logic Name"
                fullWidth
                variant="standard"
                sx={{ maxWidth: 450 }}
                value={props.logicName}
                onChange={props.onChange}
            />
            <br></br>
            <br></br>
            <br></br>
            <TextField
                required
                label="Choose Event"
                select
                onChange={handleChangeEvent}
                sx={{ minWidth: 450 }}>
                {props.events.map((oEvent) => (
                    <MenuItem
                        key={oEvent.key}
                        value={oEvent.event}>
                        {oEvent.event}
                    </MenuItem>
                ))}
            </TextField>
        </div >
    );
}