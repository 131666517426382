//Server
import Server from "../../../../../serverDetails/server";
export const handleSignUpAndSendEmail = async (
  aProperties,
  oParams,
  aPromises,
  oLogicDatas,
  stopLoop
) => {
  var bAdmin = aProperties.find(
    (oProperty) => oProperty.id === "idSignUp"
  ).value;
  const aSendMapData = aProperties.find(
    (oProperty) => oProperty.id === "idMapSendEmail"
  ).value;
  var oSignUp = {};
  if (oParams.TableData) {
    aSendMapData.params.forEach((oRow) => {
      const matchingEntry = oParams.TableData.find(
        (oEachParam) => oEachParam[oRow.mappingValue] !== undefined
      );
      if (matchingEntry) {
        oSignUp[oRow.field] = matchingEntry[oRow.mappingValue];
      }
    });
  } else {
    aSendMapData.params.forEach((oRow) => {
      oSignUp[oRow.field] = oParams.find(
        (oEachParam) => oEachParam.field === oRow.mappingValue
      ).value;
    });
  }
  if (bAdmin) {
    aPromises.push(
      Server.post(`launchpad/signup`, oSignUp)
        .then((Response) => {
          oLogicDatas.sErrorMessage(Response.data.Message);
          oLogicDatas.setMessageType("success");
          oLogicDatas.bSnackBar(true);
          var sCID = oSignUp.CID;
          var sUserId = oSignUp.USERID;
          handledForgotPassword(sUserId, sCID, oLogicDatas);
        })
        .catch((err) => {
          oLogicDatas.bBusyIndicator(false);
          oLogicDatas.sErrorMessage(err.response.data.error);
          oLogicDatas.setMessageType("error");
          oLogicDatas.bSnackBar(true);
        })
    );
  } else {
    const getValue = (field) =>
      oParams.find((oEachParam) => oEachParam.field === field)?.value;

    const Body = aSendMapData.template
      ? getValue(aSendMapData.body)
      : aSendMapData.body;
    const Subject = aSendMapData.dynamicSubject
      ? getValue(aSendMapData.subject)
      : aSendMapData.subject;

    handleSendMailNotification(Body, Subject, oSignUp, aSendMapData.template);
  }

  async function handledForgotPassword(sUserId, sCID, oLogicDatas) {
    if (sCID !== "" && sUserId !== "") {
      oLogicDatas.bBusyIndicator(true);
      await Server.post(`/sendemail/forgotpassword`, {
        CID: sCID,
        USERID: sUserId,
        subject: "Set Up Your Password",
        SITE: "Launchpad",
        link: `${process.env.REACT_APP_FORGOT_PASSSWORD}/ForgotPassword/${sCID}`,
      })
        .then((res) => {
          oLogicDatas.bBusyIndicator(false);
          if (res.data.message === "Mail sent Successfully") {
            oLogicDatas.sErrorMessage("Check Your Registered Email");
            oLogicDatas.bSnackBar(true);
          } else {
            oLogicDatas.sErrorMessage(res.data.message);
            oLogicDatas.bSnackBar(true);
          }
        })
        .catch((err) => {
          oLogicDatas.bBusyIndicator(false);
          oLogicDatas.sErrorMessage(err.message);
          oLogicDatas.bSnackBar(true);
        });
    } else {
      oLogicDatas.sErrorMessage("CID and USERID cannot Be empty");
      oLogicDatas.bSnackBar(true);
    }
  }

  async function handleSendMailNotification(Body, subject, oSignUp, bTemplate) {
    if (subject === "" || oSignUp.Email === "") {
      oLogicDatas.sErrorMessage("Subject and Email cannot be empty");
      oLogicDatas.bSnackBar(true);
      return;
    }

    oLogicDatas.bBusyIndicator(true);

    try {
      const res = await Server.post(
        `/sendemail`,
        {
          subject,
          Body,
          Email: oSignUp.Email,
          bTemplate
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      oLogicDatas.bBusyIndicator(false);

      if (res.data.message === "Mail sent Successfully") {
        oLogicDatas.iErrorCode(res.status)
        oLogicDatas.setMessageType("success");
        oLogicDatas.sErrorMessage("Mail sent Successfully");
      } else {
        oLogicDatas.iErrorCode(res.status)
        oLogicDatas.setMessageType("error");
        oLogicDatas.sErrorMessage(res.data.message);
      }

      oLogicDatas.bSnackBar(true);
    } catch (err) {
      oLogicDatas.bBusyIndicator(false);
      oLogicDatas.sErrorMessage(err.message || "An error occurred");
      oLogicDatas.bSnackBar(true);
    }
  }
};
