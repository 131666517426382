export const FetchParameter = (aProperties, oParams, oQueryParameters,oLogicDatas) => {
  formattedUIComponentsUserData(oLogicDatas);
  var oFilterQueryParams = {};
  var sFieldNames = aProperties.find((oProperty) => {
    return oProperty.id === 'idGetParameters';
  }).value;
  const fieldNamesArray = sFieldNames.map((field) => field.parameterName);
  fieldNamesArray.forEach((sField) => {
    if (Object.prototype.hasOwnProperty.call(oQueryParameters, sField)) {
      if(sField === 'StateID'){
        oFilterQueryParams[sField] = oLogicDatas.oLocation.state[sField]
      }else{
        oFilterQueryParams[sField] = oQueryParameters[sField];
      }
    }
  });
  if (oFilterQueryParams && Object.keys(oFilterQueryParams).length > 0) {
    oParams.mapSingle = oFilterQueryParams;
  }
};

function formattedUIComponentsUserData(oLogicDatas) {
  oLogicDatas.aCurrentPage.forEach(function (oComponent) {
    if (oComponent.Component === "Input" && (oComponent.UserValue || oComponent.Files)) {
      oComponent.UserValue = "";
      if (oComponent.Files && oComponent.Files.length > 0) {
        oComponent.Files = [];
        oComponent.preview = [];
      }
      var aTempProperties = oComponent.properties;
      var oInputDefaultProperty = oComponent.inputDefault;
      if (oInputDefaultProperty) {
        aTempProperties.push(oInputDefaultProperty);
      }
      oComponent.properties = aTempProperties;
    } else if (oComponent.Component === "DropDown" && oComponent.UserValue) {
      oComponent.UserValue = "";
    } else if (oComponent.Component === "Table" && oComponent.value.rows) {
      oComponent.value.rows = [];
    } else if (oComponent.Component === "G_Gantt" && oComponent.Data){
      oComponent.Data = [];
    }
  });
  oLogicDatas.resetUI([...oLogicDatas.aUIData]);
}