import React, { useState } from "react";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, MenuItem } from "@mui/material";

//Server
import Server from "../../../serverDetails/server";

export const ApiPublish = (props) => {
  const [selectedApi, setSelectedApi] = useState("");
  const [bGeoApi, setGeoApi] = useState(false);

  const handleChange = (event, key) => {
    if (key === "id") {
      setSelectedApi(event.target.value);
    } else {
      setGeoApi(event.target.checked);
    }
  };

  const handlepublishApi = async () => {
    await Server.post(
      "apipublish",
      {
        API_ID: selectedApi,
        GEO_API: bGeoApi,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    props.close();
  };

  /**
   * Event handler to close dialog
   */
  function handleClose() {
    props.close();
  }

  return (
    <div>
      <Dialog open={props.open} fullWidth={true} maxWidth="sm">
        <DialogTitle>Publish API</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              required
              margin="dense"
              id="name"
              label="Customer ID"
              fullWidth
              variant="standard"
              value={localStorage.getItem("CID")}
              InputProps={{
                readOnly: true,
              }}
            />
            <br></br>
            <br></br>
            <br></br>
            <TextField
              select
              required
              margin="dense"
              id="api"
              label="API"
              fullWidth
              variant="standard"
              value={selectedApi}
              onChange={(event) => handleChange(event, "id")}
            >
              {props.data &&
                props.data.map((api) => (
                  <MenuItem key={api.API_ID} value={api.API_ID}>
                    {api.API_DESCRIPTION}
                  </MenuItem>
                ))}
            </TextField>
            <br></br>
            <br></br>
            <br></br>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                defaultValue={bGeoApi}
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "10px",
                }}
                onChange={(event) => handleChange(event, "geo")}
              ></input>
              <label>Geo Locator API</label>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlepublishApi}>Publish</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
