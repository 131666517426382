import React from "react";

export const ConditionOperators = (props) => {
  var inputs = [];
  if(Array.isArray(props.inputs) && props.inputs.length > 0){
    inputs = props.inputs.flat();
  }
  
  function handleChangeField(oEvent) {
    props.changeField(oEvent.target.name, oEvent.target.value);
  }

  function handleChangesubField(oEvent){
    props.changesubField(oEvent.target.name, oEvent.target.value);
  }

  function handleChangeOperator(oEvent) {
    props.changeOperator(oEvent.target.name, oEvent.target.value);
  }

  function handleChangeInputValue(oEvent) {
    props.changeValue(oEvent.target.name, oEvent.target.value);
  }

  function handleChangeDataType(oEvent) {
    props.changeDataType(oEvent.target.name, oEvent.target.value);
  }

  return (
    <div>
      {props.conditions.map((oCondition, i) => {
        return (
          <div style={{display:"flex"}}>
            <input
              type="checkbox"
              checked={props.aSelectedRows[i]}
              onChange={() => props.handleCheckboxChange(i)}
            />
            <select
              className="propertiesValue-Select"
              value={oCondition.field}
              name={i}
              onChange={(oEvent) => {
                handleChangeField(oEvent);
              }}
            >
              {props.fields.map(function (Field) {
                return (
                  <option key={Field} value={Field}>
                    {Field}
                  </option>
                );
              })}
            </select>
            <input
                name={i}
                style={{width:"150px"}}
                className="propertiesValue-Input"
                value={oCondition.subfield}
                onChange={(oEvent) => {
                  handleChangesubField(oEvent);
                }}
            />
            <select
              className="propertiesValue-Select"
              value={oCondition.operator}
              name={i}
              onChange={(oEvent) => {
                handleChangeOperator(oEvent);
              }}
            >
              {props.operators.map(function (operator) {
                return (
                  <option key={operator} value={operator}>
                    {operator}
                  </option>
                );
              })}
            </select>
            {props.inputs.length > 0 ? (
              <select
                className="propertiesValue-Select"
                value={oCondition.value}
                name={i}
                onChange={(oEvent) => {
                  handleChangeInputValue(oEvent);
                }}
              >
                {inputs.map(function (input) {
                  return (
                    <option key={input} value={input}>
                      {input}
                    </option>
                  );
                })}
              </select>
            ) : (
              <input
                name={i}
                className="propertiesValue-Input"
                value={oCondition.value}
                onChange={(oEvent) => {
                  handleChangeInputValue(oEvent);
                }}
              />
            )}
            <select
              className="propertiesValue-Select"
              value={oCondition.dataType}
              name={i}
              onChange={(oEvent) => {
                handleChangeDataType(oEvent);
              }}
            >
              <option value="" disabled>
                Select Data Type
              </option>
              <option value="Number">Number</option>
              <option value="String">String</option>
              <option value="String">Date</option>
            </select>
            <br></br>
          </div>
        );
      })}
    </div>
  );
};
