import React, { useState } from "react";
import Select from "react-select";

// Material ui component
import TextField from "@mui/material/TextField";

export const FormFieldsDialog = (props) => {
  const [aSelectedOptionFields, setSelectedOptionFields] = useState([]);
  const [achooseOptions, setchooseOptions] = useState([]);

  function handleFetchDatas() {
    if (achooseOptions.length < props.aSchemaFieldsData.length) {
      var temp = [...achooseOptions];
      props.aSchemaFieldsData.forEach((oRowData, index) => {
        const options = {
          value: oRowData.inputFieldName,
          label: oRowData.inputFieldName,
        };
        temp.push(options);
      });
      setchooseOptions([...temp]);
    }
  }

  const handleSelectChange = (options) => {
    setSelectedOptionFields([...options]);
    props.setFormFields({...props.selectedFormFields,fields: options})

  }
  function handleChangePageName(oEvent){
    props.setFormFields({pageName:oEvent.target.value,fields:props.selectedFormFields.fields})

  }

  return (
    <div>
      <TextField
        required
        margin="dense"
        id="name"
        label="Second Page Name"
        fullWidth
        variant="outlined"
        value={props.selectedFormFields.pageName}
        onChange={(oEvent)=>handleChangePageName(oEvent)}
        style={{ backgroundColor: 'white' }}
      />
      <br></br>
      <br></br>
      <Select
        isMulti
        options={achooseOptions || []}
        value={props.selectedFormFields.fields || []}
        onMenuOpen={() => handleFetchDatas()}
        className="custom-select"
        onChange={(aSelectedOptionFields)=>handleSelectChange(aSelectedOptionFields)}
      />
    </div>
  );
};
