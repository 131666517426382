import React, { useState} from 'react';

//Material UI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

//Server
import Server from "../../../serverDetails/server";

export const PublishDialog = (props) => {
    const [selectedGroup, setSelectedGroup] = useState("");
    const [bOverwiteCheckbox, setOverwriteCheckbox] = useState(false);
    const [bAppDescChanged, setAppDescChanged] = useState(false);
    var bforce = false;
    var bAdmin=false;
    var sAPP_ID;

    /**
     * Event handler to publish the App
     */
    function handlePublish() {
        if(bAppDescChanged){
            sAPP_ID = null;
        }else{
            sAPP_ID = props.APPID
        }
        Server.post(
          "launchtest/publish",
          {
            APP_ID: sAPP_ID,
            APP_DESC: props.APPDESC,
            force: bforce,
            ADMINAPP:bAdmin
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
          .then(function (response) {
            if (response.data["Entry"]) {
              setOverwriteCheckbox(true);
            } else {
              setOverwriteCheckbox(false);
              setAppDescChanged(false);
              props.close();
            }
          })
          .catch(function (oError) {
            if (oError.response.data) {
              props.error(oError.response.data.Message);
            } else {
              props.error(oError.message);
            }
          });
    }

    /**
     * Event handler to close dialog
     */
    function handleClose() {
        props.close();
    }

    /**
     * Event handler to change groups
     */
    function handleChangeGroups(oEvent) {
        setSelectedGroup(oEvent.target.value)
    }

    /**
     * Event handler to enable overwrite options
     */
    function handleOverwrite() {
        bforce = true;
    }
    /**
     * Event handler to AdminCheck
     */
    function handleAdminCheckBox() {
        bAdmin = true;
    }

    function handleChangeAppDesc(oEvent){
        props.changeDesc(oEvent.target.value);
        setAppDescChanged(true);
    }

    return (
      <div>
        <Dialog open={props.open} fullWidth={true} maxWidth='sm'>
          <DialogTitle>Publish Appilication</DialogTitle>
          <DialogContent>
            <div>
              <TextField
                required
                margin="dense"
                id="name"
                label="Customer ID"
                fullWidth
                variant="standard"
                value={props.CID}
              />
              <br></br>
              <br></br>
              <br></br>
              <TextField
                required
                margin="dense"
                id="name"
                label="Appilication"
                fullWidth
                variant="standard"
                value={props.APPDESC}
                onChange={handleChangeAppDesc}
              />
              <br></br>
              <br></br>
              <br></br>
              {localStorage.getItem("ADMIN") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleAdminCheckBox}
                    />
                  }
                  label="AdminApplication"
                />
              )}
              <br></br>
              <br></br>
              {bOverwiteCheckbox && (
                <FormControlLabel
                  label="Appilication already exist do you want to Overwrite ?"
                  control={<Checkbox onChange={handleOverwrite} />}
                />
              )}
              {bAppDescChanged && <label>App ID has been changed</label>}
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={handlePublish}>
              Publish
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}