import React, { useState, useEffect } from "react";
import "./CodeWriter.css";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MonacoEditor from "react-monaco-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import * as monaco from "monaco-editor";

export const CodeWriterDialog = (props) => {
  const [fCode, setfcode] = useState();
  const [sSearch, setSearch] = useState("");
  const [sOpenAiResponse, setOpenAiResponse] = useState("");

  //Code Editor

  useEffect(() => {
    setfcode(props.defaultcode);
  }, [props.defaultcode]);

  const generateFunctionCode = () => {
    const inputParameters = props.inputs
      .filter((item) => item.Result === "Input")
      .map((item) => !item.Parameters ? item.DefaultValue : item.Parameters)
      .join(", ");

    const outputFields = props.inputs.filter(
      (item) => item.Result === "Output"
    );

    const returnStatement = outputFields
      .map((item) => !item.Parameters ? item.DefaultValue : item.DefaultValue)
      .join(", ");

    const code = `function DefaultExecuteLogic(${inputParameters}) {
    return ${returnStatement};
}`;

    return code;
  };

  if (props.inputs && props.defaultcode === "") {
    const code = generateFunctionCode();
    if (fCode != code) {
      setfcode(code);
    }
  }

  const options = {
    selectOnLineNumbers: true,
    fontSize: 16,
    validationOptions: {
      lint: {
        enable: true,
      },
      validate: true,
      schemas: [
        {
          uri: "https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-configure-json-defaults",
          fileMatch: ["*"],
        },
      ],
    },
  };

  const handleCodeChange = (newCode) => {
    setfcode(newCode);
  };

  //OpenAI
  const handleSearchOpenAi = async () => {
    try {
      const response = await fetch('https://api.openai.com/v1/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ",
        },
        body: JSON.stringify({
          model: 'text-davinci-003',
          prompt: sSearch,
          max_tokens: 100,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data.choices[0].text);
    } catch (error) {
      console.log(error);
    }
  };

  function handelUpdate() {
    props.update(fCode);
  }

  return (
    <div>
      <Dialog open={props.open} maxWidth="xl" fullWidth={true}>
        <DialogTitle style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>Code Editor</div>
          <div style={{ width: "50%", marginLeft: "50px" }}>Open AI</div>
        </DialogTitle>
        <DialogContent>
          <div className="d-flex code-body">
            <div style={{ width: "50%" }}>
              <MonacoEditor
                width="730"
                height="600"
                language="javascript"
                theme="vs-dark"
                value={fCode}
                options={options}
                onChange={handleCodeChange}
              />
            </div>
            <div className="openai">
              <div>
                <input
                  type="text"
                  className="openai-search"
                  onChange={(oEvent) => {
                    setSearch(oEvent.target.value);
                  }}
                ></input>
                <FontAwesomeIcon
                  icon={faTelegram}
                  size="2xl"
                  className="openai-icon"
                  onClick={handleSearchOpenAi}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handelUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
