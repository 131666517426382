import React, { useState } from "react";
import { Routes, Route} from "react-router-dom";
import "./App.css";
import LoginPage from "../../views/loginPage/LoginPage";
import StudioMainPage from "../../views/studioPage/StudioPage";
import ForgotPassword from "../../views/forgotpasswordpage/ForgotPassword";
import PreviewPage from "../../module/previewRenderer/previewPage/previewPage";
import { BrowserRouter } from "react-router-dom";
import PrivateRoute from "../../Context/PrivateRoute";
import { registerLicense } from '@syncfusion/ej2-base';

function Views() {
  const [aInitialCompValues, setComponentValue] = useState([]);
  registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Wd0BjXnxacH1VRGBU');
  /**
   * Refresh Table rows
   * @param {array} aComponentValue| inital load values
   */
  function refreshTableRows(aComponentValue) {
    var aComponentValues = [...aComponentValue];
    setComponentValue(aComponentValues);
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route
          path="/StudioPage"
          element={
            <PrivateRoute>
              <StudioMainPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/PreviewPage/:APP_ID/:sPageID"
          element={
            <PrivateRoute>
            <PreviewPage
              initialValues={aInitialCompValues}
              refresh={refreshTableRows}
            />
            </PrivateRoute>
          }
        />
        <Route
          path="/ForgotPassword/:CID/:USERID"
          element={
            <ForgotPassword element={<ForgotPassword/>}/>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Views;
