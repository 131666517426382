import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export const InsertDBCalculation = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  const aConditions = [
    { value: "", label: "" },
    { value: "$eq", label: "Equal To" },
    { value: "$ne", label: "Not Equal To" },
    { value: "$gt", label: "Greater Than" },
    { value: "$lt", label: "Less Than" },
    { value: "$gte", label: "Greater Than or Equal To" },
    { value: "$lte", label: "Less Than or Equal To" },
    { value: "$contains", label: "Like"}
  ];

  /**
   * Event handler for CheckBox
   * @param {Number} index
   */
  const handleCheckboxChange = (index) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[index] = !aNewSelectedRows[index];
    setSelectedRows(aNewSelectedRows);
  };

  const handleEvent = (event, index, skey) => {
    const { value } = event.target;
    const oUpdatedRows = [...props.rows];
    oUpdatedRows[index][skey] = value;
    props.update(oUpdatedRows);
  };

  /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    const aUpdatedRows = [...props.rows];
    aUpdatedRows.push({
      fieldName: "",
      formula: "",
      childName: "",
      formulaField: "",
      whereField :"",
      condition: "",
    });
    props.update([...aUpdatedRows]);
    setSelectedRows([...aSelectedRows, false]);
  }

  function handleRemoveRow() {
    const aUpdatedRows = props.rows.filter((_, index) => !aSelectedRows[index]);
    props.update([...aUpdatedRows]);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  function handleKeyChange(oEvent, oRowData) {
    var aTemp = [...props.rows];
    aTemp.forEach((oData) => {
      if (oData.value === oRowData.value) {
        if (oEvent.target.checked) {
          oData.key = "X";
        } else {
          oData.key = "";
        }
      }
    });
    props.update(aTemp);
  }
  
  /**
   * Change handleChange Fieldname
   * @param {event} Event
   * @param {object} oRowData
   */
  function handleChangeFieldName(oEvent, iIndex) {
    var aTemp = [...props.rows];
    aTemp[iIndex].fieldName = oEvent.currentTarget.value;
    props.update(aTemp);
  }

  /**
   * Change handleChange calculated Formula
   * @param {event} Event
   * @param {object} oRowData
   */
  function handleChangeCalculatedFormula(oEvent, iIndex) {
    var aTemp = [...props.rows];
    aTemp[iIndex].formula = oEvent.currentTarget.value;
    props.update(aTemp);
  }

  /**
   * Change handleChange calculated Field
   * @param {event} Event
   * @param {object} oRowData
   */
  function handleChangeCalculatedField(oEvent, iIndex) {
    var aTemp = [...props.rows];
    aTemp[iIndex].formulaField = oEvent.currentTarget.value;
    props.update(aTemp);
  }

  /**
   *
   * @param {*} oEvent
   * @param {*} oRowData
   */
  function handleChangeChildItemName(oEvent, iIndex) {
    var aTemp = [...props.rows];
    aTemp[iIndex].childName = oEvent.currentTarget.value;
    props.update(aTemp);
  }

  function handleUpdate() {
    props.handleUpdate(props.rows);
  }
  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={props.open}
        PaperProps={{
          sx: {
            maxHeight: 900,
            overflowY: "auto",
          },
        }}
      >
        <DialogTitle>DB Calculations</DialogTitle>
        <DialogContent>
        <IconButton>
          <AddIcon
            onClick={handleIncreaseRow}
            baseClassName="fas"
            className="fa-plus-circle"
            color="primary"
          />
        </IconButton>
        {props.rows.length >= 1 ? (
          <IconButton>
            <RemoveIcon
              onClick={handleRemoveRow}
              baseClassName="fas"
              className="fa-plus-circle"
              color="secondary"
            />
          </IconButton>
        ) : (
          ""
        )}
          <div>
            <table className="RenderComponent-Table">
              <th className="RenderComponent-Table-th"></th>
              <th className="RenderComponent-Table-th">Fields</th>
              <th className="RenderComponent-Table-th">Array FieldName</th>
              <th className="RenderComponent-Table-th">Formula</th>
              <th className="RenderComponent-Table-th">Fields Used</th>
              <th className="RenderComponent-Table-th">Where</th>
              <th className="RenderComponent-Table-th">Condition</th>
              <th className="RenderComponent-Table-th">Value</th>
              {props.rows.length > 0 &&
                props.rows.map((oRowData, index) => {
                  return (
                    <tr>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="checkbox"
                          checked={aSelectedRows[index]}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="text"
                          value={oRowData.fieldName ? oRowData.fieldName : ""}
                          className="RenderComponent-Table-input"
                          onChange={(oEvent) => {
                            handleChangeFieldName(oEvent, index);
                          }}
                        />
                      </td>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="text"
                          className="RenderComponent-Table-input"
                          value={oRowData.childName ? oRowData.childName : ""}
                          onChange={(oEvent) => {
                            handleChangeChildItemName(oEvent, index);
                          }}
                        />
                      </td>
                      <td className="RenderComponent-Table-td">
                        <select
                          id="dataType"
                          className="RenderComponent-Table-input"
                          value={oRowData.formula ? oRowData.formula : ""}
                          onChange={(oEvent) => {
                            handleChangeCalculatedFormula(oEvent, index);
                          }}
                        >
                          <option key="" value=""></option>
                          <option key="Sum" value="Sum">
                            Sum
                          </option>
                          <option key="Subtract" value="Subtract">
                          Subtraction
                          </option>
                        </select>
                      </td>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="text"
                          className="RenderComponent-Table-input"
                          value={
                            oRowData.formulaField ? oRowData.formulaField : ""
                          }
                          onChange={(oEvent) => {
                            handleChangeCalculatedField(oEvent, index);
                          }}
                        />
                      </td>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="text"
                          className="RenderComponent-Table-input"
                          value={oRowData.whereField ? oRowData.whereField : ""}
                          onChange={(oEvent) => {
                            handleEvent(oEvent, index,"whereField");
                          }}
                        />
                      </td>
                      <td className="RenderComponent-Table-td">
                        <select
                          className="RenderComponent-Table-input"
                          value={oRowData.condition ? oRowData.condition : ""}
                          onChange={(event) => {
                            handleEvent(event, index, "condition");
                          }}
                        >
                          {aConditions.map((odatas) => (
                            <option key={odatas.value} value={odatas.value}>
                              {odatas.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="text"
                          className="RenderComponent-Table-input"
                          value={oRowData.value ? oRowData.value: ""}
                          onChange={(oEvent) => {
                            handleEvent(oEvent, index,'value');
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
