export const CodeExecutor = (aProperties, oParams, oLogicDatas) => {
  let aInputValues;
  var aParameters = aProperties.find(
    (oProperty) => oProperty.id === "idparameterdefinition"
  ).value;
  var fFunctionCode = aProperties.find(
    (oProperty) => oProperty.id === "idcodewriter"
  ).value;
  var aMapInput = aProperties.find(
    (oProperty) => oProperty.id === "idMapInput"
  )?.value;

  if (fFunctionCode) {
    const oParameterValues = {};
    if(aMapInput){
      if (oParams.mapSingle) {
        for (const sKey in oParams.mapSingle) {
          oParameterValues[sKey] = oParams.mapSingle[sKey];
        }
      } else {
        oParams.forEach((param) => {
          oParameterValues[param.field] = param.value;
        });
      }
    }else{
      if (!oParams.TableData || oParams.TableData.length === 0) {
        if (oParams.mapSingle) {
          for (const sKey in oParams.mapSingle) {
            oParameterValues[sKey] = oParams.mapSingle[sKey];
          }
        } else {
          oParams.forEach((param) => {
            oParameterValues[param.field] = param.value;
          });
        }
      }
    }
  
    const aInputParameters = aParameters.filter(
      (param) => param.Result === "Input"
    );
    const aOutputParameter = aParameters.filter(
      (param) => param.Result === "Output"
    );

    aInputValues = aInputParameters.map((param) => {
      const { Parameters, DataType, DefaultValue } = param;

      if (DataType === "Array") {
        if (oParams[Parameters]) {
          return oParams[Parameters];
        } else {
          const aKeys = Object.keys(oParameterValues);
          return oParameterValues[aKeys[0]];
        }
      } else {
        switch (DataType) {
          case "Number":
            return Parameters !== undefined && Parameters.trim() !== ""
              ? Number(oParameterValues[Parameters])
              : DefaultValue;
          case "String":
            if (Parameters === "" && DefaultValue !== undefined) {
              if (DefaultValue === "USERID") {
                return oLogicDatas.USERID;
              } else if (DefaultValue.startsWith("{") && DefaultValue.endsWith("}")) {
                const fieldName = DefaultValue.substring(1, DefaultValue.length - 1);
                return oParameterValues[fieldName];
              } else {
                return DefaultValue;
              }
            } else {
              return oParameterValues[Parameters];
            }
          default:
            return oParameterValues[Parameters];
        }
      }
    });

    const ExecuteCode = eval(`(${fFunctionCode})`);
    try {
      if (
        (aInputParameters.length === 1 || aInputParameters.length === 2) &&
        aInputParameters[0].DataType === "Array"
      ) {
        const FinalResult =  ExecuteCode(...aInputValues);
        var sOutputField = aOutputParameter[0].Parameters;
        var sOutputDataType = aOutputParameter[0].DataType;
        if (sOutputDataType === "String" || sOutputDataType === "Number") {
          oParams.TableData = [{ [sOutputField]: FinalResult }];
        } else if (!oParams.TableData) {
          oParams[0].value = FinalResult;
        } else {
          oParams[sOutputField] = FinalResult;
        }
      } else {
        const FinalResult =  ExecuteCode(...aInputValues);
        if (aOutputParameter[0].DataType === "Object") {
          oParams.forEach(function (param) {
            if (FinalResult[param.field]) {
              param.value = FinalResult[param.field];
            }
          });
        } else {
          var sOutputField = aOutputParameter[0].Parameters;
          if (oParams.mapSingle) {
            oParams.mapSingle[sOutputField] = FinalResult;
          } else {
            oParams.forEach(function (param) {
              if (param.field === sOutputField) {
                param.value = FinalResult;
              }
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
};
