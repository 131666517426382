import React, { useState } from "react";
//Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
//Dialog Components
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const UserInputsValidationDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  const aUserInputsData = props.aUserInputsData;
  const aDataTypes = ["String", "Number", "Date"];
  const aValidationTypeFields = [
    "Should not be null",
    "Value should be between",
    "Array must contain at least one item",
    "Array Value Not Empty"
  ];
  const aMessageTypeFields = ["warning", "error"];
  var aRowsData = [];

  /**
   * Event handler for CheckBox
   * @param {Number} iIndex
   */
  const handleCheckboxChange = (iIndex) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[iIndex] = !aNewSelectedRows[iIndex];
    setSelectedRows(aNewSelectedRows);
  };

  /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    var aTemp = [...props.aUserInputsData];
    aTemp.push({
      inputField: "",
      validationType: "",
      arrayField:"",
      dataType: "",
      from: "",
      to: "",
      messageType: "",
      message: "",
    });
    props.setUserInputsData(aTemp);
    setSelectedRows([...aSelectedRows, false]);
  }

  /**
   * Event handler for Remove Rows
   */
  function handleDecreaseRow() {
    var aTemp = [...props.aUserInputsData];
    const aUpdatedRows = aTemp.filter((_, index) => !aSelectedRows[index]);
    props.setUserInputsData(aUpdatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  if (props.aUserInputsData.length > 0) {
    for (let i = 0; i < props.aUserInputsData.length; i++) {
      aRowsData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={aSelectedRows[i]}
              onChange={() => handleCheckboxChange(i)}
            />
          </td>
          <td className="td-space">
            <select
              value={aUserInputsData[i].inputField}
              onChange={(oEvent) => handleFieldsChange(oEvent, "inputField", i)}
              required
              className="column-title"
            >
              <option value="">Select </option>
              {props.fields.map((oFieldName, index) => {
                return (
                  <option
                    key={index}
                    id={oFieldName.id}
                    value={oFieldName.name}
                  >
                    {oFieldName.name}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) =>
                handleFieldsChange(oEvent, "validationType", i)
              }
              required
              value={aUserInputsData[i].validationType}
              className="column-title"
            >
              <option value="">Select </option>
              {aValidationTypeFields.map((sValidationField, index) => {
                return (
                  <option value={sValidationField} key={index}>
                    {sValidationField}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="td-space">
            <input
              type="test"
              value={aUserInputsData[i].arrayField}
              onChange={(oEvent) => handleFieldsChange(oEvent, "arrayField", i)}
              readOnly={aUserInputsData[i].validationType === "Array Value Not Empty" ? false : true}
              className="column-title"
            />
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) => handleFieldsChange(oEvent, "dataType", i)}
              required
              value={aUserInputsData[i].dataType}
              className="column-title"
            >
              <option value="">Select </option>
              {aDataTypes.map((sDataType, index) => {
                return (
                  <option value={sDataType} key={index}>
                    {sDataType}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="td-space">
            <input
              type={aUserInputsData[i].dataType}
              value={aUserInputsData[i].from}
              onChange={(oEvent) => handleFieldsChange(oEvent, "from", i)}
              className="column-title"
            />
          </td>
          <td className="td-space">
            <input
              type={aUserInputsData[i].dataType}
              value={aUserInputsData[i].to}
              onChange={(oEvent) => handleFieldsChange(oEvent, "to", i)}
              className="column-title"
            />
          </td>
          <td className="td-space">
            <select
              value={aUserInputsData[i].messageType}
              onChange={(oEvent) =>
                handleFieldsChange(oEvent, "messageType", i)
              }
              required
              className="column-title"
            >
              <option value="">Select </option>
              {aMessageTypeFields.map((sMessageFieldName, index) => {
                return (
                  <option value={sMessageFieldName} key={index}>
                    {sMessageFieldName}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="td-space">
            <input
              type="text"
              value={aUserInputsData[i].message}
              onChange={(oEvent) => handleFieldsChange(oEvent, "message", i)}
              className="column-title"
            />
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler for Fields
   * @param {*} oEvent
   * @param {Number} iIndex
   * @param {string} skey
   */
  const handleFieldsChange = (oEvent, skey, iIndex) => {
    if (skey === "inputField") {
      const selectedId =
        oEvent.target.options[oEvent.target.selectedIndex].getAttribute("id");
      var tempUserInputsData = [...props.aUserInputsData];
      tempUserInputsData[iIndex][skey] = oEvent.target.value;
      tempUserInputsData[iIndex]["inputFieldId"] = selectedId;
      props.setUserInputsData(tempUserInputsData);
    } else {
      tempUserInputsData = [...props.aUserInputsData];
      tempUserInputsData[iIndex][skey] = oEvent.target.value;
      props.setUserInputsData(tempUserInputsData);
    }
  };

  /**
   * Event handler for Update
   */
  const handleSubmit = () => {
    props.update(props.aUserInputsData);
  };

  return (
    <div>
      <Dialog
        open={props.open}
      >
        <DialogTitle>
          Configure User Inputs
          <IconButton onClick={handleIncreaseRow}>
            <AddIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="primary"
            />
          </IconButton>
          <IconButton onClick={handleDecreaseRow}>
            <RemoveIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="secondary"
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <table id="idTableConfiguration" className="RenderComponent-Table">
            <thead>
              <tr>
                <th></th>
                <th className="RenderComponent-Table-th">Input Fields</th>
                <th className="RenderComponent-Table-th">Validation Type</th>
                <th className="RenderComponent-Table-th">Array Field</th>
                <th className="RenderComponent-Table-th">Data Type</th>
                <th className="RenderComponent-Table-th">From</th>
                <th className="RenderComponent-Table-th"> To</th>
                <th className="RenderComponent-Table-th">Message Type</th>
                <th className="RenderComponent-Table-th">Message</th>
              </tr>
            </thead>
            <tbody>{aRowsData}</tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
