import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";

import { ReturnArrayParameter } from "./ReturnArrayParameter";

export const ReturnParameterDialog = (props) => {
  const [methodData, setMethodData] = useState({});
  const [selectedRows, setSelectedRows] = useState({});

  const [bReturnDialog, setReturnDialog] = useState(false);
  const [oArrayFields, setArrayFields] = useState([]);
  const [sSelectedArrayKey, setSelectedArrayKey] = useState();

  useEffect(() => {
    if (props.oMethods) {
      setMethodData(props.oMethods.ReturnParameters || {});
    }
  }, [props]);

  const handleCheckboxChange = (key) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [key]: !prevSelectedRows[key],
    }));
  };

  const handleRemoveRow = () => {
    const updatedRows = { ...methodData };
    const updatedSelectedRows = { ...selectedRows };
    Object.keys(selectedRows).forEach((key) => {
      if (selectedRows[key]) {
        delete updatedRows[key];
        delete updatedSelectedRows[key];
      }
    });
    setMethodData(updatedRows);
    setSelectedRows(updatedSelectedRows);
  };

  const handleIncreaseRow = () => {
    const newKey = `parameter${Object.keys(methodData).length + 1}`;
    setMethodData({
      ...methodData,
      [newKey]: { type: "" },
    });
  };

  const handleEventChange = (event, key, sKey) => {
    if (sKey) {
      setMethodData((prevMethodData) => ({
        ...prevMethodData,
        [key]: {
          ...prevMethodData[key],
          [sKey]: event.target.value,
        },
      }));
    } else {
      const newKey = event.target.value;
      setMethodData((prevMethodData) => {
        const updatedMethodData = { ...prevMethodData };
        updatedMethodData[newKey] = updatedMethodData[key];
        delete updatedMethodData[key];
        return updatedMethodData;
      });
    }
  };

  const handleUpdateArray = (aFields) => {
    setMethodData((prevMethodData) => ({
      ...prevMethodData,
      [sSelectedArrayKey]: {
        ...prevMethodData[sSelectedArrayKey],
        fields: aFields,
      },
    }));
    handleReturnClose();
  };

  const handleReturnDialog = (key) => {
    setArrayFields(methodData[key])
    setSelectedArrayKey(key);
    setReturnDialog(true);
  };

  const handleReturnClose = () => {
    setReturnDialog(false);
  };

  const handleCancel = () => {
    setMethodData({});
    props.close();
  };

  const handleConfirm = () => {
    props.update(methodData);
  };

  return (
    <>
      <Dialog
        open={props.open}
        maxWidth="sm"
        fullWidth={true}
        sx={{ minHeight: "300px" }}
      >
        <DialogTitle>{"ReturnParameters"}</DialogTitle>
        <DialogContent>
          <Box>
            <IconButton onClick={handleIncreaseRow}>
              <AddIcon color="primary" />
            </IconButton>
            <IconButton onClick={handleRemoveRow}>
              <RemoveIcon color="secondary" />
            </IconButton>
            <table id="idTableConfiguration" className="RenderComponent-Table">
              <thead>
                <tr>
                  <th></th>
                  <th className="RenderComponent-Table-th">
                    ReturnParameter Name
                  </th>
                  <th className="RenderComponent-Table-th">Data Type</th>
                  <th className="RenderComponent-Table-th">Array Field</th>
                </tr>
              </thead>
              <tbody>
                {methodData &&
                  Object.entries(methodData).map(([key, value], index) => (
                    <tr key={index}>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="checkbox"
                          checked={selectedRows[key] || false}
                          onChange={() => handleCheckboxChange(key)}
                        />
                      </td>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="text"
                          className="RenderComponent-Table-input"
                          value={key}
                          onChange={(event) => handleEventChange(event, key)}
                        />
                      </td>
                      <td className="RenderComponent-Table-td">
                        <select
                          className="RenderComponent-Table-input"
                          value={value.type}
                          onChange={(event) =>
                            handleEventChange(event, key, "type")
                          }
                        >
                          <option disabled value="">
                            Select DataType
                          </option>
                          <option value="Number">Number</option>
                          <option value="String">String</option>
                          <option value="Array">Array</option>
                          <option value="Object">Object</option>
                        </select>
                      </td>
                      <td className="RenderComponent-Table-td">
                        {value.type === "Array" && (
                          <IconButton
                            size="small"
                            onClick={() => {
                              handleReturnDialog(key);
                            }}
                          >
                            <SettingsIcon />
                          </IconButton>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>Update</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <ReturnArrayParameter
        open={bReturnDialog}
        close={handleReturnClose}
        arrayFields={oArrayFields}
        update={handleUpdateArray}
      />
    </>
  );
};
