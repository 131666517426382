import React, { useState } from "react";

//Material UI Components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export const CreateApi = (props) => {
  const [apiDescription, setApiDescription] = useState("");
  const [bGeoAPI, setGeoAPI] = useState(false);

  function handleCancel() {
    props.close();
  }
  function handleConfirm() {
    props.create(apiDescription,bGeoAPI);
  }

  const handleChange = (event) => {
    setGeoAPI(event.target.checked);
  };

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{"Create New API"}</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <label
            htmlFor="api-description"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            API Description
          </label>
          <input
            id="api-description"
            type="text"
            placeholder="Enter API description"
            style={{
              padding: "10px",
              fontSize: "14px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              outline: "none",
              transition: "border-color 0.3s",
              width: "100%",
            }}
            onChange={(e) => setApiDescription(e.target.value)}
            onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
            onBlur={(e) => (e.target.style.borderColor = "#ccc")}
          />
          <br></br>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              defaultValue={bGeoAPI}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "10px",
              }}
              onChange={(event) => handleChange(event)}
            ></input>
            <label>Geo Locator API</label>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Create</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
