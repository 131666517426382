import React from "react";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

//Dialog Components
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const BarChartMapping = (props) => {
  var rowData = [];

  function handleIncreaseRow() {
    var aTemp = [...props.aColumns];
    aTemp.push({
      check: "",
      fieldName: "",
      mappingValue: "",
      color: "",
    });
    props.setColumns(aTemp);
  }

  function handleDecreaseRow() {
    if (props.aColumns.length > 1) {
      var aSelectedRows = [];
      var aTemp = [...props.aColumns];
      aTemp.forEach(function (oColumn, index) {
        if (oColumn.check) {
          aSelectedRows.push(index);
        }
      });
      aSelectedRows.sort((a, b) => b - a);
      for (let i of aSelectedRows) {
        aTemp.splice(i, 1);
      }
      props.setColumns(aTemp);
    }
  }

  if (props.aColumns.length > 0) {
    for (let i = 0; i < props.aColumns.length; i++) {
      rowData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={props.aColumns[i].check}
              onChange={(oEvent) => handleCheckboxChange(oEvent, i)}
            />
          </td>
          <td className="RenderComponent-Table-td">
            <input
              className="RenderComponent-Table-input"
              value={props.aColumns[i].mappingValue}
              placeholder={
                i === 0 ? "Choose Category Here" : "Choose Values  Here"
              }
              onChange={(oEvent) => {
                handleChangeMappingValue(oEvent, i);
              }}
            />
          </td>
          <td className="RenderComponent-Table-td">
            <select
              className="RenderComponent-Table-input"
              value={props.aColumns[i].fieldName}
              onChange={(oEvent) => {
                handleChangeField(oEvent, i);
              }}
            >
              <option value="select">Select</option>
              {props.fields.map(function (Field) {
                return (
                  <option key={Field} value={Field}>
                    {Field}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="RenderComponent-Table-td">
            <select
              className="RenderComponent-Table-input"
              value={props.aColumns[i].color}
              onChange={(oEvent) => {
                handleChangeColor(oEvent, i);
              }}
            >
              <option key="input" value="select">
                select
              </option>
              <option key="input" value="blue">
                Blue
              </option>
              <option key="inputHelp" value="red">
                Red
              </option>
              <option key="dropDown" value="orange">
                Orange
              </option>
            </select>
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler for FieldName
   * @param {*} oEvent
   * * @param {Number} i
   */
  function handleChangeField(oEvent, i) {
    var tempColumns = [...props.aColumns];
    tempColumns[i].fieldName = oEvent.target.value;
    props.setColumns(tempColumns);
  }

  /**
   * Event handler for Mapping Value
   * @param {*} oEvent
   * * @param {Number} i
   */
  function handleChangeMappingValue(oEvent, i) {
    var tempColumns = [...props.aColumns];
    tempColumns[i].mappingValue = oEvent.target.value;
    props.setColumns(tempColumns);
  }

  /**
   * Event handler for Check box
   * @param {*} oEvent
   * * @param {Number} i
   */
  function handleCheckboxChange(oEvent, i) {
    var tempColumns = [...props.aColumns];
    tempColumns[i].check = oEvent.target.checked;
    props.setColumns(tempColumns);
  }

  /**
   * Event handler for Color
   * @param {*} oEvent
   * * @param {Number} i
   */
  function handleChangeColor(oEvent, i) {
    var tempColumns = [...props.aColumns];
    tempColumns[i].color = oEvent.target.value;
    props.setColumns(tempColumns);
  }
  /**
   * Event handler for Update
   */
  function handleUpdate() {
    props.update(props.aColumns);
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>
          Map BarChart
          <IconButton onClick={handleIncreaseRow}>
            <AddIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="primary"
            />
          </IconButton>
          {props.aColumns.length > 1 ? (
            <IconButton onClick={handleDecreaseRow}>
              <RemoveIcon
                baseClassName="fas"
                className="fa-plus-circle"
                color="secondary"
              />
            </IconButton>
          ) : (
            ""
          )}
        </DialogTitle>
        <DialogContent>
          <table id="idTableConfiguration" className="RenderComponent-Table">
            <tr>
              <th className="RenderComponent-Table-th">Select</th>
              <th className="RenderComponent-Table-th">Field Names</th>
              <th className="RenderComponent-Table-th">Mapping Value</th>
              <th className="RenderComponent-Table-th">Select Colour</th>
            </tr>
            {rowData}
          </table>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};