import React from "react";

//Material UI Components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export const PageConfirm = (props) => {

    function handleCancel(){
        props.cancel()
    }

    function handleConfirm(){
        props.delete(props.page)
        props.cancel()
    }

    return (
        <Dialog open={props.open} maxWidth="xs" fullWidth={true}>
          <DialogTitle>
            {"Delete Logic"} - {props.page.name} - {"?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Do you want to delete the Page ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleConfirm}>Delete</Button>
          </DialogActions>
        </Dialog>
      );
}