import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { DialogTitle } from "@mui/material";
import { _retrieveUserVersion } from "../../../views/studioPage/utils/privateMethods";

export const MobileViewDialog = (props) => {
  const [oGetVersion, setGetVersion] = useState({});

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        props.setLoading(true);
        var oResponse = await _retrieveUserVersion(props.USERID);
        if (oResponse.data.UserVersion) {
          let oVersion = oResponse.data.UserVersion;
          if(!oVersion.hasOwnProperty("MOBILE_DATA")){
            oVersion.MOBILE_DATA = {}
          }
          setGetVersion(oVersion);
          // Use oVersion as needed
        }
        props.setLoading(false);
      } catch (error) {
        console.error("Error retrieving user version:", error);
        props.setLoading(false);
      }
    };

    fetchVersion();
  }, [props.USERID]);

  function handleChangeBoth(){
    props.onBoth()
  }

  function handleChangeProceed() {
    props.onProceed();
  }

  function handleCloseMobileView() {
    props.onClose();
  }
  return (
    <Dialog open={props.open}>
      <DialogTitle> Mobile View </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to proceed with adapting the web application for mobile
          use?
          {oGetVersion.UIDATA &&
            (Object.keys(oGetVersion.MOBILE_DATA).length <= 0)&& (
              <> Additionally, do you want to import data to the mobile view?</>
            )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {oGetVersion.UIDATA &&
          (Object.keys(oGetVersion.MOBILE_DATA).length <= 0) && (
            <Button autoFocus onClick={handleChangeBoth}>
              Proceed & Import Data
            </Button>
          )}
        <Button autoFocus onClick={handleChangeProceed}>
          Proceed
        </Button>
        <Button onClick={handleCloseMobileView} autoFocus>
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};