import React, { useState } from "react";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

//Dialog Components
import { CreateLogicDialog } from "../createLogic/CreateLogicDialog";
import { ImportLogicDialog } from "../importLogic/ImportLogicDialog";

export const LogicDialog = (props) => {
  const [sLogicName, setLogicName] = useState("");
  const [sEvent, setEvent] = useState("");
  var aLogic = props.selectedComponent["Logic"];
  const [iTabValue, setTabValue] = useState(0);
  const [oAllLogics, setAllLogics] = useState({});
  const [sSelectLogicID, setSelectLogicID] = useState("");
  const [aSelectedPageLogics, setSelectedPageLogics] = useState([]);

  if (
    Object.keys(props.appJson).length > 0 &&
    Object.keys(oAllLogics).length < 1
  ) {
    var pages = Object.keys(props.appJson);
    var oLogics = {};
    pages.forEach((eachPage) => {
      if (eachPage !== "forms") {
        oLogics[eachPage] = [];
        props.appJson[eachPage].UIComponents.forEach((oComponent) => {
          if (oComponent.Logic.length > 0) {
            oComponent.Logic.forEach((oLogic) => {
              oLogics[eachPage].push(oLogic);
            });
          }
        });
      }
    });
    setAllLogics(oLogics);
  }

  /**
   * Event handler for Tab change
   * @param {event} oEvent
   * @param {Number} iNewValue
   */
  function handleTabChange(oEvent, iNewValue) {
    setTabValue(iNewValue);
  }
  /**
   * Event handler for logic name change
   * @param {event} oEvent | drop down event
   */
  function handleChangeLogicName(oEvent) {
    setLogicName(oEvent.currentTarget.value);
  }

  /**
   * Event handler for logic create
   */
  function handleCreate() {
    if ((sLogicName !== "") & (sEvent !== "")) {
      var oNewLogic = {
        label: sLogicName,
        id: "id" + "Logic" + aLogic.length + props.selectedComponent.uniqueId,
        event: sEvent,
        component: [
          {
            id: "id" + sEvent + "Event",
            type: "input",
            data: { label: sEvent, id: "id" + sEvent },
            position: { x: 50, y: 50 },
            sourcePosition: "right",
            properties: [],
          },
        ],
        edges: [],
        validations: [],
      };
      props.onCreate(oNewLogic);
    }
  }

  /**
   * return copy of arrays
   */
  function deepCopy(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(deepCopy);
    }

    const oNewObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        oNewObj[key] = deepCopy(obj[key]);
      }
    }

    return oNewObj;
  }

  /**
   * Event handler for logic input
   */
  function handleInputLogic() {
    if (sLogicName !== "" && sEvent !== "") {
      const oCopyLogic = aSelectedPageLogics.find(
        (oLogic) => oLogic.id === sSelectLogicID
      );
      const oTempComponents = deepCopy(oCopyLogic.component);
      const oTempEdges = deepCopy(oCopyLogic.edges);
      const oTempValidations = deepCopy(oCopyLogic.validations);
      const iLogicLength = aLogic.length + 1;

      const oTempLogic = {
        label: sLogicName,
        id: "id" + "Logic" + iLogicLength + props.selectedComponent.uniqueId,
        event: sEvent,
        component: [
          {
            id: "id" + sEvent + "Event",
            type: "input",
            data: { label: sEvent, id: "id" + sEvent },
            position: { x: 50, y: 50 },
            sourcePosition: "right",
            properties: [],
          },
        ],
        edges: [],
        validations: [],
      };

      oTempComponents.forEach((oComponent, index) => {
        if (index >= 1) {
          const aParts = oComponent.id.split(/Logic/);
          oComponent.id =
            aParts[0] +
            "Logic" +
            iLogicLength +
            props.selectedComponent.uniqueId;
          oTempLogic.component.push(oComponent);
        }
      });

      oTempEdges.forEach((oEdge, index) => {
        if (index >= 1) {
          const aSourceParts = oEdge.source.split(/Logic/);
          const aTargetParts = oEdge.target.split(/Logic/);
          oEdge.source =
            aSourceParts[0] +
            "Logic" +
            iLogicLength +
            props.selectedComponent.uniqueId;
          oEdge.target =
            aTargetParts[0] +
            "Logic" +
            iLogicLength +
            props.selectedComponent.uniqueId;
          oTempLogic.edges.push(oEdge);
        } else {
          const aParts = oEdge.target.split(/Logic/);
          oEdge.target =
            aParts[0] +
            "Logic" +
            iLogicLength +
            props.selectedComponent.uniqueId;
          oEdge.source = "id" + sEvent + "Event";
          oTempLogic.edges.push(oEdge);
        }
      });

      oTempValidations.forEach((oValidation, index) => {
        var iValidationLength = oTempLogic.validations.length + 1;
        oValidation.logicId = oTempLogic.id;
        const aParts = oValidation.id.split(/idLogic/);
        var aTempValidationComponents = deepCopy(oValidation.component);
        var aTempValidationEdges = deepCopy(oValidation.edges);

        aTempValidationComponents.forEach((oValidationComponent, index) => {
          if (index >= 1) {
            const aParts = oValidationComponent.id.split(/Validation/);
            oValidationComponent.id =
              aParts[0] +
              "Validation" +
              iValidationLength +
              props.selectedComponent.uniqueId;
          } else {
            oValidationComponent.id = oTempLogic.id;
            oValidationComponent.data.id = oTempLogic.id;
            oValidationComponent.data.label = oTempLogic.label;
          }
        });
        aTempValidationEdges.forEach((oValidationEdge) => {
          if (index >= 1) {
            const aSourceParts = oValidationEdge.source.split(/Validation/);
            const aTargetParts = oValidationEdge.target.split(/Validation/);
            oValidationEdge.source =
              aSourceParts[0] +
              "Validation" +
              iValidationLength +
              props.selectedComponent.uniqueId;
            oValidationEdge.target =
              aTargetParts[0] +
              "Validation" +
              iValidationLength +
              props.selectedComponent.uniqueId;
          } else {
            const aParts = oValidationEdge.target.split(/Validation/);
            oValidationEdge.target =
              aParts[0] +
              "Validation" +
              iValidationLength +
              props.selectedComponent.uniqueId;
            oValidationEdge.source = oTempLogic.id;
          }
        });
        oValidation.id = aParts[0] + oTempLogic.id;
        oValidation.component = aTempValidationComponents;
        oValidation.edges = aTempValidationEdges;
        oTempLogic.validations.push(oValidation);
      });

      props.onCreate(oTempLogic);
    }
  }

  /**
   * Event handler for change event
   * @param {string} sEvent | logic event
   */
  function handleChangeEvent(sEvent) {
    setEvent(sEvent);
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>
          Create New Logic for {props.selectedComponent.Component}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={iTabValue} onChange={handleTabChange}>
              <Tab label="Create" />
              <Tab label="Import" />
            </Tabs>
          </Box>
          <br></br>
          {iTabValue === 0 && (
            <CreateLogicDialog
              logicName={sLogicName}
              component={props.selectedComponent}
              events={props.events}
              onChange={handleChangeLogicName}
              onChangeEvent={handleChangeEvent}
            />
          )}
          {iTabValue === 1 && (
            <ImportLogicDialog
              logicName={sLogicName}
              appJson={props.appJson}
              component={props.selectedComponent}
              events={props.events}
              oAllLogics={oAllLogics}
              aSelectedPageLogics={aSelectedPageLogics}
              onChange={handleChangeLogicName}
              onChangeEvent={handleChangeEvent}
              selectLogicID={setSelectLogicID}
              selectedPageLogics={setSelectedPageLogics}
            />
          )}
        </DialogContent>
        <DialogActions>
          {iTabValue === 0 && (
            <Button type="submit" onClick={handleCreate}>
              Create
            </Button>
          )}
          {iTabValue === 1 && (
            <Button type="submit" onClick={handleInputLogic}>
              Import
            </Button>
          )}
          <Button onClick={props.onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};