export function ComponentAlignment(aUIData) {
  if (aUIData && aUIData.length > 0) {
    const iFirstLabelIndex = aUIData.findIndex(component => component.Component === 'Label');
    const iFirstInputIndex = aUIData.findIndex(component => component.Component === 'Input');
    if (iFirstLabelIndex !== -1) {
        const firstLabelLeft = aUIData[iFirstLabelIndex].left;
        const firstLabelTop = aUIData[iFirstLabelIndex].top;
        var iIncrement = 0;
        aUIData.forEach((component, index) => {
            if (component.Component === 'Label') {
                aUIData[index].left = firstLabelLeft;
                aUIData[index].top = firstLabelTop + iIncrement;
                iIncrement+=40;
            }
        });
    }
    if(iFirstInputIndex !== -1){
        const firstInputLeft = aUIData[iFirstInputIndex].left;
        const firstInputTop = aUIData[iFirstInputIndex].top;
        const firstInputHeight = aUIData[iFirstInputIndex].DivHeight;
        const firstInputWidth = aUIData[iFirstInputIndex].DivWidth;
        var iIncrement = 0;
        aUIData.forEach((component, index) => {
            if (component.Component === 'Input' || component.Component === "DropDown") {
                aUIData[index].left = firstInputLeft;
                aUIData[index].top = firstInputTop + iIncrement;
                aUIData[index].DivHeight = firstInputHeight;
                aUIData[index].DivWidth = firstInputWidth;
                iIncrement+=40;
            }
        });
    }
    return aUIData;
  } else {
    return null;
  }
}
