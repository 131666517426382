import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const VisibleColumnsDialog = (props) => {
  var aFieldCheckBox = [];

  function handleCheckBox(oEvent, i) {
    props.aColumnsDesc[i].visibleColumn = oEvent.target.checked;
  }

  if (props.aColumnsDesc.length > 0) {
    for (let i = 0; i < props.aColumnsDesc.length; i++) {
      if (props.aColumnsDesc[i].visibleColumn) {
        aFieldCheckBox.push(
          <FormControlLabel
            control={
              <Checkbox
                checked
                onChange={(oEvent) => {
                  handleCheckBox(oEvent, i);
                }}
              />
            }
            label={props.aColumnsDesc[i].fieldName}
          />
        );
      } else {
        aFieldCheckBox.push(
          <FormControlLabel
            control={
              <Checkbox
                onChange={(oEvent) => {
                  handleCheckBox(oEvent, i);
                }}
              />
            }
            label={props.aColumnsDesc[i].fieldName}
          />
        );
      }
    }
  }

  return (
    <>
      <Dialog 
        open={props.open} 
        onClose={props.handleClose}
        PaperProps={{
          sx: {
            width: 900,
            maxHeight: 900,
          },
        }}
        BackdropComponent="div"
        BackdropProps={{
          style: {
            background: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle margin={'auto'}>VisibleColumns</DialogTitle>
        <DialogContent>
          <FormGroup>{aFieldCheckBox}</FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
