import React, { useState, useEffect } from "react";

//Material ui components
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//Server
import Server from "../../../serverDetails/server";

export const CreateDialog = (props) => {
  const [aProjects, setProjects] = useState([]);
  const [aFeatures, setFeatures] = useState([]);

  useEffect(() => {
    // Runs after the first render() lifecycle
    var USERID = props.userid;
    if (aProjects.length === 0) {
      retrieveUserVersion(USERID);
    }
  }, [aProjects]);

  /**
   * Retrive user version of the appilicaiton
   * @param {string} sUserId | userID
   */
  function retrieveUserVersion(sUserId) {
    Server.get(`/editor/projects`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.data.Projects) {
          setProjects(response.data.Projects);
          setFeatures(response.data.Features);
        }
      })
      .catch(function (oError) {
        if (oError.response.data) {
          props.error(oError.response.data.Message);
        } else {
          props.error(oError.message);
        }
      });
  }

  /**
   * Event handler to select the project
   * @param {event} oEvent
   */
  function handleSelectProject(oEvent) {
    var oSelectedProject = aProjects.find(function (oProject) {
      return oProject.CNAME === oEvent.target.value;
    });
    props.select(oSelectedProject.CNAME, oSelectedProject.CID);
  }

  function handleSelectFeature(oEvent) {
    var oSelectedFeature = aFeatures.find(function (oProject) {
      return oProject.FEAT_DESC === oEvent.target.value;
    });
    props.selectFeature(oSelectedFeature.FEAT_DESC, oSelectedFeature.FEAT_ID);
  }

  return (
    <div>
      <TextField
        required
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Project Name"
        select
        value={props.selectedProject}
        sx={{ minWidth: 450 }}
        onChange={handleSelectProject}
      >
        {aProjects.map((project) => (
          <MenuItem key={project.CID} name={project.CID} value={project.CNAME}>
            {project.CNAME}
          </MenuItem>
        ))}
      </TextField>
      <br></br>
      <br></br>
      {localStorage.getItem("CID") === "SYSTEM" && (
        <>
          <TextField
            required
            labelId="demo-feature-select-label"
            id="demo-feature-select"
            label="Feature"
            select
            value={props.selectedFeature}
            sx={{ minWidth: 450 }}
            onChange={handleSelectFeature}
          >
            {aFeatures.map((feature) => (
              <MenuItem
                key={feature.FEAT_ID}
                name={feature.FEAT_ID}
                value={feature.FEAT_DESC}
              >
                {feature.FEAT_DESC}
              </MenuItem>
            ))}
          </TextField>
          <br></br>
          <br></br>
        </>
      )}
      <TextField
        required
        margin="dense"
        id="name"
        label="Appilication Name"
        fullWidth
        variant="standard"
        sx={{ maxWidth: 450 }}
        onChange={props.appNameChange}
      />
      <br></br>
      <br></br>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(oEvent) => props.selectDashBoard(oEvent, "DashBoard")}
            />
          }
          label="DashBoard"
        />
        {localStorage.getItem("ADMIN") && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(oEvent) => props.selectDashBoard(oEvent, "AdminApp")}
              />
            }
            label="AdminApplication"
          />
        )}
      </FormGroup>
    </div>
  );
};
