import React, { useState} from 'react';

//Material UI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const ColumnConfig = (props) => {
    const [aColumns, setColumns] = useState(props.columns);
    const rowData = [];
    
    if (aColumns.length < 1 && props.columns.length > 0) {
        setColumns([...props.columns]);
    }

    if(props.iVisibleColumns > aColumns.length && aColumns.length > 0){
        var atemp = [...aColumns];
        atemp.push({
            fieldName : "column",
            dataType : "String",
            dataLength: "",
            inputType: "input",
            defaultValue: "",
            visibleColumn: "",
            readOnly: "",
            key: "",
            sequenceNumber: "",
        })
        setColumns([...atemp]);
    }

    if (aColumns.length > 0) {
        for (let i = 0; i < props.iVisibleColumns; i++) {
            rowData.push(
                <tr>
                    <td
                        className='RenderComponent-Table-td'>
                        <input
                            className='RenderComponent-Table-input'
                            value={aColumns[i] ? aColumns[i].fieldName : ""}
                            onChange={(oEvent) => { handleChangeFieldName(oEvent, i) }} />
                    </td>
                    <td
                        className='RenderComponent-Table-td'>
                        <select
                            id="dataType"
                            className='RenderComponent-Table-input'
                            value={aColumns[i] ? aColumns[i].dataType : ""}
                            onChange={(oEvent) => { handleChangeDataType(oEvent, i) }}>
                            <option key="String" value="String">String</option>
                            <option key="Number" value="Number">Number</option>
                            <option key="Date" value="Date">Date</option>
                            <option key="datetime-local" value="datetime-local">Date Time</option>
                            <option key="file" value="file">file</option>

                        </select>
                    </td>
                    <td
                        className='RenderComponent-Table-td'>
                        <input
                            className='RenderComponent-Table-input'
                            type="number"
                            onChange={(oEvent) => { handleChangeDataLength(oEvent, i) }}
                            value={aColumns[i] ? aColumns[i].dataLength : ""} />
                    </td>
                    <td
                        className='RenderComponent-Table-td'>
                        <select
                            id="inputType"
                            className='RenderComponent-Table-input'
                            value={aColumns[i] ? aColumns[i].inputType : ""}
                            onChange={(oEvent) => { handleChangeInputType(oEvent, i) }}>
                            <option key="input" value="input">Input</option>
                            <option key="inputHelp" value="inputHelp">InputHelp</option>
                            <option key="dropDown" value="dropDown">DropDown</option>
                            <option key="Mselect" value="Mselect">Mselect</option>
                        </select>
                    </td>
                    <td
                        className='RenderComponent-Table-td'>
                        {aColumns[i] &&
                            (aColumns[i].dataType === 'Date' || aColumns[i].dataType === 'datetime-local') ? 
                            (<select
                                className='RenderComponent-Table-input'
                                onChange={(oEvent) => { handleChangeDefaultValue(oEvent, i, aColumns[i].dataType) }}
                                value={aColumns[i] ? aColumns[i].defaultValue : ""}
                            >
                                <option value="" disabled></option>
                                <option value="Today">Today</option>
                                <option value="Tomorrow">Tomorrow</option>
                            </select>)
                            : ( <input
                                className='RenderComponent-Table-input'
                                type={aColumns[i] ? aColumns[i].dataType : ""}
                                onChange={(oEvent) => { handleChangeDefaultValue(oEvent, i, aColumns[i].dataType) }}
                                value={aColumns[i] ? aColumns[i].defaultValue : ""}
                            />)
                        }
                    </td>
                    <td className="RenderComponent-Table-td">
                        <input
                            type="checkbox"
                            className=""
                            checked={aColumns[i] ? aColumns[i].visibleColumn : ""}
                            onChange={(oEvent) => handleChangeCheckBox(oEvent, i)}
                        />
                    </td>
                    <td className='RenderComponent-Table-td'>
                        { aColumns[i] && aColumns[i].sequenceNumber === 'X' ? (<input type="radio" name="auto1" checked value={aColumns[i].sequenceNumber} onChange={(oEvent) => { handleSequenceNumberChange(oEvent,i) }} />) :
                            (<input type="radio" name="auto1"  onChange={(oEvent) => { handleSequenceNumberChange(oEvent,i) }} />)
                        }
                    </td>
                    <td className="RenderComponent-Table-td">
                        <input
                            type="checkbox"
                            className=""
                            checked={aColumns[i] ? aColumns[i].readOnly : ""}
                            onChange={(oEvent) => handleChangeReadOnlyCheckBox(oEvent, i)}
                        />
                    </td>
                    <td className='RenderComponent-Table-td'>
                        { aColumns[i] && aColumns[i].key === 'X' ? (<input type="radio" name="auto" checked value={aColumns[i].key} onChange={(oEvent) => { handleKeyChange(oEvent,i) }} />) :
                            (<input type="radio" name="auto"  onChange={(oEvent) => { handleKeyChange(oEvent,i) }} />)
                        }
                    </td>
                </tr>
            );
        }
    }

    function handleChangeFieldName(oEvent, i){
        var tempColumns = [...aColumns];
        tempColumns[i].fieldName = oEvent.target.value;
        setColumns(tempColumns);
    }

    function handleChangeDataType(oEvent, i){
        var tempColumns = [...aColumns];
        tempColumns[i].dataType = oEvent.target.value;
        setColumns(tempColumns);
    }

    function handleChangeDataLength(oEvent, i){
        var tempColumns = [...aColumns];
        tempColumns[i].dataLength = oEvent.target.value;
        setColumns(tempColumns);
    }

    function handleChangeInputType(oEvent, i){
        var tempColumns = [...aColumns];
        tempColumns[i].inputType = oEvent.target.value;
        setColumns(tempColumns);
    }

    function handleChangeDefaultValue(oEvent, i, sdatatype){
        var tempColumns = [...aColumns];
        tempColumns[i].defaultValue = oEvent.target.value;
        setColumns(tempColumns);
    }

    function handleChangeCheckBox(oEvent, i) {
      var tempColumns = [...aColumns];
      tempColumns[i].visibleColumn = oEvent.target.checked;
      setColumns(tempColumns);
    }

    function handleChangeReadOnlyCheckBox(oEvent, i) {
      var tempColumns = [...aColumns];
      tempColumns[i].readOnly = oEvent.target.checked;
      setColumns(tempColumns);
    }

    function handleKeyChange(oEvent, i) {
      var tempColumns = [...aColumns];
      tempColumns.forEach((oColumn, index) => {
        if (index === i && oEvent.target.checked) {
          oColumn.key = "X";
        } else {
          oColumn.key = "";
        }
      });
      setColumns(tempColumns);
    }

    function handleSequenceNumberChange(oEvent, i) {
        var tempColumns = [...aColumns];
        tempColumns.forEach((oColumn, index) => {
          if (index === i && oEvent.target.checked) {
            oColumn.sequenceNumber = "X";
          } else {
            oColumn.sequenceNumber = "";
          }
        });
        setColumns(tempColumns);
    }

    /**
     * Event handler
     */
    function handleUpdate() {
        props.update(aColumns);
    }

    return (
      <div>
        <Dialog
          open={props.open}
          PaperProps={{
            sx: {
              width: 900,
              maxHeight: 900,
            },
          }}
        >
          <DialogTitle>Configure Column Description</DialogTitle>
          <DialogContent>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <table
                id="idTableConfiguration"
                className="RenderComponent-Table"
              >
                <tr>
                  <th className="RenderComponent-Table-th">Column Names</th>
                  <th className="RenderComponent-Table-th">Data Type</th>
                  <th className="RenderComponent-Table-th">length</th>
                  <th className="RenderComponent-Table-th">InputType</th>
                  <th className="RenderComponent-Table-th">Default Value</th>
                  <th className="RenderComponent-Table-th">visible Columns</th>
                  <th className="RenderComponent-Table-th">Sequence Number</th>
                  <th className="RenderComponent-Table-th">Read Only</th>
                  <th className="RenderComponent-Table-th">Key</th>
                </tr>
                {rowData}
              </table>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUpdate}>Update</Button>
            <Button onClick={props.close}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}