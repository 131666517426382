import React, { useState} from 'react';
import { PropertyTable } from './propertyTable/PropertyTable';

//Material UI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const InsertDBProperties = (props) => {
    
   const [oData, setData] = useState([]);

    function handleUpdate(){
        var oTempData = oData;
        props.updateData(oTempData);
    }

    function updateData(oUpdatedData){
        setData([...oUpdatedData])
    }

    return (
        <div>
           <Dialog maxWidth="md" fullWidth={true}
                open={props.open}
                PaperProps={{
                    sx: {
                      maxHeight: 900
                    }
                  }}>
                <DialogTitle>
                    DB Properties
                </DialogTitle>
                <DialogContent>
                    <PropertyTable
                        data={props.fields}
                        update={updateData}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button onClick={props.close}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}