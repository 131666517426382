/**
 * calculated Table Arithematic operations
 * @param {array} aProperties
 * @param {object} oParams
 * @param {array} aUIData
 */

export const CalculateTableArithematicOperations = (
  aProperties,
  oParams,
  aUIData
) => {
  var finalData = {};
  var sTableName;
  var oParamsData;
  var aColumnFields;
  var aWhereConditions;
  var aFilteredData;
  var oMapping = aProperties.find(function (oProperty) {
    return oProperty.id === "idTableCalculator";
  }).value;
  if (oMapping) {
    aColumnFields = oMapping.columnFields;
    aWhereConditions = oMapping.whereConditions;
  }
  var sTableID = aProperties.find(function (oProperty) {
    return oProperty.id === "idDropDownTable";
  }).value;
  if (sTableID) {
    aUIData.forEach(function (oComponent) {
      if (
        oComponent.Component === "Table" &&
        oComponent.uniqueId === sTableID
      ) {
        oComponent.properties.forEach(function (oProperty) {
          if (oProperty.id === "idInputTableName") {
            sTableName = oProperty.value;
          }
        });
      }
    });
  }
  if (oParams.TableData) {
    oParamsData = oParams.TableData;
  } else {
    oParams.forEach(function (oProperty) {
      if (sTableName === oProperty.field) {
        oParamsData = oProperty.value;
      }
    });
  }
  if (aWhereConditions.length > 0 && oParamsData) {
    aFilteredData = oParamsData.filter((oItem) =>
      executeConditions(oItem, aWhereConditions)
    );
    oParamsData = aFilteredData;
  }
  aColumnFields.forEach(function (oMappingProperty) {
    if (oMappingProperty.operator) {
      var data = [];
      oParamsData.forEach(function (oRowData) {
        data.push(oRowData[oMappingProperty.fieldName]);
      });
      var result = calculateData(data, oMappingProperty.operator);
      finalData[oMappingProperty.fieldName] = result;
    }
  });
  oParams.TableData = finalData;

  function calculateData(values, operator) {
    if (operator === "Add") {
      const sum = values.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    } else if (operator === "Multiply") {
      const product = values.reduce(
        (accumulator, currentValue) => accumulator * currentValue,
        1
      );
      return product;
    }
  }

  /**
   * checking each Condition
   * @param {object} oItem
   * @param {array} aCondition
   * @returns
   */

  function executeConditions(oItem, aConditions) {
    let bResult = false;

    for (let i = 0; i < aConditions.length; i++) {
      const { field, condition, value, type } = aConditions[i];
      const bCurrentConditionSatisfied = checkCondition(
        oItem,
        field,
        condition,
        value,
        type
      );

      if (i === 0) {
        bResult = bCurrentConditionSatisfied;
      } else {
        if (aConditions[i - 1].logicCondition === "OR") {
          bResult = bResult || bCurrentConditionSatisfied;
        } else {
          bResult = bResult && bCurrentConditionSatisfied;
        }
      }
    }

    return bResult;
  }

  /**
   * checking each Condition
   * @param {object} oItem
   * @param {string} field
   * @param {string} condition
   * @param {string} type
   * @returns
   */
  function checkCondition(oItem, field, condition, value, type) {
    const fieldValue = oItem[field];
    switch (condition) {
      case "$eq":
        return fieldValue === value;
      case "$ne":
        if (fieldValue !== value) {
          return true;
        }
        break;
      case "$lt":
        if (fieldValue < value) {
          return true;
        }
        break;
      case "$gt":
        if (fieldValue > value) {
          return true;
        }
        break;
      case "$gte":
        if (fieldValue >= value) {
          return true;
        }
        break;
      case "$lte":
        if (fieldValue <= value) {
          return true;
        }
        break;

      default:
        return false;
    }
  }
};
