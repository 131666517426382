import React, { useState } from "react";
//Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
//Dialog Components
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const DynamicValidationMessagesDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  const aUserInputsData = props.aValidationMessageData;
  const aDataTypes = ["String", "Number", "Date","Boolean"];

  const aConditions = [
    { value: "", label: "" },
    { value: "$eq", label: "Equal To" },
    { value: "$ne", label: "Not Equal To" },
    { value: "$gt", label: "Greater Than" },
    { value: "$lt", label: "Less Than" },
    { value: "$gte", label: "Greater Than or Equal To" },
    { value: "$lte", label: "Less Than or Equal To" },
    { value: "$contains", label: "Like" },
    { value: "$between", label: "Between" }
  ];
  const aMessageTypeFields = ["warning", "error"];
  var aRowsData = [];

  /**
   * Event handler for CheckBox
   * @param {Number} iIndex
   */
  const handleCheckboxChange = (iIndex) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[iIndex] = !aNewSelectedRows[iIndex];
    setSelectedRows(aNewSelectedRows);
  };

  /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    var aTemp = [...props.aValidationMessageData];
    aTemp.push({
      inputField: "",
      condition: "",
      dataType: "",
      value:"",
      messageType: "",
      message: "",
    });
    props.setValidationMessageData(aTemp);
    setSelectedRows([...aSelectedRows, false]);
  }

  /**
   * Event handler for Remove Rows
   */
  function handleDecreaseRow() {
    var aTemp = [...props.aValidationMessageData];
    const aUpdatedRows = aTemp.filter((_, index) => !aSelectedRows[index]);
    props.setValidationMessageData(aUpdatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }
  if (props.aValidationMessageData.length > 0) {
    for (let i = 0; i < props.aValidationMessageData.length; i++) {
      aRowsData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={aSelectedRows[i]}
              onChange={() => handleCheckboxChange(i)}
            />
          </td>
          <td className="td-space">
            <select
              value={aUserInputsData[i].inputField}
              onChange={(oEvent) => handleFieldsChange(oEvent, "inputField", i)}
              required
              className="column-title"
            >
              <option value="">Select </option>
              {props.fields.map((oFieldName, index) => {
                return (
                  <option
                    key={index}
                    id={oFieldName}
                    value={oFieldName}
                  >
                    {oFieldName}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) =>
                handleFieldsChange(oEvent, "validationType", i)
              }
              required
              value={aUserInputsData[i].validationType}
              className="column-title"
            >
              <option value="">Select </option>
              {aConditions.map((sValidationField, index) => {
                return (
                  <option value={sValidationField.value} key={index}>
                    {sValidationField.label}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) => handleFieldsChange(oEvent, "dataType", i)}
              required
              value={aUserInputsData[i].dataType}
              className="column-title"
            >
              <option value="">Select </option>
              {aDataTypes.map((sDataType, index) => {
                return (
                  <option value={sDataType} key={index}>
                    {sDataType}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="td-space">
            <input
              type={aUserInputsData[i].dataType}
              value={aUserInputsData[i].value}
              onChange={(oEvent) => handleFieldsChange(oEvent, "value", i)}
              className="column-title"
            />
          </td>
          <td className="td-space">
            <select
              value={aUserInputsData[i].messageType}
              onChange={(oEvent) =>
                handleFieldsChange(oEvent, "messageType", i)
              }
              required
              className="column-title"
            >
              <option value="">Select </option>
              {aMessageTypeFields.map((sMessageFieldName, index) => {
                return (
                  <option value={sMessageFieldName} key={index}>
                    {sMessageFieldName}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="td-space">
            <input
              type="text"
              value={aUserInputsData[i].message}
              onChange={(oEvent) => handleFieldsChange(oEvent, "message", i)}
              className="column-title"
            />
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler for Fields
   * @param {*} oEvent
   * @param {Number} iIndex
   * @param {string} skey
   */
  const handleFieldsChange = (oEvent, skey, iIndex) => {
    if (skey === "inputField") {
      var aTempUserInputsData = [...props.aValidationMessageData];
      aTempUserInputsData[iIndex][skey] = oEvent.target.value;
      props.setValidationMessageData(aTempUserInputsData);
    } else {
      aTempUserInputsData = [...props.aValidationMessageData];
      aTempUserInputsData[iIndex][skey] = oEvent.target.value;
      props.setValidationMessageData(aTempUserInputsData);
    }
  };

  /**
   * Event handler for Update
   */
  const handleSubmit = () => {
    props.update(props.aValidationMessageData);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        maxWidth="md" fullWidth={true}
      >
        <DialogTitle>
          Configure Validation Messages
          <IconButton onClick={handleIncreaseRow}>
            <AddIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="primary"
            />
          </IconButton>
          <IconButton onClick={handleDecreaseRow}>
            <RemoveIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="secondary"
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <table id="idTableConfiguration" className="RenderComponent-Table">
            <thead>
              <tr>
                <th></th>
                <th className="RenderComponent-Table-th">source Fields</th>
                <th className="RenderComponent-Table-th">Condition Type</th>
                <th className="RenderComponent-Table-th">Data Type</th>
                <th className="RenderComponent-Table-th">Condition Value</th>
                <th className="RenderComponent-Table-th">Message Type</th>
                <th className="RenderComponent-Table-th">Message</th>
              </tr>
            </thead>
            <tbody>{aRowsData}</tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
