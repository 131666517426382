import React, { useState, useEffect } from "react";

// Material ui component
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ScaleLoader from "react-spinners/ScaleLoader";

//Server
import Server from "../../../serverDetails/server";

export const OpenDialog = (props) => {
  const [aProjects, setProjects] = useState([]);
  const [aFeatures, setFeatures] = useState([]);
  const [aApps, setApps] = useState([]);
  const [aAppData, setAppData] = useState([]);
  const [bLoading, setLoading] = useState(false);

  /**
   * Fetch the project assigned to the current user
   */
  useEffect(() => {
    if (aProjects.length === 0) {
      Server.get("/editor/projects", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          if (response.data.Projects) {
            setProjects(response.data.Projects);
            setFeatures(response.data.Features);
          }
        })
        .catch(function (oError) {
          if (oError.response.data) {
            props.error(oError.response.data.Message);
          } else {
            props.error(oError.message);
          }
        });
    }
  }, [aProjects]);

  /**
   * Event handler to select the project
   * Fetch the list apps to the selected project
   * @param {event} oEvent
   */
  function handleSelectProject(oEvent) {
    var oSelectedProject = aProjects.find(function (oProject) {
      return oProject.CNAME === oEvent.target.value;
    });
    props.selectProject(oSelectedProject.CNAME, oSelectedProject.CID);
    setLoading(true);
    Server.get(`/editor/apps`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setLoading(false);
        if (response.data.Apps) {
          setAppData(response.data.Apps);
          if (localStorage.getItem("CID") === "SYSTEM") {
            setApps([]);
          } else {
            setApps(response.data.Apps);
          }
        }
      })
      .catch(function (oError) {
        setLoading(false);
        if (oError.response.data) {
          props.error(oError.response.data.Message);
        } else {
          props.error(oError.message);
        }
      });
  }

  function handleSelectFeature(oEvent) {
    var oSelectedFeature = aFeatures.find(function (oProject) {
      return oProject.FEAT_DESC === oEvent.target.value;
    });
    if (oEvent.target.value === "Admin") {
      props.selectFeature("Admin", "Admin");
    } else {
      props.selectFeature(oSelectedFeature.FEAT_DESC, oSelectedFeature.FEAT_ID);
    }
    var aData;
    if (oEvent.target.value === "Admin") {
      aData = aAppData.filter((item) => item.ADMINAPP === true);
    } else {
      aData = aAppData.filter(
        (item) => item.FEAT_ID === oSelectedFeature.FEAT_ID
      );
    }
    setApps(aData);
  }

  /**
   * Handler to select the app
   * @param {event} oEvent
   */
  function handleSelectApp(oEvent) {
    var oApp = aApps.find(function (oApp) {
      return oApp.APP_DESC === oEvent.target.value;
    });
    props.selectApp(oApp.APP_DESC, oApp.APPID);
  }

  return (
    <div>
      {bLoading && (
        <div className="overlay">
          <ScaleLoader
            color={"#033146"}
            loading={bLoading}
            aria-label="Loading Spinner"
            data-testid="loader"
            height={25}
            width={6}
          />
        </div>
      )}
      <TextField
        required
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Project Name"
        select
        value={props.selectedProject}
        onChange={handleSelectProject}
        sx={{ minWidth: 450 }}
      >
        {aProjects.map((project) => (
          <MenuItem key={project.CID} name={project.CID} value={project.CNAME}>
            {project.CNAME}
          </MenuItem>
        ))}
      </TextField>
      <br></br>
      <br></br>
      {localStorage.getItem("CID") === "SYSTEM" && (
        <>
          <TextField
            required
            labelId="demo-feature-select-label"
            id="demo-feature-select"
            label="Feature"
            select
            value={props.selectedFeature}
            sx={{ minWidth: 450 }}
            onChange={handleSelectFeature}
          >
            <MenuItem key="admin" name="admin" value="Admin">
              Admin
            </MenuItem>
            {aFeatures.map((feature) => (
              <MenuItem
                key={feature.FEAT_ID}
                name={feature.FEAT_ID}
                value={feature.FEAT_DESC}
              >
                {feature.FEAT_DESC}
              </MenuItem>
            ))}
          </TextField>
          <br></br>
          <br></br>
        </>
      )}
      <TextField
        required
        label="Appilication Name"
        select
        value={props.selectedApp}
        onChange={handleSelectApp}
        sx={{ minWidth: 450 }}
      >
        {aApps.map((app) => (
          <MenuItem key={app.APPID} name={app.APP_DESC} value={app.APP_DESC}>
            {app.APP_DESC}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};
