import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import "./WizardDialog.css";

import { SchemaDialog } from "./schemaDialog/SchemaDialog";
import { TableFieldsDialog } from "./tableFields/TableFieldsDialog";
import { SearchFieldsDialog } from "./searchFields/searchFieldsDialog";
import { FormFieldsDialog } from "./formFields/FormFields";

export const WizardDialog = (props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("Template");

  const aWizardMenuItems = [
    "Template",
    "Schema",
    "Search Fields",
    "Table Fields",
    "Form Fields",
    "Validation",
  ];
  const aTemplateCards = ["CRUD", "CRED"];

  function handleMenuItemClick(menuItem, Operation) {
    if (Operation == "next") {
      const selectedIndex = aWizardMenuItems.indexOf(selectedMenuItem);
      setSelectedMenuItem(aWizardMenuItems[selectedIndex + 1]);
    } else if (Operation == "back") {
      const selectedIndex = aWizardMenuItems.indexOf(selectedMenuItem);
      if (selectedIndex != 0) {
        setSelectedMenuItem(aWizardMenuItems[selectedIndex - 1]);
      }
    } else {
      setSelectedMenuItem(menuItem);
    }
  }

  function handleOnClickApp(sTemplateCard) {
    props.setSelectTemplateCard(sTemplateCard);
  }

  function handleCancel() {
    setSelectedMenuItem(null);
    props.handleCancel(false);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onBackdropClick="false"
        disableEscapeKeyDown
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          App Generation Wizard
        </DialogTitle>
        <DialogContent>
          <div className="wizard-control">
            <div>
              <Stack direction="row" spacing={2}>
                <Paper sx={{ width: 200, height: 400 }}>
                  <MenuList>
                    {aWizardMenuItems.map((sMenuItem) => {
                      return (
                        <MenuItem
                          sx={{
                            backgroundColor:
                              selectedMenuItem === sMenuItem
                                ? "#16202a"
                                : "inherit",
                            "&:hover": {
                              backgroundColor:
                                selectedMenuItem === sMenuItem
                                  ? "#16202a"
                                  : "#abd0e2;;",
                            },
                          }}
                        >
                          <label
                            className={
                              selectedMenuItem === sMenuItem
                                ? "Wizard-menu-Label"
                                : ""
                            }
                          >
                            {sMenuItem}
                          </label>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Paper>
              </Stack>
            </div>
            <div className="wizard-dropZone">
              {selectedMenuItem === "Template" &&
                aTemplateCards.map((sTemplateCard) => {
                  return (
                    <div
                      className={`Wizard-CardListItem ${
                        sTemplateCard === props.sSelectTemplateCard
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => {
                        handleOnClickApp(sTemplateCard);
                      }}
                    >
                      <div className="Wizard-AppList-AppTitle">
                        {sTemplateCard}
                      </div>
                    </div>
                  );
                })}
              {selectedMenuItem === "Schema" && (
                <SchemaDialog
                  aSchemaFieldsData={props.aSchemaFieldsData}
                  setSchemaFieldsData={props.setSchemaFieldsData}
                />
              )}
              {selectedMenuItem === "Table Fields" && (
                <TableFieldsDialog
                  aTableFieldsData={props.aTableFieldsData}
                  setTableFieldsData={props.setTableFieldsData}
                  aSchemaFieldsData={props.aSchemaFieldsData}
                />
              )}
              {selectedMenuItem === "Search Fields" && (
                <SearchFieldsDialog
                  aSchemaFieldsData={props.aSchemaFieldsData}
                  setSearchFields={props.setSearchFields}
                  selectedSearchFields={props.aSearchFields}
                />
              )}
              {selectedMenuItem === "Form Fields" && (
                <FormFieldsDialog
                  aSchemaFieldsData={props.aSchemaFieldsData}
                  setFormFields={props.setFormFields}
                  selectedFormFields={props.aFormFields}
                />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {selectedMenuItem !== "Template" ? (
            <Button
              onClick={() => handleMenuItemClick(selectedMenuItem, "back")}
            >
              Back
            </Button>
          ) : (
            ""
          )}

          {selectedMenuItem === "Validation" ? (
            <Button onClick={props.handlecreate}>Create</Button>
          ) : (
            <Button
              onClick={() => handleMenuItemClick(selectedMenuItem, "next")}
            >
              Next
            </Button>
          )}
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
