import { jsPDF } from 'jspdf';

export const FormDownload = (aProperties, allUIData, aFormParameters, sHeaderData) => {
  const formName = Object.keys(allUIData.forms).find(
    (forms) => allUIData.forms[forms].name === aProperties[0].value
  );
  const bJoinForm = aProperties.find(
    (oProperty) => oProperty.id === 'idJoinForm'
  ).value;

  if (bJoinForm) {
    const sJoinForm = aProperties.find(
      (oProperty) => oProperty.id === 'idJoinFormName'
    ).value;
    const joinformName = Object.keys(allUIData.forms).find(
      (forms) => allUIData.forms[forms].name === sJoinForm
    );
    var aJoinUIData = allUIData.forms[joinformName].UIComponents;
  }

  const aUIData = allUIData.forms[formName].UIComponents;

  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: 'a4'
  });
  const oTatalPages = { value: 1 };
  formDownload(doc, aUIData, aFormParameters, oTatalPages);
  if (bJoinForm && aJoinUIData) {
    doc.addPage();
    oTatalPages.value++;
    formDownload(doc, aJoinUIData, aFormParameters, oTatalPages);
  }
  var pdfname = "table.pdf";
  const sPDF = aProperties.find(
    (oProperty) => oProperty.id === "idPDFName"
  )?.value;
  const regex = /\{[^}]*\}/;
  if(sPDF){
    const sName = sPDF.replace(regex, sHeaderData).trim();
    pdfname = sName;
  }
  doc.save(pdfname);
};

function formDownload(doc, aUIData, aFormParameters, oTatalPages) {
  const iInitialPageValue = oTatalPages.value;
  let largestTop = aUIData[0].top;
  for (let i = 1; i < aUIData.length; i++) {
    if (aUIData[i].top > largestTop) {
      largestTop = aUIData[i].top;
    }
  }
  const totalPages = Math.ceil(largestTop / 600);
  for (let page = 1; page < totalPages; page++) {
    doc.addPage();
    oTatalPages.value++;
  }
  aUIData = addPagetoComponent(aUIData, oTatalPages, iInitialPageValue);
  const oTable = aUIData.filter((datas) => datas.Component === 'Table');
  const oLabel = aUIData.filter((datas) => datas.Component === 'Label');

  if (oLabel) {
    oLabel.map((datas) => {
      doc.setPage(datas.Page);
      let label = {};
      let sfontStyle = 'normal';
      let sfontSize = 12;
      datas.properties.map((datas1) => {
        if (datas1.id === 'idInputTextLabel') {
          if (datas1.value !== '') {
            label = {
              text: datas1.value,
              top: datas.top - 100,
              left: datas.left - 460
            };
            doc.setFillColor('white');
            doc.setDrawColor('white');
            doc.setLineWidth(0);
          }
        } else if (datas1.id === 'idMapData' && datas1.value) {
          aFormParameters.map((oParams) => {
            if (datas.uniqueId === oParams.field) {
              if (oParams.value === undefined || oParams.value === '') {
                label = {
                  text: 'No Data',
                  top: datas.top - 100,
                  left: datas.left - 460
                };
              } else {
                label = {
                  text: oParams.value.toString(),
                  top: datas.top - 100,
                  left: datas.left - 460
                };
              }
            }
          });
          if (label !== null && Object.keys(label).length === 0) {
            label = {
              text: 'No Data',
              top: datas.top - 100,
              left: datas.left - 460
            };
          }
          doc.setFillColor('white');
          doc.setDrawColor('white');
          doc.setLineWidth(0);
        }

        if (datas1.id === 'idInputStyleLabel') {
          if (
            datas1.value.toLowerCase() === 'bolder' ||
            datas1.value.toLowerCase() === 'bold'
          ) {
            sfontStyle = 'bold';
          }
        }
        if (datas1.id === 'idInputSizeLabel') {
          const textSizeMapping = {
            small: 10,
            medium: 12,
            large: 16,
            'x-large': 20,
            'xx-large': 24
          };
          sfontSize = textSizeMapping[datas1.value.toLowerCase()] || 12;
        }
      });
      doc.setFont('times new roman', sfontStyle);
      doc.setFontSize(sfontSize);
      doc.text(label.left, label.top, label.text);
    });
  }

  if (oTable) {
    const pageHeight = 600;
    oTable.forEach((datas) => {
      doc.setPage(datas.Page);
      const tableX = datas.left - 460;
      const tableY = datas.top - 120;
      const tableWidth = datas.DivWidth - 120;
      const headers = [];
      const values = FindTableValues(datas, aFormParameters);
      let iTableHeadSize = 12;
      let sTableFont = 'normal';
      let iTableDataSize = 12;

      datas.properties.forEach((datas1) => {
        if (datas1.id === 'idColumnConfig') {
          const fieldNames = datas1.value.map((item) => item.fieldName);
          headers.push(...fieldNames);
        } else if (datas1.id === 'idTableHeadSize') {
          iTableHeadSize = +datas1.value;
        } else if (datas1.id === 'idTableHeadFont') {
          sTableFont = datas1.value;
        } else if (datas1.id === 'idTableDataSize') {
          iTableDataSize = +datas1.value;
        }
      });

      const cellWidth = tableWidth / headers.length;
      let cellHeight = 20;
      const lineHeight = 10;
      let currentY = tableY;
      let icurrentY = tableY;

      doc.setFont('times new roman', sTableFont);
      doc.setFontSize(iTableHeadSize);
      let bNewLine = false;
      headers.forEach((header, index) => {
        const x = tableX + index * cellWidth;
        const y = tableY;
        const textWidth =
          (doc.getStringUnitWidth(header) * iTableHeadSize) /
          doc.internal.scaleFactor;
        const headerX = x + cellWidth / 2 - textWidth / 2;
        const headerY = y + cellHeight / 2 + lineHeight / 2;

        if (textWidth > cellWidth) {
          const words = header.split(' ');
          let line = '';
          let headerYPosition = headerY;
          words.forEach((word) => {
            const newLine = line + word + ' ';
            const newLineWidth =
              (doc.getStringUnitWidth(newLine) * iTableHeadSize) /
              doc.internal.scaleFactor;
            if (newLineWidth > cellWidth) {
              doc.setDrawColor(0);
              doc.setFillColor(200, 200, 200);
              doc.rect(
                x,
                headerYPosition - lineHeight / 2,
                cellWidth,
                cellHeight,
                'FD'
              );
              doc.text(line.trim(), x + 5, headerYPosition + 2);
              headerYPosition += lineHeight;
              line = word + ' ';
              bNewLine = true;
            } else {
              line = newLine;
            }
          });
          if (line.trim().length > 0) {
            doc.text(line.trim(), x + 5, headerYPosition + 2);
          }
        } else {
          doc.setDrawColor(0);
          doc.setFillColor(200, 200, 200);
          doc.rect(x, headerY - lineHeight / 2, cellWidth, cellHeight, 'FD');
          doc.text(header, headerX, headerY + 2);
        }
      });
      if (bNewLine) {
        icurrentY += lineHeight;
      }

      const keysArray = headers;
      let rowIndex = 0;
      if (values) {
        doc.setFont('times new roman', 'normal');
        values.forEach((row) => {
          let bNewLine = false;
          const rowHeight = rowIndex === 0 ? cellHeight : 0;
          if (currentY + rowHeight + cellHeight > pageHeight) {
            doc.addPage();
            currentY = 0;
            rowIndex = 0;
            icurrentY = 0;
            oTatalPages.value++;
          }
          keysArray.forEach((header, cellIndex) => {
            const cell = String(row[header]);
            const x = tableX + cellIndex * cellWidth;
            let y = icurrentY + (rowIndex + 1) * cellHeight;

            const cellTextLines = doc.splitTextToSize(cell, cellWidth);
            doc.setDrawColor(0);
            doc.setFillColor(255, 255, 255);
            if (cellTextLines.length > 1) {
              bNewLine = true;
            }

            doc.rect(x, y, cellWidth, cellHeight, 'FD');
            cellTextLines.forEach((line, index) => {
              const yOffset = y + lineHeight * (index + 1);
              doc.text(line, x + 2, yOffset);
            });
          });

          rowIndex++;
          currentY += cellHeight;
        });
      }
      doc.setFontSize(iTableDataSize);
    });
  }
}

function FindTableValues(oDatas, aFormParameters) {
  let values;
  aFormParameters.map((oParams) => {
    if (oParams.field === oDatas.uniqueId) {
      values = oParams.value;
    }
  });

  if (values) {
    values.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (row[key] === null) {
          row[key] = ' ';
        }
      });
    });
    return values;
  } else {
    return undefined;
  }
}

function addPagetoComponent(aUIData, oTotalPage, iInitialPageValue) {
  aUIData.forEach(function (oData) {
    if (oData.Component !== 'Loop') {
      const pageNumber = Math.ceil(oData.top / 600);
      oData.top = oData.top - 600 * (pageNumber - 1);
      oData.Page = iInitialPageValue + (pageNumber - 1);
    }
  });
  return aUIData;
}
