import Server from "../../../../serverDetails/server";
export default  async function fetchTableListWithFields(){
    let tableListWithFields = [];
    const requestData = {
        collections: [
            {
                collectionName: "tablenames",
                selectionFields: ["tableName", "fields"],
                selectSingle: "",
            },
        ],
        joinCondition: [
            {
                condition: "",
                fields: [
                    {
                        firstTableField: "",
                        secondTableField: "",
                    },
                ],
                firstTable: "",
                secondTable: "",
                joinName: "",
            },
        ],
        WHERE: [],
    };

    await Server.post("/logics/db/fetch", requestData, {
        headers: {
            Authorization: localStorage.getItem("token"),
        },
    }).then(function (response) {
        tableListWithFields = response.data.Data;
    });

    return tableListWithFields;
};