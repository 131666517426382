import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export const ConditionDialog = (props) => {
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setUpdatedConditions(props.datas);
  }, [props.datas]);

  /**
   * Event handler
   * @param {event} event
   * @param {Number} index
   * @param {stringr} skey
   */
  const handleEvent = (event, index, skey) => {
    const { value } = event.target;
    const updatedRows = [...updatedConditions];
    updatedRows[index][skey] = value;
    setUpdatedConditions(updatedRows);
  };

  /**
   * handler for check box
   * @param {Number} index
   */
  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  /**
   * handler for Increased Rows
   */
  function handleIncreaseRow() {
    const updatedRows = [...updatedConditions];
    updatedRows.push({
      firstTableField: "",
      secondTableField: "",
    });
    setUpdatedConditions(updatedRows);
    setSelectedRows([...selectedRows, false]);
  }

  /**
   * handler for Removed Rows
   */
  function handleRemoveRow() {
    const updatedRows = updatedConditions.filter(
      (_, index) => !selectedRows[index]
    );
    setUpdatedConditions(updatedRows);
    setSelectedRows(selectedRows.filter((_, index) => !selectedRows[index]));
  }

  /**
   * handler for Update Dialog Data
   */
  const handleUpdate = () => {
    props.updateCondition(updatedConditions);
    props.close();
  };

  /**
   * handler for Cancel Dialog
   */
  function handleCancel() {
    props.close();
  }
  return (
    <div>
      <Dialog open={props.open} maxWidth="md" fullWidth={true}>
        <DialogTitle>Condition Configuration</DialogTitle>
        <DialogContent>
          <IconButton>
            <AddIcon
              onClick={handleIncreaseRow}
              baseClassName="fas"
              className="fa-plus-circle"
              color="primary"
            />
          </IconButton>
          {updatedConditions.length > 1 ? (
            <IconButton>
              <RemoveIcon
                onClick={handleRemoveRow}
                baseClassName="fas"
                className="fa-plus-circle"
                color="secondary"
              />
            </IconButton>
          ) : (
            ""
          )}
          <Box>
            <table id="idTableConfiguration" className="RenderComponent-Table">
              <thead>
                <tr>
                  <th></th>
                  <th className="RenderComponent-Table-th">firstTableField</th>
                  <th className="RenderComponent-Table-th">secondTableField</th>
                </tr>
              </thead>
              <tbody>
                {updatedConditions.length > 0 ? (
                  updatedConditions.map((row, index) => (
                    <tr key={index}>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="checkbox"
                          checked={selectedRows[index]}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="text"
                          className="RenderComponent-Table-input"
                          value={row.firstTableField}
                          onChange={(event) =>
                            handleEvent(event, index, "firstTableField")
                          }
                        ></input>
                      </td>
                      <td className="RenderComponent-Table-td">
                        <input
                          type="text"
                          className="RenderComponent-Table-input"
                          value={row.secondTableField}
                          onChange={(event) =>
                            handleEvent(event, index, "secondTableField")
                          }
                        ></input>
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={handleUpdate}>
            Update
          </Button>
          <Button type="submit" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
