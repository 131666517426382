import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import "./ValueHelpConfig.css";
export const InputValueHelpConfig = (props) => {
  const value = props.row;
  const RowLength = parseInt(value, 10);

  const handleInputChange = (oEvent, key) => {
    const columnTitle = oEvent.target.value;
    props.setData((prevData) => ({
      ...prevData,
      [key]: {
        ...prevData[key],
        columnTitle,
      },
    }));
  };

  const handleCheckboxChange = (oEvent, key) => {
    const Visible = oEvent.target.checked;
    props.setData((prevData) => ({
      ...prevData,
      [key]: {
        ...prevData[key],
        Visible,
      },
    }));
  };

  const handleSelectChange = (oEvent, key) => {
    const mappedValue = oEvent.target.value;
    props.setData((prevData) => ({
      ...prevData,
      [key]: {
        ...prevData[key],
        mappedValue,
      },
    }));
  };

  const handleComponentChange = (oEvent, key) => {
    const selectedOption = oEvent.target.options[oEvent.target.selectedIndex];
    const selectedValueId = selectedOption.getAttribute("data-id");
    const Component = oEvent.target.value;
    props.setData((prevData) => ({
      ...prevData,
      [key]: {
        ...prevData[key],
        Component: Component,
        UniqueID: selectedValueId,
      },
    }));
  };

  const handleSubmit = () => {
    if (Object.keys(props.oData).length >= 1) {
      props.update(props.oData);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        PaperProps={{
          sx: {
            width: 900,
            maxHeight: 900,
          },
        }}
      >
        <DialogTitle>Configure Value Help</DialogTitle>
        <DialogContent>
          <table id="idTableConfiguration" className="RenderComponent-Table">
            <thead>
              <tr>
                <th className="RenderComponent-Table-th">InVisible</th>
                <th className="RenderComponent-Table-th">Column Title</th>
                <th className="RenderComponent-Table-th">Mapped Value</th>
                <th className="RenderComponent-Table-th">Component</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: RowLength }, (_, key) => (
                <tr key={key}>
                  <td className="td-space">
                    <input
                      type="checkbox"
                      checked={
                        props.oData[key] ? props.oData[key].Visible : false
                      }
                      onChange={(oEvent) => handleCheckboxChange(oEvent, key)}
                      className="column-title"
                    />
                  </td>
                  <td className="td-space">
                    <input
                      type="text"
                      value={
                        props.oData[key] ? props.oData[key].columnTitle : ""
                      }
                      onChange={(oEvent) => handleInputChange(oEvent, key)}
                      required
                      className="column-title"
                    />
                  </td>
                  <td className="td-space">
                    <select
                      value={
                        props.oData[key] ? props.oData[key].mappedValue : ""
                      }
                      onChange={(oEvent) => handleSelectChange(oEvent, key)}
                      required
                      className="column-title"
                    >
                      <option value="">Select </option>
                      {props.aDBFieldNames.map((sDBFieldName, i) => {
                        return (
                          <option value={sDBFieldName} key={i}>
                            {sDBFieldName}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td className="td-space">
                    <select
                      value={props.oData[key] ? props.oData[key].Component : ""}
                      onChange={(oEvent) => handleComponentChange(oEvent, key)}
                      required
                      className="column-title"
                    >
                      {props.tablefields && props.tablefields.length > 0
                        ? props.tablefields.map((field, i) => (
                            <option value={field.fieldName} key={i} data-id="">
                              {field.fieldName}
                            </option>
                          ))
                        : props.aInputs.map((values, i) => (
                            <option
                              value={values.name}
                              key={i}
                              data-id={values.id}
                            >
                              {values.name}
                            </option>
                          ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
