import React from "react";

//Material ui components
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

export const ImportLogicDialog = (props) => {
  /**
   * Event handler for change Logic
   * @param {event} oEvent | Event
   */
  function handleChangeLogic(oEvent) {
    props.selectLogicID(oEvent.target.value);
  }

  /**
   * Event handler for event change
   * @param {event} oEvent | Event
   */
  function handleChangeEvent(oEvent) {
    props.onChangeEvent(oEvent.target.value);
  }

  /**
   * Event handler for change Page
   * @param {event} oEvent | Event
   */
  function handleChangePage(oEvent) {
    props.selectedPageLogics(props.oAllLogics[oEvent.target.value]);
  }

  return (
    <div>
      <TextField
        required
        margin="dense"
        id="name"
        label="Logic Name"
        fullWidth
        variant="standard"
        sx={{ maxWidth: 450 }}
        value={props.logicName}
        onChange={props.onChange}
      />
      <br></br>
      <br></br>
      <br></br>
      <TextField
        required
        label="Choose Page"
        select
        onChange={handleChangePage}
        sx={{ minWidth: 450 }}
      >
        {Object.keys(props.oAllLogics).map((oEvent) => (
          <MenuItem value={oEvent}>{oEvent}</MenuItem>
        ))}
      </TextField>
      <br></br>
      <br></br>
      <br></br>
      <TextField
        required
        label="Choose Logic"
        select
        onChange={handleChangeLogic}
        sx={{ minWidth: 450 }}
      >
        {props.aSelectedPageLogics.map((oEvent) => (
          <MenuItem value={oEvent.id}>{oEvent.label}</MenuItem>
        ))}
      </TextField>
      <br></br>
      <br></br>
      <br></br>
      <TextField
        required
        label="Choose Event"
        select
        onChange={handleChangeEvent}
        sx={{ minWidth: 450 }}
      >
        {props.events.map((oEvent) => (
          <MenuItem key={oEvent.key} value={oEvent.event}>
            {oEvent.event}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};
