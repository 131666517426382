import React, { useState } from "react";
//Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
//Dialog Components
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const DynamicRowLocking = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  var aRowsData = [];

  /**
   * Event handler for CheckBox
   * @param {Number} iIndex
   */
  const handleCheckboxChange = (iIndex) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[iIndex] = !aNewSelectedRows[iIndex];
    setSelectedRows(aNewSelectedRows);
  };

  /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    var aTemp = [...props.dynamicRowLockingData];
    aTemp.push({
      tableID: "",
      conditionValue: "",
      columnField:"",
      condition:"",
      datatype:""
    });
    props.setMapDynamicRowData(aTemp);
    setSelectedRows([...aSelectedRows, false]);
  }

  /**
   * Event handler for Remove Rows
   */
  function handleDecreaseRow() {
    var aTemp = [...props.dynamicRowLockingData];
    const aUpdatedRows = aTemp.filter((_, index) => !aSelectedRows[index]);
    props.setMapDynamicRowData(aUpdatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  if (props.dynamicRowLockingData && props.dynamicRowLockingData.length > 0) {
    for (let i = 0; i < props.dynamicRowLockingData.length; i++) {
      aRowsData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={aSelectedRows[i]}
              onChange={() => handleCheckboxChange(i)}
            />
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) => handleChangeTableName(oEvent, i)}
              required
              value={props.dynamicRowLockingData[i].tableID}
              className="column-title"
            >
              <option value="">Select </option>
              {props.inputFields &&
                props.inputFields.map((oField, index) => {
                    if (oField.id === "idInputTableName") {
                      return (
                        <option value={oField.uniqueID} key={index}>
                          {oField.value}
                        </option>
                      );
                    }
                })}
            </select>
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) => handleChangeColumns(oEvent, i)}
              required
              value={props.dynamicRowLockingData[i].columnField}
              className="column-title"
            >
              <option value="">Select </option>
              {props.dynamicRowLockingData[i].columnFields &&
                props.dynamicRowLockingData[i].columnFields.map(
                  (sColumnField, index) => {
                    return (
                      <option value={sColumnField.fieldName} key={index}>
                        {sColumnField.fieldName}
                      </option>
                    );
                  }
                )}
            </select>
          </td>
          <td className="RenderComponent-Table-td">
            <input
              className="RenderComponent-Table-input"
              value={props.dynamicRowLockingData[i].conditionValue}
              onChange={(oEvent) => {
                handleChangeCondition(oEvent, i);
              }}
            />
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) => handleCondition(oEvent, i)}
              required
              value={props.dynamicRowLockingData[i].condition}
              className="column-title"
            >
              <option value="" disabled>Select </option>
              <option value="eq" >Equal to </option>
              <option value="neq" >Not Equal to </option>
              <option value="lt" >Less than</option>
              <option value="gt" >Greater Than</option>
              <option value="lte" >Less than Equal to</option>
              <option value="gte" >Greater than Equal to</option>
            </select>
          </td>
          <td className="td-space">
            <select
              onChange={(oEvent) => handleChangedatatype(oEvent, i)}
              required
              value={props.dynamicRowLockingData[i].datatype}
              className="column-title"
            >
              <option value="" disabled>Select </option>
              <option value="Number" >Number </option>
              <option value="String" >String </option>
              <option value="Date" >Date </option>
            </select>
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler for Fields
   * @param {*} oEvent
   * @param {Number} iIndex
   * @param {string} skey
   */
  function handleChangeCondition(oEvent, iIndex) {
    var aTemp = [...props.dynamicRowLockingData];
    aTemp[iIndex].conditionValue = oEvent.target.value;
    props.setMapDynamicRowData(aTemp);
  }

  function handleCondition(oEvent, iIndex) {
    var aTemp = [...props.dynamicRowLockingData];
    aTemp[iIndex].condition = oEvent.target.value;
    props.setMapDynamicRowData(aTemp);
  }

  function handleChangedatatype(oEvent, iIndex) {
    var aTemp = [...props.dynamicRowLockingData];
    aTemp[iIndex].datatype = oEvent.target.value;
    props.setMapDynamicRowData(aTemp);
  }

  function handleChangeColumns(oEvent, iIndex) {
    var aTemp = [...props.dynamicRowLockingData];
    aTemp[iIndex].columnField = oEvent.target.value;
    props.setMapDynamicRowData(aTemp);
  }

  function handleChangeTableName(oEvent, iIndex) {
    var aTemp = [...props.dynamicRowLockingData];
    aTemp[iIndex].tableID = oEvent.target.value;
    var oColumn = props.inputFields.find(function (oInputField) {
      return (
        oInputField.uniqueID === oEvent.target.value && oInputField.columns
      );
    });
    if (oColumn) {
      aTemp[iIndex].columnFields = oColumn.columns;
    } else {
      aTemp[iIndex].columnFields = [];
    }
    props.setMapDynamicRowData(aTemp);
  }

  /**
   * Event handler for Update
   */
  const handleSubmit = () => {
    props.update(props.dynamicRowLockingData);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        PaperProps={{
          sx: {
            width: 900,
            maxHeight: 900,
          },
        }}
      >
        <DialogTitle>
          Mapping Table Row Conditions
          <IconButton onClick={handleIncreaseRow}>
            <AddIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="primary"
            />
          </IconButton>
          <IconButton onClick={handleDecreaseRow}>
            <RemoveIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="secondary"
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <table id="idTableConfiguration" className="RenderComponent-Table">
            <thead>
              <tr>
                <th></th>
                <th className="RenderComponent-Table-th">Select Table</th>
                <th className="RenderComponent-Table-th">Column Field</th>
                <th className="RenderComponent-Table-th">Condition Value</th>
                <th className="RenderComponent-Table-th">Condition</th>
                <th className="RenderComponent-Table-th">Datatype</th>
              </tr>
            </thead>
            <tbody>{aRowsData}</tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
