import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faRotateLeft } from "@fortawesome/free-solid-svg-icons";

import "./valueHelp.css";

export const ValueHelpDialog = (props) => {
  const { oColumnData, avalueHelpData, sTableName } = props;
  const [aSelectedRows, setSelectedRows] = useState([]);
  const [sSearchValue, setSearchValue] = useState("");
  const [aFilteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
  }, [props.open]);

  const fieldNames = [];
  var rowData = [];
  const itemsPerPage = 10;

  const totalData = aFilteredData.length > 0 ? aFilteredData : avalueHelpData;
  const totalPages = Math.ceil(totalData.length / itemsPerPage);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalData.length);

  const pageData = totalData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages - 1));
  };
  if (oColumnData !== undefined && Object.keys(oColumnData).length > 0) {
    Object.values(oColumnData).forEach(function (oData) {
      if (!oData.Visible) {
        fieldNames.push(oData.mappedValue);
      }
    });
  }

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchIconClick = () => {
    var afilteredData = avalueHelpData.filter((oRowData) => {
      return Object.values(oRowData).some((value) => {
        const stringValue = String(value);
        return stringValue.toLowerCase().includes(sSearchValue.toLowerCase());
      });
    });
    setFilteredData(afilteredData);
  };

  const handleClearFilter = () => {
    setSearchValue("");
    setFilteredData([]);
  };

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...aSelectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  function handleRowValue(oRowData) {
    if (props.sValueHelpType === "Table") {
      var { sId, value, selectedRow } = props.parent;
      for (const key in props.oColumnData) {
        const ocurrentObject = props.oColumnData[key];
        var sTargetValue = oRowData[ocurrentObject.mappedValue];
        props.handleTable(
          sTargetValue,
          selectedRow,
          ocurrentObject.Component,
          value,
          sId
        );
      }
    } else {
      var oValueColumDatas = props.oColumnData;
      Object.keys(oValueColumDatas).forEach(function (key) {
        const element = oValueColumDatas[key];
        if (
          element.UniqueID &&
          element.UniqueID !== "" &&
          "UniqueID" in element
        ) {
          document.getElementById(element.UniqueID).value =
            oRowData[element.mappedValue];
          props.onChange(
            oRowData[element.mappedValue],
            element.UniqueID,
            props.UIData
          );
          props.oLogic(element.UniqueID);
        }
      });
    }
  }

  function getIndexesOfTrueValues(selectedRows) {
    const trueIndexes = [];
    selectedRows.forEach((value, index) => {
      if (value === true) {
        trueIndexes.push(index);
      }
    });
    return trueIndexes;
  }

  function mapValuesToComponents(config, data) {
    const mappedData = [];

    data.forEach((item) => {
      const mappedItem = {};

      Object.keys(config).forEach((key) => {
        const mapping = config[key];
        const columnName = mapping.mappedValue;
        const componentField = mapping.Component;
        mappedItem[componentField] = item[columnName];
      });

      mappedData.push(mappedItem);
    });

    return mappedData;
  }

  function handleMultiData() {
    const aSelectedIndex = getIndexesOfTrueValues(aSelectedRows);
    const aSelectedData = [];
    var sUniqueID;
    const oValueComponent = props.UIData.find(
      (oComopnent) => oComopnent.uniqueId === props.sInputID
    );
    const aLogicComponent = oValueComponent.Logic[0].component;
    const oFoundLogic = aLogicComponent.find(
      (oLogic) => oLogic.data.id === "idValueHelpData"
    );
    if (oFoundLogic) {
      const oProperty = oFoundLogic.properties.find(
        (oProp) => oProp.id === "idDropDownTable"
      );
      if (oProperty) {
        sUniqueID = oProperty.value;
      }
    }
    (aFilteredData.length > 0 ? aFilteredData : avalueHelpData).forEach(
      function (oData, index) {
        if (aSelectedIndex.includes(index)) {
          aSelectedData.push(oData);
        }
      }
    );
    const aMappedData = mapValuesToComponents(props.oColumnData, aSelectedData);
    props.handleTable(aMappedData, "", "", "", sUniqueID, true);
    props.handleClose();
  }

  if (avalueHelpData !== undefined && Object.keys(avalueHelpData).length > 0) {
    rowData = pageData.map((oRowData, index) => (
      <tr
        key={startIndex + index}
        onClick={() => {
          if (!props.multiSelect) {
            handleRowValue(oRowData);
            props.handleClose();
          }
        }}
      >
        {props.multiSelect && (
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={aSelectedRows[startIndex + index]}
              onChange={() => handleCheckboxChange(startIndex + index)}
            />
          </td>
        )}
      {fieldNames.map((fieldName, i) => (
        <td key={i} className="RenderComponent-Table-td pointer">
          {typeof oRowData[fieldName] === 'boolean'
            ? oRowData[fieldName].toString()
            : oRowData[fieldName]}
        </td>
      ))}
      </tr>
    ));
  }

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth="md"
      BackdropProps={{
        style: {
          background: "transparent",
        },
      }}
    >
      <DialogTitle fontWeight="bold">
        <div className="d-flex table_headers" style={{ width: "100%" }}>
          <div className="d-flex title">
            {sTableName !== undefined && sTableName}
          </div>
          <div className="d-flex search">
            <input
              type="text"
              value={sSearchValue}
              onChange={handleSearchChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearchIconClick();
                }
              }}
            ></input>
            <div className="icon" onClick={handleSearchIconClick}>
              <FontAwesomeIcon icon={faSearch} size="sm" />
            </div>
            <div className="icon" onClick={handleClearFilter}>
              <FontAwesomeIcon icon={faRotateLeft} size="sm" />
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <table id="idTableConfiguration" className="RenderComponent-Table">
          <thead>
            <tr>
              {props.multiSelect && (
                <th
                  key={oColumnData.columnTitle}
                  className="RenderComponent-Table-th"
                ></th>
              )}
              {oColumnData !== undefined &&
                Object.keys(oColumnData).length > 0 &&
                Object.values(oColumnData)
                  .filter((oColumnDataItem) => !oColumnDataItem.Visible)
                  .map((oColumnDataItem) => (
                    <th
                      key={oColumnDataItem.columnTitle}
                      className="RenderComponent-Table-th"
                    >
                      {oColumnDataItem.columnTitle}
                    </th>
                  ))}
            </tr>
          </thead>
          <tbody>{rowData}</tbody>
        </table>
        {totalPages > 1 && (
          <div
            id="valuehelpnextpreview"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <button
              id="valuehelpnext"
              onClick={handlePrevPage}
              disabled={currentPage === 0}
            >
              Previous
            </button>
            <button
              id="valuehelppreview"
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
            >
              Next
            </button>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {props.multiSelect && <Button onClick={handleMultiData}>OK</Button>}
        <Button onClick={props.handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
