import React, { useState } from "react";
//Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
//Dialog Components
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const GetParamsDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  var aRowsData = [];
  /**
   * Event handler for CheckBox
   * @param {Number} iIndex
   */
  const handleCheckboxChange = (iIndex) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[iIndex] = !aNewSelectedRows[iIndex];
    setSelectedRows(aNewSelectedRows);
  };

  /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    var aTemp = [...props.mappingParamsData];
    aTemp.push({
      parameterName: "",
    });
    props.setMappingParams(aTemp);
    setSelectedRows([...aSelectedRows, false]);
  }

  /**
   * Event handler for Remove Rows
   */
  function handleDecreaseRow() {
    var aTemp = [...props.mappingParamsData];
    const aUpdatedRows = aTemp.filter((_, index) => !aSelectedRows[index]);
    props.setMappingParams(aUpdatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  if (props.mappingParamsData && props.mappingParamsData.length > 0) {
    for (let i = 0; i < props.mappingParamsData.length; i++) {
      aRowsData.push(
        <tr>
          <td className="RenderComponent-Table-td">
            <input
              type="checkbox"
              checked={aSelectedRows[i]}
              onChange={() => handleCheckboxChange(i)}
            />
          </td>
          <td className="RenderComponent-Table-td">
            <input
              className="RenderComponent-Table-input"
              value={props.mappingParamsData[i].parameterName}
              onChange={(oEvent) => {
                handleChangeParameter(oEvent, i);
              }}
            />
          </td>
        </tr>
      );
    }
  }

  /**
   * Event handler for Fields
   * @param {*} oEvent
   * @param {Number} iIndex
   * @param {string} skey
   */
  function handleChangeParameter(oEvent, iIndex) {
    var aTemp = [...props.mappingParamsData];
    aTemp[iIndex].parameterName = oEvent.target.value;
    props.setMappingParams(aTemp);
  }

  /**
   * Event handler for Update
   */
  const handleSubmit = () => {
    props.update(props.mappingParamsData);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        PaperProps={{
          sx: {
            width: 900,
            maxHeight: 900,
          },
        }}
      >
        <DialogTitle>
          Mapping Params
          <IconButton onClick={handleIncreaseRow}>
            <AddIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="primary"
            />
          </IconButton>
          <IconButton onClick={handleDecreaseRow}>
            <RemoveIcon
              baseClassName="fas"
              className="fa-plus-circle"
              color="secondary"
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <table id="idTableConfiguration" className="RenderComponent-Table">
            <thead>
              <tr>
                <th></th>
                <th className="RenderComponent-Table-th">
                  Parameter Name
                </th>
              </tr>
            </thead>
            <tbody>{aRowsData}</tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
