import React, { useState } from "react";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const WhereConditionDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);
  const aConditions = [
    { value: "", label: "" },
    { value: "$eq", label: "Equal To" },
    { value: "$ne", label: "Not Equal To" },
    { value: "$gt", label: "Greater Than" },
    { value: "$lt", label: "Less Than" },
    { value: "$gte", label: "Greater Than or Equal To" },
    { value: "$lte", label: "Less Than or Equal To" },
    { value: "$contains", label: "Like" },
  ];

   /**
   * Event handler for CheckBox
   * @param {Number} index
   */
  const handleCheckboxChange = (index) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[index] = !aNewSelectedRows[index];
    setSelectedRows(aNewSelectedRows);
  }; 

   /**
   * Event handler for Fields
   * @param {*} oEvent
   * @param {Number} index
   * @param {string} skey
   */
  const handleChangeFields = (oEvent, index, sKey) => {
    const { value } = oEvent.target;
    const aUpdatedRows = [...props.rows.whereConditions];
    aUpdatedRows[index][sKey] = value;
    props.update({ ...props.rows, whereConditions: aUpdatedRows });
  };

   /**
   * Event handler for Increase RowsS
   */
  function handleIncreaseRow() {
    const aUpdatedRows = [...props.rows.whereConditions];
    aUpdatedRows.push({
      field: "",
      condition: "",
      value: "",
      type: "",
      logicCondition: "",
    });
    props.update({ ...props.rows, whereConditions: aUpdatedRows });
    setSelectedRows([...aSelectedRows, false]);
  }

  /**
   * Event handler for Remove Rows
   */
  function handleRemoveRow() {
    const aUpdatedRows = props.rows.whereConditions.filter(
      (_, index) => !aSelectedRows[index]
    );
    props.update({ ...props.rows, whereConditions: aUpdatedRows });
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  return (
    <div className="ComponentVisibility-render">
      <IconButton>
        <AddIcon
          onClick={handleIncreaseRow}
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      {props.rows.whereConditions.length >= 1 ? (
        <IconButton>
          <RemoveIcon
            onClick={handleRemoveRow}
            baseClassName="fas"
            className="fa-plus-circle"
            color="secondary"
          />
        </IconButton>
      ) : (
        ""
      )}
      <Box>
        <table
          id="idTableConfiguration"
          className="RenderComponent-Table ComponentVisibility-border"
        >
          <thead>
            <tr>
              <th></th>
              <th className="RenderComponent-Table-th">Field</th>
              <th className="RenderComponent-Table-th">Condition</th>
              <th className="RenderComponent-Table-th">Value</th>
              <th className="RenderComponent-Table-th">Type</th>
              <th className="RenderComponent-Table-th">Logic Condition</th>
            </tr>
          </thead>
          <tbody>
            {props.rows.whereConditions.map((oConditionRow, index) => (
              <tr key={index}>
                <td className="RenderComponent-Table-td">
                  <input
                    type="checkbox"
                    checked={aSelectedRows[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    className="RenderComponent-Table-input"
                    type="string"
                    value={oConditionRow.field}
                    onChange={(oEvent) => {
                      handleChangeFields(oEvent, index, "field");
                    }}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <select
                    className="RenderComponent-Table-input"
                    value={oConditionRow.condition}
                    onChange={(oEvent) => {
                      handleChangeFields(oEvent, index, "condition");
                    }}
                  >
                    {aConditions.map((oConditon) => (
                      <option key={oConditon.value} value={oConditon.value}>
                        {oConditon.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    className="RenderComponent-Table-input"
                    type={oConditionRow.type}
                    value={oConditionRow.value}
                    onChange={(oEvent) => {
                      handleChangeFields(oEvent, index, "value");
                    }}
                  />
                </td>

                <td className="RenderComponent-Table-td">
                  <select
                    className="RenderComponent-Table-input"
                    value={oConditionRow.type}
                    onChange={(oEvent) => {
                      handleChangeFields(oEvent, index, "type");
                    }}
                  >
                    <option value="">Select Type</option>
                    <option value="Number">Number</option>
                    <option value="Date">Date</option>
                    <option value="String">String</option>
                  </select>
                </td>
                <td className="RenderComponent-Table-td">
                  <select
                    className="RenderComponent-Table-input"
                    value={oConditionRow.logicCondition}
                    onChange={(oEvent) => {
                      handleChangeFields(oEvent, index, "logicCondition");
                    }}
                  >
                    <option value="">Select Condition</option>
                    <option value="AND">AND</option>
                    <option value="OR">OR</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </div>
  );
};
