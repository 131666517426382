import React, { useState } from "react";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

//Server
import Server from "../../../serverDetails/server";

export const DeployDialog = (props) => {
  const [selectedEnviron, setSelectedEnviron] = useState("");
  const [bOverwiteCheckbox, setOverwriteCheckbox] = useState(false);
  const [bAppDescChanged, setAppDescChanged] = useState(false);
  const [sDeployCID, setDeployCID] = useState("");
  const [sDeployAPPID, setDeployAPPID] = useState("");
  const [bDeploymentMessageOpen, setDeploymentMessageOpen] = useState(false);
  const [bPublishSchema, setPublishSchema] = useState(false);

  var bforce = false;
  var sAPP_ID;
  var aEnvironmentValues = [];
  if (window.location.hostname.includes("dev")) {
    aEnvironmentValues = ["Quality"];
  } else if (window.location.hostname.includes("qa")) {
    aEnvironmentValues = ["Production"];
  }

  if (!sDeployCID && props.CID && props.APPID) {
    setDeployCID(props.CID);
    setDeployAPPID(props.APPID);
  }
  /**
   * Event handler to Deploy the App
   */
  function handleDeployment() {
    if (!sDeployAPPID) {
      setDeploymentMessageOpen(true);
    }
    if (bAppDescChanged) {
      sAPP_ID = null;
    } else {
      sAPP_ID = props.APPID;
    }
    Server.post(
      "/qadeployment",
      {
        Environment: selectedEnviron,
        APPID: sAPP_ID,
        APP_DESC: props.APPDESC,
        sDeployCID: sDeployCID,
        DEPLOYMENT_APPID: sDeployAPPID,
        force: bforce,
        DBDATA: bPublishSchema,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    )
      .then(function (response) {
        if (response.data["Entry"]) {
          setOverwriteCheckbox(true);
        } else {
          setOverwriteCheckbox(false);
          setAppDescChanged(false);
          setPublishSchema(false);
          props.close();
        }
      })
      .catch(function (oError) {
        if (oError.response.data) {
          props.error(oError.response.data.Message);
        } else {
          props.error(oError.message);
        }
      });
  }

  /**
   * Event handler to close dialog
   */
  function handleClose() {
    props.close();
  }

  /**
   * Event handler to change groups
   */
  function handleChangeEnvironment(oEvent) {
    setSelectedEnviron(oEvent.target.value);
  }

  /**
   * Event handler to enable overwrite options
   */
  function handleOverwrite() {
    bforce = true;
  }

  function handleChangeAppDesc(oEvent) {
    props.changeDesc(oEvent.target.value);
    setAppDescChanged(true);
  }

  function handleChangeCID(oEvent) {
    setDeployCID(oEvent.target.value);
  }
  function handleAppIDDeploymentChange(oEvent) {
    setDeployAPPID(oEvent.target.value);
  }

  function handleChangePublish(oEvent) {
    setPublishSchema(oEvent.target.checked);
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Deploy Appilication</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              required
              margin="dense"
              id="name"
              label="Customer ID"
              fullWidth
              variant="standard"
              value={sDeployCID}
              onChange={handleChangeCID}
            />
            <br></br>
            <br></br>
            <TextField
              required
              margin="dense"
              id="name"
              label="Appilication ID"
              fullWidth
              variant="standard"
              value={props.APPID}
            />
            <br></br>
            <br></br>
            <TextField
              required
              margin="dense"
              id="name"
              label="Appilication Desc"
              fullWidth
              variant="standard"
              value={props.APPDESC}
              onChange={handleChangeAppDesc}
            />
            <br></br>
            <br></br>
            <TextField
              required
              margin="dense"
              id="name"
              label="Deployment Application ID"
              fullWidth
              variant="standard"
              value={sDeployAPPID}
              onChange={handleAppIDDeploymentChange}
            />
            <br></br>
            <br></br>
            <TextField
              required
              label="Choose Environment"
              select
              onChange={handleChangeEnvironment}
              sx={{ minWidth: 450 }}
            >
              {aEnvironmentValues.map((oEnviron) => (
                <MenuItem key={oEnviron} value={oEnviron}>
                  {oEnviron}
                </MenuItem>
              ))}
            </TextField>
            <br></br>
            <br></br>
            <div style={{ display: "flex" }}>
              <input
                type="checkbox"
                id="publishSchema"
                style={{ width: "15px", height: "15px" }}
                checked={bPublishSchema}
                onChange={handleChangePublish}
              />
              <span style={{ marginLeft: "10px" }}>Publish Schema</span>
            </div>
            <br></br>
            <br></br>
            <br></br>
            {bOverwiteCheckbox && (
              <FormControlLabel
                label="Appilication already exist do you want to Overwrite ?"
                control={<Checkbox onChange={handleOverwrite} />}
              />
            )}
            {bAppDescChanged && <label>App ID has been changed</label>}
            {bDeploymentMessageOpen && (
              <label>Make sure to Provide Deployment APP ID</label>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={handleDeployment}>
            Deploy
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
