export const MapValueHelpData = (
  aProperties,
  sUniqueID,
  aUIData,
  resetUI,
  oParams,
  oLogicDatas
) => {
  var oComponent = aUIData.find(function (oComponentModel) {
    return oComponentModel.uniqueId === sUniqueID;
  });
  var columnsData;
  var data;
  var oValueHelpTableName = aProperties.find(
    (oProperty) => oProperty.id === "idTitleInput"
  );

  let fieldsnames = aProperties.find(
    (oProperty) => oProperty.id === "idMapInputFields"
  );
  columnsData = fieldsnames.value;
  data = oParams.TableData;

  oComponent.valueHelpData = [
    {
      valueHelpColumnData: columnsData,
      valueHelpData: data,
      valueHelpDataTable: oValueHelpTableName.value,
      properties: aProperties,
    },
  ];

  if (oComponent.Component === "Input" || oComponent.Component === "Button") {
    oLogicDatas.valueHelpDialog("Input", oComponent.uniqueId);
  }
  if (
    oComponent.Component === "Table" &&
    oLogicDatas.valueHelpTable.tableEvent
  ) {
    var oTableEvent = oLogicDatas.valueHelpTable.tableEvent;
    var oColumnName = oLogicDatas.valueHelpTable.ColumnName;
    oLogicDatas.valueHelpDialog(
      "Table",
      oComponent.uniqueId,
      oTableEvent,
      oColumnName,
      oComponent.value,
      true
    );
  }
  resetUI([...aUIData]);
};
