import React, { useState } from "react";

// Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// Dialog components
import { ComponentVisibility } from "./componentsVisibilities/ComponentVisibility";
import { WhereConditionDialog } from "./whereConditions/WhereConditionDialog";

export const VisibilityDialog = (props) => {
  const [iTabValue, setTabValue] = useState(0);

  /**
   * Event handler  for Tab changes
   * @param {*} oEvent
   * @param {Number} iNewValue
   */
  function handleTabChange(oEvent, iNewValue) {
    setTabValue(iNewValue);
  }

  /**
   * Event handler  for Update
   */
  function handleUpdate() {
    props.update(props.oVisibilityData);
  }

  /**
   * Event handler  for Cancel
   */
  function handleCancel() {
    setTabValue(0);
    props.close();
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Component Visibility Configuration</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={iTabValue} onChange={handleTabChange}>
              <Tab label="Component Visibility" />
              <Tab label="Where Conditions" />
            </Tabs>
          </Box>
          <br />
          {iTabValue === 0 && (
            <ComponentVisibility
              oVisibilityData={props.oVisibilityData}
              update={props.handleUpdate}
            />
          )}
          {iTabValue === 1 && (
            <WhereConditionDialog
              rows={props.oVisibilityData}
              aFields={props.fields}
              update={props.handleUpdate}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={handleUpdate}>
            Update
          </Button>
          <Button type="submit" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
