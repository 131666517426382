import React, { useState } from "react";

export const LegendDialog = (props) => {

  /** Update the Input data
   * 
   * @param {*} event 
   * @param {*} index 
   * @param {*} skey 
   */
  const handleChangeEvent = (event, index, skey) => {
    const { value } = event.target;
    if (skey === "FieldName") {
      props.updateData({
        ...props.oData,
        Legend: { ...props.oData.Legend, FieldName: value },
      });
    }
  };

  return (
    <div>
      <select
        style={{width:"350px",height:"40px"}}
        defaultValue={props.oData.Legend.FieldName}
        onChange={(event) => {
          handleChangeEvent(event, 0, "FieldName");
        }}
      >
        {props.inputFields && props.inputFields.length > 0 && (
          <>
            <option key="" value="" disabled>
              Select Field
            </option>
            {props.inputFields.map((sdatas) => (
              <option key={sdatas} value={sdatas}>
                {sdatas}
              </option>
            ))}
          </>
        )}
      </select>
    </div>
  );
};
