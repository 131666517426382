function generateLogicComponentID(oLogicComponentModel,oSelectedComponent,oSelectLogic) {
    var aLogic = oSelectedComponent.Logic;
    var aComponents = oSelectLogic.component;
    var iLength = 0;

    if (aComponents.length > 0) {
      iLength = aComponents.length;
    }
    var sId =
      oLogicComponentModel.id +
      iLength +
      "Logic" +
      aLogic.length +
      oSelectedComponent.uniqueId;
    return sId;
  }
export function formatLogicComponent(logicComponentList, oTargetComponent,oSelectedComponent,oSelectLogic) {
  const normalizeString = (str) => str.replace(/\s+/g, "").toLowerCase();

  const targetLogicComponent = logicComponentList.find((logic) =>
    normalizeString(logic.Component).includes(
      normalizeString(oTargetComponent.ComponentID)
    )
  );

  if (!targetLogicComponent) {
    throw new Error("Target logic component not found");
  }

  let displayProperties = [...targetLogicComponent.properties];
  const updateProperty = (id, value) => {
    const prop = displayProperties.find((prop) => prop.id === id);
    if (prop) {
      prop.value = value;
    }
  };

  if (displayProperties) {
    switch (targetLogicComponent.Component) {
      case "Fetch Inputs":
        const aInputComponents =
          oTargetComponent.properties["inputcomponents"] ||
          oTargetComponent.properties["inputComponents"];
        if (aInputComponents) {
          displayProperties[0].value = aInputComponents;
        }
        break;

      case "Map Input Components":
        const oInputFieldMapper =
          oTargetComponent.properties["inputFieldMapper"];
        let oMappedFields = {};
        for (const key in oInputFieldMapper) {
          if (oInputFieldMapper.hasOwnProperty(key)) {
            oMappedFields[key] = {
              id: oInputFieldMapper[key].InputID,
              value: oInputFieldMapper[key].value,
            };
          }
        }
        displayProperties[0].value = oMappedFields;
        break;

      case "Navigation":
        const sPageList = oTargetComponent.properties["PageList"];
        if (sPageList) {
          displayProperties.find((prop) => prop.id === "idPageList").value =
            sPageList;
        }

        let aNavigationParameters = displayProperties.find(
          (prop) => prop.id === "idNavigationParameters"
        ).value;
        const aParameterFields =
          oTargetComponent.properties["NavigationParameters"];
        if (aParameterFields) {
          for (let oParam of aParameterFields) {
            aNavigationParameters.push({
              parameterName: oParam.parameterName,
              parameterValue: oParam.parameterID,
              tableSelection: oParam.tableSelection || false,
              arraySelection: oParam.arraySelection || false,
              mappingValue: oParam.mappingValue || "",
              graphField: oParam.graphField || "",
            });
          }
        }

        if (oTargetComponent.properties["AppNavigationCheckBox"]) {
          displayProperties.find(
            (prop) => prop.id === "idAppNavigationCheckBox"
          ).value = oTargetComponent.properties["AppNavigationCheckBox"];
          displayProperties.find(
            (prop) => prop.id === "idAppNavigation"
          ).value = oTargetComponent.properties["AppNavigation"];
        }
        break;

      case "Parameter":
        const aRouteComponents = oTargetComponent.properties["RouteComponents"];
        let aGetParameters = displayProperties.find(
          (prop) => prop.id === "idGetParameters"
        ).value;
        for (let component of aRouteComponents) {
          aGetParameters.push({ parameterName: component });
        }
        break;

      case "Deep Fetch DB":
        let fetchDBProperties = displayProperties.find(
          (prop) => prop.id === "idDeepFetchJoin"
        ).value;
        fetchDBProperties.collections =
          oTargetComponent.properties["collections"] ||
          fetchDBProperties.collections;

        if (oTargetComponent.properties["joinCondition"]) {
          const aJoinList = oTargetComponent.properties["joinCondition"];
          for (const oJoin of aJoinList) {
            oJoin.firstTable = oJoin.firstDB || oJoin.firstTable;
            oJoin.secondTable = oJoin.secondDB || oJoin.secondTable;
            delete oJoin.firstDB;
            delete oJoin.secondDB;
            if (oJoin.fields) {
              for (const field of oJoin.fields) {
                field.firstTableField =
                  field.firstDBField || field.firstTableField;
                field.secondTableField =
                  field.secondDBField || field.secondTableField;
                delete field.firstDBField;
                delete field.secondDBField;
              }
            }
          }
          fetchDBProperties.joinConditions = aJoinList;
        }

        if (oTargetComponent.properties.WHERE) {
          const aWhereConditions = oTargetComponent.properties.WHERE;
          for (const oCondition of aWhereConditions) {
            if (oCondition.UIComponent) {
              oCondition.value = oCondition.UIComponent;
              delete oCondition.UIComponent;
            } else if (oCondition.value) {
              oCondition.hardcoded = oCondition.value;
              oCondition.value = "";
            }
          }
          fetchDBProperties.whereConditions = aWhereConditions;
        }

        fetchDBProperties.ArrayWhereConditions =
          oTargetComponent.properties?.["ArrayWhere"]?.length > 0
            ? oTargetComponent.properties["ArrayWhere"]
            : fetchDBProperties.ArrayWhereConditions;
        fetchDBProperties.bArrayCondition =
          oTargetComponent.properties["bArrayCondition"] ||
          fetchDBProperties.bArrayCondition;
        break;

      case "Map Table Data":
        displayProperties.find((prop) => prop.id === "idDropDownTable").value =
          oTargetComponent.properties["TableID"];
        displayProperties.find((prop) => prop.id === "idMapTableFields").value =
          oTargetComponent.properties["TableMapValue"][0];
        break;
      case "Insert DB":
        const sDBNameProperty = displayProperties.find(
          (prop) => prop.id === "idInsertDBName"
        );
        if (sDBNameProperty) {
          sDBNameProperty.value = oTargetComponent.properties["DBName"] || "";
        }
        const aDBTableProperties = oTargetComponent.properties["ArrayFields"];
        let aInsertDBTable =
          Array.isArray(aDBTableProperties) && aDBTableProperties.length > 0
            ? aDBTableProperties.map((row) => ({
                value: row.field || "",
                dataType: row.dataType || "",
                key: row.key ? "X" : "" || "",
                item: row.SubLevel || "",
                itemName: row.SubLevelName || "",
                ItemKey: row.SubLevelKey || "",
                pattern: row.pattern || "",
                auto: row.autoGenerate || "",
                autoField: row.autoGenerateField || "",
                Series: row.Series || "",
              }))
            : [];
        const targetProperty = displayProperties.find(
          (prop) => prop.id === "idDBTableProperties"
        );
        if (targetProperty) {
          targetProperty.value = aInsertDBTable;
        }
        break;
      case "Delete DB":
        const { DBName: sDeleteDBName, deleteconditions: aDeleteConditions } =
          oTargetComponent.properties;
        const aDeleteProperty = displayProperties.find(
          (prop) => prop.id === "idDeleteCondition"
        );
        updateProperty("idDeleteDBName", sDeleteDBName || "");
        if (aDeleteConditions && aDeleteConditions.length > 0) {
          for (const oDeleteRow of aDeleteConditions) {
            oDeleteRow.operator =
              oDeleteRow.operator === "$eq" ? "Equal" : oDeleteRow.operator;
          }
          aDeleteProperty.value = aDeleteConditions;
        }
        break;
      case "Confirmation":
        const {
          ConfirmationMessage: sConfirmMessage,
          EnableConfirm: bEnableConfirm,
          ConfirmationCondition: aConditions,
        } = oTargetComponent.properties;
        updateProperty("idConfirmationMessage", sConfirmMessage || "");
        updateProperty("idEnableOk", bEnableConfirm || "false");
        updateProperty(
          "idConfirmationInputs",
          aConditions ||
            displayProperties.find((prop) => prop.id === "idConfirmationInputs")
              .value
        );
        break;
      case "Code Editor":
        const aParametersProperty = displayProperties.find(
          (prop) => prop.id === "idparameterdefinition"
        );
        aParametersProperty.value = oTargetComponent.properties["parameterdefinition"];
        break;
      default:
        displayProperties[0].value = oTargetComponent.properties.value;
    }
  }

  const logicComponentID = generateLogicComponentID(targetLogicComponent,oSelectedComponent,oSelectLogic);

  const logicComponent = {
    id: logicComponentID,
    data: {
      label: targetLogicComponent.Component,
      id: targetLogicComponent.id,
    },
    position: {
      x: 100,
      y: 100,
    },
    properties: displayProperties,
  };

  if (targetLogicComponent.type) {
    logicComponent.type = targetLogicComponent.type;
  }

  if (targetLogicComponent.targetPosition) {
    logicComponent.targetPosition = targetLogicComponent.targetPosition;
  }

  if (targetLogicComponent.sourcePosition) {
    logicComponent.sourcePosition = targetLogicComponent.sourcePosition;
  }

  return logicComponent;
}
