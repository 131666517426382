import React,{useState} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material";

export const InserDBSchema = (props) => {
    const [jsonData, setJsonData] = useState("");
    const handleOnChangeEvent = (oEvent) => {
        try {
          const parsedJson = JSON.parse(oEvent.target.value);
          setJsonData(parsedJson);
        } catch (error) {
          console.error("Invalid JSON:", error.message);
        }
    };

    function handelUpdate(){
        props.update(jsonData)
    }
    return (
      <div>
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={props.open}
          PaperProps={{
            sx: {
              maxHeight: 900,
              overflowY: 'auto',
            },
          }}
        >
          <DialogTitle>DB Schema</DialogTitle>
          <DialogContent>
            <TextField
              label="JSON Data"
              multiline
              maxRows={1000}
              fullWidth
              variant="outlined"
              defaultValue={JSON.stringify(props.data, null, 2)}
              onChange={(oEvent)=>{
                handleOnChangeEvent(oEvent)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handelUpdate}>Update</Button>
            <Button onClick={props.close}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}