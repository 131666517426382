import React, { useState, useEffect } from "react";
import Server from "../../../serverDetails/server";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export const DeleteCollection = (props) => {
  const [iTabValue, setTabValue] = useState(0);
  const [aSelectedRows, setSelectedRows] = useState([]);
  const [bMigratedata, setMigrateData] = useState(false);
  const [sNewCollectionName, setNewCollectionName] = useState();
  const [sErrorMessage, setErrorMessage] = useState("");
  const [bOpenSnackBar, setOpenSnackBar] = useState(false);
  const [aSchemaArray, setSchemaArray] = useState([
    {
      Field: "",
      Type: "",
    },
  ]);

  useEffect(()=>{
    if(props.aDatabaseList.length > 0 && props.collectionName){
      const aFields = props.aDatabaseList.find(item => {
        return item.tableName === props.collectionName;
      });
      if(aFields.fields){
        const outputArray = Object.entries(aFields.fields).map(([field, value]) => {
          let type;
          if (Array.isArray(value)) {
            type = 'Array'
          }else if (value.hasOwnProperty('type')) {
            type = value.type;
          }else {
            type = 'Object';
          }
          return { Field: field, Type: type };
        });
        setSchemaArray(outputArray);
      }
    }
  },[props.collectionName])

  const handelMigrateData = (oEvent) => {
    setMigrateData(oEvent.target.checked);
  };

  function handleTabChange(oEvent, newValue) {
    setTabValue(newValue);
  }

  function handleIncreaseRow() {
    const updatedRows = [...aSchemaArray];
    updatedRows.push({
      Field: "",
      Type: "",
    });
    setSchemaArray(updatedRows);
    setSelectedRows([...aSelectedRows, false]);
  }

  function handleRemoveRow() {
    const updatedRows = aSchemaArray.filter(
      (_, index) => !aSelectedRows[index]
    );
    setSchemaArray(updatedRows);
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  const handleCheckboxChange = (index) => {
    const newSelectedRows = [...aSelectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  const handleEvent = (event, index, sKey) => {
    var value;
    value = event.target.value;
    const updatedRows = [...aSchemaArray];
    updatedRows[index][sKey] = value;
    setSchemaArray(updatedRows);
  };

  async function handleSubmit() {
    const oTransformedJson = aSchemaArray.reduce((result, item) => {
      const { Field, Type } = item;
      result[Field] = { type: Type };
      return result;
    }, {});
    const oPostData = {
      collectionName: props.collectionName,
      MigrateData: bMigratedata,
      NewCollectionName: sNewCollectionName,
      NewSchema: oTransformedJson,
    };
    try {
      const oResponse = await Server.post("/editor/collection", oPostData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (oResponse.status === 201) {
        setErrorMessage("Collection Deleted");
      } else {
        setErrorMessage(oResponse.data.message);
      }
      props.close();
    } catch (error) {
      setErrorMessage(error);
    }
  }

  function handleCancel() {
    setTabValue(0);
    props.close();
  }

  return (
    <div>
      <Dialog open={props.open} fullWidth={true} maxWidth="md">
        <DialogTitle>Delete Collection {props.collectionName}</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={iTabValue} onChange={handleTabChange}>
              <Tab label="Migrate Data" />
              <Tab label="Schema Configuration" />
            </Tabs>
          </Box>
          <br />
          {iTabValue === 0 && (
            <label>
              Migrate Data
              <input
                type="checkbox"
                checked={bMigratedata}
                style={{
                  width: "20px",
                  height: "20px",
                  border: "2px solid gray",
                }}
                onChange={(oEvent) => {
                  handelMigrateData(oEvent);
                }}
              />
            </label>
          )}
          {iTabValue === 1 && bMigratedata && (
            <div>
              <label>New Collection Name</label> <br />
              <input
                type="text"
                value={sNewCollectionName}
                onChange={(oEvent) => {
                  setNewCollectionName(oEvent.target.value);
                }}
              ></input>
              <div>
                <IconButton>
                  <AddIcon
                    onClick={handleIncreaseRow}
                    baseClassName="fas"
                    className="fa-plus-circle"
                    color="primary"
                  />
                </IconButton>
                {aSchemaArray.length > 1 ? (
                  <IconButton>
                    <RemoveIcon
                      onClick={handleRemoveRow}
                      baseClassName="fas"
                      className="fa-plus-circle"
                      color="secondary"
                    />
                  </IconButton>
                ) : (
                  ""
                )}
                <Box>
                  <table
                    id="idTableConfiguration"
                    className="RenderComponent-Table"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th className="RenderComponent-Table-th">Field Name</th>
                        <th className="RenderComponent-Table-th">Field Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {aSchemaArray.map((row, index) => (
                        <tr key={index}>
                          <td className="RenderComponent-Table-td">
                            <input
                              type="checkbox"
                              checked={aSelectedRows[index]}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          </td>
                          <td className="RenderComponent-Table-td">
                            <input
                              style={{ height: "90%" }}
                              className="RenderComponent-Table-input"
                              value={row.Field}
                              onChange={(event) =>
                                handleEvent(event, index, "Field")
                              }
                            />
                          </td>
                          <td className="RenderComponent-Table-td">
                            <select
                              style={{ height: "90%" }}
                              className="RenderComponent-Table-input"
                              value={row.Type}
                              onChange={(event) =>
                                handleEvent(event, index, "Type")
                              }
                            >
                              <option value="" disabled>
                                Select Type
                              </option>
                              <option value="String">String</option>
                              <option value="Number">Number</option>
                              <option value="Date">Date</option>
                              <option value="Array">Array</option>
                              <option value="Object">Object</option>
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Delete</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {sErrorMessage && (
        <Snackbar
          open={bOpenSnackBar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            onClose={() => setErrorMessage("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {sErrorMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};
