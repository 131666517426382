import React, { useState } from "react";

// Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// Dialog components
import { LegendDialog } from "./tabConfigurationDialogs/LegendDialog";
import { PercentageDialog } from "./tabConfigurationDialogs/PercentageDialog";
import { PopupDialog } from "./tabConfigurationDialogs/PopupDialog";
import { TaskDataDialog } from "./tabConfigurationDialogs/TaskDataDialog";
import { TimeChangeDialog } from "./tabConfigurationDialogs/TimeChangeDialog";

export const GanttChartDialog = (props) => {
  const [iTabValue, setTabValue] = useState(0);

  function handleTabChange(oEvent, newValue) {
    setTabValue(newValue);
  }

  function handleUpdate() {
    props.update();
  }

  function handleCancel() {
    setTabValue(0);
    props.close();
  }

  return (
    <div>
      <Dialog open={props.open} maxWidth="md" fullWidth={true}>
        <DialogTitle>Query Configuration</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={iTabValue} onChange={handleTabChange}>
              <Tab label="Task Data" />
              <Tab label="Percentage" />
              <Tab label="Legend" />
              <Tab label="Popup" />
              <Tab label="Time Change" />
            </Tabs>
          </Box>
          <br />
          {iTabValue === 0 && (
            <TaskDataDialog
              inputFields={props.inputFields}
              oData={props.oData}
              updateData={props.updateData}
            />
          )}
          {iTabValue === 1 && (
            <PercentageDialog
              inputFields={props.inputFields}
              oData={props.oData}
              updateData={props.updateData}
            />
          )}
          {iTabValue === 2 && (
            <LegendDialog
              inputFields={props.inputFields}
              oData={props.oData}
              updateData={props.updateData}
            />
          )}
          {iTabValue === 3 && (
            <PopupDialog
              inputFields={props.inputFields}
              oData={props.oData}
              updateData={props.updateData}
            />
          )}
          {iTabValue === 4 && (
            <TimeChangeDialog
              inputFields={props.inputFields}
              oData={props.oData}
              updateData={props.updateData}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={handleUpdate}>
            Update
          </Button>
          <Button type="submit" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};