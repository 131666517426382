import React, { useState } from "react";
import { CreateValidationLogic } from "../../../dialog/rightPanel/createValidationDialog/CreateValidationDialog";
//Icon
import Add_Icon from "../logicContainer/images/Add.png";
//Material ui
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
//Dialog
import { ConfirmDialog } from "../../../dialog/rightPanel/confirmDialog/ConfirmDialog";
import "./ValidationContainer.css";

function ValidationContainer(props) {
  const [open, setOpen] = useState(false);
  const [bConfirmopen, setConfirmOpen] = useState(false);
  const [sNewValidationLogicID, setNewValidationLogicID] = useState("");
  const [oSelectedValidationLogic, setSelectedValidationLogic] = useState({});
  var aValidationsLogics = props.selectLogic["validations"]; 
  var sActiveValidationLogic;

  if (props.activeValidationLogic.id) {
    sActiveValidationLogic = props.activeValidationLogic.id;
  }

  /**
   * Event handler for Logic line item click
   * @param {object} oLogic | Logic details
   */
  function handleSelectLogicClick(oLogic) {
    props.onSelectValidationLogic(oLogic);
    sActiveValidationLogic = oLogic.id;
  }

  function handleDeleteLogic(oLogic) {
    setSelectedValidationLogic({ ...oLogic });
    setConfirmOpen(true);
  }
  function handleAddValidationLogic() {
    var sUniqueValidationLogicID = generateUniqueValidationLogicId(
      aValidationsLogics.length
    );
    setNewValidationLogicID(sUniqueValidationLogicID);
    setOpen(true);
  }

  /**
   * Generate unique page ID
   * @param {integer} index
   * @returns
   */
  function generateUniqueValidationLogicId(index) {
    var isExist = false;
    var sNewValue = "";
    for (const validation in aValidationsLogics) {
      if (
        aValidationsLogics[validation].id ===
        "idvalidation" + index + props.selectLogic.id
      ) {
        isExist = true;
      }
    }
    if (isExist) {
      sNewValue = generateUniqueValidationLogicId(index + 1);
      return sNewValue;
    } else {
      sNewValue = "idvalidation" + index + props.selectLogic.id;
      return sNewValue;
    }
  }

  function handleCreateValidationLogic(validationId, sValidationLogicName) {
    var oValidationLogic = {
      label: sValidationLogicName,
      id: validationId,
      logicId: props.selectLogic.id,
      component: [
        {
          id: props.selectLogic.id,
          type: "input",
          data: { label: props.selectLogic.label, id: props.selectLogic.id },
          position: { x: 50, y: 50 },
          sourcePosition: "right",
          properties: [],
        },
      ],
      edges: [],
    };
    props.createValidationLogic(oValidationLogic);
    setOpen(false);
  }

  function handleClose() {
    setOpen(false);
  }
  /**
   * Event handler to delete confirmation
   * @param {object} oLogic | Selected logic
   */
  function handleConfirmDelete() {
    props.onDeleteValidationLogic(oSelectedValidationLogic);
    setConfirmOpen(false);
  }

  /**
   * Event handler to cancel the delete operation
   */
  function handleCancelDelete() {
    setConfirmOpen(false);
  }

  return (
    <div className="ValidationLogicContainer-div">
      <div className="ValidationLogicContainer-Title">
        <label className="ValidationLogicContainer-Properties-Label">
          Validation Logic
        </label>
      </div>
      <div className="ValidationLogicContainer-ComponentsList">
        <div className="ValidationLogicContainer-LogicDetails">
          {aValidationsLogics &&
            aValidationsLogics.length > 0 &&
            aValidationsLogics.map((oLogic) => {
              return (
                <div>
                  <Stack direction="row" spacing={1}>
                    <label
                      className="ValidationLogicControl-LogicList"
                      onClick={(oEvent) => {
                        handleSelectLogicClick(oLogic);
                      }}
                    >
                      {oLogic.id === sActiveValidationLogic ? (
                        <strong className="active-label">{oLogic.label}</strong>
                      ) : (
                        oLogic.label
                      )}
                    </label>
                    <IconButton
                      size="small"
                      onClick={(oEvent) => {
                        handleDeleteLogic(oLogic);
                      }}
                    >
                      <DeleteForeverRoundedIcon />
                    </IconButton>
                  </Stack>
                  <ConfirmDialog
                    open={bConfirmopen}
                    logicType={"validation Logic"}
                    logic={oSelectedValidationLogic}
                    confirm={handleConfirmDelete}
                    cancel={handleCancelDelete}
                  />
                </div>
              );
            })}
        </div>
        <div className="ValidationLogicContainer-AddLogic">
          <button
            className="ValidationLogicContainer-AddLogicButton"
            onClick={handleAddValidationLogic}
          >
            <img src={Add_Icon} alt="+" width="50px" height="50px" />
          </button>
          <CreateValidationLogic
            open={open}
            validationLogicId={sNewValidationLogicID}
            submit={handleCreateValidationLogic}
            close={handleClose}
          />
        </div>
      </div>
    </div>
  );
}
export default ValidationContainer;
