import React from 'react';

export default function RenderComponents(props) {

    if (!props || !props.componentModel || props.componentModel.length < 1 || !props.visible) {
        return;
    }

    var aRenderComponents = [];
    var aComponentModel = props.componentModel;

    aComponentModel.forEach(function (oComponentModel) {
        aRenderComponents.push(renderComponent(oComponentModel, aComponentModel));
    });

    /**
     * Get logic details
     * @param {array} aLogic | Logic details for the selected component
     * @param {string} sDiv | Div id
     * @returns 
     */
    function getLogicDetaild(aLogic, sDiv) {
        if (aLogic && aLogic.length > 0) {
            return sDiv + "(" + aLogic.length + ")";
        } else {
            return "No Logic";
        }

    }

    /**
     * Event handler for logic click component
     * @param {event} oEvent 
     * @param {object} oComponent | component
     */
    function handleClickLogicComponent(oEvent, oComponent) {
        props.onSelect(oComponent);
    }

    /**
     * Render component from component model
     * @param {object} oComponentModel 
     * @returns 
     */
    function renderComponent(oComponentModel) {

        //Alignment
        var sLeft = oComponentModel.left;
        var sTop = oComponentModel.top;
        var sDivWidth = oComponentModel.DivWidth;
        var sDivHeight = oComponentModel.DivHeight;
        var sId = oComponentModel.uniqueId;

        var oComponent = React.createElement(
            "button",
            {
                id: sId,
                key: sId,
                style: { width: "100%", height: "100%", border: "dotted" }
            },
            getLogicDetaild(oComponentModel.Logic, oComponentModel.Component)
        );

        //Mainlogic to reder the dropzone
        oComponent = (
            <div
                id={sId + "div"}
                className="RenderComponent-Resizeable"
                style={{ left: sLeft, top: sTop, width: sDivWidth, height: sDivHeight }}
                onClick={(oEvent) => { handleClickLogicComponent(oEvent, oComponentModel) }}>

                {oComponent}

            </div >
        )

        return oComponent;
    }

    return (
        aRenderComponents
    )
}