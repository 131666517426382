import React from "react";
import styled from "styled-components";
import { FrappeGantt } from "frappe-gantt-react";
import "frappe-gantt/dist/frappe-gantt.css";
import "./GantChart.css";
import Server from "../../../../../serverDetails/server";

export const GetDefaultGanttChart = (
  oComponentModel,
  handleChangeView,
  sViewMode,
  oProperties,
  userID,
  selectedTask,
  handleChangeTask,
  containerRef
) => {
  var sDiv = oComponentModel.div;
  var oComponent;

  /**  Function to draw Graph
   *
   * @param {*} aTasks
   * @param {*} sChartName
   * @param {*} sViewMode
   * @param {*} oTimeChange
   * @param {*} aLegend
   * @returns
   */
  function getDefaultGanttChart(
    aTasks,
    sChartName,
    sViewMode,
    oTimeChange,
    aLegend
  ) {
    const handleChange = (event) => {
      handleChangeView(event);
    };
    var sLabelName = "Label";
    if (aTasks.length > 0) {
      sLabelName = aTasks[0].Title;
    }

    let aUpdatedTasks = [];

    /** Function to Update the Date if changed
     *
     * @param {Object} task
     * @param {Date} start
     * @param {Date} end
     */
    const handleDateChange = (oTask, start, end) => {
      const dStartDate = new Date(start);
      dStartDate.setHours(dStartDate.getHours());
      const dEndDate = new Date(end);
      dEndDate.setHours(dEndDate.getHours());

      const sFormattedStart = dStartDate.toISOString();
      const sFormattedEnd = dEndDate.toISOString();

      function findKeyByValue(mapping, targetValue) {
        for (const key in mapping) {
          if (mapping[key] === targetValue) {
            return key;
          }
        }
        return null;
      }

      if (oTimeChange && oTimeChange.Keys) {
        var oUpdatedTask;
        if (oTimeChange.Keys.includes(",")) {
          const akeysArray = oTimeChange.Keys.split(",").map((key) =>
            key.trim()
          );
          oUpdatedTask = akeysArray.reduce(
            (acc, key) => {
              var skey = findKeyByValue(oProperties.TaskData, key);
              if (skey === "name") {
                acc[key] = oTask.nameValue;
              } else {
                acc[key] = oTask[skey];
              }
              return acc;
            },
            {
              [oTimeChange.start]: sFormattedStart,
              [oTimeChange.end]: sFormattedEnd,
            }
          );
        } else {
          var skey = findKeyByValue(
            oProperties.TaskData,
            oTimeChange.Keys.trim()
          );
          if (skey === "name") {
            skey = "nameValue";
          }
          oUpdatedTask = {
            [oTimeChange.Keys.trim()]: oTask[skey],
            [oTimeChange.start]: sFormattedStart,
            [oTimeChange.end]: sFormattedEnd,
          };
        }

        const iExistingIndex = aUpdatedTasks.findIndex(
          (t) => t.TaskId === oTask.id
        );

        if (iExistingIndex !== -1) {
          if(oTimeChange.ItemKeys){
            var aItemKeys = oTimeChange.ItemKeys.split(',').map(item => item.split('.').pop());
            aItemKeys.forEach(function(sKeys){
              oUpdatedTask[sKeys] = oTask[sKeys];
            })
          }
          aUpdatedTasks[iExistingIndex] = oUpdatedTask;
        } else {
          if(oTimeChange.ItemKeys){
            var aItemKeys = oTimeChange.ItemKeys.split(',').map(item => item.split('.').pop());
            aItemKeys.forEach(function(sKeys){
              oUpdatedTask[sKeys] = oTask[sKeys];
            })
          }
          aUpdatedTasks.push(oUpdatedTask);
        }
      }
    };

    /** Function for click each task
     *
     * @param {Object} task
     * @param {*} event
     */
    const handleTaskClick = (oTask, event) => {
      handleChangeTask(oTask);
      event.preventDefault();
    };

    // Function to Update the Data to DB
    function handleUpdate() {
      if (oTimeChange && oTimeChange.TableName) {
        const keysArray = oTimeChange.Keys.includes(",")
          ? oTimeChange.Keys.split(",").map((key) => key.trim())
          : [oTimeChange.Keys.trim()];

        Server.get(
          `/tablefields/${oTimeChange.TableName}`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        ).then(function (response) {
          if (response.data.fields) {
            const oData = response.data.fields;
            var aItemKeys = [];
            var aItems = [];
            if (oTimeChange.ItemKeys) {
              aItemKeys = oTimeChange.ItemKeys.split(",").map((item)=>item.trim());
              aItems = oTimeChange.Items.split(",").map((item)=>item.trim());
              aItems = aItemKeys.concat(aItems);
            }
            if (oData) {
              var oPostData = {
                TABLE_NAME: oTimeChange.TableName,
                DATA: aUpdatedTasks,
                PROPERTIES: { key: keysArray, autoGenerate: "", items:aItems, ItemKey:aItemKeys},
                Schema: oData,
                Update: true,
              };
              Server.post("/logics/db/insert", oPostData, {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }).then(function (oResult) {
                alert("Data Updated Successfully");
              });
            }
          }
        });
      }
    }

    const EmptyState = styled.div`
      background: #f7f7f7;
      color: rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;

      h1,
      h2 {
        margin: 5px;
      }
    `;

    /** Function to make the customised Popup
     *
     * @param {Object} oTask
     * @returns
     */
    const customPopupHtml = (oTask) => {
      if (containerRef.current) {
        const X = containerRef.current._gantt.bars[oTask._index].x / 2 - 100;
        const Y = containerRef.current._gantt.bars[oTask._index].y + 30;
        return (
          <div
            className="gantt-custom-popup"
            style={{
              top: `${Y}px`,
              left: `${X}px`,
            }}
          >
            <div className="gantt-custom-popup-content">
              <button
                className="gantt-close-button"
                onClick={() => handleChangeTask(null)}
              >
                x
              </button>
              {Object.keys(oTask.Popup).map((key) => (
                <p key={key}>
                  {key} : <span>{oTask.Popup[key]}</span>
                </p>
              ))}
            </div>
          </div>
        );
      }
    };

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <select onChange={handleChange} className="gantt-view-mode">
              <option value="Day">Day</option>
              <option value="Week">Week</option>
              <option value="Month">Month</option>
              <option value="Quarter Day">Quarter Day</option>
              <option value="Half Day">Half Day</option>
            </select>
          </div>
          <div className="gantt-legend-container">
            {aLegend.map((legend, index) => (
              <div key={index} className="gantt-legend-item">
                <div className={`gantt-legend-dot ${legend.custom_class}`} />
                <div className="gantt-legend-text">{legend.FieldValue}</div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="gantt-bar">
          {aTasks.length > 0 ? (
            <div className="d-flex">
              <div className="d-flex gantt-top-bar">
                <div className="gantt-label-head">{sLabelName}</div>
                {aTasks.map((task) => (
                  <div key={task.id} className="gantt-label-body">
                    {task.nameValue}
                  </div>
                ))}
              </div>
              <FrappeGantt
                className="ganttChart"
                ref={containerRef}
                key={sChartName}
                tasks={aTasks}
                viewMode={sViewMode}
                onClick={handleTaskClick}
                onDateChange={handleDateChange}
                onProgressChange={(task, progress) =>
                  console.log(task, progress)
                }
                onTasksChange={(tasks) => console.log(tasks)}
              />
              <div>{selectedTask && <>{customPopupHtml(selectedTask)}</>}</div>
            </div>
          ) : (
            <EmptyState>
              <h1>No data available</h1>
              <h3>Generate some tasks</h3>
            </EmptyState>
          )}
        </div>
        {oTimeChange && oTimeChange.EnableUpdate && (
          <div
            className="d-flex"
            style={{
              justifyContent: "end",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <button className="gantt-btn" onClick={handleUpdate}>
              Update
            </button>
          </div>
        )}
      </div>
    );
  }

  if (sDiv === "Gantt") {
    var sChartName = oComponentModel.properties.find(
      (oProperty) => oProperty.id === "idGanttChartName"
    ).value;
    var aLegend = [
      {
        FieldValue: "Legend1",
        custom_class: "Blue",
      },
      {
        FieldValue: "Legend2",
        custom_class: "Red",
      },
    ];
    var oTimeChange;
    if (oProperties) {
      if (Object.keys(oProperties).length > 0) {
        oTimeChange = oProperties.TimeChange;
        aLegend = oProperties.Legend.Values;
      }
    }
    var aTasks = [];
    if (oComponentModel.Data.length > 0) {
      aTasks = oComponentModel.Data;
    }
    oComponent = getDefaultGanttChart(
      aTasks,
      sChartName,
      sViewMode,
      oTimeChange,
      aLegend
    );
    return oComponent;
  } else {
    return null;
  }
};
