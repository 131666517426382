import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MonacoEditor from "react-monaco-editor";
import * as monaco from "monaco-editor";

export const LogicWriterDialog = (props) => {
  const [fCode, setfcode] = useState();

  useEffect(() => {
    if(props.oMethods){
        setfcode(props.oMethods.Logic);
    }
  }, [props.oMethods]);

  const generateFunctionCode = () => {
    const inputParameters = Object.keys(props.oMethods.Parameter).join(", ");

    const code = `function DefaultExecuteLogic(mongoose,CID,${inputParameters}) {
        // Dont Change the Return Statement
        return {
         Message:"Your Reponse Message(if want)",
         Data:["Your Desired Response as array of objects"]
        };
    }`;
    return code;
  };

  if (props.oMethods && props.oMethods.Parameter && props.oMethods.Logic === "") {
    const code = generateFunctionCode();
    if (fCode !== code) {
      setfcode(code);
    }
  }

  const options = {
    selectOnLineNumbers: true,
    fontSize: 16,
    validationOptions: {
      lint: {
        enable: true,
      },
      validate: true,
      schemas: [
        {
          uri: "https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-configure-json-defaults",
          fileMatch: ["*"],
        },
      ],
    },
  };

  const handleCodeChange = (newCode) => {
    setfcode(newCode);
  };

  function handelUpdate() {
    props.update(fCode);
  }

  return (
    <div>
      <Dialog open={props.open} maxWidth="lg" fullWidth={true}>
        <DialogTitle>
          <div style={{ width: "100%" }}>Code Editor</div>
        </DialogTitle>
        <DialogContent>
          <div style={{width:"100%",height:"100%"}}>
            <div style={{ width: "100%" }}>
              <MonacoEditor
                width="95%"
                height="600"
                language="javascript"
                theme="vs-dark"
                value={fCode}
                options={options}
                onChange={handleCodeChange}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handelUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
