import React, { useState } from 'react';
import "./LogicContainer.css"

//Icon
import Add_Icon from "./images/Add.png"

//Material ui
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

//Dialog
import { ConfirmDialog } from '../../../dialog/rightPanel/confirmDialog/ConfirmDialog';


function LogicContainer(props) {

    const [open, setOpen] = useState(false);
    const [oSelectedLogic, setSelectedLogic] = useState({});
    var aLogic = props.selectedComponent["Logic"];

    /**
     * Event handler for Logic line item click
     * @param {object} oLogic | Logic details
     */
    function handleLogicClick(oLogic) {
        props.onSelectLogic(oLogic);
    }

    /**
     * Event handler for Add Logic Button click
     */
    function handleAddLogic() {
        props.addLogic();
    }

    /**
     * Event handler for delete Logic lineitem click
     * @param {object} oLogic | Logic details
     */
    function handleDeleteLogic(oLogic) {
        setSelectedLogic({ ...oLogic });
        setOpen(true);
    }

    /**
     * Event handler to delete confirmation
     * @param {object} oLogic | Selected logic
     */
    function handleConfirmDelete() {
        props.onDeleteLogic(oSelectedLogic);
        setOpen(false);
    }

    /**
     * Event handler to cancel the delete operation
     */
    function handleCancelDelete() {
        setOpen(false);
    }

    return (
        <div className="LogicContainer-div">
            {props.sSelectedMenuItem === 'form' ? (<></>) : (<>
            <div className="LogicContainer-Title">
                <label className="LogicContainer-Properties-Label">
                    Component Logic
                </label>
            </div>
            <div className="LogicContainer-ComponentsList">
                <div className="LogicContainer-LogicDetails">
                    {aLogic && aLogic.length > 0 &&
                        aLogic.map(oLogic => {
                            return (
                                <div>
                                    <Stack
                                        direction="row"
                                        spacing={1}>
                                        <label
                                            className='LogicControl-LogicList'
                                            onClick={(oEvent) => { handleLogicClick(oLogic) }} >
                                            {oLogic.label}
                                        </label>
                                        <IconButton
                                            size="small"
                                            style={{ color: 'white' }}
                                            onClick={(oEvent) => { handleDeleteLogic(oLogic) }}>
                                            <DeleteForeverRoundedIcon />
                                        </IconButton>
                                    </Stack>
                                    <ConfirmDialog
                                        open={open}
                                        logicType={"Logic"}
                                        logic={oSelectedLogic}
                                        confirm={handleConfirmDelete}
                                        cancel={handleCancelDelete} />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="LogicContainer-AddLogic">
                    <button
                        className="LogicContainer-AddLogicButton"
                        onClick={handleAddLogic}>
                        <img src={Add_Icon} alt="+" width="50px" height="50px" />
                    </button>
                </div>
            </div>
            </>)}
        </div>
    )
}

export default LogicContainer;