var original_width = 0;
var original_height = 0;
var original_x = 0;
var original_y = 0;
var original_mouse_x = 0;
var original_mouse_y = 0;
const minimum_size_width = 40;
const minimum_size_height = 15;
var oResizeElement;
var oGlobalComponentModel;
var fnCallback;

/**
 * Create Resize divs and drag logic
 * @param {node} oElement 
 * @param {JSONModel} oComponentModel 
 * @param {function} fnUpdate | callback function to update the server
 */
export function createResizeDivs(oElement, oComponentModel, fnUpdate) {
    var aResizers = document.getElementsByClassName("RenderComponent-Resizer");
    var aChildNodes = oElement.childNodes;
    oGlobalComponentModel = oComponentModel;
    fnCallback = fnUpdate;

    for (let index = 0; index < aResizers.length; index++) {
        aResizers[index].style.display = "none";
    }

    var iDivLength = 0;
    aChildNodes.forEach(function (oNode) {
        if (oNode.tagName === "DIV" && !oNode.id.includes("idTabs") && iDivLength <= 3) {
            oNode.style.display = "";
            iDivLength++;
            //TODO: tobe refactored
            oNode.addEventListener("mousedown", function (e) {
                e.preventDefault();
                oResizeElement = e.currentTarget;
                original_width = parseFloat(
                    getComputedStyle(oElement, null)
                        .getPropertyValue("width")
                        .replace("px", "")
                );
                original_height = parseFloat(
                    getComputedStyle(oElement, null)
                        .getPropertyValue("height")
                        .replace("px", "")
                );
                original_x = oElement.getBoundingClientRect().left;
                original_y = oElement.getBoundingClientRect().top;
                original_mouse_x = e.pageX;
                original_mouse_y = e.pageY;
                window.addEventListener("mousemove", mouseMoveEvent);
                window.addEventListener("mouseup", resizeEvent);
            });
        }
    })
}

/**
 * function to stop resize
 */
function resizeEvent() {
    if (!oResizeElement.id.includes("Tabs")) {
        stopResize(oResizeElement)
    }
}


/**
* Function to resize the ui component
* @param {object} oEvent | mousedownEvent
* @param {object} oElement | resized element
*/
function resize(oEvent, oElement) {
    switch (oElement.id) {
        case "idResizeTopLeft":
            resizeTopLeft(oEvent, oElement);
            break;
        case "idResizeTopRight":
            resizeTopRight(oEvent, oElement);
            break;
        case "idResizeBottomLeft":
            resizeBottomLeft(oEvent, oElement);
            break;
        case "idResizeBottomRight":
            resizeBottomRight(oEvent, oElement);

    }
}

/**
 * Resize top left position
 * @param {object} oEvent | mousedown event
 * @param {object} oElement | adjusted element
 */
function resizeTopLeft(oEvent, oElement) {
    var oElement = oElement.parentElement;
    const width = original_width - (oEvent.pageX - original_mouse_x);
    const height = original_height - (oEvent.pageY - original_mouse_y);

    if (width > minimum_size_width) {
        oElement.style.width = width + "px";
        oElement.style.left = original_x + (oEvent.pageX - original_mouse_x) + "px";
    }

    if (height > minimum_size_height) {
        oElement.style.height = height + "px";
        oElement.style.top = original_y + (oEvent.pageY - original_mouse_y) + "px";
    }
}

/**
 * Resize top right position
 * @param {object} oEvent | mousedown event
 * @param {object} oElement | adjusted element
 */
function resizeTopRight(oEvent, oElement) {
    var oElement = oElement.parentElement;
    const width = original_width + (oEvent.pageX - original_mouse_x);
    const height = original_height - (oEvent.pageY - original_mouse_y);

    if (width > minimum_size_width) {
        oElement.style.width = width + "px";
    }

    if (height > minimum_size_height) {
        oElement.style.height = height + "px";
        oElement.style.top = original_y + (oEvent.pageY - original_mouse_y) + "px";
    }
}

/**
 * Resize bottom left position
 * @param {object} oEvent | mousedown event
 * @param {object} oElement | adjusted element
 */
function resizeBottomLeft(oEvent, oElement) {
    var oElement = oElement.parentElement;
    const height = original_height + (oEvent.pageY - original_mouse_y);
    const width = original_width - (oEvent.pageX - original_mouse_x);

    if (height > minimum_size_height) {
        oElement.style.height = height + "px";
    }

    if (width > minimum_size_width) {
        oElement.style.width = width + "px";
        oElement.style.left = original_x + (oEvent.pageX - original_mouse_x) + "px";
        oElement.style.pleft =(original_x + (oEvent.pageX - original_mouse_x))-600 + "px";
    }
}

/**
 * Resize bottom right position
 * @param {object} oEvent | mousedown event
 * @param {object} oElement | adjusted element
 */
function resizeBottomRight(oEvent, oElement) {
    var oElement = oElement.parentElement;
    const width = original_width + (oEvent.pageX - original_mouse_x);
    const height = original_height + (oEvent.pageY - original_mouse_y);

    if (width > minimum_size_width) {
        oElement.style.width = width + "px";
    }

    if (height > minimum_size_height) {
        oElement.style.height = height + "px";
    }
}

/**
* Event mouse move
* @param {object} oWindowEvent | Window event
*/
function mouseMoveEvent(oWindowEvent) {
    resize(oWindowEvent, oResizeElement)
}

/**
 * Mouse up event to stop the resize
 * @param {object} oElement | Adjusted Element
 */
function stopResize(oElement, callbackFnStopResize) {
    oGlobalComponentModel.DivHeight = parseFloat(oResizeElement.parentElement.style.height.slice(0, -2));
    oGlobalComponentModel.DivWidth = parseFloat(oResizeElement.parentElement.style.width.slice(0, -2));
    oGlobalComponentModel.left = parseFloat(oResizeElement.parentElement.style.left.slice(0, -2));
    oGlobalComponentModel.top = parseFloat(oResizeElement.parentElement.style.top.slice(0, -2));
    window.removeEventListener("mousemove", mouseMoveEvent);
    fnCallback(oGlobalComponentModel);
    window.removeEventListener("mouseup", resizeEvent);
}
