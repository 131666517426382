import React, { useState } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

export const PopupDialog = (props) => {
  const [aSelectedRows, setSelectedRows] = useState([]);

  /** CheckBox Handle function to delete the selected rows
   * 
   * @param {*} index 
   */
  const handleCheckboxChange = (index) => {
    const aNewSelectedRows = [...aSelectedRows];
    aNewSelectedRows[index] = !aNewSelectedRows[index];
    setSelectedRows(aNewSelectedRows);
  };

  /** Update the Input data
   * 
   * @param {*} event 
   * @param {*} index 
   * @param {*} skey 
   */
  const handleChangeEvent = (event, index, skey) => {
    const { value } = event.target;
    const oUpdatedRows = [...props.oData.Popup];
    oUpdatedRows[index][skey] = value;
    props.updateData({ ...props.oData, Popup: oUpdatedRows });
  };

  /** Increase No Rows in Table when Click + Icon
   * 
   */
  function handleIncreaseRow() {
    const oUpdatedRows = [...props.oData.Popup];
    oUpdatedRows.push({
      FieldName: "",
      Value: "",
      Type:"",
      Format:""
    });
    props.updateData({ ...props.oData, Popup: oUpdatedRows });
    setSelectedRows([...aSelectedRows, false]);
  }

  /**Decrease no of rows when click - icon
   * 
   */
  function handleRemoveRow() {
    const oUpdatedRows = props.oData.Popup.filter(
      (_, index) => !aSelectedRows[index]
    );
    props.updateData({ ...props.oData, Popup: oUpdatedRows });
    setSelectedRows(aSelectedRows.filter((_, index) => !aSelectedRows[index]));
  }

  const aDateFormatType = ['DD/MM/YYYY','DD/MON/YYYY','MON/DD/YYYY','MON DD,YYYY'];
  const aTimeFormatType = ['12 HRS','24 HRS'];
  const aStringFormatType = ['String'];

  const oFormatOptions = {
    Date: aDateFormatType,
    Time: aTimeFormatType,
    String: aStringFormatType
  };

  return (
    <div>
      <IconButton>
        <AddIcon
          onClick={handleIncreaseRow}
          baseClassName="fas"
          className="fa-plus-circle"
          color="primary"
        />
      </IconButton>
      {props.oData.Popup.length > 1 ? (
        <IconButton>
          <RemoveIcon
            onClick={handleRemoveRow}
            baseClassName="fas"
            className="fa-plus-circle"
            color="secondary"
          />
        </IconButton>
      ) : (
        ""
      )}
      <Box>
        <table id="idTableConfiguration" className="RenderComponent-Table">
          <thead>
            <tr>
              <th></th>
              <th className="RenderComponent-Table-th">FieldName</th>
              <th className="RenderComponent-Table-th">Value</th>
              <th className="RenderComponent-Table-th">Type</th>
              <th className="RenderComponent-Table-th">Format</th>
            </tr>
          </thead>
          <tbody>
            {props.oData.Popup.map((row, index) => (
              <tr key={index}>
                <td className="RenderComponent-Table-td">
                  <input
                    type="checkbox"
                    checked={aSelectedRows[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <input
                    name={index}
                    className="RenderComponent-Table-input"
                    value={row.FieldName}
                    onChange={(oEvent) => {
                      handleChangeEvent(oEvent, index, "FieldName");
                    }}
                  />
                </td>
                <td className="RenderComponent-Table-td">
                  <select
                    className="RenderComponent-Table-input"
                    value={row.Value}
                    onChange={(event) => {
                      handleChangeEvent(event, index, "Value");
                    }}
                  >
                    <option key="" value="" disabled>
                      Select Fields
                    </option>
                    {props.inputFields.map((oField) => (
                      <option key={oField} value={oField}>
                        {oField}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="RenderComponent-Table-td">
                  <select
                    className="RenderComponent-Table-input"
                    value={row.Type}
                    onChange={(event) => {
                      handleChangeEvent(event, index, "Type");
                    }}
                  >
                    <option key="" value="" disabled>
                      Select Fields
                    </option>
                    <option key="String" value="String">
                      String
                    </option>
                    <option key="Date" value="Date">
                      Date
                    </option>
                    <option key="Time" value="Time">
                      Time
                    </option>
                  </select>
                </td>
                <td className="RenderComponent-Table-td">
                  <select
                    className="RenderComponent-Table-input"
                    value={row.Format}
                    onChange={(event) => {
                      handleChangeEvent(event, index, "Format");
                    }}
                  >
                    <option key="" value="" disabled>Select Format</option>
                    {oFormatOptions[row.Type]?.map((element) => (
                      <option key={element} value={element}>
                        {element}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </div>
  );
};
