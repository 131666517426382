import React from "react";

//Dialog Imports
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const CreateFormDialog = (props) => {
    var sFormName = "";
    /**
     * Event handler for event change
     * @param {event} oEvent | Event
     */
    function handleChangeEvent(oEvent) {
        sFormName = oEvent.target.value
    }

    /**
     * Submit handler
     */
    function handleSubmit() {
        props.submit(props.formId, sFormName);
    }

    /**
     * Close dialog
     */
    function handleClose() {
        props.close()
    }

    return (
        <div>
            <Dialog
                open={props.open}>
                <DialogTitle>
                    Create New Form
                </DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        margin="dense"
                        id="idFormname"
                        label="Form Name"
                        fullWidth
                        variant="standard"
                        sx={{ minWidth: 450 }}
                        onChange={handleChangeEvent}
                    />
                    <br></br>
                    <br></br>
                    <TextField
                        required
                        margin="dense"
                        id="idFormid"
                        label="Form id"
                        fullWidth
                        disabled
                        variant="standard"
                        sx={{ maxWidth: 500 }}
                        value={props.formId}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        onClick={handleSubmit}>
                        Create
                    </Button>
                    <Button
                        onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}