import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions,Button } from "@mui/material";

export const ExecuteConfirmation = (props) => {

    const handleConfirm = (bValue) =>{
        props.close(bValue);
    }
  return (
    <Dialog open={props.open}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <p>{props.message}</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleConfirm(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        {props.confirm ? (
          <Button
            onClick={() => {
              handleConfirm(true);
            }}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
