import React, { useState } from "react";

// Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// Dialog components
import { CollectionDialog } from "./collections/CollectionDialog";
import { JoinConditionDialog } from "./joinConditions/JoinConditionDialog";
import { WhereConditionDialog } from "./whereConditions/WhereConditionDialog";
import { ArrayWhereConditionDialog } from "./whereConditions/ArrayWhereConditionDialog";

export const QueryConfigurationDialog = (props) => {
  const [iTabValue, setTabValue] = useState(0);
  const [bcollection, setcollection] = useState(true);
  const [bjoin, setjoin] = useState(false);
  const [bwhere, setwhere] = useState(false);
  const [bArrayWhere, setArrayWhere] = useState(false);
  const [selectedArrayFields , setselectedArrayFields] = useState({});
  const [selectedNonArrayFields, setSelectedNonArrayFields] = useState([]);

  function handleTabChange(oEvent, newValue) {
    setTabValue(newValue);
    if (newValue === 0) {
      setcollection(true);
      setjoin(false);
      setwhere(false);
      setArrayWhere(false)
    } else if (newValue === 1) {
      setcollection(false);
      setjoin(true);
      setwhere(false);
      setArrayWhere(false)
    } else if (newValue === 2){
      setcollection(false);
      setjoin(false);
      setwhere(true);
      setArrayWhere(false)
    } else {
      setArrayWhere(true)
      setcollection(false);
      setjoin(false);
      setwhere(false);
    }
  }

  function handleUpdate() {
    props.update(selectedArrayFields,selectedNonArrayFields);
  }

  function handleCancel() {
    setTabValue(0);
    setcollection(true);
    setjoin(false);
    setwhere(false);
    setArrayWhere(false)
    props.close();
  }

  return (
    <div>
      <Dialog open={props.open} maxWidth="md" fullWidth={true}>
        <DialogTitle>Query Configuration</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={iTabValue} onChange={handleTabChange}>
              <Tab label="Collections" />
              <Tab label="Join Conditions" />
              <Tab label="Where Conditions" />
              <Tab label="Array Where Condition" />
            </Tabs>
          </Box>
          <br />
          {bcollection && (
            <CollectionDialog
              rows={props.rows}
              update={props.updaterow}
              arrayFields={selectedArrayFields}
              updateArrayFields={setselectedArrayFields}
              updateNonArrayFields={setSelectedNonArrayFields}
              CID={props.CID}
              admin={props.admin}
            />
          )}
          {bjoin && (
            <JoinConditionDialog
              rows={props.rows}
              update={props.updaterow}
              CID={props.CID}
            />
          )}
          {bwhere && (
            <WhereConditionDialog
              rows={props.rows}
              update={props.updaterow}
              CID={props.CID}
              inputs={props.inputs}
            />
          )}
          {bArrayWhere && (
            <ArrayWhereConditionDialog
              rows={props.rows}
              update={props.updaterow}
              inputs={props.inputs}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={handleUpdate}>
            Update
          </Button>
          <Button type="submit" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
